import React from 'react'

import { Box, Field, Name, Text } from './FieldUtils'
import Specification from './Specification'
import ReceiptHeader from './ReceiptHeader'
import SummaryTable from './SummaryTable'
import { getValuesFromData } from '../helpers/getValuesFromData'

const BlankContent = ({
  hotelName,
  personData,
  reservation,
  calculatedItems,
  breakfasts
}) => {
  const values = getValuesFromData({ reservation, calculatedItems, breakfasts })

  return (
    <React.Fragment>
      <Specification />
      <Field>
        <Name>Исполнитель</Name>
        <Text
          stretch
          caption="(наименование и организационно-правовая форма — для организаций"
        >
          {hotelName}
        </Text>
      </Field>
      <Field>
        <Text
          stretch
          caption="фамилия, имя, отчество — для индивидуального предпринимателя)"
        >
          {personData.name}
        </Text>
      </Field>
      <Field>
        <Text
          stretch
          caption="(место нахождения постоянно действующего исполнительного органа юр. лица, иного органа или лица, имеющего право"
        >
          {personData.address}
        </Text>
      </Field>
      <Field>
        <Text
          width={57}
          caption="действовать от имени юр. лица без доверенности)"
        />
        <Name style={{ marginLeft: 'auto' }}>ИНН</Name>
        <Text width={25}>{personData.inn}</Text>
      </Field>

      <ReceiptHeader />

      <Field>
        <Name>Ф.И.О.</Name>
        <Text width={55}>{values.guestName}</Text>
        <Name>Индекс поселения</Name>
        <Text stretch />
      </Field>
      <Field>
        <Name>№ комнаты</Name>
        <Text width={10}>{values.roomID}</Text>
        <Name>№ корпуса</Name>
        <Text width={10} />
        <Name>Заезд</Name>
        <Text stretch caption="(дата, часы)">
          {values.formattedCheckIn}
        </Text>
      </Field>

      <SummaryTable values={values} />

      <Field>
        <Name>Полная стоимость услуги</Name>
        <Text stretch notLast caption="(сумма прописью)">
          {values.totalPriceIntString}
        </Text>
        <Name>руб.</Name>
        <Text width={10}>{values.totalPriceFract}</Text>
        <Name>коп.</Name>
      </Field>
      <Field>
        <Name>Со ст. 779-783 ГК РФ ознакомлен</Name>
        <Text width={20} caption="(заказчик, подпись)" />
      </Field>
      <Field>
        <Name>Лицо ответственное за оформление заказа</Name>
        <Text stretch caption="(должность, Ф.И.О., подпись)" />
      </Field>
      <Field>
        <Name bold>Оплачено:</Name>
        <Name>
          наличными денежными средствами или использованием платежных карт
        </Name>
      </Field>
      <Field>
        <Text width={10}>{values.totalPriceInt}</Text>
        <Name>руб.</Name>
        <Text width={10}>{values.totalPriceFract}</Text>
        <Name>коп.</Name>
        <Text stretch notLast caption="(сумма прописью)">
          {values.totalPriceIntString}
        </Text>
        <Name>руб.</Name>
        <Text width={10}>{values.totalPriceFract}</Text>
        <Name>коп.</Name>
      </Field>
      <Field>
        <Name>Заказчик</Name>
        <Text width={35} caption="(подпись, дата)" />
      </Field>
      <Box>
        <Field style={{ paddingRight: '2.5cm' }}>
          <Name>Получено лицом ответственным за совершение операции</Name>
          <Name>и правильности ее оформления</Name>
          <Text
            stretch
            caption="(дата, должность исполнителя, Ф.И.О., подпись)"
          />
        </Field>
        <Field>
          <Name>Дата составления</Name>
          <Name>документа</Name>
          <Text stretch caption=" ">
            {values.paymentDate}
          </Text>
        </Field>
      </Box>
      <Field>
        <Name>Услуга получена</Name>
        <Text width={40} caption="(клиент, подпись, дата)" />
        <Name style={{ marginLeft: '2.5cm' }}>М.П.</Name>
      </Field>
    </React.Fragment>
  )
}

export default BlankContent
