import React from 'react'
import { EditSection } from '../../common/EditSection'
import { Content } from './components/Content'
import { Footer } from './components/Footer'

export const Services = ({
  items,
  availableDates,
  availableServices,
  validationErrors,
  actions,
  availableActions = {
    create: true,
    update: true,
    copy: true,
    delete: true,
    refund: false,
  },
}) => {
  const content = items.length > 0 && (
    <Content
      items={items}
      availableDates={availableDates}
      actions={actions}
      validationErrors={validationErrors}
      availableActions={availableActions}
    />
  )

  const footer = availableActions.create && (
    <Footer
      availableDates={availableDates}
      availableServices={availableServices}
      actions={actions}
    />
  )

  return <EditSection name="Услуги" content={content} footer={footer} />
}
