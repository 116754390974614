import React, { useEffect } from 'react'
import { useActions } from '../common/hooks/use-actions'
import { blacklistActions, blacklistSelectors } from './reducers/blacklist'
import { useSelector } from 'react-redux'
import { BlacklistView } from './BlacklistView'

export const Blacklist = () => {
  const items = useSelector(blacklistSelectors.items)
  const loadingState = useSelector(blacklistSelectors.loadingState)
  const { load, reset } = useActions(blacklistActions)

  useEffect(() => {
    load()

    return () => reset()
  }, [load, reset])

  return <BlacklistView items={items} loadingState={loadingState} />
}
