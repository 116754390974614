import React, { Component } from 'react'
import { DefaultTemplate } from '../../ui/templates'
import { ThemeProvider } from '../../ui/theme'
import Styles from '../../styles'
import { SelectionForm } from './components/SelectionForm'
import { ChecksTable } from './components/ChecksTable'
import { RefundsTable } from './components/RefundsTable'
import Note from './components/Note'
import Results from './components/Results'
import LeftSide from './components/LeftSide'
import RightSide from './components/RightSide'
import { ChecksStats } from './components/ChecksStats'
import Status from './components/Status'
import moment from 'moment'
import PrintButton from './components/PrintButton'

export class MainPage extends Component {
  componentDidMount() {
    const { lcode } = this.props
    const { checksActions, refundsActions } = this.props

    const date = moment().format('YYYY-MM-DD')

    checksActions.getChecks({ lcode, date })
    refundsActions.getRefunds({ lcode, date })
  }

  renderResults = () => {
    const { checks, checksStats, refunds } = this.props

    const placeholderCondition =
      !checks.isLoaded &&
      !checks.isFailed &&
      !refunds.isLoaded &&
      !refunds.isFailed

    const loadingCondition = checks.isLoading || refunds.isLoading

    if (loadingCondition) {
      return <Status>Загрузка...</Status>
    }

    if (placeholderCondition) {
      return <Status>Используйте форму выше для получения данных</Status>
    }

    const isButtonDisabled =
      checks.isFailed || refunds.isFailed || checksStats.isFailed

    return (
      <React.Fragment>
        <PrintButton
          data={{ checks, checksStats, refunds }}
          disabled={isButtonDisabled}
        />
        <Note color="pageText">
          Чтобы показать подробную информацию по чеку, нажмите на нужную строку
          таблицы
        </Note>
        <Results>
          <LeftSide>
            <ChecksTable checks={checks} checksStats={checksStats} />
            <RefundsTable refunds={refunds} />
          </LeftSide>
          <RightSide>
            <ChecksStats checks={checks} checksStats={checksStats} />
          </RightSide>
        </Results>
      </React.Fragment>
    )
  }

  render() {
    const { lcode } = this.props
    const { checksActions, refundsActions } = this.props

    return (
      <React.Fragment>
        <Styles />
        <ThemeProvider>
          <DefaultTemplate>
            <SelectionForm
              lcode={lcode}
              checksActions={checksActions}
              refundsActions={refundsActions}
            />
            {this.renderResults()}
          </DefaultTemplate>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}
