import React from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import TimeField from 'react-simple-timefield'

export const TimeInput = ({
  label,
  placeholder = 'Введите время',
  value,
  onChange = () => {},
  disabled = false,
  hasError = false,
  size = 'small',
  noMargin = false,
  showSeconds = false,
}) => {
  return (
    <FormGroup
      validationState={hasError ? 'error' : null}
      style={{ marginBottom: noMargin && 0 }}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <TimeField
        input={
          <FormControl
            placeholder={placeholder}
            disabled={disabled}
            componentClass="input"
            bsSize={size}
          />
        }
        value={value}
        onChange={onChange}
        showSeconds={showSeconds}
      />
    </FormGroup>
  )
}
