import React from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import axios from 'axios'
import { downloadFile } from '../../Modules/helpers/downloadFile'

const ContentBlock = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
`

const TableGroup = styled.div`
  display: flex;
  flex-direction: row;
`

const TableBlock = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 16px;
  margin-right: 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const TableName = styled.h2`
  margin: 0 0 32px;
  color: #505050;
  font-size: 24px;
  text-align: center;
`

const DownloadButton = styled.button`
  padding: none;
  border: none;
  border-bottom: 1px dashed transparent;
  color: #2b62ff;
  background-color: transparent;
  outline: none;

  &:hover {
    border-bottom-color: #2b62ff;
  }
`

const getFilename = url => {
  const parts = url.split('/')
  const fileName = parts[parts.length - 1]
  return decodeURIComponent(fileName)
}

const FilesTable = ({ name, items }) => {
  const rows = items.map(item => {
    const { pk, file, description, timestamp } = item

    const filename = getFilename(file)

    const onDownloadClick = () =>
      axios({
        url: file,
        method: 'get',
        responseType: 'blob',
      })
        .then(res => res.data)
        .then(blob => {
          downloadFile(blob, filename)
        })

    return (
      <tr key={pk}>
        <td>{description}</td>
        <td>{timestamp}</td>
        <td>
          <DownloadButton onClick={onDownloadClick}>Скачать</DownloadButton>
        </td>
      </tr>
    )
  })

  return (
    <TableBlock>
      <TableName>{name}</TableName>
      <Table
        responsive
        className="table-hover condensed"
        style={{ width: '100%', marginBottom: 0 }}
      >
        <thead>
          <tr>
            <th>Описание</th>
            <th>Дата загрузки</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </TableBlock>
  )
}

const Content = ({ sharedFiles, privateFiles, loadingState }) => {
  const { isLoading, isLoaded, isFailed } = loadingState

  if (isLoading) {
    return <div>Загрузка..</div>
  }

  if (isFailed) {
    return <div>Произошла ошибка при загрузке данных</div>
  }

  if (!isLoaded) {
    return <div>Нет данных</div>
  }

  return (
    <TableGroup>
      <FilesTable name="Файлы для данного отеля" items={privateFiles} />
      <FilesTable name="Общие файлы" items={sharedFiles} />
    </TableGroup>
  )
}

export const FilesView = ({ sharedFiles, privateFiles, loadingState }) => {
  return (
    <ContentBlock>
      <Content
        sharedFiles={sharedFiles}
        privateFiles={privateFiles}
        loadingState={loadingState}
      />
    </ContentBlock>
  )
}
