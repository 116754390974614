import React from 'react'
import styled from 'styled-components'
import getReceiptNumber from '../helpers/getReceiptNumber'

const ReceiptHeaderTitle = styled.h1`
  margin: 0;
  font-size: 18pt;
  line-height: 1.4;
  font-weight: bold;
`

const ReceiptHeaderNumber = styled.span`
  color: #555;
`

const ReceiptHeaderDescription = styled.p`
  font-size: 10pt;
  font-weight: bold;
  line-height: 1.1;
  margin: 0;
`

const series = ['АЕ', 'АА', 'КС', 'АБ', 'АИ']
const getRandomSerie = () => series[Math.floor(Math.random() * series.length)]

const ReceiptHeaderInfo = () => (
  <div>
    <ReceiptHeaderDescription>
      на услуги мотелей, кемпингов, <br />
      гостиниц
      <br />
      <br />
      Серия {getRandomSerie()}
    </ReceiptHeaderDescription>
  </div>
)

const ReceiptHeaderTableCell = styled.td`
  padding: 2mm 4mm;
  border: 1px solid black;
  width: ${props => props.width};
  font-size: ${props => props.fontSize};
  text-align: center;
`

const StyledReceiptHeaderTable = styled.table`
  width: 8cm;
  border-collapse: collapse;
  font-size: 10pt;
`

const ReceiptHeaderTable = () => (
  <StyledReceiptHeaderTable>
    <thead>
      <tr>
        <ReceiptHeaderTableCell colSpan={2}>
          Форма <b>БО-18</b>
        </ReceiptHeaderTableCell>
      </tr>
    </thead>
    <tbody>
      <tr>
        <ReceiptHeaderTableCell width="3cm">Код услуги</ReceiptHeaderTableCell>
        <ReceiptHeaderTableCell fontSize="12pt">041021</ReceiptHeaderTableCell>
      </tr>
    </tbody>
  </StyledReceiptHeaderTable>
)

const ReceiptHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledReceiptHeader = styled.div`
  margin: 0 0 3mm;
`

const ReceiptHeader = () => (
  <StyledReceiptHeader>
    <ReceiptHeaderTitle>
      КВИТАНЦИЯ-ДОГОВОР{' '}
      <ReceiptHeaderNumber>№ {getReceiptNumber()}</ReceiptHeaderNumber>
    </ReceiptHeaderTitle>
    <ReceiptHeaderContent>
      <ReceiptHeaderInfo />
      <ReceiptHeaderTable />
    </ReceiptHeaderContent>
  </StyledReceiptHeader>
)

export default ReceiptHeader
