import React from 'react'
import { Info } from './Info'
import { Actions } from './Actions'
import { Form } from './Form'
import { ConfirmButton } from './ConfirmButton'

export const Content = () => {
  return (
    <React.Fragment>
      <Form />
      <Info />
      <ConfirmButton />
      <Actions />
    </React.Fragment>
  )
}
