import React, {Fragment, useEffect, useState} from 'react'
import {Col, Row, Tab, Tabs} from 'react-bootstrap'
import LoadingModal from '../common/LoadingModal'
import ErrorMessage from '../common/ErrorMessage'
import BedclothesDataTable from './components/BedclothesDataTable'
import TableName from './components/TableName'
import moment from 'moment'
import {callAlert} from '../dynamic-modal'
import {useActions} from "../common/hooks/use-actions";
import {bedclothesChangeActions, bedclothesChangeSelectors} from "../../Modules/bedclothesChange";
import {MaidsModal} from "./components/MaidsModal";
import PrintStyles from "./components/PrintStyles";
import BedclothesGrid from "./components/BedclothesGrid";
import {PrintButtons, StatusText} from "./components/PrintButtons";
import {SingleDatePicker} from "../common/DatePicker";
import {Button} from "../buttons";
import PrintButton from "./components/PrintButton";
import {MaidsCheckOut} from "./components/MaidsCheckOut";
import {useSelector} from "react-redux";
import {sessionSelectors} from "../../Modules/session";
import {settingsSelectors} from "../../Modules/settings";
import {maidsActions} from "../../Modules/maids";

export const Cleaning = () => {

    const isRoomStatusesEnabled = useSelector(settingsSelectors.isRoomStatusesEnabled)
    const {loadData} = useActions(bedclothesChangeActions)
    const {load: maidsLoad, getMaidsCheckedIns} = useActions(maidsActions)
    const {
        schedule,
        leavedRooms,
        otherRooms,
        printData,
        isLoading,
        isLoaded,
        isFailed,

        isPrintDataLoading,
        isPrintDataLoaded,
    } = useSelector(bedclothesChangeSelectors.everything)


    const hotelName = useSelector(sessionSelectors.lcodeName)
    const adminName = useSelector(sessionSelectors.adminName)
    const lcode = useSelector(sessionSelectors.lcode)
    const numberOfBathrooms = useSelector(settingsSelectors.numberOfBathrooms)
    const disableBannerPrintCleaningSheet = useSelector(settingsSelectors.disableBannerPrintCleaningSheet)
    const isMaidEnable = useSelector(settingsSelectors.isMaidEnable)
    const makeRoomsDirtyTime = useSelector(settingsSelectors.makeRoomsDirtyTime)


    const [maidsModalOpen, setMaidsModalOpen] = useState(false)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

    useEffect(() => {
        loadData(date)
        maidsLoad()
        getMaidsCheckedIns({lcode})
    }, [lcode, date]);


    const closeModal = () => {
        getMaidsCheckedIns({lcode})
        setMaidsModalOpen(false)
    }

    const openModal = () => {
        if (disableBannerPrintCleaningSheet) {
            if (isMaidEnable)
                setMaidsModalOpen(true)
            return true
        }
        const format = 'hh:mm:ss';
        const time = moment()
        const afterTime = moment('07:00:00', format);
        const beforeTime = moment('12:00:00', format);
        const notAccept = !time.isBetween(afterTime, beforeTime)
        if (notAccept) {
            callAlert({
                title: 'Внимание',
                text: 'Печать листа на уборку возможна только в период с 7:00 до 12:00.',
            })
            return false
        }
        if (isMaidEnable) setMaidsModalOpen(true)
        return true
    }

    if (isLoading) return <LoadingModal isOpen={true}/>
    if (isFailed)
        return (
            <BedclothesGrid>
                <ErrorMessage style={{textAlign: 'center'}}>
                    Ошибка загрузки данных
                </ErrorMessage>
            </BedclothesGrid>
        )
    if (!isLoaded) return null

    return (
        <Fragment>
            <MaidsModal
                isOpen={maidsModalOpen}
                close={closeModal}
                reservationsData={{
                    schedule,
                    leavedRooms,
                    otherRooms,
                    admin: adminName,
                    hotelName,
                    printData,
                    cleaningDate: date
                }}
            />
            <PrintStyles/>
            <Tabs defaultActiveKey="cleaning" id="cleaninginfo">
                <Tab eventKey="cleaning" title="График уборки">
                    <BedclothesGrid>
                        <PrintButtons>
                            <SingleDatePicker
                                label="Дата уборки"
                                value={date}
                                onChange={setDate}
                            />
                            {isMaidEnable ? <Button size="sm" onClick={openModal}>Печать</Button> :
                                <PrintButton
                                    text="Печать"
                                    pageUrl="/print_bedclothes_change_rooms"
                                    dataKey="BEDCLOTHES_CHANGE_ROOMS_DATA"
                                    data={{
                                        schedule,
                                        leavedRooms,
                                        otherRooms,
                                        admin: adminName,
                                        hotelName,
                                        printData,
                                        cleaningDate: date
                                    }}
                                    disabled={isPrintDataLoading || !isPrintDataLoaded}
                                    event={openModal}
                                />
                            }
                            <PrintButton
                                text="Печать графика уборки санузлов"
                                pageUrl="/print_bathrooms_schedule"
                                dataKey="BATHROOMS_SCHEDULE_DATA"
                                data={{
                                    numberOfBathrooms,
                                }}
                                disabled={isPrintDataLoading || !isPrintDataLoaded}
                            />
                        </PrintButtons>
                        {isRoomStatusesEnabled && <Row>
                            <StatusText>
                                Статусы номеров в шахматке автоматически обновляются ежедневно в {makeRoomsDirtyTime} согласно данным
                                ниже
                            </StatusText>
                        </Row>}
                        <Row>
                            <Col xs={6}>
                                <TableName>Смена белья по графику</TableName>
                                <BedclothesDataTable
                                    reservations={schedule}
                                    isSchedule={true}
                                />
                            </Col>
                            <Col xs={6}>
                                <TableName>Номера, освобождающиеся сегодня</TableName>
                                <BedclothesDataTable
                                    reservations={leavedRooms}
                                />
                            </Col>
                        </Row>
                    </BedclothesGrid>
                </Tab>
                <Tab eventKey="maids" title="Чекаут горничных">
                    <BedclothesGrid>
                        <MaidsCheckOut/>
                    </BedclothesGrid>
                </Tab>
            </Tabs>
        </Fragment>
    )
}
