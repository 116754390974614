import React, { forwardRef, useRef } from 'react'
import GlobalStyle from './components/GlobalStyle'
import BlankContent from './components/BlankContent'
import { withPrintData } from '../HOCs/withPrintData'
import { Pages } from '../common/Pages'
import { Page } from '../common/Page'
import { useReactToPrint } from 'react-to-print'
import { Button } from 'react-bootstrap'

const BlankView = ({ printData }) => {
  const { hotelName, personData, reservation, calculatedItems, breakfasts } = printData
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const ComponentToPrint = forwardRef((props, ref) => (
    <div ref={ref}>
      <Pages>
        <GlobalStyle />
        <Page>
          <BlankContent
            hotelName={hotelName}
            personData={personData}
            reservation={reservation}
            calculatedItems={calculatedItems}
            breakfasts={breakfasts}
          />
        </Page>
      </Pages>
    </div>
  ))

  return (
    <>
      <Button
        block
        bsStyle="success"
        style={{ margin: '10px 0' }}
        onClick={handlePrint}>Печать
      </Button>
      <ComponentToPrint ref={componentRef} />
    </>
  )
}

export const Blank = withPrintData('BLANK_DATA')(BlankView)
