import React from 'react'
import { connect } from 'react-redux'
import Iframe from 'react-iframe'
import LoadingModal from '../common/LoadingModal'

class TrelloContainer extends React.Component {
  state = {
    isLoading: true
  }

  render() {
    return (
    <div style={{ display: 'contents' }}>
      <Iframe
        url={`https://technicians.winterfell-hotels.ru/${this.props.session.lcode}/?author=${this.props.session.admin}`}
        width="100%"
        height="100%"
        id="trello-boards"
        display="initial"
        position="relative"
        allowFullScreen
        onLoad={() => this.setState({ isLoading: false })}
      />}
        <LoadingModal isOpen={this.state.isLoading} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session,
})

const mapDispatchToProps = dispatch => ({})
export const Trello = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrelloContainer)
