import React from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import uuid from 'uuid/v4'

const Select = ({
  name,
  value,
  values,
  onChange,
  disabled,
  validationState,
  bsSize,
  style,
}) => {
  return (
    <FormGroup
      controlId="formControlsSelect"
      style={style || { marginBottom: 0 }}
      validationState={validationState}
    >
      <ControlLabel>{name}</ControlLabel>
      <FormControl
        componentClass="select"
        placeholder="select"
        value={value}
        onChange={e => onChange(e.target.value)}
        style={{ cursor: disabled ? null : 'pointer' }}
        disabled={disabled}
        bsSize={bsSize}
      >
        <option value="">Выберите</option>
        {values.map(value => (
          <option key={uuid()} value={value.value}>
            {value.text}
          </option>
        ))}
      </FormControl>
    </FormGroup>
  )
}

export default Select
