import React from 'react'
import styled, { css } from 'styled-components'
import { Field } from '../../../common'

export const HotelName = styled.div`
  font-size: 12pt;
  font-weight: 700;
  text-align: right;
  margin-bottom: 5mm;
  font-style: italic;
`

const Italic = styled.span`
  font-style: italic;
`

export const BigField = ({ name, value }) => {
  return (
    <Field
      name={<Italic>{name}</Italic>}
      value={value}
      sizes={{
        fonts: { name: '12pt', value: '11pt' },
        line: { width: '40mm', height: '0.5mm' },
      }}
    />
  )
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 8pt;
  line-height: 8pt;
  margin-left: 1px;
`

export const Column = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.rowMargin}mm;
`

export const Cell = styled.div`
  min-width: ${props => props.theme.cellWidth}mm;
  height: ${props => props.theme.cellHeight}mm;
  line-height: 4mm;

  margin-left: -1px;
  padding: 0 0.5mm;

  text-align: center;

  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;

  ${props => props.isReady && isReady}
`

export const isReady = css`
  position: relative;

  &:before {
    content: "\\2713";
    font-weight: bold;
  }
`

export const NoBorderCell = styled(Cell)`
  border-bottom: none;
  border-left: none;
`

export const CornerCell = styled(Cell)`
  border-left: none;
`

export const FirstCell = styled(Cell)`
  text-align: right;
  border-left: 1px solid;
`

export const TimeCell = styled(Cell)`
  border-top: 1px solid;
  padding-left: 0.25mm;
  padding-right: 0.25mm;
  border-bottom: none;

  ${props => props.hasLate && hasLate}
  ${props => props.hasEarly && hasEarly}
`

export const RoomIdCell = styled(Cell)`
  border-top: 1px solid;
  padding-left: 0.25mm;
  padding-right: 0.25mm;
`

export const withCircle = css`
  border: 1px solid black;
  border-radius: 50%;
`

export const hasLate = css`
  position: relative;

  &:before {
    content: "П";
    font-weight: bold;
    position: absolute;
    right: 2px;
    top: 0;
  }
`

export const hasEarly = css`
  position: relative;

  &:after {
    content: "Р";
    font-weight: bold;
    position: absolute;
    left: 2px;
    top: 0;
  }
`

export const RoomId = styled.span`
  display: inline-block;
  padding: 0.25mm;
  max-height: 100%;

  ${props => props.withCircle && withCircle}
  ${props => props.hasLate && hasLate}
  ${props => props.hasEarly && hasEarly}
`

export const TotalCell = RoomIdCell

export const MarginCell = styled(Cell)`
  border-bottom: none;
  border-right: none;
`

const Circle = styled.span`
  display: inline-block;
  width: 4mm;
  height: 4mm;
  border: 1px solid;
  border-radius: 50%;
  margin-right: 2mm;
`

const LegendRow = styled.div`
  display: flex;
  flex-direction: row;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 10pt;
  margin-right: 10mm;

  &:last-child {
    margin-right: 0;
  }
`

export const Legend = () => {
  return (
    <LegendRow>
      <LegendItem>
        <Circle /> - выезд сегодня
      </LegendItem>
      <LegendItem>
        <div><strong>Р</strong> - ранний заезд</div>
      </LegendItem>
      <LegendItem>
        <div><strong>П</strong> - поздний выезд</div>
      </LegendItem>
      <LegendItem>
        <div><strong>+</strong> - смена белья по графику</div>
      </LegendItem>
    </LegendRow>
  )
}
