import React from 'react'
import styled from 'styled-components'

const StyledSpecification = styled.div`
  font-size: 7pt;
  text-align: right;
  padding-left: 43%;
  margin-bottom: 4mm;
`

const Specification = () => (
  <StyledSpecification>
    Утверждена приказом Объединения работодателей «Росбытсоюз» от 30 июня 2008
    г. № 14 в соответствии с Постановлением Правительства РФ от 06.05.2008 г. №
    359
  </StyledSpecification>
)

export default Specification
