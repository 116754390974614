import React from 'react'
import { getData } from '../helpers/getData'

export const withPrintData = localStorageKey => PrintPage =>
  class PrintDataProvider extends React.Component {
    state = {
      data: null,
      isLoaded: false,
    }

    componentDidMount() {
      const data = getData(localStorageKey)

      this.setState({
        data,
        isLoaded: true,
      })
    }

    render() {
      const { data, isLoaded } = this.state

      if (!isLoaded) return null

      return <PrintPage printData={data} />
    }
  }
