import {
  logPressAction,
  LOADING_SHOW,
  LOADING_HID,
  showPrintServerError,
  logEvent,
  SUCCESS_MODAL_SHOW,
  logError,
  showRefundModal,
  SET_DUPLICATES,
  submitTempLogs,
  setReservation,
  validate,
  emailConfirmation, setBookingNumber, getValidation, checkPaymentTypes,
} from '../../ReservationModule'
import doNeedToPrint from '../../../CheckPrintController/doNeedToPrint'
import { doNeedToPrintAdvanced } from '../../doNeedToPrintAdvanced'
import isServerRunning from '../../../CheckPrintController/isServerRunning'
import { loyaltySelectors } from '../../../loyalty'
import { ReservationSaving } from '../../../reservation-saving'
import { prepareReservation } from './prepareReservation'
import { checkPassports } from '../../checkPassports'
import {
  callModal,
  modalTemplates,
} from '../../../../Components/dynamic-modal'
import { createPreSaveDebugMessage } from '../helpers/create-pre-save-debug-message'
import { LogsAPI } from '../../../logs-api'
import { reservationSelectors } from '../..'
import { MODES } from '../../../helpers/types'
import { useLoyaltyHandler } from '../helpers/use-loyalty-handler'
import { finishPurchaseBonusesCalculation } from '../helpers/finish-purchase-bonuses-calculation'
import { setPk } from '../../actions/general'
import { settingsSelectors } from '../../../settings'
import { getItemsCashOrCard } from '../../../reservation-saving/scenaries/purchase/helpers/get-items'
import {
  checkReservationInsertionPossibilityCall,
  getMultipleReservationsCall,
  saveNewReservationCall
} from '../../../api/ccAPI/requests'

export const saveNewReservation = () => async (dispatch, getState) => {
  dispatch(logPressAction('Создать новую бронь'))
  dispatch(setBookingNumber())
  let state = getState()

  const reservation = reservationSelectors.everything(state)
  const modes = reservationSelectors.modes(state)

  const settings = getState().settings

  if (reservation.has_refund) {
    if (
      !reservation.refundConfirmed ||
      typeof reservation.refundConfirmed !== 'boolean'
    ) {
      dispatch(showRefundModal())
      return
    }
  }
  await dispatch(checkPaymentTypes())

  await dispatch(validate())

  state = getState()
  const hasValidationErrors = reservationSelectors.hasValidationErrors(state)
  const hasPaymentValidationErrors = reservationSelectors.hasPaymentValidationErrors(state)

  if (hasValidationErrors || hasPaymentValidationErrors) {
    return
  }

  if (
    reservation.has_refund &&
    reservation.reserved_days.some(d => d.payment_type !== 'Карта')
  ) {
    const { action } = await callModal(
      modalTemplates.confirm({
        text: [
          'Невозвратные брони обычно оплачиваются картой.',
          'Все равно продолжить сохранение?',
        ],
        buttons: [
          'Отмена',
          {
            text: 'Продолжить сохранение',
            color: 'warning',
            action: modalTemplates.confirm.actions.confirm,
          },
        ],
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }
  }

  if (!reservation.isDuplicateConfirmed) {
    dispatch(logEvent('Проверяем номер брони на существование'))

    const validationResponse = await checkReservationInsertionPossibilityCall(
      reservation.booking_number
    )
    if (validationResponse.result === 'ok') {
      dispatch(logEvent('Дубликатов брони не найдено, сохраняем'))
    } else {
      dispatch(
        logEvent(
          `Найдены дубликаты: ${validationResponse.reservations.join(', ')}`
        )
      )

      const reservationsResponse = await getMultipleReservationsCall(
        validationResponse.reservations
      )

      const foundReservations = []
      reservationsResponse.forEach(r =>
        foundReservations.push({
          start: r.start,
          room: r.reserved_days.length ? r.reserved_days[0].room && r.reserved_days[0].room.room_id : null,
        })
      )

      let detailsMessage
      switch (validationResponse.details) {
        case 'wubook-today':
          dispatch(logEvent('Найден дубликат Wubook с заселением сегодня'))
          detailsMessage =
            'Данная бронь уже импортирована из Wubook. Её можно заселить, нажав на неё в списке слева от таблицы на главной странице.'
          break

        case 'wubook-in-future':
          dispatch(logEvent('Найден дубликат Wubook с заселением в будущем'))
          detailsMessage =
            'Данная бронь уже импортирована из Wubook. Её можно заселить, нажав на неё в разделе «Новые брони в Wubook».'
          break

        case 'existing-reseravaion':
          dispatch(logEvent('Найден уже заселенный дубликат'))
          detailsMessage = 'Бронь с данным номером уже была заселена'
          break

        default:
          dispatch(logEvent('Не удалось определить тип дубликата'))
          detailsMessage = 'Тип брони-дубликата не удалось определить.'
      }

      dispatch({
        type: SET_DUPLICATES,
        payload: { duplicates: foundReservations, message: detailsMessage },
      })

      return
    }
  }

  const loyaltyValidation = loyaltySelectors.validation(state)

  if (!loyaltyValidation.isValid) {
    callModal(
      modalTemplates.alert({
        title: 'Данные лояльности невалидны',
        text: [loyaltyValidation.error, loyaltyValidation.suggestion],
      })
    )
    return
  }

  dispatch({ type: LOADING_SHOW })

  const { hasPassed, hasNetworkError, renderedMessage } = await checkPassports(
    reservation.passport
  )

  if (!hasPassed && !hasNetworkError) {
    dispatch({ type: LOADING_HID })
    callModal(
      modalTemplates.alert({
        title: 'Найдены заблокированные паспорта',
        text: renderedMessage,
      })
    )
    return
  }

  const insertionPossibilityCheck = await checkReservationInsertionPossibilityCall(reservation.booking_number)

  if (insertionPossibilityCheck.result === 'error') {
    dispatch({ type: LOADING_HID })
    callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: 'Выбранные даты в этом номере заняты другой бронью',
      })
    )
    return
  }

  // Do need to print check on this editing
  let needToPrint = await doNeedToPrint({
    reservation,
    settings,
    modes,
  })

  let printScopes = { days: true, services: true }
  if (needToPrint) {
    printScopes = await doNeedToPrintAdvanced(
      reservation,
      modes,
      reservation.bnVariants
    )
    needToPrint = printScopes.days || printScopes.services
  }

  // If we need to print check but server is off,
  // show error modal and exit this function
  if (needToPrint) {
    const isServerOK = await isServerRunning()

    if (!isServerOK) {
      dispatch({ type: LOADING_HID })
      dispatch(showPrintServerError())
      return
    }
  }

  const createReservationCallback = async ({
    reservation,
    setScenarioState,
  }) => {
    try {
      const message = createPreSaveDebugMessage(reservation)
      LogsAPI.custom(message)

      const savedReservation = await saveNewReservationCall(reservation)

      dispatch(logEvent('Бронь успешно создана'))

      window.localStorage.setItem("reservation_changed", +new Date());

      const { pk } = savedReservation
      dispatch(setPk(pk))
      dispatch(submitTempLogs(pk))

      setScenarioState({
        result: 'saved',
        savedReservation,
      })
    } catch (err) {
      dispatch(logError('Не удалось создать новую бронь'))

      setScenarioState({
        result: 'failed',
        error: err?.message ?? 'Неизвестная ошибка',
      })
    }
  }

  const showSavingResultCallback = ({ scenarioState }) => {
    const { succeed, error, savedReservation } = scenarioState

    if (!succeed) {
      callModal(
        modalTemplates.alert({
          title: 'Ошибка при сохранении брони',
          text: error,
        })
      )

      return
    }

    dispatch(
      setReservation({
        reservation: savedReservation,
        mode: MODES.EDIT,
      })
    )

    dispatch({ type: SUCCESS_MODAL_SHOW })
  }

  const printFailureCallback = ({ printResult }) => {
    callModal(
      modalTemplates.alert({
        title: 'Ошибка при печати чека',
        text: printResult.Error,
      })
    )
  }

  const confirmEmail = async () => {
    const settings = settingsSelectors.everything(state)
    if (settings.sendReceiptToEmail) {
      const reservation = reservationSelectors.everything(state)
      const items = getItemsCashOrCard(reservation)
      const hasChanges = items.days.length || items.services.length
      if (hasChanges && settings.printerIsOn) {
        await dispatch(emailConfirmation(reservation))
      }
    }
  }

  const hideLoadingCallback = () => dispatch({ type: LOADING_HID })

  const validateReservation = async () => {
    const reservation = reservationSelectors.everything(state)
    return await dispatch(getValidation(reservation))
  }

  const handlerBasicData = {
    modes,
    printScopes,
    confirmEmail,
    validateReservation,
    prepareReservation,
    beforePrint: ReservationSaving.callbacks.startPaymentTimer,
    afterPrint: ReservationSaving.callbacks.combine([
      ReservationSaving.callbacks.stopPaymentTimer,
      ReservationSaving.callbacks.logPrintResult,
    ]),
    onSuccess: ReservationSaving.callbacks.pipe([
      ReservationSaving.callbacks.saveTransaction,
      createReservationCallback,
      ReservationSaving.callbacks.savePaymentAction,
      ReservationSaving.callbacks.saveServiceTransaction,
      ReservationSaving.callbacks.saveInventory,
      ReservationSaving.callbacks.saveBreakfastActions,
      ReservationSaving.callbacks.processWashingServices,
      showSavingResultCallback,
    ]),
    onFailure: printFailureCallback,
    onFinish: hideLoadingCallback,
    whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
      createReservationCallback,
      ReservationSaving.callbacks.savePaymentAction,
      ReservationSaving.callbacks.saveServiceTransaction,
      ReservationSaving.callbacks.saveBreakfastActions,
      ReservationSaving.callbacks.processWashingServices,
      ReservationSaving.callbacks.saveInventory,
      showSavingResultCallback,
    ]),
  }

  const { isCanceled } = await finishPurchaseBonusesCalculation({ state })

  if (isCanceled) {
    dispatch({ type: LOADING_HID })
    return
  }

  state = getState()

  return useLoyaltyHandler({ state })
    ? ReservationSaving.handleLoyaltyPurchase(handlerBasicData)
    : ReservationSaving.handlePurchase(handlerBasicData)
}
