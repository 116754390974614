import React from 'react'
import { EditSection } from '../../common/EditSection'
import { Content } from './components/Content'

export const LoyaltyClient = ({
  isLoyaltyEnabled,
  loyaltyClient,
  isActual,
  isLoyaltyAllowed,
  loyaltyClientActions,
}) => {
  if (!isLoyaltyEnabled) return null
  if (!isLoyaltyAllowed) return null

  return (
    <EditSection
      size="small"
      name="Данные клиента"
      content={
        <Content
          loyaltyClient={loyaltyClient}
          isActual={isActual}
          loyaltyClientActions={loyaltyClientActions}
        />
      }
      withVisibilityToggle
      customColor="white"
    />
  )
}
