import { createSelector } from 'reselect'

export function createLoadStateSelector({ isLoading, isLoaded, isFailed }) {
  return createSelector(
    isLoading,
    isLoaded,
    isFailed,
    (isLoading, isLoaded, isFailed) => ({
      isLoading,
      isLoaded,
      isFailed,
    })
  )
}
