import styled from 'styled-components'

export const RemindersList = styled.div`
  margin-bottom: 20px;
`

export const Reminder = styled.div`
  text-align: center;
  color: crimson;
  font-size: 15px;
`
