import React, { useMemo, useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import { FormGroup, ControlLabel } from 'react-bootstrap'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

function mapToOptions(services) {
  return services.map(service => {
    const normalizedName = service.name.replace(/ [0-9]+р/, '')

    return {
      label: `${normalizedName} (${service.price} руб)`,
      value: service,
    }
  })
}

export const ServiceTypeSelect = ({
  service,
  onChange,
  services: { common, special },
  hasError,
}) => {
  const [realValue, setRealValue] = useState(null)

  useEffect(() => {
    if (!realValue) onChange(null)
    else onChange(realValue.value)
  }, [realValue, onChange])

  const [commonOptions, specialOptions] = useMemo(
    () => [mapToOptions(common), mapToOptions(special)],
    [common, special]
  )

  useEffect(() => {
    if (service) return
    setRealValue(null)
  }, [service])

  return (
    <FormGroup validationState={hasError ? 'error' : null}>
      <ControlLabel>Тип услуги</ControlLabel>
      <ReactSelect
        placeholder="Выберите услугу"
        value={realValue}
        onChange={setRealValue}
        options={[
          { label: 'Обычные услуги', options: commonOptions },
          { label: 'Специальные услуги', options: specialOptions },
        ]}
        formatGroupLabel={formatGroupLabel}
        styles={{
          control: base => {
            const newStyles = Object.assign({}, base)

            newStyles.height = 30
            newStyles.minHeight = 30
            newStyles.fontSize = 12

            if (hasError) {
              newStyles.borderColor = '#a94442 !important'
              newStyles.boxShadow = 'none !important'
            }

            return newStyles
          },
          dropdownIndicator: base => ({
            ...base,
            padding: 4,
          }),
          clearIndicator: base => ({
            ...base,
            padding: 4,
          }),
          valueContainer: base => ({
            ...base,
            padding: '0px 6px',
          }),
          input: base => ({
            ...base,
            margin: 0,
            padding: 0,
          }),
          option: base => ({
            ...base,
            fontSize: 12,
            paddingTop: 6,
            paddingBottom: 6,
          }),
        }}
      />
    </FormGroup>
  )
}
