import React from 'react'
import { Row, Cell, HeadCell } from './styleds'

const Item = ({ data }) => {
  const amount = Number(data.amount).toFixed(2)
  const method = data.payment_method
  const note = data.note || '-'
  const date = data.start || '-'
  const payed_at = data.payed_at
  const payed_by = data.payed_by

  return (
    <Row>
      <Cell>{date}</Cell>
      <Cell>{amount} руб.</Cell>
      <Cell>{method}</Cell>
      <Cell>{note}</Cell>
      <Cell>{payed_at}</Cell>
      <Cell>{payed_by}</Cell>
    </Row>
  )
}

export const Table = ({ items }) => {
  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <table style={{ fontSize: 12, width: 540 }}>
        <thead>
          <Row>
            <HeadCell>Дата заезда</HeadCell>
            <HeadCell>Сумма</HeadCell>
            <HeadCell>Метод</HeadCell>
            <HeadCell>Примечание</HeadCell>
            <HeadCell>Дата оплаты</HeadCell>
            <HeadCell>Плательщик</HeadCell>
          </Row>
        </thead>
        <tbody>
          {items.map(item => (
            <Item key={item.pk} data={item} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
