import React from 'react'
import { Table as BootstrapTable } from 'react-bootstrap'
import styled, { css } from 'styled-components'
import history from '../../../../Modules/helpers/history'
import { ccAPI } from '../../../../Modules/api/ccAPI'
import { Notifications } from '../../../../Modules/Notifications'
import { breakfastsBookedActions } from '../../../../Modules/breakfastsBooked'
import { useActions } from '../../../common/hooks/use-actions'
import { callConfirm } from '../../../dynamic-modal'
import { Button } from '../../../buttons'

const Cell = styled.td`
  vertical-align: middle !important;
  padding: 16px 8px;
  width: ${props => props.width}px;
  text-align: ${props => props.textAlign};
  ${props =>
          props.clickable &&
          css`
            cursor: pointer;
          `}
`

export const Table = ({ children }) => {
  return (
    <BootstrapTable
      responsive
      className="table-hover condensed"
      style={{ fontSize: 12, width: '100%' }}
    >
      <thead>
      <tr>
        <th>Гость</th>
        <th>Комната</th>
        <th>Количество</th>
        <th>Действия</th>
        <th />
      </tr>
      </thead>
      <tbody>{children}</tbody>
    </BootstrapTable>
  )
}

export const Row = ({ reservation, breakfast }) => {
  const { load } = useActions(breakfastsBookedActions)
  const goToEditPage = () => {
    history.push('/reservation?pk=' + reservation.pk)
  }

  const days = reservation.reserved_days
  const room = days[days.length - 1]?.room
  const roomName = room ? `${room.room_id} (${room.name})` : 'Неизвестная комната'

  const giveaway = async () => {
    const payload = await callConfirm({
      title: 'Подтверждение',
      text: [
        `Выдать завтрак? (${reservation.guest_name} ${roomName} x ${breakfast.qty})`,
      ],
    })
    if (payload) {
      ccAPI.breakfastGiveaway(breakfast.pk).then(_ => {
        Notifications.success('Завтрак выдан')
        load()
      }).catch(_ => {
        Notifications.failure('Завтрак не выдан')
      })
    }
  }

  return (
    <tr>
      <Cell width={120}>{reservation.guest_name}</Cell>
      <Cell width={120}>
        {roomName}
      </Cell>
      <Cell width={60}>{breakfast.qty}</Cell>
      <Cell>
        <Button size="xs" onClick={goToEditPage}>Перейти в бронь</Button>
        <Button size="xs" type="warning" onClick={giveaway}>Выдать</Button>
      </Cell>
    </tr>
  )
}
