function min(
  threshold,
  message = `Значение должно быть больше или равно ${threshold}`
) {
  return {
    fn: value => {
      return value >= threshold
    },
    message,
  }
}

function max(
  threshold,
  message = `Значение должно быть меньше или равно ${threshold}`
) {
  return {
    fn: value => {
      return value <= threshold
    },
    message,
  }
}

export const number = {
  min,
  max,
}
