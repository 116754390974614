import * as types from './types'
import * as selectors from './selectors'

export const start = ({ duration = 60, onFinish = () => {} }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.START,
    payload: {
      untilDate: new Date(new Date().getTime() + duration * 1000),
    },
  })

  const processFn = () => {
    const state = getState()

    const timerState = selectors.everything(state)

    const { isActive, untilDate } = timerState

    if (!isActive) return

    if (new Date(untilDate) <= new Date()) {
      dispatch(stop())
      onFinish()
    } else {
      window.setTimeout(processFn, 500)
    }
  }

  processFn()
}

export const stop = () => ({
  type: types.STOP,
})
