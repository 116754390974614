import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const PrintButtonBlock = styled.div`
  text-align: center;
  margin-bottom: 36px;
`

export const PrintButton = styled(Button)`
  padding: 8px 16px;
  border: none;
  border-radius: 0;
`

export default ({ data, disabled }) => {
  const onClick = () => {
    localStorage.setItem('DAY_TOTALS_DATA', JSON.stringify(data))
    window.location.href = '/print_day_totals'
  }

  return (
    <PrintButtonBlock>
      <PrintButton bsStyle="success" onClick={onClick} disabled={disabled}>
        Распечатать
      </PrintButton>
    </PrintButtonBlock>
  )
}
