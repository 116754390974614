import styled from 'styled-components'

export const ModalHeader = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid #dcdcdc;
`

export const ModalContent = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid #dcdcdc;
`

export const ModalActions = styled.div`
  text-align: right;
  padding: 12px;
`
