import { connect } from 'react-redux'
import { reservationSelectors } from '../../Modules/reservation'
import { settingsSelectors } from '../../Modules/settings'
import { loyaltySelectors } from '../../Modules/loyalty'
import { PrintBlankButtonView } from './PrintBlankButtonView'
import { sessionSelectors } from '../../Modules/session'

const mapStateToProps = (state, ownProps) => ({
  reservation: reservationSelectors.everything(state),
  settings: settingsSelectors.everything(state),
  session: sessionSelectors.everything(state),
  calculatedItems: loyaltySelectors.calculatedItems(state),
  ...ownProps,
})

export const PrintBlankButton = connect(mapStateToProps)(PrintBlankButtonView)
