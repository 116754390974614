import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!rest.isAuthorized) return <Redirect to="/login" />
        if (rest.render) return rest.render()
        return <Component {...props} />
      }}
    />
  )
}

const mapStateToProps = state => ({
  isAuthorized: state.session.isAuthorized,
})

export default connect(mapStateToProps)(PrivateRoute)
