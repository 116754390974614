import { connect } from 'react-redux'
import { MainPage } from './MainPage'
import { gridActions } from './reducers/grid'
import { tasksActions } from './reducers/tasks'
import { todayCheckInsActions } from './reducers/today-check-ins'
import { todayNoShowActions } from './reducers/today-no-show'
import { breakfastsBookedActions } from '../../Modules/breakfastsBooked'
import { specialServiceTypesActions } from '../../Modules/special-service-types'
import { bindActionCreators } from 'redux'
import { settingsSelectors } from '../../Modules/settings'
import { canceledWarningsActions } from './reducers/canceled-warnings'

const mapStateToProps = state => ({
  isTodoListEnabled: settingsSelectors.isTodoListEnabled(state)
})

const mapDispatchToProps = dispatch => ({
  loadData: async () => {
    await Promise.all([
      dispatch(gridActions.loadData()),
      dispatch(todayCheckInsActions.loadData()),
      dispatch(breakfastsBookedActions.load()),
      dispatch(specialServiceTypesActions.load()),
      dispatch(todayNoShowActions.loadData()),
      dispatch(canceledWarningsActions.loadData()),
    ])
  },
  resetData: () => {
    dispatch(gridActions.reset())
    dispatch(tasksActions.reset())
    dispatch(todayCheckInsActions.reset())
    dispatch(todayNoShowActions.reset())
  },
  tasksActions: bindActionCreators(tasksActions, dispatch)
})

export const ConnectedMainPage = connect(mapStateToProps, mapDispatchToProps)(MainPage)
