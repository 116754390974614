import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { Formik, Field } from 'formik'
import {
  ModalControls as DefaultModalControls,
  ModalButton,
  ModalTitle,
} from '../../../common/ModalParts'
import { modalDefaultStyles } from '../../../../App'
import { Notifications } from '../../../../Modules/Notifications'
import { useActions } from '../../../common/hooks/use-actions'
import { settingsActions } from '../../../../Modules/settings'
import { callAlert, callModal, modalTemplates } from '../../../dynamic-modal'
import { localhost } from '../../../../Constants/urls'
import { TimeInput } from '../../../form-stuff'

const ModalControls = styled(DefaultModalControls)`
  padding: 20px;
`

const FormBody = styled.div`
  padding: 20px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
`

export const ModalView = ({ isOpen, close, settings }) => {
  const { setCCflags } = useActions(settingsActions)
  if (!isOpen) return ''
  return (
    <Modal
      isOpen={isOpen}
      defaultStyles={{
        ...modalDefaultStyles,
        overlay: {
          ...modalDefaultStyles.overlay,
          zIndex: 201,
        },
        content: {
          ...modalDefaultStyles.content,
          width: 400,
          minWidth: 400,
          maxWidth: 'auto',
        },
      }}
    >
      <Formik
        initialValues={{
          sendReceiptToEmail: settings.sendReceiptToEmail,
          checksV2Enabled: settings.checksV2Enabled,
          printerIsOn: settings.printerIsOn,
          isLoyaltyEnabled: settings.isLoyaltyEnabled,
          isCloudGhotelOn: settings.isCloudGhotelOn,
          isMaidsBonusesEnabled: settings.isMaidsBonusesEnabled,
          isUsersManagementV2Enabled: settings.isUsersManagementV2Enabled,
          isTodoListEnabled: settings.isTodoListEnabled,
          isDebugMenuEnabled: settings.isDebugMenuEnabled,
          useLocalBaseUrl: false,
          isLockEnabled: settings.isLockEnabled,
          isMaidEnable: settings.isMaidEnable,
          breakfastLimitTime: settings.breakfastLimitTime
        }}
        onSubmit={async (values) => {
          if (values.useLocalBaseUrl) {
            await fetch('http://127.0.0.1:8000/', { method: "HEAD" })
              .then(async (_) => {
                const reload = await callModal(
                  modalTemplates.confirm({
                    text:
                      'страница будет перезагружена',
                  })
                )
                if (reload.action === modalTemplates.confirm.actions.confirm) {
                  await setCCflags(values)
                  close()
                  window.location.reload()
                }
              })
              .catch(_ => {
                callAlert({
                  title: 'Ошибка',
                  text: `${localhost} недоступен`,
                })
              })
          } else {
            setCCflags(values)
            close()
            Notifications.success('Настройки сохранены')
          }
        }}
        render={({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <ModalTitle>Предложить улучшение</ModalTitle>
            <FormBody>
              <label>
                <Field type="checkbox" name="isMaidEnable"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_maid_enable
              </label><br />
              <label>
                <Field type="checkbox" name="isLockEnabled"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_lock_enabled
              </label><br />
              <label>
                <Field type="checkbox" name="sendReceiptToEmail"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_e_checks_enabled
              </label><br />
              <label>
                <Field type="checkbox" name="checksV2Enabled" style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_checks_v2_enabled
              </label><br />
              <label>
                <Field type="checkbox" name="printerIsOn" style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_checks_printer_enabled
              </label><br />
              <label>
                <Field type="checkbox" name="isLoyaltyEnabled" style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_ghotel_loyalty_enabled
              </label><br />
              <label>
                <Field type="checkbox" name="isCloudGhotelOn" style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_cloud_ghotel_on
              </label><br />
              <label>
                <Field type="checkbox" name="isMaidsBonusesEnabled"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_maids_bonuses_enabled
              </label><br />
              <label>
                <Field type="checkbox" name="isUsersManagementV2Enabled"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_users_management_v2_enabled
              </label>
              <label>
                <Field type="checkbox" name="isTodoListEnabled"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_todo_list_enabled
              </label>

              <TimeInput
                label="breakfast_limit_time"
                placeholder="Введите время"
                value={values.breakfastLimitTime}
                onChange={val => setFieldValue('breakfastLimitTime', val)}
                noMargin
                showSeconds
              />

              <label>
                <Field type="checkbox" name="useLocalBaseUrl"
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                use base_url = {localhost}
              </label>
              <label>
                <Field type="checkbox" name="isDebugMenuEnabled" disabled={true}
                       style={{ margin: '0 10px', verticalAlign: 'middle' }} />
                is_debug_menu_enabled
              </label>
            </FormBody>

            <ModalControls>
              <ModalButton
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={close}
              >
                Отмена
              </ModalButton>
              <ModalButton
                type="submit"
                bsStyle="success"
                style={{ marginLeft: 'auto' }}
              >
                Сохранить
              </ModalButton>
            </ModalControls>
          </form>
        )}
      />
    </Modal>
  )
}
