export function createScenario() {
  // result = null | 'saved' | 'deleted' | 'failed'
  //   null - initial state, no actions were done yet
  //   'saved' - reservation saved successfully
  //   'deleted' - reservation was deleted since all days were refunded
  //   'failed' - there was an error on saving/deleting stage
  // succeed - boolean
  //   true - result = 'saved' | 'deleted'
  //   false - result = null | 'failed'
  // error - null | string
  //   - error returned from API after failure on any stage
  // savedReservation = null | reservation
  //   - reservation returned from API after successful saving
  const scenarioState = {
    result: null,
    succeed: false,
    error: null,
    savedReservation: null,
  }

  /**
   * @param {object} newState
   * @param {'saved' | 'deleted' | 'failed'} [newState.result]
   * @param {string} [newState.error]
   * @param {{ [key: string]: * }} [newState.savedReservation]
   */
  const setScenarioState = newState => {
    for (const key in newState) {
      const value = newState[key]
      scenarioState[key] = value
    }

    if (newState.result) {
      scenarioState.succeed = ['saved', 'deleted'].includes(newState.result)
    }
  }

  return {
    scenarioState,
    setScenarioState,
  }
}
