import { connect } from 'react-redux'
import NewReservation from '../Components/reservation/create/NewReservation'
import {
  onEndChange,
  onStartChange,
  onDayPriceChange,
  onDayMethodChange,
  onReservationPayedChange,
  onDayPayedChange,
  onReservationDayAdd,
  onReservationDayRemove,
  onDaySummCopy,
  onDayMethodCopy,
  onNameChange,
  onPhoneChange,
  onMailChange,
  onBookingNumberChange,
  onNoteChange,
  onRefundChange,
  hideRefundModal,
  showRefundActionModal,
  hideRefundActionModal,
  loadServices,
  getReservation,
  onSaleClick,
  onRefundClick,
  toggleEntryModal,
  onNotPayedDayPriceChange,
  onNotPayedDayMethodChange,
  onNotPayedDayPayedChange,
  clearReservation,
  onDayRefund,
  createNewReservation,
  hideSuccessModal,
  writeCard,
  clearWritecardError,
  hideErrorModal,
  confirmDuplicate,
  clearDuplicates,
  onGuestsNumberChange,
  hidePrintServerError,
  printCheckAgain,
  confirmRefund,
  onPassportCreate,
  onPassportEdit,
  onPassportRemove,
  logPressAction,
  logEditAction,
  logEvent,
  logError,
  addPassportsToBlacklist,
  getRegistrationForms,
} from '../Modules/reservation/ReservationModule'
import { bindActionCreators } from 'redux'
import { loyaltyClientActions } from '../Modules/loyalty-client'
import {
  saveExistingReservation,
  saveNewReservation,
} from '../Modules/reservation/saveActions'
import { loyaltySelectors } from '../Modules/loyalty'
import { reservationSelectors } from '../Modules/reservation'
import { settingsSelectors } from '../Modules/settings'
import { modalsActions } from '../Modules/modals'

const mapStateToProps = state => ({
  modes: reservationSelectors.modes(state),

  // Whole reservation data
  reservation: state.reservation,

  // Settings
  settings: state.settings,

  // First and last dates of reservation
  start: state.reservation.start,
  end: state.reservation.end,
  initialEnd: state.reservation.initial_end,
  days: reservationSelectors.days(state),
  newDays: reservationSelectors.newDays(state),
  notPayedDays: reservationSelectors.notPayedDays(state),
  payedDays: reservationSelectors.payedDays(state),
  daysDates: reservationSelectors.daysDates(state),
  services: state.reservation.services,
  createdServices: reservationSelectors.serviceServices(state),
  createdUpgrades: reservationSelectors.upgradeServices(state),
  allServices: reservationSelectors.services(state),
  name: state.reservation.guest_name,
  note: state.reservation.note,
  phone: state.reservation.guest_phone,
  mail: state.reservation.guest_mail,
  guestsNumber: state.reservation.guests_number,
  bookingNumber: state.reservation.booking_number,
  hasRefund: state.reservation.has_refund,
  isRefundModalOpened: state.reservation.isRefundModalOpened,
  isRefundActionModalOpened: state.reservation.isRefundActionModalOpened,
  payed: state.reservation.payed,
  globalCheckIn: state.reservation.check_in_time,
  globalCheckOut: state.reservation.check_out_time,
  isShowingEntryModal: state.reservation.isShowingEntryModal,
  isSale: state.reservation.isSale,
  isRefund: state.reservation.isRefund,
  refundSumm: state.reservation.refundSumm,
  rooms: state.reservation.rooms,
  showSuccessModal: state.reservation.showSuccessModal,
  showErrorModal: state.reservation.showErrorModal,
  isLoading: state.reservation.isLoading,
  isSaving: state.reservation.isSaving,
  writecardError: state.reservation.writecardError,
  upgradeableDaysDates: reservationSelectors.upgradeableDaysDates(state),
  errorPK: state.reservation.errorPK,
  isDuplicatesFound: state.reservation.isDuplicatesFound,
  duplicatesData: state.reservation.duplicatesData,
  isDuplicateConfirmed: state.reservation.isDuplicateConfirmed,
  isPrintServerErrorModalActive:
    state.reservation.isPrintServerErrorModalActive,
  isPrinterOn: state.settings.printerIsOn,
  passport: reservationSelectors.passport(state),
  generalValidationErrors: reservationSelectors.generalValidationErrors(state),
  daysValidationErrors: reservationSelectors.daysValidationErrors(state),

  isLoyaltyEnabled: settingsSelectors.isLoyaltyEnabled(state),
  isLoyaltyUsed: loyaltySelectors.isUsed(state),
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      onEndChange,
      onStartChange,
      onDayPriceChange,
      onDayMethodChange,
      onReservationPayedChange,
      onDayPayedChange,
      onReservationDayAdd,
      onReservationDayRemove,
      onDaySummCopy,
      onDayMethodCopy,
      onNameChange,
      onPhoneChange,
      onMailChange,
      onBookingNumberChange,
      onNoteChange,
      onRefundChange,
      hideRefundModal,
      showRefundActionModal,
      hideRefundActionModal,
      loadServices: loadServices,
      getReservation,
      onSaleClick,
      onRefundClick,
      toggleEntryModal,
      onNotPayedDayPriceChange,
      onNotPayedDayMethodChange,
      onNotPayedDayPayedChange,
      saveReservation: saveExistingReservation,
      clearReservation,
      onDayRefund,
      createNewReservation,
      saveNewReservation,
      hideSuccessModal,
      writeCard,
      clearWritecardError,
      hideErrorModal,
      confirmDuplicate,
      clearDuplicates,
      onGuestsNumberChange,
      hidePrintServerError,
      printCheckAgain,
      confirmRefund,
      onPassportCreate,
      onPassportEdit,
      onPassportRemove,
      addPassportsToBlacklist,
      getRegistrationForms
    },
    dispatch
  ),
  logs: bindActionCreators(
    {
      logPressAction,
      logEditAction,
      logEvent,
      logError,
    },
    dispatch
  ),
  loyaltyClientActions: bindActionCreators(loyaltyClientActions, dispatch),
  modalsActions: bindActionCreators(modalsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewReservation)
