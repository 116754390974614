import React, {useState} from "react";
import {ControlLabel, FormGroup} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

const createOption = (label) => ({
    label,
    value: label,
});

export const CarNumberSelector = ({carNumberValue, setCarNumberValue, hasLabel, hasError, carLimit = 1}) => {
    const [carNumberInputValue, setCarNumberInputValue] = useState('');

    const handleKeyDown = (event) => {
        if (carNumberValue?.length >= carLimit || !carNumberInputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setCarNumberValue((prev) => {
                    return [...(prev || []), createOption(carNumberInputValue)]
                })
                setCarNumberInputValue('');
                event.preventDefault();
        }
    };
    return (
        <FormGroup>
            {hasLabel && <ControlLabel style={{color: hasError && '#a94442'}}>Номер автомобиля</ControlLabel>}
            <CreatableSelect
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '12px',
                        minHeight: '30px',
                        borderColor: hasError ? '#a94442' : '#ccc',
                    }),
                }}
                textFieldProps={{
                    label: 'Label',
                    InputLabelProps: {
                        shrink: true,
                    },
                }}
                components={{
                    DropdownIndicator: null,
                }}
                inputValue={carNumberInputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => setCarNumberValue(newValue)}
                onInputChange={(newValue) => setCarNumberInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Введите значения"
                value={carNumberValue}
            />
        </FormGroup>
    )
}
