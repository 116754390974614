import React from 'react'
import Modal from 'react-modal'
import { modalDefaultStyles } from '../../App'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ModalButton, ModalTitle } from '../common/ModalParts'

import { Form, MainFormPart, Field, ModalControls } from './components/common'
import { FormControl, FormGroup } from 'react-bootstrap'
import { RoomSelect } from './components/RoomSelect'
import { useSelector } from 'react-redux'
import { specialServiceTypesSelectors } from '../../Modules/special-service-types'

const ValidationSchema = Yup.object().shape({
  bookingType: Yup.string()
    .oneOf(['Агода', 'Экспедиа'])
    .required(),
  paymentAmount: Yup.string()
    .matches(/^[0-9]{1,}([,.][0-9]+)?$/)
    .trim()
    .required(),
  room: Yup.string().required(),
})

export const BookingSellActionModal = ({
  title,
  close,
  submitAction,
  ...restProps
}) => {
  const livingService = useSelector(specialServiceTypesSelectors.livingService)
  return (
    <Modal
      defaultStyles={{
        ...modalDefaultStyles,
        content: {
          ...modalDefaultStyles.content,
          width: 400,
          minWidth: 400,
          maxWidth: 'auto',
        },
      }}
      {...restProps}
    >
      <Formik
        initialValues={{
          bookingType: '',
          room: '',
          paymentAmount: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          const normalizedValues = ValidationSchema.cast(values)

          const { bookingType, paymentAmount, room } = normalizedValues
          const formattedPaymentAmount = paymentAmount.replace(',', '.').trim()

          submitAction({
            reservationPk: '-',
            room,
            guestName: bookingType,
            bookingNumber: bookingType,
            paymentAmount: formattedPaymentAmount,
            livingService,
          })

          actions.setSubmitting(false)
          close()
        }}
        render={({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <ModalTitle>{title}</ModalTitle>
            <MainFormPart>
              <FormGroup>
                <FormControl
                  name="bookingType"
                  componentClass="select"
                  value={values.bookingType}
                  onChange={handleChange}
                >
                  <option value="">Выберите тип</option>
                  <option value="Агода">Агода</option>
                  <option value="Экспедиа">Экспедиа</option>
                </FormControl>
              </FormGroup>

              <FormGroup>
                <Field
                  name="paymentAmount"
                  placeholder="Сумма в рублях"
                  value={values.paymentAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.paymentAmount && errors.paymentAmount}
                />
              </FormGroup>

              <RoomSelect
                name="room"
                value={values.room}
                onChange={handleChange}
              />
            </MainFormPart>

            <ModalControls>
              <ModalButton
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={close}
              >
                Закрыть
              </ModalButton>
              <ModalButton
                type="submit"
                bsStyle="success"
                style={{ marginLeft: 'auto' }}
                disabled={!isValid || isSubmitting}
              >
                Произвести продажу
              </ModalButton>
            </ModalControls>
          </Form>
        )}
      />
    </Modal>
  )
}
