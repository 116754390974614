import { createSelector } from 'reselect'

export const everything = state => state.allPrepayeds

export const prepayedsObj = state => everything(state).prepayeds

export const prepayeds = state => everything(state).prepayeds.results

export const count = state => everything(state).prepayeds.count

export const previousPage = createSelector(
  prepayedsObj,
  (prepayeds) => {
    return prepayeds.previous ? new URLSearchParams(prepayeds.previous.split('/?')[1]).get('page') : null
  }
)

export const nextPage = createSelector(
  prepayedsObj,
  (prepayeds) => {
    return prepayeds.next ? new URLSearchParams(prepayeds.next.split('/?')[1]).get('page') : null
  }
)

export const currentPage = createSelector(
  prepayedsObj,
  (prepayeds) => {
    if(prepayeds.previous) {
      const page = new URLSearchParams(prepayeds.previous.split('/?')[1]).get('page')
      return page ? page + 1 : 2
    } else return 1
  }
)

export const isPrepayedsLoading = state => everything(state).isPrepayedsLoading

export const isPrepayedsLoaded = state => everything(state).isPrepayedsLoaded

export const isPrepayedsFailed = state => everything(state).isPrepayedsFailed

export const prepayedsLoadingState = createSelector(
  isPrepayedsLoading,
  isPrepayedsLoaded,
  isPrepayedsFailed,
  (isLoading, isLoaded, isFailed) => ({ isLoading, isLoaded, isFailed })
)

export const reservationsData = state => everything(state).reservationsData

export const isReservationsDataLoading = state =>
  everything(state).isReservationsDataLoading

export const isReservationsDataLoaded = state =>
  everything(state).isReservationsDataLoaded

export const isReservationsDataFailed = state =>
  everything(state).isReservationsDataFailed

export const reservationsDataLoadingState = createSelector(
  isReservationsDataLoading,
  isReservationsDataLoaded,
  isReservationsDataFailed,
  (isLoading, isLoaded, isFailed) => ({ isLoading, isLoaded, isFailed })
)

export const reservationListsByBookingNumber = createSelector(
  reservationsData,
  reservationsData =>
    reservationsData.reduce((acc, data) => {
      const { booking_number, reservations } = data

      acc[booking_number] = reservations

      return acc
    }, {})
)
