import React, {Component} from 'react'
import Textfield from '../common/Textfield'
import debounce from 'lodash.debounce'
import CheckBoxComponent from '../common/Checkbox'
import SearchResults from '../common/SearchResults'
import {searchCall} from '../../Modules/api/ccAPI/requests'
import {connect} from 'react-redux'
import {sessionSelectors} from '../../Modules/session'

const SearchBlock = props => (
    <div
        style={{
            backgroundColor: '#eee',
            width: 1200,
            margin: '50px auto',
            padding: 30,
            borderRadius: 5,
        }}
    >
        {props.children}
    </div>
)

class Search extends Component {
    constructor(props) {
        super(props)

        this.setQuery = debounce(this.setQuery, 500)
    }

    state = {
        query: '',
        isRefund: false,
        searchResult: null,
        isCanceled: false,
        all_hotels_search: false
    }

    setAllHotelsSearch = all_hotels_search => {
        this.setState({all_hotels_search})
        this.updateResult({
            all_hotels_search,
            query: this.state.query,
            isRefund: this.state.isRefund,
            isCanceled: this.state.isCanceled
        })
    }

    setQuery = query => {
        this.setState({query})
        this.updateResult({
            query,
            isRefund: this.state.isRefund,
            isCanceled: this.state.isCanceled,
            all_hotels_search: this.state.all_hotels_search
        })
    }

    setRefund = isRefund => {
        this.setState({isRefund})
        this.updateResult({
            query: this.state.query,
            isRefund,
            isCanceled: this.state.isCanceled,
            all_hotels_search: this.state.all_hotels_search
        })
    }

    setCanceled = isCanceled => {
        this.setState({isCanceled})
        this.updateResult({
            query: this.state.query,
            isRefund: this.state.isRefund,
            isCanceled,
            all_hotels_search: this.state.all_hotels_search
        })
    }

    setResult = searchResult => this.setState({searchResult})
    clearResult = () => this.setState({searchResult: null})

    updateResult = async ({query, isRefund, isCanceled, all_hotels_search}) => {
        if (!query && !isRefund && !isCanceled && !all_hotels_search) return this.clearResult()
        let items = await searchCall({q: query, all_hotels_search})
        if (isRefund) {
            items = items.filter(it => it.has_refund)
        }
        if (isCanceled) {
            items = items.filter(it => it.canceled)
        }
        this.setResult(items)
    }

    render() {
        return (
            <SearchBlock>
                <Textfield
                    name={'Номер брони, имя или телефон клиента'}
                    onChange={this.setQuery}
                    style={{marginBottom: 15}}
                />

                <CheckBoxComponent
                    title={'Поиск по всем отелям'}
                    value={this.state.all_hotels_search}
                    onChange={this.setAllHotelsSearch}
                    style={{marginBottom: 15}}
                />

                <CheckBoxComponent
                    title={'Только невозвратные'}
                    value={this.state.isRefund}
                    onChange={this.setRefund}
                    style={{marginBottom: 15}}
                />

                <CheckBoxComponent
                    title={'Только отмененные'}
                    value={this.state.isCanceled}
                    onChange={this.setCanceled}
                    style={{marginBottom: 15}}
                />

                <SearchResults
                    reservations={this.state.searchResult}
                    hintText="Введите что-нибудь для поиска"
                    showStatus={true}
                />
            </SearchBlock>
        )
    }
}
const mapStateToProps = state => ({
    lcode: sessionSelectors.lcode(state)
})

export default connect(mapStateToProps)(Search)
