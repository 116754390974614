import { callModal, modalTemplates } from '../../../Components/dynamic-modal'

export const showLoyaltyError = ({ loyaltyError }) => {
  callModal(
    modalTemplates.alert({
      title: 'Ошибка лояльности',
      text: loyaltyError,
    })
  )
}
