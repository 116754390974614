import React, { useEffect } from 'react'
import { EditSection } from '../../common/EditSection'
import { Content } from './components/Content'
import { useSelector } from 'react-redux'
import { settingsSelectors } from '../../../Modules/settings'
import { reservationSelectors } from '../../../Modules/reservation'
import { loyaltySelectors } from '../../../Modules/loyalty'
import { useActions } from '../../common/hooks/use-actions'
import { loyaltyPurchaseActions } from '../../../Modules/loyalty-purchase'

export const LoyaltyPurchase = () => {
  const isLoyaltyEnabled = useSelector(settingsSelectors.isLoyaltyEnabled)
  const isLoyaltyAllowed = useSelector(reservationSelectors.isLoyaltyAllowed)
  const isLoyaltyUsed = useSelector(loyaltySelectors.isUsed)
  const isClientActual = useSelector(loyaltySelectors.isClientActual)
  const isStartOk = useSelector(
    reservationSelectors.isStartOkForManualLoyaltyApply
  )
  const { reset } = useActions(loyaltyPurchaseActions)

  useEffect(() => {
    if (!isStartOk) {
      reset()
    }
  }, [isStartOk, reset])

  if (!isLoyaltyEnabled) return null
  if (!isLoyaltyAllowed) return null

  if (!isLoyaltyUsed) return null
  if (!isClientActual) return null

  if (!isStartOk) return null

  return (
    <EditSection
      size="small"
      name="Бонусы"
      content={<Content />}
      withVisibilityToggle
      customColor="white"
    />
  )
}
