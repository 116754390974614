import { sendBugReport } from '../../bug-report/send'
import { LogsAPI } from '../../logs-api'
import { createInventory } from '../../api/ccAPI/requests'

export const saveInventory = async ({ checkType, items, reduxStates }) => {
  try {
    LogsAPI.event('Начато сохранение /inventory')

    const { reservation, session } = reduxStates.current

    if (!reservation.pk) {
      throw new Error('отсутствует pk брони')
    }

    const { specialServices } = reservation

    const nameField = checkType === 'sell' ? 'service' : 'name'

    const inventoryItems = items.services
      .map(service => {
        const serviceData = specialServices.find(
          ({ name }) => name === service[nameField]
        )

        return { service, serviceData }
      })
      .filter(({ serviceData }) => {
        // is special
        return Boolean(serviceData)
      })
      .filter(({ serviceData }) => {
        return serviceData.is_inventory_enabled
      })

    if (inventoryItems.length === 0) {
      LogsAPI.event(
        'Прервано сохранение /inventory - не найдено подходящих услуг'
      )
      return { success: true }
    }

    const { booking_number } = reservation
    const { admin, lcodeObj } = session

    const action = checkType === 'sell' ? 'sell' : 'income'

    for (const { service, serviceData } of inventoryItems) {
      await createInventory({
        booking_number,
        booking_number_uuid: reservation.pk,
        lcode: lcodeObj.pk,
        admin,
        position: serviceData.pk,
        qty: service.quantity,
        action,
      })
    }

    return { success: true }
  } catch (error) {
    const errorMessage = error?.message ?? 'Неизвестная ошибка'
    const message = `Не удалось сохранить /inventory: ${errorMessage}`

    LogsAPI.error(message)

    sendBugReport({
      message: message + '(сообщение сгенерировано автоматически)',
    })

    return { success: false, error: errorMessage }
  }
}
