import axios from 'axios'
import { logoutAdmin } from '../../session/actions'
import Cookies from 'js-cookie'
import * as types from '../../settings/types'
import { localhost } from '../../../Constants/urls'
import { loyaltyErrors } from './constants/loyaltyErrors'

const CC_TOKEN = 'CC_TOKEN'

let defaultUrl = process.env.REACT_APP_PMS_BE_BASE_URL

const localSettings = Cookies.get(types.LOCAL_SETTINGS)
if (localSettings) {
  if (JSON.parse(localSettings).useLocalBaseUrl) {
    defaultUrl = localhost
  }
}
const loyaltyUrl = defaultUrl + '/loyalty'

const axiosInstance = axios.create({
  baseURL: loyaltyUrl,
})

export const loyaltyRequest = async ({
                                       url,
                                       method,
                                       data,
                                       params,
                                       headers = {},
                                       useToken = true,
                                       ...rest
                                     }) => {
  const addedHeaders = {}

  const token = localStorage.getItem(CC_TOKEN)
  addedHeaders.Authorization = `Bearer ${token}`
  return axiosInstance({
    url,
    method,
    data: {
      body: data
    },
    params,
    headers: {
      ...addedHeaders,
      ...headers,
    },
    ...rest,
  })
    .then(res => {
      const data = res.data
      if (data.errorCode) {
        return data
      }
      return data
    })
    .catch(() => {
      return {
        errorCode: 'WRAPPER_ERROR',
        description: loyaltyErrors.WRAPPER_ERROR,
      }
    })
}

function ccRequestInterceptors(store) {
  axiosInstance.interceptors.response.use(
    requestState => requestState,
    async error => {
      if (!error) return
      const { response } = error
      if (response && response.status === 401) {
        await store.dispatch(logoutAdmin())
        window.location = '/login'
      }
      return Promise.reject(error.response.data)
    }
  )
}
