import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { bindActionCreators } from 'redux'

export function useActions(actionCreators) {
  const dispatch = useDispatch()

  const boundActionCreators = useMemo(() => {
    return bindActionCreators(actionCreators, dispatch)
  }, [actionCreators, dispatch])

  return boundActionCreators
}
