import paymentTypes from '../../helpers/paymentTypes'
import { dayTypes } from '../../helpers/types'

const getItems = reservation => {
  const { reserved_days, additional_services } = reservation

  // Get days from
  // - not payed days with filled payment_date field
  // - newly created days with filled payment_date field
  const daysFilterFunc = d =>
    d.payment_date !== '' && d.payment_type !== paymentTypes.bank

  const days = reserved_days
    .filter(day => [dayTypes.new, dayTypes.notPayed].includes(day.type))
    .filter(daysFilterFunc)

  // Get services from additional services with false payed field
  const servicesFilterFunc = s =>
    !s.payed && s.payment_type !== paymentTypes.bank

  const services = additional_services.filter(servicesFilterFunc)

  return {
    days,
    services,
  }
}

export default getItems
