import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import TableMessage from './TableMessage'

const TitleNote = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
`

const BedclothesDataTable = ({ reservations }) => {
  if (!reservations.length)
    return <TableMessage>Такие комнаты не найдены</TableMessage>
  let counter = 0
  const allRows =
    reservations
      .sort((r1, r2) => r1.room - r2.room)
      .map(({ room, reservation }) => (
          <tr key={reservation.booking_number}>
            <td>{++counter}</td>
            <td>{reservation.booking_number}</td>
            <td>{reservation.check_out_time}</td>
            <td>{reservation.guest_name}</td>
            <td>{room || '--'}</td>
            <td>{reservation.has_refund || !room ? 'Уточнить у админа' : '-'}</td>
          </tr>
        )
      )

  return (
    <Fragment>
      <TitleNote>Всего номеров: {counter}</TitleNote>
      <Table style={{ fontSize: 13 }}>
        <thead>
        <tr>
          <th>№</th>
          <th>Номер брони</th>
          <th>Время выезда</th>
          <th>Имя гостя</th>
          <th>Комнаты</th>
          <th>Примечание</th>
        </tr>
        </thead>
        <tbody>{allRows}</tbody>
      </Table>
    </Fragment>
  )
}

export default BedclothesDataTable
