import React from 'react'
import { ccAPI } from '../api/ccAPI'
import { fullDate } from '../formatters/date'

export const checkPassports = async passports => {
  const passportNumbers = passports
    .map(item => item.passport)
    .filter(Boolean)
    .map(number => number.replace(/ /g, ''))

  const promises = passportNumbers.map(passportNumber => {
    return ccAPI.getPassportBlacklistRecords({
      passport_number: passportNumber,
    })
  })

  try {
    const responses = await Promise.all(promises)

    const records = responses.reduce((acc, response) => {
      return acc.concat(response)
    }, [])

    if (records.length === 0)
      return {
        hasPassed: true,
        hasNetworkError: false,
      }

    const renderedBanLogs = records.map((record, idx) => {
      const { passport_number, reason, created_at } = record

      const date = fullDate(created_at)

      const isLast = idx === records.length - 1

      return (
        <li key={passport_number} style={{ marginBottom: isLast ? 0 : 10 }}>
          <div>
            <b>Номер:</b> {passport_number}
          </div>
          <div>
            <b>Причина:</b> {reason}
          </div>
          <div>
            <b>Дата:</b> {date}
          </div>
        </li>
      )
    })

    const renderedMessage = (
      <>
        <p style={{ marginBottom: 20 }}>
          Бронь не может быть сохранена, так как следующие паспорта были найдены
          в чёрном списке:
        </p>
        <ul style={{ paddingLeft: 20, marginBottom: 0 }}>{renderedBanLogs}</ul>
      </>
    )

    return {
      hasPassed: false,
      hasNetworkError: false,
      renderedMessage,
    }
  } catch (err) {
    return {
      hasPassed: false,
      hasNetworkError: true,
    }
  }
}
