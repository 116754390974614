import React from 'react'
import classNames from 'classnames'
import { useIsSelected } from '../selection/use-is-selected'

const EmptyCell = ({ style, roomID, date, today, isRepair, isBusyByEmployee, status }) => {
  const isSelected = useIsSelected({ room: roomID, date })

  const emptyCellClass = classNames({
    'tbody-cell': true,
    'today': date === today,
    'selected': isSelected,
    'repair': isRepair,
    'is-busy-by-employee': isBusyByEmployee
  })

  return (
    <div
      style={style}
      className={emptyCellClass}
      data-room={roomID}
      data-date={date}
      data-is-empty={true}
    />
  )
}

export default EmptyCell
