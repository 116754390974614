import styled from 'styled-components'

export const Block = styled.div`
  margin: 16px 0;
`

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
`

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
`

export const StyledItem = styled.li`
  display: block;
  margin: 0 0 5px;

  &:last-child {
    margin-bottom: 0;
  }
`
