import React from 'react'
import { Button } from 'react-bootstrap'
import styled, { css } from 'styled-components'

const mapPositionToStyle = {
  left: null,
  right: css`
    margin-left: auto !important;

    & ~ [position='right'] {
      margin-left: 10px !important;
    }
  `,
}

const ModalButton = styled(Button).attrs(props => ({
  bsSize: 'sm',
  bsStyle: props.color,
}))`
  margin-right: 10px;
  margin-bottom: 10px;

  ${props => mapPositionToStyle[props.position]}
`

export function renderButtons({
  buttons,
  defaultButtons,
  resolve,
  params = {},
  beforeClick = () => {},
}) {
  const result = []

  let key = 1

  function appendButton(options) {
    const { type = 'button', text, color, action, position = 'right' } = options

    const dependingProps = {}

    if (action) {
      if (type !== 'submit') {
        dependingProps.onClick = () => {
          beforeClick(options)
          return resolve(action, params)
        }
      } else {
        console.warn('Button with type "submit" cannot have action')
      }
    }

    result.push(
      <ModalButton
        key={key++}
        type={type}
        color={color}
        position={position}
        {...dependingProps}
      >
        {text}
      </ModalButton>
    )
  }

  for (let i = 0; i < buttons.length; i++) {
    const button = buttons[i]

    if (typeof button === 'object') {
      appendButton(button)

      continue
    }

    if (typeof button === 'string') {
      if (defaultButtons[i]) {
        appendButton({
          ...defaultButtons[i],
          text: button,
        })
      }

      continue
    }

    if (typeof button === 'function') {
      result.push(
        button({
          ModalButton,
          resolve,
          params,
          key: key++,
        })
      )

      continue
    }
  }

  return result
}
