import { useSelector } from 'react-redux'
import { breakfastsActions, breakfastsSelectors } from '../../../Modules/breakfasts'
import { reservationSelectors } from '../../../Modules/reservation'
import { useActions } from '../../common/hooks/use-actions'
import React, { useCallback, useEffect, useState } from 'react'
import { useModalState } from '../../common/hooks/use-modal-state'
import { EditSection } from '../../common/EditSection'
import PrePayedBreakfast from '../../reservation/common/PrePayedBreakfast'
import { ChangeBreakfastModal } from '../../reservation/common/EditBreakfastModal'

export const PrePaidBreakfasts = ({
                                    prePayedBreakfast,
                                  }) => {
  const breakfastByPk = useSelector(breakfastsSelectors.breakfastByPk)
  const breakfastActionsList = useSelector(breakfastsSelectors.breakfastValueList)

  const isNotRefund = useSelector(reservationSelectors.isNotRefundMode)

  const availableDates = useSelector(
    reservationSelectors.availableBreakfastDates
  )
  const { update, load } = useActions(breakfastsActions)

  const [renderData, setRenderData] = useState([])

  useEffect(() => {
      setRenderData(prePayedBreakfast
        ? prePayedBreakfast.map(it => {
          const res = { ...it }
          res.breakfastObj = breakfastByPk[it.breakfast]
          return res
        })
        : [])
    }, [prePayedBreakfast]
  )

  const {
    isOpen: islModalOpen,
    open: openModal,
    close: closeModal,
  } = useModalState()


  const [selectedData, setSelectedData] = useState(null)


  const selectBreakfast = useCallback(breakfast => {
    setSelectedData(breakfast)
    openModal()
  }, [openModal])

  if (!prePayedBreakfast) return null

  const save = async ({ pk, date }) => {
    selectedData.breakfast = pk
    selectedData.delivery_date = date
    await update(selectedData.pk, selectedData)
    await load()
    setRenderData(
      renderData.map(it => {
        if (it.pk === selectedData.pk) {
          it.breakfast = pk
          it.breakfastObj = breakfastByPk[pk]
        }
        return it
      })
    )
    setSelectedData(null)
  }
  return (
    renderData && (
      <>
        <EditSection
          name="Предоплаченные завтраки"
          content={renderData
            .filter(it => it.is_prepayed === true)
            .sort(
              (d1, d2) =>
                new Date(d1.delivery_date) - new Date(d2.delivery_date)
            )
            .map(breakfast => (
              <PrePayedBreakfast
                key={breakfast.service_uuid}
                breakfast={breakfast}
                editable={isNotRefund && availableDates.find(it => it === breakfast.delivery_date)}
                click={() => selectBreakfast(breakfast)}
              />
            ))}
        />
        {selectedData && (
          <ChangeBreakfastModal
            isOpen={islModalOpen}
            hideModal={closeModal}
            editData={selectedData}
            breakfasts={breakfastActionsList}
            availableDates={availableDates}
            save={save}
            breakfast_pk={selectedData.breakfast}
            delivery_date={selectedData.delivery_date}
            qty={selectedData.qty}
          />
        )}
      </>
    )
  )
}
