import { getRoomsCall } from '../../ccAPI/requests'
import { request } from './request'
import moment from 'moment'

const API_KEY_FIELD = 'API-KEY'

export const checkinCall = ({
                              room, check_in_date,
                              check_in_time,
                              check_out_date,
                              check_out_time,
                              guestName,
                              baseUrl, apiKey
                            }) =>
  request({
    url: `${baseUrl}/IssueCard/`,
    method: "post",
    headers: {
      [API_KEY_FIELD]: apiKey
    },
    data: {
      room,
      checkInDateTime: check_in_date + " " + check_in_time,
      checkOutDateTime: check_out_date + " " + check_out_time,
      guestName,
      cardIssuer: "info_HSU",
      cardType: 0,
    },
    log: true,
  }).then(() => ({
    method: "checkin",
    room
  }) )

export const checkoutCall = ({ room, baseUrl, apiKey }) =>
  request({
    url: `${baseUrl}/CheckOut/`,
    method: 'post',
    headers: {
      [API_KEY_FIELD]: apiKey
    },
    data: {
      room,
      cardIssuer: "Info_HSU"
    },
    log: true,
  })

export const copycardCall = ({
                               room,
                               check_in_date,
                               check_in_time,
                               check_out_date,
                               check_out_time,
                               guestName,
                               baseUrl, apiKey
                             }) =>
  request({
    url: `${baseUrl}/IssueJoinerCard/`,
    method: "post",
    headers: {
      [API_KEY_FIELD]: apiKey
    },
    data: {
      room,
      checkInDateTime: check_in_date + " " + check_in_time,
      checkOutDateTime: check_out_date + " " + check_out_time,
      guestName,
      cardIssuer: "info_HSU",
      cardType: 1,
    },
    log: true,
  }).then(() => ({
    method: "copycard",
    room
  }))

export const checkCardCall = async ({ lcode, baseUrl, apiKey }) => {
  return await readCardCall({ baseUrl, apiKey }).then(async res => {
    const { room, checkInDateTime, checkOutDateTime } = res
    if (!room) {
      return {
        isSuccess: true,
        meta: { isEmpty: true },
      }
    }
    const rooms = await getRoomsCall(lcode)
    const roomObj = rooms.find(it => it.room_id === room)

    if (!roomObj)
      return {
        isSuccess: true,
        meta: { isEmpty: false, roomNotFound: true },
      }

    const { name: roomName } = roomObj

    const checkin = moment(checkInDateTime, 'DD/MM/YYYY HH:mm').format('DD.MM.YYYY HH:mm')
    const [checkInDate, checkInTime] = checkin.split(" ")

    const checkout = moment(checkOutDateTime, 'DD/MM/YYYY HH:mm').format('DD.MM.YYYY HH:mm')
    const [checkOutDate, checkOutTime] = checkout.split(" ")

    return {
      isSuccess: true,
      meta: {
        isEmpty: false,
        roomNotFound: false,
        roomID: room,
        roomName,
        checkInDate,
        checkInTime,
        checkOutDate,
        checkOutTime,
      },
    }
  }).catch(e => ({
    isSuccess: false,
    error: e.message || 'Неизвестная ошибка',
  }))
}

export const readCardCall = ({ baseUrl, apiKey }) =>
  request({
    url: `${baseUrl}/ReadCard/`,
    method: "post",
    headers: {
      [API_KEY_FIELD]: apiKey
    },
  })


export const clearCardCall = ({ baseUrl, apiKey }) =>
  readCardCall({ baseUrl, apiKey }).then(({ room }) =>
    checkoutCall({ room, baseUrl, apiKey })
      .then(_ => ({ status: 'ok' })))
    .catch(e => ({
      isSuccess: false,
      error: e.message || 'Неизвестная ошибка',
    }))

