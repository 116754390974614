export function createPreSaveDebugMessage(reservation) {
  const days = reservation.reserved_days
    .map(day => {
      const { date, price, payment_type, payment_date, discount = 0 } = day
      return `${date}|${price}|${payment_type}|${payment_date}|${discount}`
    })
    .join(', ')

  const services = reservation.additional_services
    .map(service => {
      const {
        uuid,
        date,
        service: name,
        price,
        quantity,
        payment_type,
        payment_date,
        discount = 0,
      } = service

      return `${uuid}|${date}|${name}|${price}|${quantity}|${payment_type}|${payment_date}|${discount}`
    })
    .join(', ')

  return `Данные при сохранении: ДНИ: ${days}; УСЛУГИ: ${services}`
}
