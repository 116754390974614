import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CheckTimes } from './CheckTimes'
import { reservationSelectors } from '../../../Modules/reservation'
import {
  createCheckIn,
  createCheckOut,
  deleteCheckIn,
  deleteCheckOut,
  refundCheckIn,
  refundCheckOut,
  changeCheckInTime,
  changeCheckOutTime,
  changeCheckInPaymentType,
  changeCheckOutPaymentType,
  changeCheckInPrice,
  changeCheckOutPrice,
} from '../../../Modules/reservation/actions/services'

const mSTP = (state, ownProps) => ({
  checkIn: reservationSelectors.checkInService(state),
  checkOut: reservationSelectors.checkOutService(state),
  checkInTime: reservationSelectors.checkInTime(state),
  checkOutTime: reservationSelectors.checkOutTime(state),
  validationErrors: reservationSelectors.servicesValidationErrors(state),
  ...ownProps,
})

const mDTP = dispatch => ({
  actions: {
    checkIn: bindActionCreators(
      {
        create: createCheckIn,
        changeTime: changeCheckInTime,
        changePaymentType: changeCheckInPaymentType,
        changePrice: changeCheckInPrice,
        delete: deleteCheckIn,
        refund: refundCheckIn,
      },
      dispatch
    ),
    checkOut: bindActionCreators(
      {
        create: createCheckOut,
        changeTime: changeCheckOutTime,
        changePaymentType: changeCheckOutPaymentType,
        changePrice: changeCheckOutPrice,
        delete: deleteCheckOut,
        refund: refundCheckOut,
      },
      dispatch
    ),
  },
})

export const ConnectedCheckTimes = connect(mSTP, mDTP)(CheckTimes)
