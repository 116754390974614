import React from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

const TextField = props => {
  return (
    <FormGroup
      style={props.style ? props.style : { marginBottom: 0 }}
      validationState={props.validationState}
    >
      <ControlLabel style={{ letterSpacing: 0.5 }}>{props.name}</ControlLabel>
      <FormControl
        type={props.type}
        defaultValue={props.defaultValue}
        value={props.value}
        placeholder={props.placeholder}
        onChange={e => props.onChange(e.target.value)}
        disabled={props.disabled}
        inputRef={props.setRef || null}
      />
    </FormGroup>
  )
}

export default TextField
