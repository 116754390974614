import styled from 'styled-components'

export const Block = styled.div`
  margin: 16px 0;
`

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`

export const Row = styled.tr`
  vertical-align: baseline;
`

export const HeadCell = styled.th`
  font-weight: 500;
  padding: 3px 6px;
`

export const Cell = styled.td`
  padding: 3px 6px;
`
