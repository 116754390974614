import * as types from '../types'

export const changeField = (field, value) => ({
  type: types.CHANGE_FIELD,
  payload: {
    field,
    value,
  },
})

export const setPk = value => changeField('pk', value)
