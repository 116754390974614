import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import uuid from 'uuid/v4'
import { ccAPI } from '../../Modules/api/ccAPI'
import { washingActions, washingSelectors } from '../../Modules/washing'
import { callAlert, callPrompt } from '../dynamic-modal'
import { useActions } from '../common/hooks/use-actions'
import { sessionSelectors } from '../../Modules/session'
import { startWashing } from '../../Modules/api/ccAPI/requests'

const PageBlock = styled.div`
  background-color: #eee;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  text-align: center;
`

const Message = styled.p`
  text-align: center;
  color: ${props => props.color ?? '#333'};
  font-size: ${props => props.size ?? '16px'};
`

export const FreeWashing = () => {
  const lcode = useSelector(sessionSelectors.lcode)
  const freeMachines = useSelector(washingSelectors.freeMachines)
  const [loading, setLoading] = useState(false)
  const [usedMachine, setUsedMachine] = useState(null)
  const { loadMachines } = useActions(washingActions)

  const createWashing = async () => {
    setUsedMachine(null)

    const password = await callPrompt({
      title: 'Проверка',
      text: ['Введите пароль для стирки', 'Запросите его у админ. директора'],
    })

    if (password == null) {
      return
    }
    setLoading(true)
    await ccAPI
      .getWashingMachinePassword(password)
      .then(async () => {
        const [firstFree] = freeMachines
        try {
          await startWashing({
            lcode,
            machine: firstFree.number,
            service_uuid: uuid(),
          })

          callAlert({
            title: 'Стирка создана',
            text: `Номер вашей машинки: ${firstFree.number}`,
          })

          loadMachines()
        } catch {
          callAlert({
            title: 'Ошибка',
            text: 'Не удалось создать стирку',
          })
        }
      })
      .catch(() => {
        callAlert({
          title: 'Ошибка',
          text: 'Неправильный пароль',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <PageBlock>
      <Message>Свободных машинок: {freeMachines.length}</Message>

      <Button
        onClick={createWashing}
        disabled={freeMachines.length === 0 || loading}
        style={{ marginBottom: 8 }}
      >
        Создать стирку
      </Button>

      {loading && <Message size="14px">Загрузка..</Message>}

      {usedMachine !== null && (
        <Message color="#10a530" size="14px">
          Стирка создана. Номер вашей машинки: {usedMachine}
        </Message>
      )}
    </PageBlock>
  )
}
