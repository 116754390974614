import styled from 'styled-components'

export default styled.div`
  justify-content: flex-end;
  flex-shrink: 0;
  position: sticky;
  top: 90px;
  width: 240px;
  margin-left: 8px;
`
