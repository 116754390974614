import paymentTypes from '../../../../helpers/paymentTypes'

function filterItems(items) {
  return items.filter(item => item.payment_type !== paymentTypes.bank)
}

export const getPrintedItems = ({ items, printScopes }) => ({
  days: printScopes.days ? filterItems(items.days) : [],
  services: printScopes.services ? filterItems(items.services) : [],
})
