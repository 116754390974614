import React from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { reservationSelectors } from '../../../../../Modules/reservation'
import { Item } from './Item'

export const Content = ({ actions, validationErrors, availableActions, carLimit }) => {
  const parkingServices = useSelector(reservationSelectors.parkingServices)

  return (
    <Table style={{ marginBottom: 0 }}>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Стоимость за штуку</th>
          <th>Количество</th>
          <th>Номер автомобиля</th>
          <th>Метод оплаты</th>
          <th
            style={{
              textAlign: 'right',
            }}
          >
            Действия
          </th>
        </tr>
      </thead>
      <tbody>
        {parkingServices.map(item => (
          <Item
            key={item.id}
            data={item}
            validationErrors={validationErrors[item.id] || {}}
            actions={actions}
            availableActions={availableActions}
            carLimit={carLimit}
          />
        ))}
      </tbody>
    </Table>
  )
}
