import React from 'react'
import { Table as BootstrapTable } from 'react-bootstrap'

export const Table = ({ children }) => {
  return (
    <BootstrapTable
      responsive
      className="table-hover condensed"
      style={{ fontSize: 12, width: '100%' }}
    >
      <thead>
        <tr>
          <th>Гость</th>
          <th>Дата заезда</th>
          <th>Дата выезда</th>
          <th style={{ textAlign: 'right' }}>Цена</th>
          <th />
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </BootstrapTable>
  )
}
