import moment from 'moment'
import uuid from 'uuid/v4'
import { getDiscountedItemTotal, getItemTotal } from '../item-selectors'
import { loyaltyAPI } from '../../api/loyaltyAPI'

export async function cancelBonusesForItems({
  days,
  services,
  phoneNumber,
  settings,
  session,
}) {
  const hasRefundId = item => Boolean(item.refund_id)

  const groupByRefundId = (acc, item) => {
    const total = item.service_type ? getDiscountedItemTotal(item) : getItemTotal(item)

    const refundId = item.refund_id
    const executedAt = item.payed_at

    const current = acc[refundId] || {
      total: 0,
      executedAt: null,
    }

    acc[refundId] = {
      total: current.total + total,
      executedAt,
    }

    return acc
  }

  const toPlainArray = refundsById => {
    const result = []

    for (let refundId in refundsById) {
      const { total, executedAt } = refundsById[refundId]

      result.push({
        refundId,
        total,
        executedAt,
      })
    }

    return result
  }

  const byExecutedAt = (i1, i2) => {
    return new Date(i2.executedAt) - new Date(i1.executedAt)
  }

  const itemsToProcess = [
    ...days.filter(hasRefundId),
    ...services.filter(hasRefundId),
  ]

  const refundsById = itemsToProcess.reduce(groupByRefundId, {})
  const refunds = toPlainArray(refundsById).sort(byExecutedAt)

  for (let refund of refunds) {
    const { refundId, total } = refund

    const calculateRes = await loyaltyAPI.calculateReturn({
      calculate: {
        phoneNumber,
        purchaseId: refundId,
        refundAmount: total,
      },
    })

    if (calculateRes.errorCode)
      return {
        isSuccess: false,
        errorCode: calculateRes.errorCode,
      }

    const { calculation } = calculateRes
    const { lcode, lcodeName } = session

    const applyRes = await loyaltyAPI.applyReturn({
      transaction: {
        phoneNumber,
        id: uuid(),
        executedAt: moment().format(),
        purchaseId: calculation.purchaseId,
        refundAmount: calculation.refundAmount,
        shopCode: lcode,
        shopName: lcodeName,
      },
    })

    if (applyRes.errorCode)
      return {
        isSuccess: false,
        errorCode: applyRes.errorCode,
      }
  }

  return {
    isSuccess: true,
  }
}
