import React from 'react'
import { Actions } from './Actions'
import { Info } from './Info'

export const Content = ({ loyaltyClient, isActual, loyaltyClientActions }) => {
  const { data, isLoading, isLoaded, isFailed, error } = loyaltyClient

  return (
    <React.Fragment>
      <Info
        data={data}
        isActual={isActual}
        isLoading={isLoading}
        isLoaded={isLoaded}
        isFailed={isFailed}
        error={error}
      />
      <Actions
        isLoaded={isLoaded}
        isLoading={isLoading}
        isActual={isActual}
        setup={loyaltyClientActions.setupLoyalty}
        sendRegistrationSMS={loyaltyClientActions.sendRegistrationSMS}
        updatePhoneNumber={loyaltyClientActions.updatePhoneNumber}
      />
    </React.Fragment>
  )
}
