import * as types from './types'

const initialState = {
  loyaltyClientsPhones: [],
  isApplying: false,
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  errorMessage: null,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_LOYALTY_CLIENTS_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      }

    case types.LOAD_LOYALTY_CLIENTS_SUCCESS:
      return {
        ...state,
        loyaltyClientsPhones: payload.loyaltyClientsPhones,
        isLoading: false,
        isLoaded: true,
      }

    case types.LOAD_LOYALTY_CLIENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        errorMessage: payload.message ?? 'Неизвестная ошибка',
      }

    case types.START_APPLYING:
      return {
        ...state,
        isApplying: true,
      }

    case types.STOP_APPLYING:
      return {
        ...state,
        isApplying: false,
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
