export function createCheckLine({ name, price, quantity, total }) {
  return {
    Register: {
      Name: name,
      Quantity: quantity,
      Price: price,
      Amount: total,
      Tax: -1,
      SignCalculationObject: 4,
      SignMethodCalculation: 4,
      MeasurementUnit: 'шт',
    },
  }
}
