import {
  checkPaymentTypes,
  deleteReservation, emailConfirmation, getValidation,
  LOADING_HID,
  LOADING_SHOW,
  logError,
  logEvent,
  logPressAction,
  setReservation, SHOW_CHANGE_DAYS_COUNT_MODAL,
  showPrintServerError,
  SUCCESS_MODAL_SHOW,
  validate,
} from '../../ReservationModule'
import doNeedToPrint from '../../../CheckPrintController/doNeedToPrint'
import { doNeedToPrintAdvanced } from '../../doNeedToPrintAdvanced'
import isServerRunning from '../../../CheckPrintController/isServerRunning'
import history from '../../../helpers/history'
import { reservationSelectors } from '../..'
import { loyaltySelectors } from '../../../loyalty'
import { ReservationSaving } from '../../../reservation-saving'
import { prepareReservation } from './prepareReservation'
import { checkIfNeedToUpdateCards } from '../../../helpers/checkIfNeedToUpdateCards'
import { checkPassports } from '../../checkPassports'
import { LogsAPI } from '../../../logs-api'
import {
  callModal,
  modalTemplates,
} from '../../../../Components/dynamic-modal'
import { createPreSaveDebugMessage } from '../helpers/create-pre-save-debug-message'
import { MODES } from '../../../helpers/types'
import { useLoyaltyHandler } from '../helpers/use-loyalty-handler'
import { finishPurchaseBonusesCalculation } from '../helpers/finish-purchase-bonuses-calculation'
import { settingsSelectors } from '../../../settings'
import { getItemsCashOrCard } from '../../../reservation-saving/scenaries/purchase/helpers/get-items'
import {
  checkReservationInsertionPossibilityCall,
  getReadyReservationsByBookingNumber,
  saveReservationCall
} from '../../../api/ccAPI/requests'
import React from 'react'

export const saveExistingReservation = ({
  showWritecardModal,
  onSuccess,
}) => async (dispatch, getState) => {
  dispatch(logPressAction('Сохранить бронь'))
  let state = getState()

  const reservation = reservationSelectors.everything(state)
  const modes = reservationSelectors.modes(state)

  const settings = getState().settings

  await dispatch(checkPaymentTypes())

  await dispatch(validate())

  state = getState()
  const hasValidationErrors = reservationSelectors.hasValidationErrors(state)
  const hasPaymentValidationErrors = reservationSelectors.hasPaymentValidationErrors(state)

  if (hasValidationErrors || hasPaymentValidationErrors) {
    return
  }

  const loyaltyValidation = loyaltySelectors.validation(state)

  if (!loyaltyValidation.isValid) {
    callModal(
      modalTemplates.alert({
        title: 'Данные лояльности невалидны',
        text: [loyaltyValidation.error, loyaltyValidation.suggestion],
      })
    )
    return
  }

  dispatch({ type: LOADING_SHOW })

  if(!modes.refund) {
    const { hasPassed, hasNetworkError, renderedMessage } = await checkPassports(
      reservation.passport
    )

    if (!hasPassed && !hasNetworkError) {
      dispatch({ type: LOADING_HID })
      callModal(
        modalTemplates.alert({
          title: 'Найдены заблокированные паспорта',
          text: renderedMessage,
        })
      )
      return
    }
  }

  const insertionPossibilityCheck = await checkReservationInsertionPossibilityCall(reservation.booking_number)

  if (insertionPossibilityCheck.result === 'error') {
    dispatch({ type: LOADING_HID })
    callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: 'Выбранные даты в выбранном номере заняты другой бронью',
      })
    )
    return
  }

  if (modes.wubook) {
    try {
      const ready = await getReadyReservationsByBookingNumber(
        reservation.booking_number
      )
      if (ready.length > 0) {
        LogsAPI.event(
          `Найден уже заселенный дубликат с номером ${reservation.booking_number}`
        )

        const { action } = await callModal(
          modalTemplates.confirm({
            title: 'Конфликт',
            text: [
              'Найден уже заселенный дубликат с таким номером.',
              'Все равно продолжить сохранение?',
            ],
            buttons: [
              'Отмена',
              {
                text: 'Продолжить сохранение',
                color: 'warning',
                action: modalTemplates.confirm.actions.confirm,
              },
            ],
          })
        )

        if (action !== modalTemplates.confirm.actions.confirm) {
          dispatch({ type: LOADING_HID })
          return
        }

        LogsAPI.pressAction(
          'Продолжить сохранение несмотря на наличие дубликата'
        )
      }
    } catch (err) {
      LogsAPI.error('Не удалось проверить бронь на дубликат')
    }
  }

  // Do need to print check on this editing
  let needToPrint = await doNeedToPrint({
    reservation,
    settings,
    modes,
  })

  let printScopes = { days: true, services: true }
  if (needToPrint) {
    printScopes = await doNeedToPrintAdvanced(
      reservation,
      modes,
      reservation.bnVariants
    )

    needToPrint = printScopes.days || printScopes.services
  }

  // If we need to print check but server is off,
  // show error modal and exit this function
  if (needToPrint) {
    const isServerOK = await isServerRunning()

    if (!isServerOK) {
      dispatch({ type: LOADING_HID })
      dispatch(showPrintServerError())
      return
    }
  }

  const editReservationCallback = async ({ reservation, setScenarioState }) => {
    try {
      const { initialData } = reservation
      const isDoorLocks = settings.doorLocks

      const [
        isNeedToUpdateCards,
        updateCardsReasons,
      ] = checkIfNeedToUpdateCards({
        newData: {
          days: reservation.reserved_days,
          checkInTime: reservation.check_in_time,
          checkOutTime: reservation.check_out_time,
          cards: reservation.hasCard,
        },
        oldData: {
          days: initialData.reserved_days,
          checkInTime: initialData.check_in_time,
          checkOutTime: initialData.check_out_time,
          cards: initialData.hasCard,
        },
      })

      const message = createPreSaveDebugMessage(reservation)
      LogsAPI.custom(message)

      if (reservation.reserved_days.length === 0) {
       const {wasDeleted} =  await dispatch(deleteReservation())

        if(wasDeleted) {
          setScenarioState({
            result: 'deleted',
          })

          dispatch(logEvent('Бронь удалена, так как в ней не осталось дней'))
        } else setScenarioState({
          error: 'Возникла ошибка при возврате бронирования',
        })
      } else {
        const savedReservation = await saveReservationCall({
          ...reservation,
          hasCard: isNeedToUpdateCards ? 0 : reservation.hasCard,
        })

        setScenarioState({
          result: 'saved',
          savedReservation,
        })

        dispatch(logEvent('Бронь успешно сохранена'))
        window.localStorage.setItem("reservation_changed", +new Date());

        if (isDoorLocks ? isNeedToUpdateCards : false) {
          await callModal(
            modalTemplates.alert({
              title: 'Обновление карт',
              text: [
                'Старые карты были сброшены, так как в брони произошли следующие изменения:',
                updateCardsReasons.join(', '),
                'Нажмите «Ок» и перезапишите карты',
              ],
            })
          )

          showWritecardModal()
        }
      }
    } catch (err) {
      dispatch(logError('Не удалось сохранить бронь'))

      setScenarioState({
        result: 'failed',
        error: err?.message ?? err?.detail ?? err?.error ?? 'Неизвестная ошибка',
      })
    }
  }

  const showSavingResultCallback = async ({ scenarioState }) => {
    const { succeed, result, error, savedReservation } = scenarioState

    if (!succeed) {
      callModal(
        modalTemplates.alert({
          title: 'Ошибка при сохранении брони',
          text: error,
        })
      )

      return
    }

    if (result === 'saved') {
      dispatch(
        setReservation({
          reservation: savedReservation,
          mode: MODES.EDIT,
        })
      )

      dispatch({ type: SUCCESS_MODAL_SHOW })

      if (
        modes.refund &&
        reservation.initial_reserved_days_count > reservation.reserved_days.length
      ) {
        dispatch({ type: SHOW_CHANGE_DAYS_COUNT_MODAL })
      }

      onSuccess()

      return
    }

    if (result === 'deleted') {
      await callModal(
        modalTemplates.alert({
          title: 'Бронь была удалена',
          text: [
            'Так как в брони не осталось дней, она была удалена.',
            'Бонусы за оставшиеся услуги были возвращены.',
            'Сейчас вы будете перенаправлены на главную страницу.',
          ],
        })
      )

      history.push('/')
    }
  }

  const printFailureCallback = ({ printResult }) => {
    callModal(
      modalTemplates.alert({
        title: 'Ошибка при печати чека',
        text: printResult.Error,
      })
    )
  }

  const hideLoadingCallback = () => dispatch({ type: LOADING_HID })

  const confirmEmail = async () => {
    const settings = settingsSelectors.everything(state)
    const reservation = reservationSelectors.everything(state)
    if (settings.sendReceiptToEmail && reservation.mode !== 'refund') {
      const items = getItemsCashOrCard(reservation)
      const hasChanges = items.days.length || items.services.length || items.refund.length

      if (hasChanges && settings.printerIsOn) {
        await dispatch(emailConfirmation(reservation))
      }
    }
  }

  const validateReservation = async () => {
    const reservation = reservationSelectors.everything(state)
    return await dispatch(getValidation(reservation))
  }

  const baseOptions = {
    modes,
    printScopes,
    confirmEmail,
    prepareReservation,
    beforePrint: ReservationSaving.callbacks.startPaymentTimer,
    afterPrint: ReservationSaving.callbacks.combine([
      ReservationSaving.callbacks.stopPaymentTimer,
      ReservationSaving.callbacks.logPrintResult,
    ]),
    onFailure: printFailureCallback,
    onFinish: hideLoadingCallback,
    whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
      editReservationCallback,
      ReservationSaving.callbacks.savePaymentAction,
      ReservationSaving.callbacks.saveServiceTransaction,
      ReservationSaving.callbacks.saveBreakfastActions,
      ReservationSaving.callbacks.processWashingServices,
      ReservationSaving.callbacks.saveInventory,
      showSavingResultCallback,
    ]),
  }

  const commonSuccessCallback = ReservationSaving.callbacks.pipe([
    ReservationSaving.callbacks.stopPaymentTimer,
    ReservationSaving.callbacks.savePaymentAction,
    ReservationSaving.callbacks.saveServiceTransaction,
    ReservationSaving.callbacks.saveInventory,
    editReservationCallback,
  ])

  if (modes.refund) {
    return ReservationSaving.handleRefund({
      ...baseOptions,
      onSuccess: ReservationSaving.callbacks.pipe([
        commonSuccessCallback,
        ReservationSaving.callbacks.saveBreakfastActions,
        ReservationSaving.callbacks.deleteIrrelevantWashingOperations,
        showSavingResultCallback,
      ]),
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        editReservationCallback,
        ReservationSaving.callbacks.savePaymentAction,
        ReservationSaving.callbacks.saveServiceTransaction,
        ReservationSaving.callbacks.saveBreakfastActions,
        ReservationSaving.callbacks.deleteIrrelevantWashingOperations,
        ReservationSaving.callbacks.saveInventory,
        showSavingResultCallback,
      ]),
    })
  }

  const { isCanceled } = await finishPurchaseBonusesCalculation({ state })

  if (isCanceled) {
    dispatch({ type: LOADING_HID })
    return
  }

  state = getState()

  const successCallback = ReservationSaving.callbacks.pipe([
    commonSuccessCallback,
    ReservationSaving.callbacks.saveTransaction,
    ReservationSaving.callbacks.saveBreakfastActions,
    ReservationSaving.callbacks.processWashingServices,
    showSavingResultCallback,
  ])

  const handlerOptions = {
    ...baseOptions,
    validateReservation,
    onSuccess: successCallback,
  }

  return useLoyaltyHandler({ state })
    ? ReservationSaving.handleLoyaltyPurchase(handlerOptions)
    : ReservationSaving.handlePurchase(handlerOptions)
}
