import * as types from './types'

const initialState = {
  ruForm: null,
  enForm: null,
  espForm: null,
  chForm: null,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case types.FORMS_LOADED:
      return {
        ...state,
        ruForm: payload['ru'],
        enForm: payload['en'],
        espForm: payload['esp'],
        chForm: payload['ch'],
      }

    default:
      return state
  }
}
