import {callModal, modalTemplates} from "../dynamic-modal";
import history from "../../Modules/helpers/history";
import {logoutAdmin} from "../../Modules/session/actions";
import {checkShift, openShift, resetShift} from "../../Modules/shifts/actions";
import {OPENED} from "../../Modules/shifts/selectors";

export const getOpenShiftModal = (lcode) => async (dispatch, getState) => {
    await dispatch(checkShift(lcode))
    const {shifts, settings} = getState()

    if (!settings.isChecklistEnabled) return

    const {checklistsCompleted, status} = shifts

    if (status !== OPENED) {
        return dispatch(openShiftModal(lcode))
    } else if (!checklistsCompleted) {
        return openCheckListModal(lcode)
    }
    return true
}

export const getCloseShiftModal = (lcode) => async (dispatch, getState) => {
    const {settings} = getState()

    if (!settings.isChecklistEnabled) {
        dispatch(logoutAdmin())
        return
    }

    dispatch(getOpenShiftModal(lcode)).then(res => {
        if (res)
            dispatch(closeShiftModal(lcode))
    })
}

const closeShiftModal = () => async (dispatch) => {
    const action = await callModal(
        modalTemplates.confirm({
            text:
                'Вы желаете закрыть смену?',
            buttons: ['Я передумал', 'Закрыть и выйти', 'Выйти'],
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        history.push('/check-list', {close: true})
    } else if (action.action === modalTemplates.confirm.actions.other) {
        dispatch(resetShift())
        dispatch(logoutAdmin())
    }
}

const openShiftModal = (lcode) => async (dispatch) => {
    const action = await callModal(
        modalTemplates.confirm({
            text:
                'Вы желаете открыть смену?',
            buttons: ['Напомнить позже', 'Открыть смену и заполнить чек-листы', 'Открыть смену'],
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        history.push('/check-list')
    } else if (action.action === modalTemplates.confirm.actions.other) {
        await dispatch(openShift(lcode))
    }
}

const openCheckListModal = async () => {
    const action = await callModal(
        modalTemplates.confirm({
            text:
                'Вы желаете заполнить чек-листы?',
            buttons: ['Напомнить позже', 'Заполнить чек-листы'],
        })
    )
    if (action.action === modalTemplates.confirm.actions.confirm) {
        history.push('/check-list')
    }
}
