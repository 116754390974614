export function getModalOpenLogText({ title, text }) {
  let result = 'Открытие модального окна'

  if (title) {
    result += ` "${title}"`
  }

  if (!text) {
    return result
  }

  let textString = '[EMPTY]'

  if (typeof text === 'string') {
    textString = text
  }

  if (Array.isArray(text)) {
    const textStrings = text.map(p => {
      if (typeof p !== 'string') {
        return '<JSX />'
      }

      return p
    })

    textString = textStrings.join(' [br] ')
  }

  return result + ` с текстом "${textString}"`
}
