import React from 'react'
import moment from 'moment'
import {useSelector} from "react-redux";
import {todayCheckInsSelectors} from "../../reducers/today-check-ins";
import styled from 'styled-components'
import {CheckIns} from "./CheckIns";

const CheckInsTitle = styled.h2`
    font-size: 24px;
    font-weight: 700;
    margin: 0;
`

export const NightCheckins = () => {
    const nightCheckins = useSelector(todayCheckInsSelectors.nightCheckins)
    const count = nightCheckins.length

    if(!count) return <div/>

    return <div style={{padding: 30}}>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
            }}
        >
            <CheckInsTitle> Ожидаемые заезды{' '}
                {moment()
                    .subtract(1, 'days')
                    .format('DD MMMM')}</CheckInsTitle>
        </div>
        <CheckIns count={count} wubook={nightCheckins}/>
    </div>
}

