import paymentTypes from '../../helpers/paymentTypes'
import { getPrintedDiscountedItemTotal } from '../../helpers/item-selectors'
import { currency } from '../../helpers/converters'
import { sendBugReport } from '../../bug-report/send'
import moment from 'moment'
import { saveCheckCall, saveCheckCallV2, saveCheckRefundCallV2 } from '../../api/ccAPI/requests'

function getTotal(value) {
  const total = getPrintedDiscountedItemTotal(value)
  return currency.toString(total)
}

const getSellPositionsLine = (items, usedPaymentType) => {
  const { days, services } = items

  const positions = []

  days
    .filter(day => day.payment_type === usedPaymentType)
    .forEach(day => {
      const total = getTotal(day)
      positions.push(`Проживание за ${day.date}~1~${currency.toNumber(day.price)}`)
    })

  services
    .filter(service => service.payment_type === usedPaymentType)
    .forEach(service => {
      const { service: name, date, quantity } = service
      const total = getTotal(service)
      positions.push(`${name} за ${date}~${quantity}~${total}`)
    })

  return positions.join('@')
}

const getRefundPositionsLine = (items, usedPaymentType) => {
  const { days, services } = items

  const positions = []

  days
    .filter(day => day.payment_type === usedPaymentType)
    .forEach(day => {
      const total = getTotal(day)
      positions.push(`Проживание за ${day.date}~1~${total}`)
    })

  services
    .filter(service => service.payment_type === usedPaymentType)
    .forEach(service => {
      const { name, date, quantity } = service
      const total = getTotal(service)
      positions.push(`${name} за ${date}~${quantity}~${total}`)
    })

  return positions.join('@')
}

const getSellActionItems = (items, usedPaymentType) => {
  const { days, services } = items

  const actionItems = []

  const daysTotal = days
    .filter(day => day.payment_type === usedPaymentType)
    .reduce((acc, day) => acc + getPrintedDiscountedItemTotal(day), 0)

  if (daysTotal > 0) {
    actionItems.push({
      item_name: 'Проживание',
      amount: currency.toString(daysTotal),
    })
  }

  services
    .filter(service => service.payment_type === usedPaymentType)
    .forEach(service => {
      actionItems.push({
        item_name: service.service,
        amount: getTotal(service),
        special_service_id: service.special_service_id,
      })
    })

  return actionItems
}

const getSellActionItemsV2 = (items, usedPaymentType) => {
  const { days, services } = items
  const actionItems = []

  const daysTotal = days.filter(
    day => day.payment_type === paymentTypes[usedPaymentType]
  )

  daysTotal.forEach(day => {
    actionItems.push({
      price: +day.price,
      refunded: false,
      service_id: day.special_service_id + '',
    })
  })

  services
    .filter(service => service.payment_type === paymentTypes[usedPaymentType])
    .forEach(service => {
      for (let q = 0; q < service.quantity; q++) {
        actionItems.push({
          id: service.id,
          refunded: false,
          price: service.price,
          service_id: service.special_service_id + '',
        })
      }
    })

  return actionItems
}

const getRefundActionItemsV2 = (items, usedPaymentType, refundCheckList) => {
  const { days, services } = items

  const actionItems = []
  const daysTotal = days.filter(
    day => day.payment_type === paymentTypes[usedPaymentType]
  )

  const usedId = {}

  daysTotal.forEach(day => {
    const checks = findDayCheck(day, refundCheckList, usedPaymentType)
    if (checks.length) {
      const availableCheck = checks.map(it => {
        const items = it.items.filter(i => !usedId[i.id])
        if (items.length) {
          it.items = items
          return it
        } else return null
      }).filter(it => it !== null)
      const check = availableCheck[0]
      const list = {
        check,
        items: [],
      }
      const item = check.items.find(it => +it.service_id === +day.special_service_id && it.price === +day.price && !it.refunded)
      item.refunded = true
      list.items.push(item)
      actionItems.push(list)
      usedId[item.id] = item.id
    }
  })
  const used = {}
  services
    .filter(service => service.payment_type === paymentTypes[usedPaymentType])
    .forEach(service => {
      const checks = findServiceCheck(service, refundCheckList)
      if (checks.length) {
        const check = checks[0]
        const list = {
          check,
          items: [],
        }
        for (let q = 0; q < service.quantity; q++) {
          const item = check.items.find(it => +it.service_id === +service.special_service_id && it.price === +service.price && !used[it.id] && !it.refunded)
          item.refunded = true
          list.items.push(item)
          used[item.id] = item.id
        }
        actionItems.push(list)
      }
    })

  return actionItems
}

const getRefundActionItems = (items, usedPaymentType) => {
  const { days, services } = items

  const actionItems = []

  const daysTotal = days
    .filter(day => day.payment_type === usedPaymentType)
    .reduce((acc, day) => acc + getPrintedDiscountedItemTotal(day), 0)

  if (daysTotal > 0) {
    actionItems.push({
      item_name: 'Проживание',
      amount: currency.toString(daysTotal),
      refund: true,
      service_id: null,
    })
  }

  services
    .filter(service => service.payment_type === usedPaymentType)
    .forEach(service => {
      for (let q = 0; q < service.quantity; q++) {
        actionItems.push({
          id: service.id,
          item_name: service.name,
          refund: false,
          amount: service.price,
          service_id: service.special_service_id,
        })
      }
    })

  return actionItems
}

function getLastRoom(reservation) {
  const { reserved_days, lastRoomID } = reservation
  return reserved_days.length > 0
    ? reserved_days[reserved_days.length - 1].room.room_id
    : lastRoomID
}

export const savePaymentAction = async ({
                                          checkType,
                                          items,
                                          printedItems,
                                          totals,
                                          checkTotals,
                                          printDateObj,
                                          reduxStates,
                                        }) => {
  const { reservation, settings, session } = reduxStates.current

  if (!reservation.pk) {
    sendBugReport({
      message:
        'Не удалось сохранить payment_action - отсутствует pk брони ' +
        '(сообщение сгенерировано автоматически)',
    })

    return
  }

  const { guest_name, booking_number, refundCheckList } = reservation
  const { checksV2Enabled, printerIsOn } = settings
  const { adminID, lcodeObj } = session
  const { lcode, pk: lcodePk } = lcodeObj

  const date = printerIsOn ? printDateObj.toISOString() : getDate(items)
  const isRefund = checkType === 'refund'

  const base = {
    lcode,
    date,
    isRefund,
    reservationPk: reservation.pk,
    room: getLastRoom(reservation),
    bookingNumber: booking_number,
    guestName: guest_name,
    adminID,
  }

  const promises = []
  const [usedItems, usedTotals] = isRefund ? [items, totals] : [printedItems, checkTotals]
  const [getPositionsLine, getActionItems] =
    isRefund
      ? [
        getRefundPositionsLine,
        checksV2Enabled ? getRefundActionItemsV2 : getRefundActionItems,
      ]
      : [
        getSellPositionsLine,
        checksV2Enabled ? getSellActionItemsV2 : getSellActionItems,
      ]
  let positions = null
  let actionItems = null
  let paymentType = null
  let paymentKey = null
  if (usedTotals.cash > 0) {
    paymentKey = 'cash'
    const refundList = refundCheckList ? refundCheckList.filter(it => it.payment_type === paymentKey) : []
    paymentType = checksV2Enabled ? 'cash' : paymentTypes.cash
    positions = getPositionsLine(usedItems, paymentTypes.cash)
    actionItems = getActionItems(usedItems, paymentType, refundList)
  }

  if (usedTotals.card > 0) {
    paymentKey = 'card'
    const refundList = refundCheckList ? refundCheckList.filter(it => it.payment_type === paymentKey) : []
    paymentType = checksV2Enabled ? 'card' : paymentTypes.card
    positions = getPositionsLine(items, paymentTypes.card)
    actionItems = getActionItems(items, paymentType, refundList)
  }

  if (!actionItems) return
  promises.push(
    checksV2Enabled
      ? isRefund
        ? saveRefundListCallV2({
          ...base,
          lcodeId: lcodePk,
          paymentType,
          items: actionItems,
          total: currency.toString(usedTotals[paymentKey]),
        })
        : saveCheckCallV2({
          ...base,
          lcodeId: lcodePk,
          paymentType,
          items: actionItems,
          total: currency.toString(usedTotals[paymentKey]),
        })
      : saveCheckCall({
        ...base,
        paymentType,
        items: actionItems,
        positions,
        total: currency.toString(usedTotals[paymentKey]),
      })
  )

  try {
    await Promise.all(promises)

    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

export const saveCustomPaymentAction = ({
                                          reservationPk,
                                          room,
                                          guestName,
                                          bookingNumber,
                                          total,
                                          method,
                                          items,
                                          positions,
                                          isRefund = false,
                                          checkList,
                                        }) => async ({ reduxStates, printDateObj }) => {
  const { settings, session } = reduxStates.current
  const { checksV2Enabled, printerIsOn } = settings
  const { adminID, lcodeObj } = session
  const { lcode, pk: lcodePk } = lcodeObj

  const date = printerIsOn && printDateObj ? printDateObj.toISOString() : getDate(items)
  try {
    if (checksV2Enabled) {
      if (isRefund) {
        const checks = await findDayCheck(items[0], checkList, method)
        if (checks.length) {
          const check = checks[0]
          const item = items[0]
          item.id = check.items[0].id
          const list = {
            check,
            items: [item],
          }
          await saveRefundListCallV2({
            total,
            date,
            reservationPk,
            bookingNumber,
            lcodeId: lcodePk,
            paymentType: method,
            items: [list],
            adminID,
          })
        }
      } else {
        await saveCheckCallV2({
          total: currency.toString(total),
          date,
          reservationPk,
          bookingNumber,
          lcodeId: lcodePk,
          paymentType: method,
          items,
          adminID,
        })
      }
    } else {
      await saveCheckCall({
        reservationPk,
        room,
        lcode,
        date,
        isRefund,
        adminID,
        items,
        positions,
        bookingNumber,
        guestName,
        paymentType: paymentTypes[method],
        total: currency.toString(total),
      })
    }
    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

const findServiceCheck = (service, list) => {
  return list
    .filter(
      it => moment(it.date).format('YYYY-MM-DD') === moment(service.payment_date).format('YYYY-MM-DD')
    ).filter(
      it =>
        it.items.filter(
          item =>
            +item.service_id === service.special_service_id &&
            item.price === service.price &&
            !item.refunded
        ).length >= service.quantity
    )
}

const findDayCheck = (service, list) => {
  return list
    .filter(
      it =>
        service.payment_date
          ? moment(it.date).format('YYYY-MM-DD') === moment(service.payment_date).format('YYYY-MM-DD')
          : true
    )
    .filter(
      it =>
        it.items.filter(
          item =>
            (+item.service_id === +service.special_service_id ||
              +item.service_id === +service.service_id) &&
            item.price === service.price &&
            !item.refunded
        ).length > 0
    )
}

const saveRefundListCallV2 = async ({
                                      total,
                                      date,
                                      reservationPk,
                                      bookingNumber,
                                      lcodeId,
                                      paymentType,
                                      items,
                                      adminID,
                                    }) => {
  const promises = []
  items.forEach(item => {
    if (item.check) {
      const data = {
        lcode: lcodeId,
        total: +total,
        date,
        booking_number_uuid: reservationPk,
        booking_number: bookingNumber,
        payment_type: paymentType,
        check_id: item.check.id,
        items: item.items,
        user_id: adminID,
      }
      promises.push(saveCheckRefundCallV2(data))
    }
  })
  try {
    await Promise.all(promises)

    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

const getDate = (items) => {
  if (items.days && items.days.length) {
    return items.days[0].payed_at
  } else if (items.services && items.services.length) {
    return items.services[0].payed_at
  } else {
    return new Date().toISOString()
  }
}
