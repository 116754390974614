import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  currentAdminSellsSelectors,
  currentAdminSellsActions,
} from './reducers/current-admin-sells'
import { CurrentAdminSellsView } from './CurrentAdminSellsView'
import { hotelsSelectors } from '../../Modules/hotels'
import moment from 'moment'
import {settingsSelectors} from "../../Modules/settings";

class CurrentAdminSellsContainer extends React.Component {
  componentDidMount() {
    const { currentAdminSellsActions } = this.props
    currentAdminSellsActions.load({ month: moment().format('MMMM YYYY') })
  }

  componentWillUnmount() {
    const { currentAdminSellsActions } = this.props
    currentAdminSellsActions.reset()
  }

  render() {
    const {
      items,
      total,
      totalRaw,
      loadingState,
      hotelOptions,
      currentAdminSellsActions,
      checksV2Enabled,
    } = this.props

    return (
      <CurrentAdminSellsView
        items={items}
        total={total}
        totalRaw={totalRaw}
        loadingState={loadingState}
        hotelOptions={hotelOptions}
        currentAdminSellsActions={currentAdminSellsActions}
        checksV2Enabled={checksV2Enabled}
      />
    )
  }
}

const mSTP = state => ({
  items: currentAdminSellsSelectors.items(state),
  total: currentAdminSellsSelectors.total(state),
  totalRaw: currentAdminSellsSelectors.totalRaw(state),
  loadingState: currentAdminSellsSelectors.loadingState(state),
  hotelOptions: hotelsSelectors.hotelOptionsWithAll(state),
  checksV2Enabled: settingsSelectors.checksV2Enabled(state),
  settingsV2IsLoaded: settingsSelectors.isLoaded(state),
})

const mDTP = dispatch => ({
  currentAdminSellsActions: bindActionCreators(
    currentAdminSellsActions,
    dispatch
  ),
})

export const CurrentAdminSells = connect(mSTP, mDTP)(CurrentAdminSellsContainer)
