const checkIfDayIsPayed = day => {
  return Boolean(day.payment_date)
}

const getCardDistance = days => {
  const segments = []
  let totalLength = 0

  let start = null
  let end = null

  for (let day of days) {
    const isPayed = checkIfDayIsPayed(day)

    if (isPayed) {
      if (!start) start = day.date
      end = day.date

      totalLength += 1
    }

    if (!isPayed) {
      if (!start || !end) continue

      segments.push([start, end])
      start = null
      end = null
    }
  }

  if (start && end) {
    segments.push([start, end])
  }

  return {
    segments,
    totalLength,
  }
}

/**
 * @typedef Data
 * @type {object}
 * @prop {Array<object>} days
 * @prop {string} checkInTime
 * @prop {string} checkOutTime
 * @prop {number} cards
 */

/**
 * @param {object} options
 * @param {Data} options.newData
 * @param {Data} options.oldData
 */
export const checkIfNeedToUpdateCards = ({ newData, oldData }) => {
  if (!oldData.cards) return [false]

  const newDistance = getCardDistance(newData.days)
  const oldDistance = getCardDistance(oldData.days)

  if (newDistance.totalLength === 0) return [false]

  const hasDistanceChanged = newDistance.totalLength !== oldDistance.totalLength
  const hasCheckinChanged = newData.checkInTime !== oldData.checkInTime
  const hasCheckoutChanged = newData.checkOutTime !== oldData.checkOutTime

  if (!hasDistanceChanged && !hasCheckinChanged && !hasCheckoutChanged)
    return [false]

  const reasons = []

  if (hasDistanceChanged) reasons.push('Изменено количество оплаченных дней')
  if (hasCheckinChanged) reasons.push('Изменено время заезда')
  if (hasCheckoutChanged) reasons.push('Изменено время выезда')

  return [true, reasons]
}
