import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { modalDefaultStyles } from '../../../../App'
import {
  ModalButton,
  ModalTitle,
  ModalControls as DefaultModalControls,
} from '../../../common/ModalParts'

const ModalBody = styled.div`
  padding: 5px 20px;
`

export const ModalControls = styled(DefaultModalControls)`
  padding: 20px;
`

export const CompleteModal = ({ isOpen, close }) => (
  <Modal
    isOpen={isOpen}
    defaultStyles={{
      ...modalDefaultStyles,
      content: {
        ...modalDefaultStyles.content,
        width: 400,
        minWidth: 400,
        maxWidth: 'auto',
      },
    }}
  >
    <ModalTitle>Действие выполнено</ModalTitle>
    <ModalBody>
      Оплата отменена. Отредактируйте дни проживания у брони через возврат.
    </ModalBody>
    <ModalControls>
      <ModalButton
        bsStyle="success"
        style={{ marginLeft: 'auto' }}
        onClick={close}
      >
        Закрыть
      </ModalButton>
    </ModalControls>
  </Modal>
)
