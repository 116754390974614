import React from 'react'
import { StyledItem, StyledList } from './styleds'

const Item = ({ text }) => {
  return <StyledItem>– {text}</StyledItem>
}

export const List = ({ items }) => {
  return (
    <StyledList>
      {items.map(item => {
        const { note } = item
        return <Item key={note} text={note} />
      })}
    </StyledList>
  )
}
