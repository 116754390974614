import store from '../../../../store'
import { kkmAPI } from '../../../api/kkm-api'
import { checkKKMShift } from '../check-kkm-shift'
import { loyaltyErrors } from '../../../api/loyaltyAPI/constants/loyaltyErrors'
import { settingsSelectors } from '../../../settings'
import { callModal, modalTemplates } from '../../../../Components/dynamic-modal'
import { cancelBonusesForItems } from '../../../helpers/loyalty/cancel-bonuses-for-items'
import { reservationSelectors } from '../../../reservation'
import moment from 'moment'
import { sessionSelectors } from '../../../session'
import { logPrintObject } from '../log-print-object'
import { createCallbacksHelpers } from '../create-callbacks-helpers'

export const common = ({ getPrintDataFn }) => async ({
  modes,
  printScopes = { days: true, services: true },
  confirmEmail,
  validateReservation = async () => true,
  prepareReservation,
  beforePrint = () => {},
  afterPrint = () => {},
  onSuccess = () => {},
  onFailure = () => {},
  onPrintFinish = () => {},
  onCancel = () => {},
  onFinish = () => {},
  whenCheckPrinterIsDisabled = () => {},
  onShiftCheckFailure = ({ shiftCheckError }) => {
    callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: `Не удалось проверить смену KKM: \n${shiftCheckError}`,
      })
    )
  },
  onLoyaltyFailure = () => {},
}) => {
  const { addCallbacksData, call, finish } = createCallbacksHelpers({
    onFinish,
  })

  const isValid = await validateReservation()
  if (!isValid) {
    return finish([onCancel])
  }

  await confirmEmail()

  const state = store.getState()

  const reservation = await prepareReservation({
    reservation: state.reservation,
    modes,
    adminID: sessionSelectors.adminId(state),
    adminName: sessionSelectors.adminName(state),
  })

  const isLoyaltyEnabled = settingsSelectors.isLoyaltyEnabled(state)
  const isPrinterOn = state.settings.printerIsOn
  const sendReceiptToEmail =
    state.settings.sendReceiptToEmail && reservation.sendConfirmToEmail

  const printData = await getPrintDataFn({
    state,
    reservation,
    printScopes,
    isPrinterOn,
    sendReceiptToEmail,
  })

  addCallbacksData(printData)
  addCallbacksData({
    wasPrinted: false,
    printResult: null,
  })

  if (modes.refund && isLoyaltyEnabled) {
    const refundedItemsByType = reservationSelectors.refundedItemsByType(state)
    const phoneNumber = reservationSelectors.phoneNumber(state)
    const settings = settingsSelectors.everything(state)
    const session = sessionSelectors.everything(state)

    const cancelResult = await cancelBonusesForItems({
      ...refundedItemsByType,
      phoneNumber,
      settings,
      session
    })

    if (!cancelResult.isSuccess) {
      const loyaltyError = loyaltyErrors[cancelResult.errorCode]

      const { action } = await callModal(
        modalTemplates.confirm({
          title: 'Ошибка лояльности',
          text: `Не удалось отменить бонусы: ${loyaltyError}.`,
          buttons: [
            'Отменить возврат',
            {
              text: 'Продолжить без лояльности',
              color: 'warning',
              action: modalTemplates.confirm.actions.confirm,
            },
          ],
        })
      )

      if (action !== modalTemplates.confirm.actions.confirm) {
        addCallbacksData({
          loyaltyError: loyaltyErrors[cancelResult.errorCode],
        })

        return finish([onLoyaltyFailure])
      }
    }
  }

  if (!isPrinterOn) {
    return finish([whenCheckPrinterIsDisabled])
  }

  try {
    await checkKKMShift(state)
  } catch (err) {
    addCallbacksData({
      shiftCheckError: err.message,
    })

    return finish([onShiftCheckFailure])
  }

  if (printData.wasCanceled) {
    return finish([onCancel])
  }

  let wasPrinted = !printData.shouldPrint
  let printResult = null
  let printDateObj = moment()

  await call(beforePrint)

  if (printData.shouldPrint) {
    logPrintObject(printData.printObject)
    const isCash = printData.checkTotals.cash !== 0
    const isRefund = printData.checkType === 'refund'

    printResult = await kkmAPI.print(printData.printObject)
    printDateObj = moment()
    wasPrinted =
      !printResult.Error &&
      (isRefund || isCash || 'RezultProcessing' in printResult)
    if (!wasPrinted && !printResult.Error) {
      printResult.Error = 'Что-то пошло не так'
    }
  }

  addCallbacksData({
    wasPrinted,
    printResult,
    printDateObj,
  })

  await call(afterPrint)

  if (wasPrinted) {
    await call(onSuccess)
  } else {
    await call(onFailure)
  }

  return finish([onPrintFinish])
}
