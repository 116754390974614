import { createTransactionWithoutAuthCall } from '../../api/ccAPI/requests'

export const saveTransaction = async ({
  printResult,
  reduxStates,
  checkTotals,
  meta,
  printDateObj,
}) => {
  if (!printResult) return { success: true }
  if (!meta.isCardUsed) return { success: true }

  const { session } = reduxStates.current
  const { lcode } = session

  const date = printDateObj.toISOString()

  const { RezultProcessing } = printResult
  const { AuthorizationCode, RRNCode } = RezultProcessing

  try {
    await createTransactionWithoutAuthCall({
      rrn: RRNCode,
      authCode: AuthorizationCode,
      lcode,
      date,
      total: checkTotals.card * 100,
    })

    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}
