import { createDefer } from '../helpers/createDefer'

let runningOperations = new Set()

export const CriticalOperations = {
  get isRunning() {
    return runningOperations.size > 0
  },
  start() {
    const operation = {
      defer: createDefer(),
    }

    runningOperations.add(operation)

    return () => {
      operation.defer.resolve()
      runningOperations.delete(operation)
    }
  },
  async waitForFinish() {
    if (!this.isRunning) {
      return
    }

    const promises = Array.from(runningOperations.values()).map(
      ({ defer }) => defer.promise
    )

    await Promise.all(promises)

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.waitForFinish())
      }, 3000)
    })
  },
}
