import { createLine } from './create-line'

export const LOYALTY_REGISTRATION_LINES = [
  createLine('************', 24),
  createLine(
    'Зарегистрируйтесь в программе лояльности ' +
      'на winterfell-hotels.ru ' +
      'и получайте до 15% бонусными баллами за каждое бронирование. ' +
      'Баллами можно оплачивать проживание и услуги. 1 балл = 1 рубль.',
    12
  ),
  createLine('************', 24),
]
