import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  page-break-inside: avoid;
  justify-content: space-between;
`

export const Field = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1mm;
  page-break-inside: avoid;
`

export const Name = styled.span`
  display: inline-block;
  margin-right: 2mm;
  font-size: 10pt;
  font-weight: ${props => props.bold && 'bold'};
`

export const Text = styled.div`
  position: relative;
  display: inline-block;
  line-height: 1.2;
  border-bottom: 1px solid black;
  width: ${props => props.width}%;
  font-size: 10pt;
  height: 12.5pt;
  flex: ${props => props.stretch && 1};
  margin-right: ${props => (!props.stretch || props.notLast) && '2mm'};
  margin-bottom: ${({ caption, captionLines }) =>
    caption && 1 + (captionLines ? 3 * captionLines : 3) + 'mm'};
  text-align: center;

  &:before {
    box-sizing: border-box;
    display: block;
    content: "${props => props.caption}";
    position: absolute;
    top: calc(100% + 1mm);
    width: 100%;
    padding: 0 10%;
    font-size: 7pt;
    text-align: center;
  }
`
