import React, { useRef, useEffect } from 'react'
import Modal from 'react-modal'
import {
  ModalTitle,
  ModalHR,
  ModalControls,
  ModalContent,
} from '../../common/ModalParts'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { Formik } from 'formik'
import styled from 'styled-components'
import { renderText } from '../helpers/render-text'
import { renderButtons } from '../helpers/render-buttons'
import { getStyle } from '../helpers/get-style'
import { LogsAPI } from '../../../Modules/logs-api'
import { getModalOpenLogText } from '../helpers/get-modal-open-log-text'
import { useCloseOnClickOutside } from '../helpers/use-close-on-click-outside'

const TextContainer = styled.div`
  margin-bottom: 20px;
`

const ErrorMessage = styled.div`
  margin-top: 6px;
  font-size: 12px;
  color: #a94442;
`

export const modalActions = {
  enter: 'enter',
  close: 'close',
}

const defaultButtons = [
  {
    text: 'Отмена',
    color: 'danger',
    action: modalActions.close,
    position: 'left',
  },
  {
    text: 'Продолжить',
    type: 'submit',
    color: 'success',
    position: 'right',
  },
]

export const defaultValidators = [
  {
    fn: value => Boolean(value),
    message: 'Поле обязательно к заполнению',
  },
]

export function prompt({
  title = 'Введите значение',
  text,
  fieldName,
  fieldPlaceholder,
  initialValue = '',
  buttons = defaultButtons,
  validators = defaultValidators,
  normalizer = value => value,
  width = 640,
  disableCloseOnClickOutside = false,
}) {
  return ({ isOpen, resolve }) => {
    const modalRef = useRef()
    const inputRef = useRef()

    const refSetter = ref => value => {
      ref.current = value
    }

    useEffect(() => {
      const logText = getModalOpenLogText({ title, text })
      LogsAPI.event(logText)
    }, [])

    useCloseOnClickOutside({
      ref: modalRef,
      disableCloseOnClickOutside,
      onClose: () => resolve(modalActions.close),
    })

    return (
      <Modal
        isOpen={isOpen}
        contentRef={refSetter(modalRef)}
        style={getStyle({ width, zIndex: 1000 })}
        onAfterOpen={() => {
          if (!inputRef.current) return
          inputRef.current.focus()
        }}
      >
        <Formik
          initialValues={{
            value: initialValue,
          }}
          onSubmit={({ value }, actions) => {
            const errors = {}
            let wereErrors = false

            for (let validator of validators) {
              const isValid = validator.fn(value)

              if (!isValid) {
                errors.value = validator.message
                wereErrors = true
                break
              }
            }

            if (wereErrors) {
              actions.setErrors(errors)
              actions.setSubmitting(false)
              return
            }

            const submitButton = buttons.find(
              button => button.type === 'submit'
            )

            if (submitButton) {
              LogsAPI.pressAction(submitButton.text)
            }

            resolve(modalActions.enter, { value: normalizer(value) })
          }}
        >
          {({ values, handleChange, handleSubmit, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalTitle>{title}</ModalTitle>
                <ModalHR />
                <ModalContent>
                  {text && <TextContainer>{renderText(text)}</TextContainer>}
                  <FormGroup
                    style={{ marginBottom: 0 }}
                    validationState={errors.value ? 'error' : null}
                  >
                    {fieldName && <ControlLabel>{fieldName}</ControlLabel>}
                    <FormControl
                      name="value"
                      placeholder={fieldPlaceholder}
                      value={values.value}
                      onChange={handleChange}
                      inputRef={refSetter(inputRef)}
                    />
                    {errors.value && (
                      <ErrorMessage>{errors.value}</ErrorMessage>
                    )}
                  </FormGroup>
                </ModalContent>
                <ModalHR />
                <ModalControls>
                  {renderButtons({
                    buttons,
                    defaultButtons,
                    resolve,
                    beforeClick: ({ text }) => LogsAPI.pressAction(text),
                  })}
                </ModalControls>
              </form>
            )
          }}
        </Formik>
      </Modal>
    )
  }
}

prompt.actions = modalActions
prompt.defaultValidators = defaultValidators
prompt.defaultButtons = defaultButtons
