import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { allNotesSelectors, allNotesActions } from '../../Modules/all-notes'
import WubookList from './WubookList'
import { sessionSelectors } from '../../Modules/session'
import { settingsSelectors } from '../../Modules/settings'
import { wubookReservationsActions, wubookReservationsSelectors } from './reducer'

const mapStateToProps = state => ({
  allNotesByBN: allNotesSelectors.byBookingNumber(state),
  lcode: sessionSelectors.lcode(state),
  isManualNewBookingsFetchEnabled: settingsSelectors.isManualNewBookingsFetchEnabled(state),
  selecteWRdate: wubookReservationsSelectors.date(state)
})

const mapDispatchToProps = dispatch => ({
  allNotesActions: bindActionCreators(allNotesActions, dispatch),
  wubookReservationsActions: bindActionCreators(wubookReservationsActions, dispatch),
})

export const ConnectedWubookList = connect(
  mapStateToProps,
  mapDispatchToProps
)(WubookList)
