import {
  upgradeService,
  earlyCheckInService,
  lateCheckOutService,
} from '../helpers/servicesNames'

export const getServiceType = service => {
  const { service: serviceName } = service

  switch (serviceName) {
    case earlyCheckInService:
      return 'checkin'

    case lateCheckOutService:
      return 'checkout'

    case upgradeService:
      return 'upgrade'

    case 'Завтрак':
      return 'breakfast'

    case 'Парковка':
      return 'parking'

    default:
      return 'service'
  }
}
