import { connect } from 'react-redux'
import { modalsSelectors, modalsActions } from '../../Modules/modals'
import { PayTimerFinishModalView } from './PayTimerFinishModalView'
import { LogsAPI } from '../../Modules/logs-api'

const mSTP = state => ({
  isOpen: modalsSelectors.isOpen(state, 'pay-timer-finish'),
})

const mDTP = dispatch => ({
  close: () => {
    dispatch(modalsActions.close('pay-timer-finish'))
    LogsAPI.event('Закрыто окно с предупреждением об истечении времени оплаты')
  },
})

export const PayTimerFinishModal = connect(mSTP, mDTP)(PayTimerFinishModalView)
