import styled from 'styled-components'

export default styled.input.attrs(() => ({
  type: 'text',
}))`
  display: block;
  width: 240px;
  padding: 12px 15px;
  margin: 0 0 24px;
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.colors.transparentGray};
  }

  &:focus::placeholder {
    opacity: 0;
  }
`
