import * as types from './types'

const initialState = {
  items: [],
  completing: [],
  isCreating: false,
  isCreatingFailed: false,
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_DATA_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      }

    case types.LOAD_DATA_SUCCESS:
      return {
        ...state,
        items: payload.items,
        isLoading: false,
        isLoaded: true,
      }

    case types.LOAD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      }

    case types.COMPLETE_TASK_REQUEST:
      return {
        ...state,
        completing: state.completing.concat(payload.pk),
      }

    case types.COMPLETE_TASK_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.pk !== payload.pk),
        completing: state.completing.filter(pk => pk !== payload.pk),
      }

    case types.COMPLETE_TASK_FAILURE:
      return {
        ...state,
        completing: state.completing.filter(pk => pk !== payload.pk),
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
