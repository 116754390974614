import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import history from '../../../../../Modules/helpers/history'
import { useActions } from '../../../../common/hooks/use-actions'
import {
  reservationsWaitingForBonusesActions,
  reservationsWaitingForBonusesSelectors,
} from '../../../reducers/reservations-waiting-for-bonuses'
import { useSelector } from 'react-redux'
import { callModal, modalTemplates } from '../../../../dynamic-modal'
import { Button } from '../../../../buttons'

const calculateTotal = reservation => {
  let total = 0
  for (let i = 0; i < reservation.reserved_days.length; i++) {
    total = total + parseFloat(reservation.reserved_days[i].price)
  }

  for (let i = 0; i < reservation.additional_services.length; i++) {
    total = total + parseFloat(reservation.additional_services[i].price)
  }

  return Math.round(total)
}

const Cell = styled.td`
  vertical-align: middle !important;
  padding: 16px 8px;
  width: ${props => props.width}px;
  text-align: ${props => props.textAlign};
  ${props =>
          props.clickable &&
          css`
            cursor: pointer;
          `}
`

export const Row = ({ reservation }) => {
  const isApplying = useSelector(
    reservationsWaitingForBonusesSelectors.isApplying
  )
  const { applyBonusesForPaidItemsInReservation } = useActions(
    reservationsWaitingForBonusesActions
  )

  const apply = async () => {
    const { action } = await callModal(
      modalTemplates.confirm({
        text: [
          'Баллы начисляются только по факту заезда гостя.',
          'Гость действительно заехал?',
        ],
        buttons: ['Нет', 'Да'],
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }

    applyBonusesForPaidItemsInReservation({ reservation })
  }

  const goToEditPage = () => {
    history.push('/reservation?pk=' + reservation.pk)
  }

  return (
    <tr>
      <Cell width={120} clickable onClick={goToEditPage}>
        {reservation.guest_name}
      </Cell>
      <Cell width={100} clickable onClick={goToEditPage}>
        {moment(reservation.start).format('DD MMM')}
      </Cell>
      <Cell width={100} clickable onClick={goToEditPage}>
        {moment(reservation.end).format('DD MMM')}
      </Cell>
      <Cell
        width={80}
        clickable
        onClick={goToEditPage}
        textAlign="right"
        style={{ whiteSpace: 'nowrap' }}
      >
        {calculateTotal(reservation)} ₽
      </Cell>
      <Cell>
        <Button size="xs" onClick={apply} disabled={isApplying}> {isApplying ? 'Загрузка..' : 'Начислить'}</Button>
      </Cell>
    </tr>
  )
}
