const DAY_MS = 24 * 60 * 60 * 1000

const state = {
  isActive: false,
  interval: null,
  tasks: [],
}

export function addTask({ time, action, isEveryDay = false }) {
  const lastRun = new Date()

  lastRun.setHours(time.hours)
  lastRun.setMinutes(time.minutes)
  lastRun.setSeconds(0, 0)

  const now = new Date()

  // lastRun should be yesterday if task's time has not passed today
  // otherwise, lastRun should be today
  if (lastRun >= now) {
    lastRun.setDate(lastRun.getDate() - 1)
  }

  state.tasks.push({
    action,
    isEveryDay,
    lastRun,
  })
}

export function clear() {
  state.tasks = []
}

export function start() {
  if (state.isActive) return

  function processTasks() {
    const currentDate = new Date()
    const completedTasks = []

    for (const task of state.tasks) {
      const { action, isEveryDay, lastRun } = task

      const diff = currentDate - lastRun

      if (diff < DAY_MS) {
        continue
      }

      window.setTimeout(action, 0)

      lastRun.setDate(lastRun.getDate() + 1)

      if (isEveryDay) {
        continue
      }

      completedTasks.push(task)
    }

    state.tasks = state.tasks.filter(task => {
      return !completedTasks.includes(task)
    })
  }

  // every 30 seconds
  state.interval = window.setInterval(processTasks, 30 * 1000)

  state.isActive = true
}

export function stop() {
  if (!state.isActive) return

  window.clearInterval(state.interval)
  state.interval = null

  state.isActive = false
}
