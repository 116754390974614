import { useState, useCallback, useEffect } from 'react'
import { TooltipState } from './tooltip-state'

export function useTooltipState() {
  const [, setState] = useState({})

  const forceRerender = useCallback(() => {
    setState({})
  }, [])

  useEffect(() => {
    TooltipState.subscribe(forceRerender)
    return () => TooltipState.unsubscribe(forceRerender)
  }, [forceRerender])

  return TooltipState.getState()
}
