import { useState, useCallback, useEffect, useRef } from 'react'
import { SelectionState } from './selection-state'
import moment from 'moment'

export function useIsSelected({ room, date }) {
  const ref = useRef(false)
  const [, setState] = useState({})

  const forceRerender = useCallback(() => {
    setState({})
  }, [])

  useEffect(() => {
    const subscriber = newState => {
      const { selectedRoom } = newState

      const handlers = {
        selected: () => {
          // already selected
          if (ref.current) return

          ref.current = true
          forceRerender()
        },
        notSelected: () => {
          // already not selected
          if (!ref.current) return

          ref.current = false
          forceRerender()
        },
      }

      if (selectedRoom !== room) return handlers.notSelected()

      let { start, end } = newState
      if (moment(start).isAfter(end)) [start, end] = [end, start]

      if (moment(date).isBefore(start)) return handlers.notSelected()
      if (moment(date).isAfter(end)) return handlers.notSelected()

      return handlers.selected()
    }

    SelectionState.subscribe(subscriber)
    return () => SelectionState.unsubscribe(subscriber)
  }, [room, date, forceRerender])

  return ref.current
}
