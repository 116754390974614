import React from 'react'
import styled from 'styled-components'

const StyledCell = styled.div`
  display: table-cell;
  font-size: 13px;
  padding: 16px;
  vertical-align: middle;
  color: ${props => props.theme.colors[props.color]};

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
`

const getJCfromTA = textAlign => {
  switch (textAlign) {
    case 'left':
      return 'flex-start'

    case 'center':
      return 'center'

    case 'right':
      return 'flex-end'

    default:
      return 'flex-start'
  }
}

const StyledCellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => getJCfromTA(props.textAlign)};
`

export default ({ children, textAlign, ...restProps }) => (
  <StyledCell {...restProps}>
    <StyledCellContent textAlign={textAlign}>{children}</StyledCellContent>
  </StyledCell>
)
