const initialState = {
  isActive: false,
  selectedRoom: null,
  start: null,
  end: null,
}

export const SelectionState = {
  state: initialState,
  getState() {
    return this.state
  },
  subscribers: [],
  subscribe(callback) {
    this.subscribers.push(callback)
  },
  unsubscribe(callback) {
    this.subscribers = this.subscribers.filter(subscriber => {
      return subscriber !== callback
    })
  },
  callSubscribers() {
    for (const subscriber of this.subscribers) {
      subscriber(this.state)
    }
  },
  updateState(newState) {
    this.state = newState
    this.callSubscribers()
  },
  reset() {
    this.state = initialState
    this.callSubscribers()
  },
}
