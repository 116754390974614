import { kkmAPI } from '../../api/kkm-api'

const wrap = async promise => {
  const result = await promise

  if (result.Error) {
    throw new Error(result.Error)
  }

  return result
}

export const checkKKMShift = async state => {
  const { settings } = state

  const CashierName = settings.cashier
  const CashierVATIN = settings.inn

  const dataKKT = await wrap(kkmAPI.getKKTState())

  if (!dataKKT.Info) {
    throw new Error(
      'Некорректный ответ при получении состояния KKM. \n' +
        'Попробуйте перезапустить KKM-сервер.'
    )
  }

  const { SessionState } = dataKKT.Info

  if (SessionState === 1)
    return wrap(
      kkmAPI.openShift({
        CashierName,
        CashierVATIN,
      })
    )

  if (SessionState === 3) {
    await wrap(
      kkmAPI.closeShift({
        CashierName,
        CashierVATIN,
      })
    )

    return wrap(
      kkmAPI.openShift({
        CashierName,
        CashierVATIN,
      })
    )
  }

  return
}
