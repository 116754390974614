import React from 'react'
import { connect } from 'react-redux'
import { getTelephony } from './reducer/telephony/actions'
import {
  Button,
  FormControl,
  FormGroup,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap'

export const PhoneTable = ({ list }) => (
  <div>
    <Table striped bordered hover>
      <thead>
      <tr>
        <th>КОНТАКТ</th>
        <th>КАК ЗВОНИТЬ</th>
        <th>ПРИМЕЧАНИЕ</th>
      </tr>
      </thead>
      <tbody>
      {list &&
        list.map((it) => {
          return (
            <tr key={it.pk} style={{ paddingBottom: 30 }}>
              <td>{it.name}</td>
              <td>{it.phone_number}</td>
              <td>{it.comment}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  </div>
)

class TelephonyContainer extends React.Component {
  state = {
    search: '',
  }

  render() {
    return (
      <div
        style={{
          padding: 100,
        }}
      >
        <FormGroup
          className="m-0"
          style={{
            display: 'flex',
          }}
        >
          <FormControl
            value={this.state.search}
            onChange={e => this.setState({ search: e.target.value })}
            onKeyUp={this.search}
            type="text"
          />
          <Button
            id="button-addon2"
            className="btn btn-primary"
            onClick={this.search}
          >
            Поиск
          </Button>
        </FormGroup>
        <Tabs defaultActiveKey="phones" id="telephony">
          <Tab eventKey="phones" title="Телефоны">
            <PhoneTable list={this.props.phones} />
          </Tab>
          <Tab eventKey="internal" title="Внутренняя телефония">
            <PhoneTable list={this.props.internal}/>
          </Tab>
        </Tabs>
      </div>
    )
  }

  search = () => {
    this.props.getTelephony(this.state.search)
  }

  componentDidMount() {
    this.props.getTelephony(this.state.search)
  }
}

const mapStateToProps = state => ({
  phones: state.telephony.phones,
  internal: state.telephony.internal,
  search: state.telephony.search,
})

const mapDispatchToProps = dispatch => ({
  getTelephony: item => dispatch(getTelephony(item)),
})

export const Telephony = connect(
  mapStateToProps,
  mapDispatchToProps
)(TelephonyContainer)
