import { callModal } from './call-modal'
import { modalTemplates } from './templates'

export async function callAlert({ title, text, maxContentHeight }) {
  await callModal(
    modalTemplates.alert({
      title,
      text,
      maxContentHeight,
    })
  )
}

export async function callPrompt({
  title,
  text,
  initialValue,
  validators,
  normalizer,
}) {
  const { action, params } = await callModal(
    modalTemplates.prompt({
      title,
      text,
      validators,
      initialValue,
      normalizer,
    })
  )

  if (action === modalTemplates.prompt.actions.close) {
    return null
  }

  return params.value
}

export async function callConfirm({ title, text }) {
  const { action } = await callModal(
    modalTemplates.confirm({
      title,
      text,
    })
  )

  return action === modalTemplates.confirm.actions.confirm
}
