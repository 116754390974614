import React, { useState, useCallback } from 'react'
import { FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap'
import styled from 'styled-components'

const MainBlock = styled.div`
  margin-bottom: 10px;
`

const MainLabel = styled(({ hasError, ...rest }) => <ControlLabel {...rest} />)`
  display: block;
  ${props => props.hasError && 'color: #a94442;'}
`

const TipForRF = () => (
  <span
    style={{
      display: 'block',
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 5,
    }}
  >
    (серия указывается в случае РФ)
  </span>
)

const ItemBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
`

const StyledGroup = styled(FormGroup)`
  margin-right: 5px;
  margin-bottom: 0;
`

const StyledLabel = styled(ControlLabel)`
  font-size: 12px;
`

class PassportItem extends React.Component {
  editName = value => {
    const { id, edit } = this.props
    edit(id, 'name', value)
  }

  editNumber = value => {
    const { id, edit } = this.props
    edit(id, 'passport', value)
  }

  removeItem = () => {
    const { id, remove } = this.props
    remove(id)
  }

  render() {
    const { data, hasError, mode } = this.props

    const nameHasError = hasError && !data.name
    const passportHasError = hasError && !data.passport

    return (
      <ItemBlock>
        <StyledGroup validationState={nameHasError ? 'error' : null}>
          <StyledLabel>Имя</StyledLabel>
          <Field
            placeholder="Иванов Иван Иванович"
            value={data.name}
            handler={this.editName}
            disabled={mode === "readonly"}
          />
        </StyledGroup>
        <StyledGroup validationState={passportHasError ? 'error' : null}>
          <StyledLabel>Паспорт</StyledLabel>
          <Field
            placeholder="1234 567890"
            value={data.passport}
            handler={this.editNumber}
            disabled={mode === "readonly"}
          />
        </StyledGroup>
        <Button onClick={this.removeItem} bsSize="sm" bsStyle="danger">
          Удалить
        </Button>
      </ItemBlock>
    )
  }
}

class Field extends React.Component {
  onChange = e => {
    const { handler } = this.props
    handler(e.target.value)
  }

  render() {
    const { placeholder, value, disabled } = this.props

    return (
      <FormControl
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={this.onChange}
        disabled={disabled}
        bsSize="sm"
      />
    )
  }
}

export const PassportFieldGroupView = ({ items, hasError, actions, mode }) => {
  const [isBlacklisting, setIsBlacklisting] = useState(false)

  const blacklist = useCallback(async () => {
    setIsBlacklisting(true)
    await actions.blacklist()
    setIsBlacklisting(false)
  }, [actions])

  return (
    <MainBlock>
      <MainLabel hasError={hasError}>Паспорта</MainLabel>
      <TipForRF />
      {items.map((item, idx) => (
        <PassportItem
          key={item.id}
          id={item.id}
          data={item}
          edit={actions.edit}
          remove={actions.remove}
          mode={mode}
        />
      ))}
      <Button onClick={actions.create} bsSize="sm" bsStyle="success">
        Добавить
      </Button>
      <Button
        onClick={blacklist}
        disabled={isBlacklisting}
        bsSize="sm"
        bsStyle="danger"
        style={{ display: 'block', marginTop: 8 }}
      >
        Добавить в черный список
      </Button>
    </MainBlock>
  )
}
