import { ALL_PHONES, GET_INTERNAL, GET_PHONES } from './types'

import { getPhones } from '../../../../Modules/api/ccAPI/requests'

const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  )

export const groupByKind = groupBy('kind')

export function getTelephony(param) {
  return async (dispatch, getState) => {
    if (getState().telephony.all.length > 0) {
      dispatch(filerData(getState().telephony.all, param))
    } else {
      const resp = await getPhones()
      const grouped = groupByKind(resp)
      dispatch(filerData(grouped, param))
      dispatch(setAll(grouped))
    }
  }
}

export function filerData(data, param) {
  return async dispatch => {
    if (param === '') {
      dispatch(setPhones(data[1].slice(1)))
      dispatch(setInternal(data[2].slice(2)))
    } else {
      const phones = []
      data[1].slice(1).forEach(it => {
        if (it.name.toLowerCase().includes(param.toLowerCase())) {
          phones.push(it)
        }
      })
      dispatch(setPhones(phones))
      const internal = []
      data[2].slice(2).forEach(it => {
        if (it.name.toLowerCase().includes(param.toLowerCase())) {
          internal.push(it)
        }
      })
      dispatch(setInternal(internal))
    }
  }
}

const setPhones = phones => ({
  type: GET_PHONES,
  payload: { phones },
})

const setInternal = internal => ({
  type: GET_INTERNAL,
  payload: { internal },
})

const setAll = all => ({
  type: ALL_PHONES,
  payload: { all },
})
