import * as types from './types'
import {getCapacityWithCheckins} from '../../../../Modules/api/ccAPI/requests'

const loadingRequest = payload => ({
    type: types.LOADING_DATA,
    payload,
})

const loadingDataSuccess = payload => ({
    type: types.LOAD_DATA_SUCCESS,
    payload,
})

const loadingFailure = () => ({
    type: types.LOAD_DATA_FAILURE,
})


export const loadWithCheckins = ({lcode, date}) => async (dispatch) => {
    dispatch(loadingRequest({date}))

    try {

        const data = await getCapacityWithCheckins({lcode, date})

        dispatch(loadingDataSuccess(data))
    } catch (err) {
        dispatch(loadingFailure())
    }
}

export const reset = () => ({
    type: types.RESET,
})
