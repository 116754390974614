import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  page-break-inside: avoid;
  justify-content: space-between;
`

export const Field = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1mm;
  page-break-inside: avoid;
  justify-content: ${props => props.justify};
`

export const Name = styled.span`
  display: inline-block;
  margin-right: 2mm;
  font-size: 10pt;
  font-weight: ${props => props.bold && 'bold'};
  font-style: ${props => props.italic && 'italic'};
  margin-bottom: 1mm;
`

export const Text = styled.div`
  position: relative;
  display: inline-block;
  line-height: 1.2;
  border-bottom: 1px solid black;
  width: ${props => props.width}%;
  font-size: 10pt;
  height: 12.5pt;
  flex: ${props => props.stretch && 1};
  margin-right: ${props => (!props.stretch || props.notLast) && '2mm'};
  margin-bottom: ${({ caption, captionLines }) =>
          caption && 1 + (captionLines ? 3 * captionLines : 3) + 'mm'};
  text-align: center;

  &:before {
    box-sizing: border-box;
    display: block;
    content: "${props => props.caption}";
    position: absolute;
    top: calc(100% + 1mm);
    width: 100%;
    padding: 0 10%;
    font-size: 7pt;
    text-align: center;
  }
`

export const Space = styled.div`
  height: ${props => props.height}mm;
`

export const TitleBlock = styled.div`
  margin: 8mm 0;
`

export const StyledText = styled.h1`
  font-size: 10pt;
  font-weight: bold;
  margin: 0;
  text-align: center;
`

const offsets = { dashed: "2.5mm", dotted: "1mm" }
export const SeparatorLine = styled.div`  border-bottom: 2px ${props => props.variant} black;
  text-align: center;
  width: 100%;
  margin: 8mm 0;`

export const SeparatorText = styled.h3` 
  position: relative;
  display: inline-block;
  margin: 0 auto;
  font-size: 12pt;
  font-weight: ${props => props.weight || "normal"};
  top: ${props => offsets[props.variant]};
  background: white!important;
  box-shadow: 0 0 0 1mm white!important;
  visibility: ${props => props.empty && "hidden"};
`

export const Head = styled.img`
  width: 100%;
  height: auto;
`

export const A4 = styled.div`
  padding: 0.8cm 1.2cm;
`
