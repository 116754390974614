import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../shared/HelpStyles/Text'
import { CashIcon, CardIcon } from '../../../../../ui/icons/shopping'
import { rub } from '../../../../../../../Modules/formatters/currency'

const NameBlock = styled.div`
  margin-bottom: 6px;
`

const StatsBlock = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Right = styled.span`
  margin-left: auto;
`

const StatLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StatsPart = ({ name, totals }) => (
  <StatsBlock>
    <NameBlock>{name}</NameBlock>
    <StatLine>
      <CashIcon />
      <Text>Наличными:</Text>
      <Right>{rub(totals.cash)}</Right>
    </StatLine>
    <StatLine>
      <CardIcon />
      <Text>Картой:</Text>
      <Right>{rub(totals.card)}</Right>
    </StatLine>
    <StatLine>
      Всего: <Right>{rub(totals.all)}</Right>
    </StatLine>
  </StatsBlock>
)

export default StatsPart
