import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import ConnectedResultsTable from './ConnectedResultsTable'
import { SingleDatePicker } from '../common/DatePicker'

const SearchBlock = styled.div`
  background-color: #eee;
  width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
`

const ActionHint = styled.span`
  display: block;
  text-align: center;
  color: crimson;
  padding: 15px;
`

class TransactionsList extends Component {
  state = {
    date: moment().format('YYYY-MM-DD'),
    isRefund: false,
    searchResult: null,
  }

  componentDidMount() {
    this.updateResult()
  }

  handleDateChange = date => {
    this.setState({ date }, this.updateResult)
  }

  setResult = searchResult => this.setState({ searchResult })
  clearResult = () => this.setState({ searchResult: null })

  updateResult = async () => {
    const { getTransactionsFunc } = this.props
    const { date } = this.state
    const result = await getTransactionsFunc(date)
    this.setResult(result)
  }

  render() {
    const { actionHint, transactionClickAction } = this.props

    if (!this.state.date) return null

    return (
      <SearchBlock>
        <SingleDatePicker
          label="Выберите дату"
          value={this.state.date}
          onChange={this.handleDateChange}
        />

        {this.state.searchResult && this.state.searchResult.length > 0 && (
          <ActionHint>{actionHint}</ActionHint>
        )}

        <ConnectedResultsTable
          items={this.state.searchResult}
          updateResult={this.updateResult}
          transactionClickAction={transactionClickAction}
          withModal={this.props.withModal}
        />
      </SearchBlock>
    )
  }
}

export default TransactionsList
