// Main functions
import getItems from './getItems'
import paymentTypes from '../../helpers/paymentTypes'
import checkPaymentType from './checkPaymentType'

const getValues = reservation => {
  const { days, services } = getItems(reservation)

  // Values to return
  const checkType = 'refund'
  let refundedByCash = false,
    cashSumm = 0,
    refundedByCard = false,
    cardSumm = 0,
    refundedSumm = 0

  // Process days to count refunded summ
  days.forEach(d => {
    const price = parseFloat(parseFloat(d.price).toFixed(2))

    if (d.payment_date) {
      switch (d.payment_type) {
        case paymentTypes.cash:
          cashSumm += price
          break
        case paymentTypes.card:
          cardSumm += price
          break
        default:
          break
      }
    }
  })

  // Process services to count refunded summ
  services.forEach(s => {
    const price = parseFloat(parseFloat(s.price).toFixed(2))
    const quantity = parseInt(s.quantity, 10)

    if (s.payment_date) {
      switch (s.payment_type) {
        case paymentTypes.cash:
          cashSumm += price * quantity
          break
        case paymentTypes.card:
          cardSumm += price * quantity
          break
        default:
          break
      }
    }
  })

  if (checkPaymentType([days, services], paymentTypes.card)) {
    refundedByCard = true
  }

  if (checkPaymentType([days, services], paymentTypes.cash)) {
    refundedByCash = true
  }

  // Format refunded summ to float number with 2-digit fraction
  // (to avoid JS float fraction issues)
  refundedSumm = (cashSumm + cardSumm).toFixed(2)

  return {
    checkType,
    refundedByCash,
    cashSumm,
    refundedByCard,
    cardSumm,
    refundedSumm,
  }
}

export default getValues
