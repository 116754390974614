import { purchasePrintHandler } from './scenaries/purchase'
import { refundPrintHandler } from './scenaries/refund'

import { loyaltyPurchasePrintHandler } from './scenaries/loyalty-purchase'

import { customPurchasePrintHandler } from './scenaries/custom-purchase'
import { customRefundPrintHandler } from './scenaries/custom-refund'

import * as callbacks from './util-callbacks'

const ReservationSaving = {
  handlePurchase: purchasePrintHandler,
  handleRefund: refundPrintHandler,
  handleLoyaltyPurchase: loyaltyPurchasePrintHandler,
  handleCustomPurchase: customPurchasePrintHandler,
  handleCustomRefund: customRefundPrintHandler,
  callbacks,
}

export { ReservationSaving }
