import Day from './Day'
import React from 'react'

export const NotPayedDays = ({
                               list,
                               onDayPriceChange,
                               onDayPaymentChange,
                               onDayPayedChange,
                               modes,
                               dayRefund,
                               lastId,
                               validationErrors
                             }) => {

  return (
    <>
      {list
        .map(day => (
          <Day
            key={day.id}
            day={day}
            price={day.price}
            method={day.payment_type}
            onDayPriceChange={(value) => onDayPriceChange({ value, day })}
            onDayPaymentChange={(value) => onDayPaymentChange({ value, day })}
            onDayPayedChange={(value) => onDayPayedChange({ value, day })}
            payed={day.payment_date !== ''}
            modes={modes}
            dayRefund={dayRefund}
            isLast={day.id === lastId}
            validationErrors={validationErrors}
            disabled={!modes.edit && !modes.wubook}
          />))}
    </>
  )
}
