import uuid from 'uuid/v4'
import { reservationSelectors } from '../../reservation'
import { buildCalculationQuery } from './build-calculation-query'
import { loyaltyErrors } from '../../api/loyaltyAPI/constants/loyaltyErrors'
import { getCalculatedItems } from '../../helpers/loyalty/getCalculatedItems'
import clone from 'clone'
import { saveReservationCall } from '../../api/ccAPI/requests'
import { loyaltyAPI } from '../../api/loyaltyAPI'

function toMapByKey(array, key) {
  return array.reduce((acc, item) => {
    acc[item[key]] = item
    return acc
  }, {})
}

export async function applyBonusesForPaidItems({ state, applyBonuses }) {
  const paidDaysWithoutRefundId = reservationSelectors.paidDaysWithoutRefundId(
    state
  )
  const paidServicesWithoutRefundId = reservationSelectors.paidServicesWithoutRefundId(
    state
  )

  const areTherePaidItemsWithoutRefundId = reservationSelectors.areTherePaidItemsWithoutRefundId(
    state
  )

  if (!areTherePaidItemsWithoutRefundId)
    return {
      isSuccess: false,
      error: 'Нет оплаченных позиций, за которые еще не были начислены бонусы',
    }

  const calculationQuery = buildCalculationQuery({
    state,
    days: paidDaysWithoutRefundId,
    services: paidServicesWithoutRefundId,
    applyBonuses,
  })

  const calculateRes = await loyaltyAPI.calculatePurchase({
    calculationQuery,
  })

  if (calculateRes.errorCode)
    return {
      isSuccess: false,
      error:
        loyaltyErrors[calculateRes.errorCode] ??
        'Неизвестная ошибка при расчете бонусов',
    }

  const txid = uuid()

  const setPurchaseRes = await loyaltyAPI.setPurchase({
    txid,
    calculationQuery,
  })

  if (setPurchaseRes.errorCode)
    return {
      isSuccess: false,
      error:
        loyaltyErrors[setPurchaseRes.errorCode] ??
        'Неизвестная ошибка при предварительном создании продажи',
    }

  const {
    rows: calculatedRows,
    bonuses: { collected: collectedBonuses },
  } = calculateRes.calculationResult

  const calculatedItems = getCalculatedItems({
    items: {
      days: paidDaysWithoutRefundId,
      services: paidServicesWithoutRefundId,
    },
    txid,
    calculatedRowsMap: toMapByKey(calculatedRows, 'id'),
  })

  const calculatedItemsMap = {
    days: toMapByKey(calculatedItems.days, 'id'),
    services: toMapByKey(calculatedItems.services, 'id'),
  }

  const reservation = reservationSelectors.everything(state)

  const reservationBackup = clone(reservation)

  const updatedReservation = {
    ...reservation,
    reserved_days: reservation.reserved_days.map(day => {
      const calculatedDay = calculatedItemsMap.days[day.id]
      if (calculatedDay) return calculatedDay
      return day
    }),
    additional_services: reservation.additional_services.map(service => {
      const calculatedService = calculatedItemsMap.services[service.id]
      if (calculatedService) return calculatedService
      return service
    }),
  }

  const { ticket } = setPurchaseRes

  let savedReservation

  try {
    savedReservation = await saveReservationCall(updatedReservation)
  } catch (error) {
    await loyaltyAPI.discardTicket({
      txid,
      ticket,
    })

    return {
      isSuccess: false,
      error: error?.message ?? error?.detail ?? error?.error ?? 'Неизвестная ошибка при сохранении брони',
    }
  }

  const confirmTicketRes = await loyaltyAPI.confirmTicket({
    txid,
    ticket,
  })

  if (confirmTicketRes.errorCode) {
    try {
      await saveReservationCall(reservationBackup)
    } catch {
      // skip
    }

    return {
      isSuccess: false,
      error:
        loyaltyErrors[confirmTicketRes.errorCode] ??
        'Неизвестная ошибка при применении бонусов',
    }
  }
  window.localStorage.setItem("reservation_changed", +new Date());
  return {
    isSuccess: true,
    savedReservation,
    collectedBonuses,
  }
}
