import React from 'react'
import {todayCheckInsActions, todayCheckInsSelectors} from '../../reducers/today-check-ins'
import {useSelector} from "react-redux";
import {Glyphicon} from "react-bootstrap";
import {useActions} from "../../../common/hooks/use-actions";
import styled from 'styled-components'
import {CheckIns} from "./CheckIns";

const UpdateButton = styled.span`
    padding: 5px 10px;
    font-size: 12px;
    display: inline-block;
    color: #fff;
    background-color: #444;
    border-radius: 3px;
    line-height: 1.3;
    cursor: pointer;
    ${props => props.disabled && 'opacity: 0.5;'}
`

const CheckInsTitle = styled.h2`
    font-size: 24px;
    font-weight: 700;
    margin: 0;
`

export const TodayCheckIns = () => {
    const wubook = useSelector(todayCheckInsSelectors.todayCheckins)
    const count = useSelector(todayCheckInsSelectors.count)
    const {updateData} = useActions(todayCheckInsActions)
    const isUpdating = useSelector(todayCheckInsSelectors.isUpdating)

    const updateButton = isUpdating ? (
        <UpdateButton disabled>Загрузка..</UpdateButton>
    ) : (
        <UpdateButton onClick={updateData}>
            <Glyphicon glyph="refresh" style={{marginRight: 8}}/>
            Загрузить новые
        </UpdateButton>
    )

    return <div style={{padding: 30}}>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
            }}
        >
            <CheckInsTitle>Заезды сегодня</CheckInsTitle>
            {updateButton}
        </div>
        <CheckIns count={count} wubook={wubook}/>
    </div>

}
