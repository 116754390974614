import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Navbar from '../Components/Navbar/Navbar'
import {settingsSelectors} from '../Modules/settings'
import {bindActionCreators} from "redux";
import {getCloseShiftModal} from "../Components/Shifts/get-shift-modal";
import {shiftsSelectors} from "../Modules/shifts";

const mapStateToProps = state => ({
    admin: state.session.admin,
    isAuthorized: state.session.isAuthorized,
    printerIsOn: state.settings.printerIsOn,
    lcode: state.session.lcode,
    lcodeObj: state.session.lcodeObj,
    doorLockType: settingsSelectors.isDoorLocks(state),
    apiData: settingsSelectors.apiData(state),
    isShiftOpened: shiftsSelectors.isShiftOpened(state)

})

const mapDispatchToProps = dispatch => ({
    getCloseShift: bindActionCreators(getCloseShiftModal, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
