import React from 'react'
import {
  FaUndoAlt as RefundIcon,
  FaShoppingCart as SellIcon,
} from 'react-icons/fa'

const CashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
  >
    <path d="M3.5 10H3v-.5c0-.658-1-.67-1 0v1c0 .262.238.5.5.5h1c.658 0 .67-1 0-1zm8 0h.5v-.5c0-.658 1-.67 1 0v1c0 .262-.238.5-.5.5h-1c-.658 0-.67-1 0-1zm-8-5H3v.5c0 .658-1 .67-1 0v-1c0-.262.238-.5.5-.5h1c.658 0 .67 1 0 1zM7 4.5v.594c-.585.204-1 .753-1 1.406 0 1.03.67 1.484 1.5 1.5.275.01.5.236.5.5 0 .274-.23.5-.5.5h-1c-.662 0-.66 1 0 1H7v.5c0 .667 1 .665 1 0v-.563c.586-.204 1-.783 1-1.437 0-.826-.597-1.492-1.5-1.492-.282 0-.5-.236-.5-.508 0-.274.23-.5.5-.5h1c.704 0 .613-1 0-1H8v-.5c0-.666-1-.656-1 0zm4.5.5h.5v.5c0 .658 1 .67 1 0v-1c0-.262-.238-.5-.5-.5h-1c-.658 0-.67 1 0 1zm-10-3C.678 2 0 2.678 0 3.5v8c0 .822.678 1.5 1.5 1.5h12c.822 0 1.5-.678 1.5-1.5v-8c0-.822-.678-1.5-1.5-1.5zm0 1h12c.286 0 .5.214.5.5v8c0 .286-.214.5-.5.5h-12c-.286 0-.5-.214-.5-.5v-8c0-.286.214-.5.5-.5z" />
  </svg>
)

const CardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
  >
    <path d="M11.5 10h1c.68 0 .652 1 0 1h-1c-.668 0-.65-1 0-1zm-3 0h1c.68 0 .652 1 0 1h-1c-.668 0-.654-1 0-1zm-3 0h1c.68 0 .652 1 0 1h-1c-.668 0-.654-1 0-1zm-3 0h1c.68 0 .652 1 0 1h-1c-.668 0-.67-1 0-1zM10 4c-1.1 0-2 .9-2 2s.9 2 2 2c.392 0 .702-.216 1-.406.298.19.608.406 1 .406 1.1 0 2-.9 2-2s-.9-2-2-2c-.375 0-.695.192-1 .375-.305-.183-.625-.375-1-.375zm0 1c.26 0 .483.096.656.25.184.174.504.174.688 0 .173-.154.395-.25.656-.25.558 0 1 .442 1 1s-.442 1-1 1c-.246 0-.466-.11-.656-.28-.184-.175-.504-.175-.688 0-.19.17-.41.28-.656.28-.558 0-1-.442-1-1s.442-1 1-1zM1.5 2C.678 2 0 2.678 0 3.5v8c0 .822.678 1.5 1.5 1.5h13c.822 0 1.5-.678 1.5-1.5v-8c0-.822-.678-1.5-1.5-1.5zm0 1h13c.286 0 .5.214.5.5v8c0 .286-.214.5-.5.5h-13c-.286 0-.5-.214-.5-.5v-8c0-.286.214-.5.5-.5z" />
  </svg>
)

export { SellIcon, RefundIcon, CashIcon, CardIcon }
