import moment from 'moment'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { washingSelectors } from '../../Modules/washing'
import { callModal } from '../dynamic-modal'
import { getStyle } from '../dynamic-modal/helpers/get-style'
import {
  ModalButton,
  ModalContent,
  ModalControls,
  ModalHR,
  ModalTitle,
} from '../common/ModalParts'
import Select from '../common/Select'

const Actions = {
  Enter: Symbol('enter'),
}

const WashingMachineSelectionModal = ({ isOpen, resolve, ownProps }) => {
  const { service } = ownProps
  const date = moment(service.date).format('DD.MM.YYYY')

  const [value, setValue] = useState('')
  const [error, setError] = useState(null)
  const freeMachines = useSelector(washingSelectors.freeMachines)

  return (
    <Modal isOpen={isOpen} style={getStyle({ width: 400, zIndex: 1000 })}>
      <ModalTitle>Стирка</ModalTitle>
      <ModalHR />
      <ModalContent>
        <p style={{ marginBottom: 0 }}>
          Выберите стиральную машинку для стирки за {date}
        </p>
        <Select
          value={value}
          values={freeMachines.map(machine => ({
            text: machine.number,
            value: machine.number,
          }))}
          onChange={setValue}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </ModalContent>
      <ModalHR />
      <ModalControls>
        <ModalButton
          bsStyle="success"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            if (value === '') {
              setError('Не выбран номер машинки')
              return
            }

            resolve(Actions.Enter, { value })
          }}
        >
          Продолжить
        </ModalButton>
      </ModalControls>
    </Modal>
  )
}

export async function selectWashingMachine({ service }) {
  const { params } = await callModal(WashingMachineSelectionModal, { service })
  return params.value
}
