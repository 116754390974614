import React from 'react'
import styled from 'styled-components'
import {
  isItemDiscounted,
  getPrintedItemQtyPrice, getPrintedDiscountedServicePrice,
} from '../../Modules/helpers/item-selectors'
import { rub } from '../../Modules/formatters/currency'

const colorMapping = {
  red: '#e43636',
  default: 'inherit',
}

const StyledItemPrice = styled.span`
  color: ${props => colorMapping[props.color]};
`

export const ItemPrice = ({ item }) => {
  const displayPrice = getPrintedItemQtyPrice(item)
  const hasDiscount = isItemDiscounted(item)

  const color = hasDiscount ? 'red' : 'default'

  return <StyledItemPrice color={color}>{rub(displayPrice)}</StyledItemPrice>
}

export const ItemRegularPrice = ({ item }) => {
  return <StyledItemPrice>{rub(item.price)}</StyledItemPrice>
}

export const ServiceItemPrice = ({ item }) => {
  const displayPrice = getPrintedDiscountedServicePrice(item)
  const hasDiscount = isItemDiscounted(item)

  const color = hasDiscount ? 'red' : 'default'
  return <StyledItemPrice color={color}>{rub(displayPrice)}</StyledItemPrice>
}
