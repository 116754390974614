import styled from 'styled-components'

export default styled.h2`
  padding: 16px;
  font-size: 16px;
  margin: 0;
  color: ${props => props.theme.colors.pageText};
  letter-spacing: 0.5px;
  font-weight: 600;
`
