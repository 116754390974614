import React, { forwardRef, useRef } from 'react'
import { withPrintData } from '../HOCs/withPrintData'
import { RoomsSchedule } from './components/RoomsSchedule'
import { Pages } from '../common/Pages'
import { useReactToPrint } from 'react-to-print'
import { Button } from 'react-bootstrap'

const BedclothesChangeView = ({ printData }) => {
  const {
    schedule,
    leavedRooms,
    otherRooms,
    admin,
    hotelName,
    printData: pagesPrintData,
    maid, maid_url, maid_id,
    cleaningDate
  } = printData

  const componentBedRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentBedRef.current,
  });
  const { roomsList, bedLinen } = pagesPrintData

  const ComponentToPrint = forwardRef((props, ref) => (
    <div ref={ref}>
      <Pages>
        <RoomsSchedule
          admin={admin}
          hotelName={hotelName}
          roomsList={roomsList}
          bedLinen={bedLinen}
          schedule={schedule}
          leavedRooms={leavedRooms}
          otherRooms={otherRooms}
          maid_url={maid_url}
          maid={maid}
          maid_id={maid_id}
          cleaningDate={cleaningDate}
        />
      </Pages>
    </div>
  ))
  return (
    <>
      <Button
        block
        bsStyle="success"
        style={{ margin: '10px 0' }}
        onClick={handlePrint}>Печать
      </Button>
      <ComponentToPrint ref={componentBedRef} />
    </>
  )
}

export const BedclothesChange = withPrintData('BEDCLOTHES_CHANGE_ROOMS_DATA')(
  BedclothesChangeView
)
