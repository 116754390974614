export const isPassportValid = passport => {
  if (passport.length === 0) {
    return false
  }

  for (let item of passport) {
    if (!item.name) return false
    if (!item.passport) return false
  }

  return true
}
