import React from 'react'
import { Table } from './Table'
import { Block, Title } from './styleds'

const Content = ({ data }) => {
  const { items, isLoading, isFailed } = data

  if (isLoading) return <div>Загрузка..</div>
  if (isFailed) return <div>Ошибка загрузки</div>
  if (items.length === 0) return <div>Нет данных</div>

  return <Table items={items} />
}

export const Prepayeds = ({ data }) => {
  return (
    <Block>
      <Title>Предоплаты</Title>
      <Content data={data} />
    </Block>
  )
}
