import React from 'react'
import styled from 'styled-components'
import Block from '../Block'
import Content from '../Content'

const StyledBlock = styled(Block)`
  padding-top: 90px;
  padding-bottom: 90px;
`

const StyledContent = styled(Content)``

export default ({ children }) => (
  <StyledBlock>
    <StyledContent>{children}</StyledContent>
  </StyledBlock>
)
