import React from 'react'
import TransactionsList from '../TransactionsList'
import TransactionsAPI from '../../../Modules/TransactionsAPI'

export default () => (
  <TransactionsList
    actionHint="Нажмите на необходимую транзакцию для её подтверждения"
    getTransactionsFunc={TransactionsAPI.get}
    transactionClickAction={TransactionsAPI.confirm}
    withModal={true}
  />
)
