import * as types from './types'
import {getBreakfastsRestrictions} from "../api/ccAPI/requests"

const loadRequest = () => ({
    type: types.LOAD_REQUEST,
})

const loadSuccess = ({items}) => ({
    type: types.LOAD_SUCCESS,
    payload: {items},
})

const loadFailure = () => ({
    type: types.LOAD_FAILURE,
})

export const load = () => async dispatch => {
    dispatch(loadRequest())

    try {
        const items = await getBreakfastsRestrictions()

        dispatch(loadSuccess({items}))
    } catch {
        dispatch(loadFailure())
    }
}

