import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { modalDefaultStyles } from '../../App'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  ModalControls as DefaultModalControls,
  ModalButton,
  ModalTitle,
} from '../common/ModalParts'

const ModalControls = styled(DefaultModalControls)`
  padding: 20px;
`

const Form = styled.form.attrs(() => ({
  autoComplete: 'off',
}))`
  padding: 5px;
`

const FormBody = styled.div`
  padding: 5px 20px;
`

const Field = styled.input.attrs(() => ({
  type: 'text',
}))`
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  height: 34px;
  padding: 6px 12px;
  transition: border-color 0.15s, box-shadow 0.15s;
  width: 100%;
  margin-bottom: 15px;
  outline: none;

  ${props =>
    props.invalid &&
    `
    box-shadow: inset 0 1px 1px rgba(255, 0, 0, 0.2);
    border-color: #ff9090;
  `}
`

const ValidationSchema = Yup.object().shape({
  guestName: Yup.string().required(),
  bookingNumber: Yup.string().required(),
})

const TransactionConfirmModal = ({
  title,
  close,
  submitAction,
  lcode,
  date,
  ...restProps
}) => (
  <Modal
    defaultStyles={{
      ...modalDefaultStyles,
      content: {
        ...modalDefaultStyles.content,
        width: 400,
        minWidth: 400,
        maxWidth: 'auto',
      },
    }}
    {...restProps}
  >
    <Formik
      initialValues={{
        guestName: '',
        bookingNumber: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        submitAction({
          ...values,
          lcode,
          date,
        })

        actions.setSubmitting(false)
        close()
      }}
      render={({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ModalTitle>{title}</ModalTitle>
          <FormBody>
            <Field
              name="guestName"
              placeholder="Имя гостя"
              value={values.guestName}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.guestName && errors.guestName}
            />
            <Field
              name="bookingNumber"
              placeholder="Номер брони"
              value={values.bookingNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.bookingNumber && errors.bookingNumber}
            />
          </FormBody>

          <ModalControls>
            <ModalButton
              bsStyle="danger"
              style={{ marginLeft: 0 }}
              onClick={close}
            >
              Закрыть
            </ModalButton>
            <ModalButton
              type="submit"
              bsStyle="success"
              style={{ marginLeft: 'auto' }}
              disabled={!isValid || isSubmitting}
            >
              Произвести продажу
            </ModalButton>
          </ModalControls>
        </Form>
      )}
    />
  </Modal>
)

export default TransactionConfirmModal
