import store from '../../../store'
import { payTimerActions } from '../../pay-timer'
import { modalsActions } from '../../modals'
import { PAY_TIMER_DURATION } from '../../../Constants/pay-timer-duration'
import { LogsAPI } from '../../logs-api'

export const startPaymentTimer = ({ checkType, checkTotals }) => {
  if (checkType === 'sell' && checkTotals.card > 0) {
    store.dispatch(
      payTimerActions.start({
        duration: PAY_TIMER_DURATION,
        onFinish: () => {
          store.dispatch(modalsActions.open('pay-timer-finish'))
          LogsAPI.event(
            'Вышло время оплаты картой, открыто окно с предупреждением'
          )
        },
      })
    )

    LogsAPI.event(`Начат таймер оплаты картой (${PAY_TIMER_DURATION} секунд)`)
  }
}
