import { isPassportValid } from './isPassportValid'
import {
  callModal,
  modalTemplates,
} from '../../Components/dynamic-modal'
import store from '../../store'
import { reservationSelectors } from '../reservation'

export async function handleWriteCardClick({ showWriteCardModal }) {
  const state = store.getState()
  const passport = reservationSelectors.passport(state)

  const isValid = isPassportValid(passport)

  if (!isValid) {
    await callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: 'Карту нельзя записать, так как в брони не указан номер паспорта',
      })
    )
    return
  }

  showWriteCardModal()
}
