import * as types from './types'
import { ccAPI } from '../api/ccAPI'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = () => async dispatch => {
  dispatch(loadRequest())

  try {
    const items = await ccAPI.getMaidsList()

    dispatch(loadSuccess({ items }))
  } catch {
    dispatch(loadFailure())
  }
}

export const getMaidsCheckedIns = ({ lcode }) => async dispatch => {
  dispatch(loadRequest())

  try {
    const checkedInList = await ccAPI.getMaidsCheckedInList({ lcode })
    dispatch({
      type: types.LOAD_CHECKED_IN_LIST,
      payload: { checkedInList },
    })
  } catch {
  }
}

export const removeFromCheckOutList = ({ id }) => async (dispatch, getState) => {
  const { maids } = getState()
  const checkedInList = maids.checkedInList.filter(it => it.id !== id)
  dispatch({
    type: types.LOAD_CHECKED_IN_LIST,
    payload: { checkedInList },
  })
}
