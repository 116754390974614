export const CONNECT_LOYALTY_REQUEST =
  'ghotel/loyalty-client/CONNECT_LOYALTY_REQUEST'
export const CONNECT_LOYALTY_SUCCESS =
  'ghotel/loyalty-client/CONNECT_LOYALTY_SUCCESS'
export const CONNECT_LOYALTY_FAILURE =
  'ghotel/loyalty-client/CONNECT_LOYALTY_FAILURE'
export const CONNECT_LOYALTY_CANCEL =
  'ghotel/loyalty-client/CONNECT_LOYALTY_CANCEL'

export const START_SENDING_REGISTRATION_SMS =
  'ghotel/loyalty-client/START_SENDING_REGISTRATION_SMS'
export const STOP_SENDING_REGISTRATION_SMS =
  'ghotel/loyalty-client/STOP_SENDING_REGISTRATION_SMS'

export const RESET = 'ghotel/loyalty-client/RESET'
