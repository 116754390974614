import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Arial", sans-serif;
  }
`

export default GlobalStyle
