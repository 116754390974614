import { useMemo, useReducer } from 'react'
import debounce from 'lodash.debounce'
import { AlphaBookingNumberRE } from '../../../Modules/helpers/regex/AlphaBookingNumberRE'
import { getAllReservationsByBookingNumberNotCanceled } from "../../../Modules/api/ccAPI/requests"

function searchReservations(reservations, string, isExact = false) {
  const foundReservations = reservations
    /* search */
    .filter(reservation => {
      const { guest_name, booking_number } = reservation

      const [lowerCasedString, lowerCasedGuestName] = [
        string,
        guest_name,
      ].map(value => value.toLowerCase())

      if (isExact) return booking_number === lowerCasedString

      return (
        booking_number.includes(lowerCasedString) ||
        lowerCasedGuestName.includes(lowerCasedString)
      )
    })
    /* only unique */
    .reduce((acc, reservation) => {
      if (acc.find(item => item.pk === reservation.pk)) {
        return acc
      }

      return acc.concat(reservation)
    }, [])

  return foundReservations
}

const ACTION_TYPES = {
  LOAD_REQUEST: 'load-request',
  LOAD_SUCCESS: 'load-success',
  LOAD_FAILURE: 'load-failure',
  MAKE_SEARCH: 'make-search',
  RESET: 'reset',
}

const initialState = {
  reservations: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  foundResults: [],
  exactResults: [],
  foundFor: null,
}

const reservationsSearchReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_REQUEST:
      return {
        ...state,
        reservations: [],
        isLoading: true,
        isLoaded: false,
        isFailed: false,
        foundResults: [],
        exactResults: [],
      }

    case ACTION_TYPES.LOAD_SUCCESS:
      return {
        ...state,
        reservations: action.payload,
        isLoading: false,
        isLoaded: true,
        foundResults: action.payload,
      }

    case ACTION_TYPES.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      }

    case ACTION_TYPES.MAKE_SEARCH:
      return {
        ...state,
        foundResults: searchReservations(state.reservations, action.payload),
        exactResults: searchReservations(
          state.reservations,
          action.payload,
          true
        ),
        foundFor: action.payload,
      }

    case ACTION_TYPES.RESET:
      return initialState

    default:
      return state
  }
}

export function useReservationsSearch() {
  const [state, dispatch] = useReducer(reservationsSearchReducer, initialState)

  const {
    reservations,
    foundResults,
    exactResults,
    foundFor,
    isLoading,
    isLoaded,
    isFailed,
  } = state

  const debouncedMakeSearch = useMemo(() => {
    const makeSearch = string =>
      dispatch({
        type: ACTION_TYPES.MAKE_SEARCH,
        payload: string,
      })

    return debounce(makeSearch, 500)
  }, [])

  const loadReservations = async (booking_number) => {
    if (!booking_number || booking_number.length < 4) return []
    dispatch({ type: ACTION_TYPES.LOAD_REQUEST })
    try {
      const promises = []
      promises.push(getAllReservationsByBookingNumberNotCanceled(booking_number))
      const results = await Promise.all(promises)
      const reservations = results.reduce((acc, result) => {
        return acc.concat(result)
      }, [])

      dispatch({
        type: ACTION_TYPES.LOAD_SUCCESS,
        payload: reservations,
      })
      return reservations
        .map(reservation => reservation.booking_number)
        .filter(booking_number => !AlphaBookingNumberRE.test(booking_number))
        .map(booking_number => ({
          label: booking_number,
          value: booking_number,
        }))
    } catch (err) {
      dispatch({ type: ACTION_TYPES.LOAD_FAILURE })
    }
  }

  const bookingNumberOptions = useMemo(() => {
    return reservations
      .map(reservation => reservation.booking_number)
      .filter(booking_number => !AlphaBookingNumberRE.test(booking_number))
      .map(booking_number => ({
        label: booking_number,
        value: booking_number,
      }))
  }, [reservations])

  return {
    foundResults,
    exactResults,
    foundFor,
    bookingNumberOptions,
    isLoading,
    isLoaded,
    isFailed,
    loadReservations,
    makeSearch: debouncedMakeSearch,
    reset: () => dispatch({ type: ACTION_TYPES.RESET }),
  }
}
