import React from 'react'
import { Item } from './Item'

export const Content = ({ checkLines }) => {
  const renderedLines = checkLines.map(line => {
    return (
      <Item
        key={line.id}
        type={line.type}
        name={line.name}
        date={line.date}
        quantity={line.quantity}
        total={line.total}
        payment_type={line.payment_type}
      />
    )
  })

  return <React.Fragment>{renderedLines}</React.Fragment>
}
