import * as types from './types'
import { createReducer } from '../helpers/redux/createReducer'

const initialState = {
  machines: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = createReducer(initialState, {
  [types.LOAD_MACHINES_REQUEST]: state => ({
    ...state,
    machines: [],
    isLoading: true,
    isLoaded: false,
    isFailed: false,
  }),
  [types.LOAD_MACHINES_SUCCESS]: (state, payload) => ({
    ...state,
    machines: payload.machines,
    isLoading: false,
    isLoaded: true,
  }),
  [types.LOAD_MACHINES_FAILURE]: state => ({
    ...state,
    isLoading: false,
    isFailed: true,
  }),
})
