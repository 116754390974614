import { createSelector } from 'reselect'

export const everything = state => state.maids

export const items = state => everything(state).items

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const checkedInList = state => everything(state).checkedInList


function mapItemToOption(item) {
  return {
    label: item.name,
    value: item.id
  }
}

export const maidsOptions = createSelector(items, items => (items.map(mapItemToOption)))

