import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  @page {
    size: A4;
    margin: 0;
  }

  body {
    margin: 0;
  }

  @media print {
    html,
    body {
      width: 210mm;    
    }
  }
`
