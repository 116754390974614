import { compose } from 'redux'
import { connect } from 'react-redux'
import { CheckLines } from './CheckLines'
import { checkSelectors } from '../../Modules/check'

const mapStateToProps = (state, ownProps) => ({
  checkLines: checkSelectors.lines(state),
  ...ownProps,
})

const enhance = compose(connect(mapStateToProps))

export const EnhancedCheckLines = enhance(CheckLines)
