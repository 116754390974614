import React from 'react'
import { useSelector } from 'react-redux'
import { reservationSelectors } from '../../../Modules/reservation'
import { specialServiceTypesSelectors } from '../../../Modules/special-service-types'
import { EditSection } from '../../common/EditSection'
import { Content } from './components/Content'
import { Footer } from './components/Footer'

export const Breakfasts = ({
  availableActions = {
    create: true,
    update: true,
    copy: true,
    delete: true,
    refund: false,
  },
}) => {
  const breakfastServices = useSelector(reservationSelectors.breakfastServices)
  const breakfastService = useSelector(
    specialServiceTypesSelectors.breakfastService
  )
  const validationErrors = useSelector(
    reservationSelectors.servicesValidationErrors
  )

  const content = breakfastServices.length > 0 && (
    <Content
      validationErrors={validationErrors}
      availableActions={availableActions}
    />
  )

  const footer = availableActions.create && (
    <Footer breakfastService={breakfastService} />
  )

  return (
    <EditSection
      name="Завтраки"
      content={content}
      footer={footer}
    />
  )
}
