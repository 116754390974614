import React from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

const TextField = props => {
  return (
    <FormGroup
      controlId="formControlsSelect"
      style={props.style ? props.style : { marginBottom: 0 }}
    >
      <ControlLabel style={{ letterSpacing: 0.5 }}>{props.name}</ControlLabel>
      <FormControl
        componentClass="textarea"
        placeholder={props.placeholder}
        style={{
          ...props.formStyle,
          resize: 'vertical',
        }}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        disabled={props.disabled}
      />
    </FormGroup>
  )
}

export default TextField
