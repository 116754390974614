import * as types from './types'
import * as utils from './utils'
import { settingsSelectors } from '../settings'
import { getWashingMachines } from '../api/ccAPI/requests'

const loadMachinesRequest = () => ({
  type: types.LOAD_MACHINES_REQUEST,
})

const loadMachinesSuccess = ({ machines }) => ({
  type: types.LOAD_MACHINES_SUCCESS,
  payload: { machines },
})

const loadMachinesFailure = () => ({
  type: types.LOAD_MACHINES_FAILURE,
})

export const loadMachines = () => async (dispatch, getState) => {
  utils.startLoading()
  dispatch(loadMachinesRequest())

  const { session } = getState()

  try {
    const machines = await getWashingMachines(session.lcode)

    dispatch(loadMachinesSuccess({ machines }))
  } catch {
    dispatch(loadMachinesFailure())
  }

  utils.stopLoading()
}

let intervalId = null

export const startPolling = () => dispatch => {
  if (intervalId) return
  intervalId = setInterval(() => dispatch(loadMachines()), 60000)
}

export const stopPolling = () => () => {
  if (!intervalId) return
  clearInterval(intervalId)
  intervalId = null
}

export const init = () => (dispatch, getState) => {
  const state = getState()
  const isWashingOperationsOn = settingsSelectors.isWashingOperationsOn(state)

  if (!isWashingOperationsOn) return

  dispatch(loadMachines())
  dispatch(startPolling())
}
