import React from 'react'
import styled from 'styled-components'

import { Field, Name, Text } from './FieldUtils'

function formatPrice(price) {
  return price.toFixed(2).replace('.', ',')
}

const SummaryTableCell = styled.td`
  padding: 1mm 3mm;
  border: 1px solid black;
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign || 'center'};
  border: ${props => props.border};
`

const StyledSummaryTableRow = styled.tr`
  page-break-inside: avoid;
`

const SummaryTableRow = props => (
  <StyledSummaryTableRow>
    <SummaryTableCell>{props.id}</SummaryTableCell>
    <SummaryTableCell>{props.service}</SummaryTableCell>
    <SummaryTableCell>{props.unit}</SummaryTableCell>
    <SummaryTableCell fontSize="15pt">{props.count}</SummaryTableCell>
    <SummaryTableCell fontSize="15pt">
      {formatPrice(props.price)}
    </SummaryTableCell>
    <SummaryTableCell fontSize="15pt">
      {formatPrice(props.totalPrice)}
    </SummaryTableCell>
  </StyledSummaryTableRow>
)

const SummaryTableFinalRow = props => (
  <StyledSummaryTableRow>
    <SummaryTableCell
      colSpan={5}
      fontSize="15pt"
      textAlign="right"
      border="none"
    >
      Итого
    </SummaryTableCell>
    <SummaryTableCell fontSize="15pt">
      {formatPrice(props.total)}
    </SummaryTableCell>
  </StyledSummaryTableRow>
)

const PeriodService = props => (
  <React.Fragment>
    <Field style={{ marginTop: '2mm' }}>
      <Name>{props.name}</Name>
    </Field>
    <Field>
      <Name>С</Name>
      <Text stretch caption="(дата, часы)">
        {props.from}
      </Text>
    </Field>
    <Field>
      <Name>По</Name>
      <Text stretch caption="(дата, часы)">
        {props.to}
      </Text>
    </Field>
  </React.Fragment>
)

const StyledSummaryTable = styled.table`
  width: 100%;
  margin: 5mm 0;
  border-collapse: collapse;
  font-size: 10pt;
`

const SummaryTable = props => {
  const { values } = props

  let rowsCounter = 1

  return (
    <StyledSummaryTable>
      <thead>
      <tr>
        <SummaryTableCell>
          №<br />
          п/п
        </SummaryTableCell>
        <SummaryTableCell>Виды платежей</SummaryTableCell>
        <SummaryTableCell>Ед. изм.</SummaryTableCell>
        <SummaryTableCell>Кол-во единиц</SummaryTableCell>
        <SummaryTableCell>Цена, руб.</SummaryTableCell>
        <SummaryTableCell>Сумма, руб.</SummaryTableCell>
      </tr>
      </thead>
      <tbody>
      <SummaryTableRow
        id={rowsCounter++}
        service={
          <PeriodService
            name="Проживание"
            from={values.formattedCheckIn}
            to={values.formattedCheckOut}
          />
        }
        unit="к/с"
        count={values.daysCount}
        price={values.dayAveragePrice}
        totalPrice={values.daysTotalPrice}
      />
      {values.processedServices.map(service => {
        return (
          <SummaryTableRow
            id={rowsCounter++}
            key={rowsCounter}
            service={service.name}
            unit="к/с"
            count={service.quantity}
            price={service.price}
            totalPrice={service.totalPrice}
          />
        )
      })}
      {values.processedBreakfasts.quantity > 0 && <SummaryTableRow
        id={rowsCounter++}
        service={values.processedBreakfasts.name}
        unit="к/с"
        count={values.processedBreakfasts.quantity}
        price={values.processedBreakfasts.price}
        totalPrice={values.processedBreakfasts.totalPrice}
      />}
      <SummaryTableFinalRow total={values.totalPrice} />
      </tbody>
    </StyledSummaryTable>
  )
}

export default SummaryTable
