import React from 'react'
import { InfoLabel, InfoText, InfoError } from '../../common/info'

export const Info = ({
                       data,
                       isActual,
                       isLoading,
                       isLoaded,
                       isFailed,
                       error,
                     }) => {
  if (isLoading) return <div>Загрузка..</div>
  if (isFailed) return <InfoError>{error}</InfoError>
  if (!isLoaded) return <div>Нет данных</div>
  if (!isActual) {
    return (
      <div>Загруженные данные о лояльности не актуальны для номера телефона, который сейчас установлен в брони. </div>
    )
  }

  const { phoneNumber, fullName, email, bonuses, pendingBonuses, level } = data

  return (
    <React.Fragment>
      <InfoLabel>Номер телефона</InfoLabel>
      <InfoText>{phoneNumber}</InfoText>
      <InfoLabel>Полное имя</InfoLabel>
      <InfoText>{fullName}</InfoText>
      <InfoLabel>E-mail</InfoLabel>
      <InfoText>{email || '-'}</InfoText>
      <InfoLabel>Уровень в программе</InfoLabel>
      <InfoText>{level}</InfoText>
      <InfoLabel>Бонусы</InfoLabel>
      <InfoText>
        Доступно: <b>{bonuses}</b>
      </InfoText>
      <InfoText>
        В ожидании: <b>{pendingBonuses}</b>
      </InfoText>
    </React.Fragment>
  )
}
