import history from '../helpers/history'
import moment from 'moment'

export const onNewReservationClick = (room, roomType, firstDay, daysCount) => {
  const lastDay = moment(firstDay)
    .add(daysCount, 'days')
    .format('YYYY-MM-DD')
  history.push(
    `/new-reservation?room=${room}&roomType=${roomType}&start=${firstDay}&end=${lastDay}`
  )
}

export const onReservationClick = pk => {
  history.push(`/reservation?pk=${pk}`)
}
