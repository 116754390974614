import styled from 'styled-components'

export const Cell = styled.td`
  vertical-align: middle !important;
`

export const ReadOnlyCell = styled(Cell)`
  font-size: 16px;
  line-height: 1 !important;
`
