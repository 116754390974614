import React, { Component } from 'react'
import SearchResults from '../common/SearchResults'
import { SingleDatePicker } from '../common/DatePicker'
import moment from 'moment'
import { sessionSelectors } from '../../Modules/session'
import { connect } from 'react-redux'
import { searchByStartCall } from '../../Modules/api/ccAPI/requests'

const SearchBlock = props => (
  <div
    style={{
      backgroundColor: '#eee',
      width: 800,
      margin: '50px auto',
      padding: 30,
      borderRadius: 5,
    }}
  >
    {props.children}
  </div>
)

class SearchByStart extends Component {
  state = {
    date: moment().format('YYYY-MM-DD'),
    searchResult: null,
  }

  componentDidMount() {
    this.updateResult()
  }

  setDate = date => {
    this.setState({ date }, this.updateResult)
  }

  setResult = searchResult => this.setState({ searchResult })
  clearResult = () => this.setState({ searchResult: null })

  updateResult = async () => {
    if (!this.state.date) return this.clearResult()
    const result = await searchByStartCall(this.props.lcode, this.state.date)
    this.setResult(result)
  }

  render() {
    return (
      <SearchBlock>
        <SingleDatePicker
          label="Дата заезда"
          value={this.state.date}
          onChange={this.setDate}
        />

        <SearchResults
          reservations={this.state.searchResult}
          hintText="Выберите дату для поиска"
        />
      </SearchBlock>
    )
  }
}

const mapStateToProps = state => ({
  lcode: sessionSelectors.lcode(state)
})

export default connect(mapStateToProps)(SearchByStart)
