import React, { forwardRef, useRef } from 'react'
import moment from 'moment'
import { Fields } from '../BedclothesChange/components/common'

import { BigField } from './components/common'
import { withPrintData } from '../HOCs/withPrintData'
import { Pages } from '../common/Pages'
import { Page } from '../common/Page'

import bathroomsScheduleTable from './assets/bathrooms-schedule.png'
import { useReactToPrint } from 'react-to-print'
import { Button } from 'react-bootstrap'

const BathroomsScheduleView = ({ printData }) => {
  const { numberOfBathrooms } = printData

  const componentBathRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentBathRef.current,
  });

  const date = moment().format('DD.MM.YYYY')

  const pages = []
  for (let i = 1; i <= numberOfBathrooms; i++) {
    pages.push(
      <Page key={i} horizontal>
        <div style={{ width: '100%', maxWidth: '281mm' }}>
          <h2 style={{ marginTop: 0, marginBottom: 40 }}>Санузел №{i}</h2>
          <BigField name="ДАТА" value={date} />
          <Fields>
            <BigField name="Администратор" />
            <BigField name="Исполнитель" />
          </Fields>
          <img
            src={bathroomsScheduleTable}
            style={{ maxWidth: '100%' }}
            alt="Bath rooms schedule"
          />
        </div>
      </Page>
    )
  }
  const ComponentToPrint = forwardRef((props, ref) => (
    <div ref={ref}>
      <Pages>{pages}</Pages>
    </div>
  ))

  return (
    <>
      <Button
        block
        bsStyle="success"
        style={{ margin: '10px 0' }}
        onClick={handlePrint}>Печать
      </Button>
      <ComponentToPrint ref={componentBathRef} />
    </>
  )
}

export const BathroomsSchedule = withPrintData('BATHROOMS_SCHEDULE_DATA')(
  BathroomsScheduleView
)
