import * as types from './types'
import { loyaltyClientSelectors } from '../loyalty-client'
import { reservationSelectors } from '../reservation'
import { LoyaltyError } from '../api/loyaltyAPI/helpers/LoyaltyError'
import { loyaltyPurchaseSelectors } from '.'
import { LogsAPI } from '../logs-api'
import { buildCalculationQuery } from './helpers/build-calculation-query'
import { loyaltyAPI } from '../api/loyaltyAPI'

const calculateRequest = () => ({
  type: types.CALCULATE_REQUEST,
})

const calculateSuccess = ({
  calculationQuery,
  calculationResult,
  calculatedFor,
}) => dispatch => {
  const logData = JSON.stringify({
    calculationQuery,
    calculationResult,
    calculatedFor,
  })

  LogsAPI.custom(`Завершен расчет бонусов: ${logData}`)

  dispatch({
    type: types.CALCULATE_SUCCESS,
    payload: {
      calculationQuery,
      calculationResult,
      calculatedFor,
    },
  })
}

const calculateFailure = error => dispatch => {
  LogsAPI.custom(`Ошибка при расчете бонусов: ${error}`)

  dispatch({
    type: types.CALCULATE_FAILURE,
    payload: {
      error,
    },
  })
}

export const calculate = ({ usedBonuses = 0 }) => async (
  dispatch,
  getState
) => {
  dispatch(calculateRequest())

  const state = getState()
  const phoneNumber = loyaltyClientSelectors.phoneNumber(state)
  const totalAmount = reservationSelectors.purchaseTotal(state)

  const itemsWithoutRefundId = reservationSelectors.itemsWithoutRefundId(state)
  const itemsWithoutRefundIdTotal = reservationSelectors.itemsWithoutRefundIdTotal(
    state
  )

  if (itemsWithoutRefundIdTotal === 0) {
    const message = 'Нет покупок к которым можно применить бонусы'

    dispatch(calculateFailure(message))
    return
  }

  try {
    const { days, services } = itemsWithoutRefundId

    const calculationQuery = buildCalculationQuery({
      state,
      days,
      services,
      applyBonuses: usedBonuses,
    })

    const price = calculationQuery.rows.reduce((a,b) => a + (b.noApplyBonuses ? 0 : b.product.blackPrice), 0)

    if (usedBonuses === price) {
      throw new Error(
        `К данной покупке нельзя применить больше ${price - 1} бонусов.` +
        ' Нажмите «Использовать бонусы частично» чтобы вручную указать бонусы.'
      )
    }

    const calculateRes = await loyaltyAPI.calculatePurchase({
      calculationQuery,
    })

    if (calculateRes.errorCode) {
      if (calculateRes.errorCode === 6) {
        throw new Error(
          `К данной покупке нельзя применить больше ${calculateRes.maxBonuses} бонусов.` +
            ' Нажмите «Использовать бонусы частично» чтобы вручную указать бонусы.'
        )
      }

      throw new LoyaltyError(calculateRes.errorCode)
    }

    const { calculationResult } = calculateRes

    const itemsIds = reservationSelectors.itemsWithoutRefundIdIds(state)

    dispatch(
      calculateSuccess({
        calculationQuery,
        calculationResult,
        calculatedFor: {
          phoneNumber,
          totalAmount,
          usedBonuses,
          itemsIds,
        },
      })
    )
  } catch (err) {
    const message = err.message || 'Неизвестная ошибка'

    dispatch(calculateFailure(message))
  }
}

export const refresh = () => (dispatch, getState) => {
  const state = getState()
  const { usedBonuses } = loyaltyPurchaseSelectors.calculatedFor(state)

  dispatch(calculate({ usedBonuses }))
}

export const apply0 = () => dispatch => {
  return dispatch(calculate({ usedBonuses: 0 }))
}

export const applyAll = () => (dispatch, getState) => {
  const state = getState()
  const bonuses = loyaltyClientSelectors.bonuses(state)

  return dispatch(calculate({ usedBonuses: bonuses }))
}

export const applyCustom = usedBonuses => dispatch => {
  return dispatch(calculate({ usedBonuses }))
}

export const confirm = () => ({
  type: types.CONFIRM,
})

export const cancel = () => ({
  type: types.CANCEL,
})

export const reset = () => ({
  type: types.RESET,
})
