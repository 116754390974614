import * as types from './types'
import { ccAPI } from '../api/ccAPI'

export const loadForms = () => (dispatch) => {

  return ccAPI.getRegistrationForms().then(it => {
    const res = {}
    it.map(it => res[it.language] = it.file)
    dispatch({
      type: types.FORMS_LOADED,
      payload: { ...res },
    })
  })
}
