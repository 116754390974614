import React, {Component} from 'react'
import {Button, Pagination} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import LoadingModal from '../common/LoadingModal'
import WubookReservationsTable from './WubookReservationsTable'
import styled from 'styled-components'
import Select from 'react-select'
import {processPrepayeds} from '../../Modules/helpers/processPrepayeds'
import {setNewWubookLoaded} from '../../Modules/helpers/wasNewWubookLoadedRecently'
import {Notifications} from '../../Modules/Notifications'
import {ccAPI} from '../../Modules/api/ccAPI'
import {SingleDatePicker} from '../common/DatePicker'
import {page_size} from '../../Constants/others'

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 15px;
`

const byStart = 'start,-created_at'
const byCreate = '-created_at'
const fieldBySort = {
    [byStart]: 'start',
    [byCreate]: 'created_at'
}

const WubookWarning = () => (
    <p style={{margin: '0 0 15px', color: 'crimson', textAlign: 'center'}}>
        Нажмите на бронь для открытия карточки брони и дальнейшего заселения
    </p>
)

const WubookListBlock = props => (
    <div
        style={{
            backgroundColor: '#eee',
            width: '100%',
            maxWidth: 1200,
            margin: '50px auto',
            padding: 30,
            borderRadius: 5,
            position: 'relative',
        }}
    >
        {props.children}
    </div>
)

class WubookList extends Component {
    state = {
        reservationsRegistry: {},
        prepayedsRegistry: {},
        filterByDate: false,
        selectedDate: moment().format('YYYY-MM-DD'),
        canceled: true,
        ordering: byStart,
        count: 0,
        page: 1,
        pages: [],
        pageCount: 0,
        isLoading: false,
        reservationsList: []
    }

    async componentDidMount() {
        const {allNotesActions, selecteWRdate} = this.props
        this.startLoading()
        if (selecteWRdate) {
            this.setState({selectedDate: selecteWRdate, filterByDate: true})
        }

        allNotesActions.loadData()
        await this.loadData()

        this.endLoading()
    }

    loadData = async () => {
        const {canceled, ordering, page} = this.state
        const {wubookReservationsActions} = this.props
        wubookReservationsActions.load({page, canceled, ordering, page_size})
            .then(([reservations, prepayeds]) => {
                const {results = [], count = 0} = reservations || {}
                const prepayedsRegistry = processPrepayeds(prepayeds)
                const pageCount = Math.ceil(count / page_size)
                const pages = Array.from({length: pageCount}, (_, i) => i + 1)

                this.setState({
                    reservationsRegistry: Object.groupBy(results, ({ booking_number }) => booking_number),
                    prepayedsRegistry,
                    count,
                    pages,
                    pageCount,
                    reservationsList: results
                })
            })
    }

    loadNewData = async () => {
        const {lcode, isManualNewBookingsFetchEnabled} = this.props
        this.startLoading()

        try {
            if (isManualNewBookingsFetchEnabled) {
                await ccAPI.checkNewWubookCall(lcode)
            }

            setNewWubookLoaded()
            Notifications.success(
                'Синхронизация с сервером произведена, загружаем актуальные данные..'
            )

            await this.loadData()
        } catch (err) {
            Notifications.failure('Не удалось загрузить новые брони')
        }

        this.endLoading()
    }

    toggleFilterByDate = async () => {
        this.setState(prev => ({filterByDate: !prev.filterByDate}))
        if (this.state.filterByDate) {
            this.selectDate(null)
        } else {
            this.selectDate(moment().format('YYYY-MM-DD'))
        }
    }

    toggleOnlyConfirmed = async () => this.setState(prev => ({canceled: !prev.canceled || null}), this.loadData)

    selectDate = selectedDate => {
        this.props.wubookReservationsActions.setDate({date: selectedDate})
        this.setState({selectedDate}, this.loadData)
    }

    clearDate = () => this.setState({selectedDate: null}, this.loadData)

    startLoading = () => this.setState({isLoading: true})
    endLoading = () => this.setState({isLoading: false})

    changeFocus = datePickerFocused => this.setState({datePickerFocused})

    onSortFieldChange = ({value}) => this.setState({ordering: value}, this.loadData)

    setPage = page => this.setState({page}, this.loadData)

    render() {
        if (this.state.isLoading) {
            return <LoadingModal isOpen={true}/>
        }

        return (
            <WubookListBlock>
                <StyledLabel>
                    <input
                        type="checkbox"
                        value={this.state.filterByDate}
                        onChange={this.toggleFilterByDate}
                    />{' '}
                    поиск по дате
                </StyledLabel>

                {this.state.filterByDate && (
                    <SingleDatePicker
                        label="Дата заезда"
                        value={this.state.selectedDate}
                        onChange={this.selectDate}
                    />
                )}

                <Button
                    bsStyle="success"
                    style={{position: 'absolute', right: 30, top: 30}}
                    onClick={() => setTimeout(async () => {
                        await this.loadNewData()
                    }, 4000)}
                    disabled={this.state.isLoading}
                >
                    Загрузить новые брони
                </Button>
                <StyledLabel>
                    <input
                        type="checkbox"
                        value={this.state.canceled || false}
                        onChange={this.toggleOnlyConfirmed}
                    />{' '}
                    только подтвержденные
                </StyledLabel>
                <Select
                    options={[
                        {label: 'По дате заезда', value: byStart},
                        {label: 'По дате бронирования', value: byCreate},
                    ]}
                    defaultValue={{label: 'По дате заезда', value: byStart}}
                    onChange={this.onSortFieldChange}
                    styles={{
                        container: defaultStyles => ({
                            ...defaultStyles,
                            width: 240,
                            marginBottom: 20,
                        }),
                    }}
                />
                <WubookWarning/>
                <WubookReservationsTable
                    reservations={this.state.reservationsRegistry}
                    prepayeds={this.state.prepayedsRegistry}
                    allNotesByBN={this.props.allNotesByBN}
                    reservationsList={this.state.reservationsList}
                    sortingField={fieldBySort[this.state.ordering]}
                />
                <Pagination>
                    <Pagination.First onClick={() => this.setPage(1)}/>
                    <Pagination.Prev onClick={() => this.setPage(this.state.page - 1 || 1)}/>
                    {this.state.pages.map(it =>
                        <Pagination.Item
                            key={it}
                            active={this.state.page === it}
                            onClick={() => this.setPage(it)}
                        >{it}</Pagination.Item>
                    )}
                    <Pagination.Next onClick={() => {
                        if (this.state.page + 1 <= this.state.pageCount) this.setPage(this.state.page + 1)
                    }}/>
                    <Pagination.Last onClick={() => this.setPage(this.state.pageCount)}/>
                </Pagination>
                <ReactTooltip id="global-icon-tooltip" place="top"/>
            </WubookListBlock>
        )
    }
}

export default WubookList
