export const apiUrls = {
  cc: 'https://cc.winterfell-hotels.ru',
  g: 'https://g.winterfell-hotels.ru',
  ccWrapper: 'http://localhost:9000/cc_wrapper/',
}

export const apiCredentials = {
  username: 'admin',
  password: '2007200788a',
}

export const admin = {
  username: 'admin',
  password: 'winterfellhotelschecks2018',
}
