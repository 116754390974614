import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loyaltyPurchaseActions } from '../../../../../Modules/loyalty-purchase'
import { loyaltyClientSelectors } from '../../../../../Modules/loyalty-client'
import { FormView } from './FormView'
import { loyaltySelectors } from '../../../../../Modules/loyalty'

const mSTP = state => ({
  clientBonuses: loyaltyClientSelectors.bonuses(state),
  canApplyBonusesAutomatically: loyaltySelectors.canBeAppliedAutomatically(
    state
  ),
  loyaltyAvailableActions: loyaltySelectors.availableActions(state),
})

const mDTP = dispatch => ({
  loyaltyPurchaseActions: bindActionCreators(loyaltyPurchaseActions, dispatch),
})

export const Form = connect(mSTP, mDTP)(FormView)
