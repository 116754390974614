// Main functions
import getSellValues from './sellCheck/getValues'
import getRefundValues from './refundCheck/getValues'

const int = value => parseInt(value, 10)

const doNeedToPrint = async ({ reservation, settings, modes }) => {
  if (!settings.printerIsOn) return false

  // For refund mode
  if (modes.refund) {
    const refundValues = getRefundValues(reservation)

    const { refundedSumm } = refundValues

    // We don't need to print a check when there are no refunded items
    if (int(refundedSumm) === 0) return false
  } else {
    // For any modes except refund
    const sellValues = getSellValues(reservation, true) // With checkMode

    const { cashSumm, cardSumm } = sellValues

    // We don't need to print a check when his total is 0
    if (int(cashSumm) === 0 && int(cardSumm) === 0) return false
  }

  // In other cases
  return true
}

export default doNeedToPrint
