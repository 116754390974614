import { getBasicCheckData } from '../../lib/get-basic-check-data'
import { getItems } from './helpers/get-items'
import { getCheckData } from './helpers/get-check-data'
import { getTotals } from './helpers/get-totals'
import { getMeta } from './helpers/get-meta'
import { getPrintedItems } from './helpers/get-printed-items'

export const getPrintData = async ({ state, reservation, isPrinterOn }) => {
  const basicCheckData = getBasicCheckData(state)

  const items = getItems(reservation)

  const printedItems = getPrintedItems({
    items,
  })

  const {
    shouldPrint,
    wasCanceled,
    totals: checkTotals,
    printObject,
  } = await getCheckData({
    basicData: basicCheckData,
    printedItems,
    isPrinterOn,
  })

  const totals = getTotals(items)
  const meta = getMeta(items)

  return {
    checkType: 'refund',
    isPrinterOn,
    shouldPrint,
    checkTotals,
    printObject,
    wasCanceled,
    state,
    reservation,
    items,
    printedItems,
    totals,
    meta,
  }
}
