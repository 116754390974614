import React from 'react'
import { Content } from './Content'
import { canceledWarningsSelectors } from '../../reducers/canceled-warnings'
import { warningsKind } from '../../../../Constants/warning-types'
import { useSelector } from 'react-redux'

export const ReservationWarnings = () => {
  const items = useSelector(canceledWarningsSelectors.reservationWarnings)
  const handelCheck = (booking) => {
    let { warnings } = booking
    warnings = warnings.map(it => {
      if (it.kind === warningsKind.canceled_and_ready) {
        it.action_performed = true
      }
      if (it.kind === warningsKind.group_part_canceled) {
        it.action_performed = true
      }
      return it
    })
    booking.warnings = warnings
    return booking
  }

  return (
    <Content
      items={items}
      handelCheck={handelCheck}
      header={<h2>Отмененные брони, заселенные в шахматку</h2>}
      text={<p>Указанные ниже брони заселены в шахматку, но были отменены. Пожалуйста, проверьте данные брони, и если
        они
        были предоплачены <b>по безналу или картой</b>, свяжитесь с бухгалтерией. В противном случае возможна ситуация, что гость
        получит возврат после отмены и проживет бесплатно</p>}
    />
  )
}
