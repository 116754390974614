import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {RoomInfo, RoomsNumber, SecondaryTitle, Segment, Segments} from './components/common'
import CheckBoxComponent from "../../../common/Checkbox";
import {useSelector} from "react-redux";
import {sessionSelectors} from "../../../../Modules/session";
import {SingleDatePicker} from "../../../common/DatePicker";
import {Button} from "../../../buttons";
import {useActions} from "../../../common/hooks/use-actions";
import {freeRoomsActions, freeRoomsSelectors} from "../../reducers/free-rooms";
import moment from "moment";

const MainBlock = styled.div`
    padding: 30px;
`

const MainTitle = styled.h2`
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
`

export const FreeRooms = () => {
    const lcode = useSelector(sessionSelectors.lcode)
    const isLoading = useSelector(freeRoomsSelectors.isLoading)
    const isLoaded = useSelector(freeRoomsSelectors.isLoaded)
    const isFailed = useSelector(freeRoomsSelectors.isFailed)
    const defaultItems = useSelector(freeRoomsSelectors.defaultItems)
    const withCheckInsItems = useSelector(freeRoomsSelectors.withCheckInsItems)

    const {loadWithCheckins} = useActions(freeRoomsActions)

    const [includesCheckins, setIncludesCheckins] = useState(false)
    const [date, setDate] = useState()


    useEffect(() => {
        loadWithCheckins({lcode, date})
    }, [date])


    const update = () => {
        setDate(null)
        setIncludesCheckins(false)
        loadWithCheckins({lcode})
    }

    const title = date
        ? <SecondaryTitle>За {moment(date).format("DD.MM.YYYY")} по типу:</SecondaryTitle>
        : <SecondaryTitle>Cегодня по типу:</SecondaryTitle>

    return (
        <MainBlock>
            <MainTitle>Свободные комнаты</MainTitle>

            <Segments>
                <Segment>
                    {title}
                    {includesCheckins
                        ? <FreeRoomsByTypeContent
                            isLoading={isLoading}
                            isLoaded={isLoaded}
                            isFailed={isFailed}
                            calculatedData={withCheckInsItems}
                        />
                        : <FreeRoomsByTypeContent
                            isLoading={isLoading}
                            isLoaded={isLoaded}
                            isFailed={isFailed}
                            calculatedData={defaultItems}
                        />}
                </Segment>

                <Segment>
                    <SingleDatePicker label="За дату:"
                                      value={date} onChange={setDate}
                                      containerStyle={{
                                          display: 'flex',
                                          width: '100%',
                                          maxWidth: '100%',
                                          alignItems: 'center',
                                          marginBottom: 0,
                                          gap: '10px'
                                      }}
                    />
                    <CheckBoxComponent
                        title="Отображать с учетом ожидаемых заездов"
                        value={includesCheckins}
                        onChange={setIncludesCheckins}
                        style={{marginBottom: "10px"}}
                    />
                    <Button onClick={update}>Обновить</Button>

                </Segment>
            </Segments>
        </MainBlock>
    )
}

const FreeRoomsByTypeContent = ({
                                    isLoading,
                                    isLoaded,
                                    isFailed,
                                    calculatedData,
                                }) => {
    if (isLoading) return (<div>Загрузка..</div>)
    if (isFailed) return (<div>Ошибка загрузки данных</div>)
    if (!isLoaded) return (<div>Нет данных</div>)


    return Object.entries(calculatedData).map(([key, val]) =>
        <RoomInfo key={key}>
            {key}: <RoomsNumber>{val}</RoomsNumber>
        </RoomInfo>
    )
}
