import * as types from './types'
import { ccAPI } from '../../../../Modules/api/ccAPI'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items, count }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items, count },
})

const loadNewCountSuccess = ({ newNewsCount }) => ({
  type: types.LOAD_NEW_COUNT_SUCCESS,
  payload: { newNewsCount },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

const loadCategoriesSuccess = ({ categories }) => ({
  type: types.LOAD_CATEGORIES_SUCCESS,
  payload: { categories },
})

export const load = (params, str) => async dispatch => {
  dispatch(loadRequest())

  try {
    const { count, results: items } = await ccAPI.getNews(params, str)
    dispatch(loadSuccess({ items, count }))
  } catch {
    dispatch(loadFailure())
  }
}

export const loadNewCount = () => async dispatch => {
  try {
    const { count: newNewsCount } = await ccAPI.newNewsCount()
    dispatch(loadNewCountSuccess({ newNewsCount }))
  } catch {
  }
}

export const checkNews = (data) => async (dispatch, getState) => {
  try {
    await ccAPI.setRead(data)
    const { items: list, count } = getState().news
    const items = list.map(it => {
      if (it.id === data.id) {
        it.mark = true
      }
      return it
    })
    dispatch(loadSuccess({ items, count }))
  } catch {
  }
}

export const getNewNewsCount = () => async _ => {
  await ccAPI.newNewsCount()
}

export const getCategories = () => async (dispatch) => {
  await ccAPI.newsCategories().then(({ results: categories }) => {
      dispatch(loadCategoriesSuccess({ categories }))
    }
  )
}

export const readAll = (data) => async (dispatch, getState) => {
  await ccAPI.readAllNews(data).then(() => {
      const ids = data.categories.map(({ id }) => id)
      const { items: list, count } = getState().news
      const items = list.map(it => {
        const has = it.categories.find(el => ids.includes(el.id))
        if (has) {
          it.mark = true
        }
        return it
      })
      dispatch(loadSuccess({ items, count }))
    }
  )
}
