import * as types from './types'

const initialState = {
  prepayeds: [],
  isPrepayedsLoading: false,
  isPrepayedsLoaded: false,
  isPrepayedsFailed: false,

  reservationsData: [],
  isReservationsDataLoading: false,
  isReservationsDataLoaded: false,
  isReservationsDataFailed: false,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_PREPAYEDS_REQUEST:
      return {
        ...state,
        prepayeds: [],
        isPrepayedsLoading: true,
        isPrepayedsLoaded: false,
        isPrepayedsFailed: false,
      }

    case types.LOAD_PREPAYEDS_SUCCESS:
      return {
        ...state,
        prepayeds: payload.prepayeds,
        isPrepayedsLoading: false,
        isPrepayedsLoaded: true,
      }

    case types.LOAD_PREPAYEDS_FAILURE:
      return {
        ...state,
        isPrepayedsLoading: false,
        isPrepayedsFailed: true,
      }

    case types.LOAD_RESERVATIONS_DATA_REQUEST:
      return {
        ...state,
        reservationsData: [],
        isReservationsDataLoading: true,
        isReservationsDataLoaded: false,
        isReservationsDataFailed: false,
      }

    case types.LOAD_RESERVATIONS_DATA_SUCCESS:
      return {
        ...state,
        reservationsData: payload.reservationsData,
        isReservationsDataLoading: false,
        isReservationsDataLoaded: true,
      }

    case types.LOAD_RESERVATIONS_DATA_FAILURE:
      return {
        ...state,
        isReservationsDataLoading: false,
        isReservationsDataFailed: true,
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
