export const everything = state => state.freeRooms


export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const date = state => everything(state).date

export const defaultItems = state => everything(state).default
export const withCheckInsItems = state => everything(state).with_check_ins
