import React from 'react'
import Modal from 'react-modal'
import {
  ModalTitle,
  ModalHR,
  ModalText,
  ModalControls,
  ModalButton,
} from '../../../common/ModalParts'

export const PaymentMethodErrorsModal = ({ isOpen, close }) => {


  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>Ошибки</ModalTitle>
      <ModalHR />
      <ModalText>
        Оплатить наличными и картой в одном чеке нельзя. Сначала проведите оплату одним способом оплаты и затем вторым
      </ModalText>
      <ModalHR />
      <ModalControls>
        <ModalButton
          bsStyle="danger"
          style={{ marginLeft: 'auto' }}
          onClick={close}
        >
          Закрыть
        </ModalButton>
      </ModalControls>
    </Modal>
  )
}
