import React from 'react'
import Row from '../table-common/Row'
import Cell from '../table-common/Cell'
import { Link } from 'react-router-dom'

export const TableDataRow = ({ rows, clickAction }) => (
  <Row onClick={clickAction} isClickable={clickAction != null}>
    {rows.map(({ content, url, ...rest }, idx) => (
      <Cell key={idx} {...rest}>
        {url ? <Link to={url}>{content}</Link> : content}
      </Cell>
    ))}
  </Row>
)
