export const CHANGE_FIELD = 'ghotel/reservation/general/CHANGE_FIELD'

export const CREATE_DAY = 'ghotel/reservation/days/CREATE'
export const UPDATE_DAY = 'ghotel/reservation/days/UPDATE'
export const UPDATE_DAYS = 'ghotel/reservation/days/UPDATE_MULTIPLE'
export const DELETE_DAY = 'ghotel/reservation/days/DELETE'
export const REFUND_DAY = 'ghotel/reservation/days/REFUND'

export const CREATE_SERVICE = 'ghotel/reservation/services/CREATE'
export const UPDATE_SERVICE = 'ghotel/reservation/services/UPDATE'
export const COPY_SERVICE = 'ghotel/reservation/services/COPY'
export const DELETE_SERVICE = 'ghotel/reservation/services/DELETE'
export const REFUND_SERVICE = 'ghotel/reservation/services/REFUND'

export const UPDATE_BREAKFAST = 'ghotel/reservation/breakfasts/UPDATE'

export const VALIDATE_MANUAL = 'manual'
export const VALIDATE_GROUP = 'group'


export const CARDS_CLEARED_AFTER_RELOCATION =
  'ghotel/reservation/cards/CARDS_CLEARED_AFTER_RELOCATION'
