import React from 'react'
import { useSelector } from 'react-redux'
import { loyaltyPurchaseSelectors } from '../../Modules/loyalty-purchase'
import { loyaltySelectors } from '../../Modules/loyalty'
import { settingsSelectors } from '../../Modules/settings'

const Content = () => {
  const calculationResult = useSelector(
    loyaltyPurchaseSelectors.calculationResult
  )
  const nextBonusesCount = useSelector(loyaltySelectors.nextBonusesCount)

  return (
    <React.Fragment>
      <p>
        Потрачено бонусов: <b>{calculationResult.bonuses.applied}</b>
      </p>
      <p>
        Получено бонусов: <b>{calculationResult.bonuses.collected}</b>
      </p>
      <p>
        Текущее количество бонусов на счету: <b>{nextBonusesCount}</b>
      </p>
    </React.Fragment>
  )
}

export const ModalLoyaltyInfo = () => {
  const isLoyaltyEnabled = useSelector(settingsSelectors.isLoyaltyEnabled)
  const isLoyaltyUsed = useSelector(loyaltySelectors.isUsed)
  const isCalculated = useSelector(loyaltyPurchaseSelectors.isCalculated)

  const showLoyaltyInfo = isLoyaltyEnabled && isLoyaltyUsed && isCalculated

  if (!showLoyaltyInfo) {
    return null
  }

  return <Content />
}
