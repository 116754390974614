import { getBasicCheckData } from '../../lib/get-basic-check-data'
import { getCheckData } from './helpers/get-check-data'

export const getPrintData = async ({
  state,
  customData,
  isPrinterOn,
  useProcessing,
}) => {
  const { total, method } = customData
  const basicCheckData = getBasicCheckData(state)

  const {
    shouldPrint,
    wasCanceled,
    totals: checkTotals,
    printObject,
  } = await getCheckData({
    basicData: basicCheckData,
    method,
    total,
    isPrinterOn,
    useProcessing,
  })
  const totals = checkTotals
  const meta = { isCardUsed: method === 'card' }

  return {
    checkType: 'sell',
    isPrinterOn,
    shouldPrint,
    checkTotals,
    printObject,
    wasCanceled,
    state,
    totals,
    meta,
  }
}
