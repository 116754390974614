import { connect } from 'react-redux'
import { ValidationErrorsModal } from './ValidationErrorsModal'
import { reservationSelectors } from '../../../../Modules/reservation'
import { closeValidationErrorsModal } from '../../../../Modules/reservation/ReservationModule'

const mSTP = state => ({
  validationErrors: reservationSelectors.validationErrors(state),
  isOpen: state.reservation.isValidationErrorsModalOpen,
})

const mDTP = dispatch => ({
  close: () => dispatch(closeValidationErrorsModal()),
})

export const ConnectedValidationErrorsModal = connect(
  mSTP,
  mDTP
)(ValidationErrorsModal)
