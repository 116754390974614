import { useCallback, useRef } from 'react'

export function useActualCallback(callback) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  const memoizedCallback = useCallback(
    (...args) => callbackRef.current(...args),
    []
  )

  return memoizedCallback
}
