export const LOGIN_SUCCESS = 'ghotel/session/LOGIN_SUCCESS'
export const LOGIN_FAILED = 'ghotel/session/LOGIN_FAILED'
export const LOGOUT = 'ghotel/session/LOGOUT'
export const LOADING_STARTED = 'ghotel/session/LOADING_STARTED'
export const LOADING_ENDED = 'ghotel/session/LOADING_ENDED'
export const ERROR_SET = 'ghotel/session/ERROR_SET'
export const ERROR_CLEAR = 'ghotel/session/ERROR_CLEAR'

export const COOKIE_KEY = 'SESSION/ADMIN_DATA'
export const CC_TOKEN = 'CC_TOKEN'

export const LCODE = 'LCODE'
