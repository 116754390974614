import { createSelector } from 'reselect'

export const everything = state => state.currentAdminSells

export const items = state => everything(state).items
export const total = state => everything(state).total
export const totalRaw = state => everything(state).totalRaw

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const loadingState = createSelector(
  isLoading,
  isLoaded,
  isFailed,
  (isLoading, isLoaded, isFailed) => ({ isLoading, isLoaded, isFailed })
)
