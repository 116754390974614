import { createSelector } from 'reselect'

export const everything = state => state.loyaltyPurchase

export const data = state => everything(state).data

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed
export const error = state => everything(state).error

export const loadingState = createSelector(
  isLoading,
  isLoaded,
  isFailed,
  error,
  (isLoading, isLoaded, isFailed, error) => ({
    isLoading,
    isLoaded,
    isFailed,
    error,
  })
)

export const isCalculated = state => isLoaded(state)

export const calculationQuery = state => everything(state).calculationQuery
export const calculationResult = state => everything(state).calculationResult

export const calculatedRows = state => calculationResult(state).rows

export const calculatedRowsMap = createSelector(
  isCalculated,
  calculatedRows,
  (isCalculated, calculatedRows) => {
    if (!isCalculated) return {}

    return calculatedRows.reduce((acc, row) => {
      acc[row.id] = row
      return acc
    }, {})
  }
)

export const calculatedFor = state => everything(state).calculatedFor

export const usedBonuses = state => calculatedFor(state).usedBonuses
export const itemsIds = state => calculatedFor(state).itemsIds

export const isConfirmed = state => everything(state).isConfirmed
