import { getPrintedItemPrice } from '../item-selectors'
import { dayTypes } from '../types'
import { breakfastServiceName } from '../servicesNames'
import paymentTypes from "../paymentTypes";

export const buildCalculationRows = ({
  days,
  services,
  serviceLoyaltyAllowabilityMap,
  isReady
}) => {
  const rows = []

  for (let day of days) {
    const sku = 'Проживание'
    const title = `Проживание за ${day.date}`
    const blackPrice = getPrintedItemPrice(day)
    const isPaid = day.type === dayTypes.payed

    rows.push({
      id: day.id,
      product: {
        sku,
        title,
        blackPrice,
      },
      qty: 1,
      noApplyBonuses: day.payment_type === paymentTypes.bank && isPaid ? isReady :  isPaid,
    })
  }

  for (let service of services) {
    const isAllowed = serviceLoyaltyAllowabilityMap[service.service]
    if (!isAllowed) continue

    const sku = service.service
    const title = `${service.service} за ${service.date}`
    const blackPrice = getPrintedItemPrice(service)
    const isPaid = Boolean(service.payment_date)

    rows.push({
      id: service.id,
      product: {
        sku,
        title,
        blackPrice,
      },
      qty: service.quantity,
      noApplyBonuses: isPaid,
      noCollectBonuses: sku === breakfastServiceName
    })
  }

  return rows
}
