import { sendBugReport } from '../../bug-report/send'
import { LogsAPI } from '../../logs-api'
import { createBreakfastAction } from '../../api/ccAPI/requests'

const actionItemNormalizers = {
  sell: service => ({
    uuid: service.uuid,
    item_name: service.service,
    qty: service.quantity,
    delivery_date: service.breakfast_delivery_date,
    payment_date: service.payment_date,
    breakfast_pk: service.breakfast_pk,
  }),
  refund: service => ({
    uuid: service.uuid,
    item_name: service.name,
    qty: service.quantity,
    delivery_date: service.breakfast_delivery_date,
    payment_date: service.payment_date,
    breakfast_pk: service.breakfast_pk,
  }),
}

export const saveBreakfastActions = async ({
  checkType,
  items,
  reduxStates,
}) => {
  try {
    LogsAPI.event('Начато сохранение /breakfast_action')

    const { services } = items

    const actionServices = services.filter(
      ({ service_type }) => service_type === 'breakfast'
    )

    if (actionServices.length === 0) {
      LogsAPI.event(
        'Пропущено сохранение /breakfast_service - не найдено подходящих услуг'
      )
      return { success: true }
    }

    const { reservation, session } = reduxStates.current
    const { lcode } = session

    if (!reservation.pk) {
      throw new Error('отсутствует pk брони')
    }

    for (const service of actionServices) {
      const normalizedService = actionItemNormalizers[checkType](service)

      const requestPayload = {
        action: checkType,
        booking_number_uuid: reservation.pk,
        service_uuid: normalizedService.uuid,
        lcode,
        qty: normalizedService.qty,
        breakfast: normalizedService.breakfast_pk,
        order_date: normalizedService.payment_date,
        delivery_date: normalizedService.delivery_date,
        source: 'reception',
      }

      await createBreakfastAction(requestPayload)
    }

    return { success: true }
  } catch (error) {
    const errorMessage = error?.message ?? 'Неизвестная ошибка'
    const message = `Не удалось сохранить /breakfast_action: ${errorMessage}`

    LogsAPI.error(message)

    sendBugReport({
      message: message + '(сообщение сгенерировано автоматически)',
    })

    return { success: false, error: errorMessage }
  }
}
