import React from 'react'
import styled from 'styled-components'

export const ModalTitle = styled.h2`
  margin: 0 0 24px;
  font-size: 18px;
  color: #4d5c6e;
`

const ModalCloseBlock = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`

const RotatedPlusBlock = styled.div`
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  line-height: 50px;
  text-align: center;
  font-size: 40px;
  color: #888;
`

export const ModalClose = ({ onClick }) => (
  <ModalCloseBlock onClick={onClick}>
    <RotatedPlusBlock>+</RotatedPlusBlock>
  </ModalCloseBlock>
)

export const ModalBody = styled.div`
  padding: 25px;
  color: #4d5c6e;
`
