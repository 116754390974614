function min(threshold, message) {
  return {
    fn: value => {
      return value.length >= threshold
    },
    message,
  }
}

function max(threshold, message) {
  return {
    fn: value => {
      return value.length <= threshold
    },
    message,
  }
}

function exact(length, message) {
  return {
    fn: value => {
      return value.length === length
    },
    message,
  }
}

export const length = {
  exact,
  min,
  max,
}
