import * as types from './types'

const initialState = []

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.OPEN:
      return [...state, payload.name]

    case types.CLOSE:
      return state.filter(name => name !== payload.name)

    case types.RESET:
      return initialState

    default:
      return state
  }
}
