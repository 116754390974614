import {createSelector} from "reselect";

export const everything = state => state.breakfastsRestrictions

export const items = state => everything(state).items

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const breakfastRestrictionsObj = createSelector(
    items,
    (items) => items.reduce((a,b) => {
        a.push(b.date)
        return a
    }, [])
)
