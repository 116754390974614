export function getStyle({ width, zIndex = 160 }) {
  return {
    content: {
      width: '100%',
      maxWidth: width,
    },
    overlay: {
      zIndex,
    },
  }
}
