import React from 'react'
import HeadRow from './components/HeadRow'
import Head from '../table-common/Head'
import Cell from '../table-common/Cell'

export const TableHeadRow = ({ rows }) => (
  <Head>
    <HeadRow>
      {rows.map(({ content, ...rest }, idx) => (
        <Cell key={idx} {...rest}>
          {content}
        </Cell>
      ))}
    </HeadRow>
  </Head>
)
