import * as types from './types'
import * as constants from './constants'
import ls from 'local-storage'
import { Notifications } from '../../../../Modules/Notifications'
import {
  getAllGroupReservationsCall,
  getGridReservations,
} from "../../../../Modules/api/ccAPI/requests"
import moment from 'moment'

const loadDataRequest = () => ({
  type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = ({ reservations }) => ({
  type: types.LOAD_DATA_SUCCESS,
  payload: {
    reservations,
  },
})

const loadDataFailure = ({ error }) => ({
  type: types.LOAD_DATA_FAILURE,
  payload: {
    error,
  },
})

const loadGroupReservationsSuccess = ({ items }) => ({
  type: types.LOAD_GROUP_RESERVATIONS_SUCCESS,
  payload: {
    items,
  },
})

const loadGroupReservations = () => async (dispatch, getState) => {
  try {
    const { session } = getState()
    const items = await getAllGroupReservationsCall(session.lcode)

    dispatch(loadGroupReservationsSuccess({ items }))
  } catch (err) {
    const message =
      'Не удалось загрузить список групповых броней, ' +
      'соответствующие иконки не будут показаны в сетке'

    Notifications.failure(message)
  }
}

export const loadData = () => async (dispatch, getState) => {
  dispatch(loadDataRequest())
  const { settings } = getState()
  const { daysInFuture, daysInPast, gridDate = null } = settings

  const currentDate = gridDate || new Date()
  const start = moment(currentDate).add(-daysInPast, 'days').format('YYYY-MM-DD')
  const end = moment(currentDate).add(daysInFuture, 'days').format('YYYY-MM-DD')

  try {
    const reservations = await getGridReservations({start, end})

    dispatch(loadDataSuccess({ reservations }))

    dispatch(loadGroupReservations())
  } catch (err) {
    dispatch(
      loadDataFailure({ error: 'Не удалось загрузить основные данные сетки' })
    )
  }
}

export const updateReservation = ({ reservation }) => ({
  type: types.UPDATE_RESERVATION,
  payload: { reservation },
})

export const setSize = size => dispatch => {
  dispatch({
    type: types.SET_SIZE,
    payload: size,
  })

  ls.set(constants.SIZE_LS_KEY, size)
}

export const reset = () => ({
  type: types.RESET,
})
