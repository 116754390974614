import * as types from './types'

const initialState = {
  isActive: false,
  untilDate: null,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.START:
      return {
        ...state,
        isActive: true,
        untilDate: payload.untilDate,
      }

    case types.STOP:
      return {
        ...state,
        isActive: false,
        untilDate: null,
      }

    default:
      return state
  }
}
