import React from 'react'
import TransactionsList from '../../TransactionsList'
import TransactionsAPI from '../../../../Modules/TransactionsAPI'
import { CompleteModal } from './CompleteModal'

export default class extends React.Component {
  state = {
    isCompleteModalOpened: false,
  }

  openCompleteModal = () => this.setState({ isCompleteModalOpened: true })
  closeCompleteModal = () => this.setState({ isCompleteModalOpened: false })

  render() {
    const { isCompleteModalOpened } = this.state

    return (
      <React.Fragment>
        <TransactionsList
          actionHint="Нажмите на необходимую транзакцию для её отмены"
          getTransactionsFunc={TransactionsAPI.getWithoutAuth}
          transactionClickAction={async (...params) => {
            const isSuccess = await TransactionsAPI.return(...params)

            if (!isSuccess) {
              return false
            }

            this.openCompleteModal()

            return true
          }}
        />
        <CompleteModal
          isOpen={isCompleteModalOpened}
          close={this.closeCompleteModal}
        />
      </React.Fragment>
    )
  }
}
