import { createDefer } from '../helpers/createDefer'

const initialDefer = createDefer()
initialDefer.resolve()

const state = {
  defer: initialDefer,
}

export function startLoading() {
  state.defer = createDefer()
}

export function stopLoading() {
  state.defer.resolve()
}

export async function waitForLoading() {
  await state.defer.promise
}
