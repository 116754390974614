import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ccAPI } from '../../Modules/api/ccAPI'
import { specialServiceTypesSelectors } from '../../Modules/special-service-types'
import { enterDateRange } from './EnterDateRangeModal'
import { SingleDatePicker } from '../common/DatePicker'
import { downloadFile } from '../../Modules/helpers/downloadFile'
import { sessionSelectors } from '../../Modules/session'

const PageBlock = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
  position: relative;
`

const Message = styled.p`
  text-align: center;
  color: ${props => props.color ?? '#333'};
  font-size: ${props => props.size ?? '16px'};
`

const DownloadButton = styled.button`
  padding: 0;
  border: none;
  border-bottom: 1px dashed transparent;
  color: #2b62ff;
  background-color: transparent;
  outline: none;
`

async function getInventoryState({ date, lcode, specialServices }) {
  const result = []

  for (const service of specialServices) {
    const serviceState = await ccAPI.getInventoryState({
      lcode,
      position_pk: service.pk,
      date,
    })

    result.push({
      pk: service.pk,
      name: service.name,
      state: serviceState,
    })
  }

  return result
}

const Content = ({ date, inventoryState, loading }) => {
  const [downloadingPks, setDownloadingPks] = useState([])

  const lcode = useSelector(sessionSelectors.lcode)

  if (loading) return <Message size="14px">Загрузка..</Message>
  if (!inventoryState) return <Message size="14px">Нет данных</Message>

  return (
    <table className="inventory-table" style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>Услуга</th>
          <th>Приход</th>
          <th>Продажи</th>
          <th>Списано</th>
          <th>Итог</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        {inventoryState.map(({ pk, name, state }) => {
          const download = async () => {
            const range = await enterDateRange({ date })
            if (!range) return

            const { start, end } = range

            setDownloadingPks(current => current.concat(pk))

            const blob = await ccAPI.getInventoryMovementExcel({
              position_pk: pk,
              lcode,
              start,
              end,
            })

            downloadFile(
              blob,
              `Отчет_по_движению_для_${name}_от_${start}_до_${end}.xlsx`
            )

            setDownloadingPks(current => current.filter(item => item !== pk))
          }

          return (
            <tr key={name}>
              <td>{name}</td>
              <td>{state.income}</td>
              <td>{state.sell}</td>
              <td>{state.loss}</td>
              <td>{state.total}</td>
              <td>
                {downloadingPks.includes(pk) ? (
                  'Загрузка..'
                ) : (
                  <DownloadButton onClick={download}>
                    Скачать отчет по движению
                  </DownloadButton>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export const Inventory = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [inventoryState, setInventoryState] = useState(null)
  const [loading, setLoading] = useState(false)

  const lcode = useSelector(sessionSelectors.lcode)
  const specialServices = useSelector(specialServiceTypesSelectors.items)

  useEffect(() => {
    if (!lcode) return
    if (specialServices.length === 0) return

    setLoading(true)

    getInventoryState({ date, lcode, specialServices }).then(inventoryState => {
      setInventoryState(inventoryState)
      setLoading(false)
    })
  }, [date, lcode, specialServices])

  return (
    <PageBlock>
      <div style={{ width: 240, margin: '0 auto 24px' }}>
        <SingleDatePicker
          label="Дата"
          value={date}
          onChange={setDate}
          maxDate={new Date(moment().format('YYYY-MM-DD'))}
        />
      </div>
      <Content date={date} inventoryState={inventoryState} loading={loading} />
    </PageBlock>
  )
}
