import store from '../../../store'
import { callCallback } from './call-callback'
import { createScenario } from './create-scenario'

export function createCallbacksHelpers({ onFinish }) {
  const initialState = store.getState()
  const { scenarioState, setScenarioState } = createScenario()

  const reduxStates = {
    initial: initialState,
    current: initialState,
  }

  const callbacksData = {
    reduxStates,
    scenarioState,
    setScenarioState,
  }

  const addCallbacksData = data => {
    for (const key in data) {
      callbacksData[key] = data[key]
    }
  }

  const call = callback => {
    return callCallback(callback, callbacksData)
  }

  const finish = async additionalCallbacks => {
    const calls = additionalCallbacks.map(call)
    await Promise.all(calls)
    await call(onFinish)
  }

  return {
    addCallbacksData,
    call,
    finish,
  }
}
