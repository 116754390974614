import { VALIDATE_MANUAL, VALIDATE_GROUP } from './types'
import { checkNotPrintCall } from '../api/ccAPI/requests'

export const doNeedToPrintAdvanced = async (reservation, modes, bnVariants) => {
  const daysAndServices = { days: true, services: true }
  const onlyServices = { days: false, services: true }

  if (modes.refund) return daysAndServices

  const { has_refund, booking_number } = reservation

  if (!has_refund) return daysAndServices

  if (bnVariants.includes(booking_number)) return daysAndServices

  const notPrintGroupRes = await checkNotPrintCall(booking_number, VALIDATE_GROUP)
  const { result: notPrintGroup } = notPrintGroupRes

  if (notPrintGroup) {
    if (!modes.wubook) return daysAndServices
    return onlyServices
  }

  if (modes.wubook) return daysAndServices

  if (modes.create) {
    const notPrintManualRes = await checkNotPrintCall(booking_number, VALIDATE_MANUAL)
    const { result: notPrintManual } = notPrintManualRes

    if (notPrintManual) return onlyServices
  }

  return daysAndServices
}
