import styled from 'styled-components'
import ColoredText from '../../../ui/ColoredText'

export default styled(ColoredText)`
  display: block;
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
  font-size: 13px;
`
