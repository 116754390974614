import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TodayNoShow from './TodayNoShow'
import {
  todayNoShowActions,
  todayNoShowSelectors,
} from '../../reducers/today-no-show'

const mapStateToProps = state => ({
  todayNoShow: todayNoShowSelectors.everything(state),
})

const mapDispatchToProps = dispatch => ({
  todayNoShowActions: bindActionCreators(todayNoShowActions, dispatch),
})

export const ConnectedTodayNoShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodayNoShow)
