import axios from "axios";

const base = axios.create({
  baseURL: "http://127.0.0.1:8099"
});

const baseRequest = ({ url, method, params, data, headers, ...rest }) =>
  base({
    url,
    method,
    params,
    data,
    headers,
    ...rest
  }).then(res => res.data)

export const request = options =>
  baseRequest(options).then(response => {
    if (options?.data?.method === "checkout")
      return {
        ...response,
        method: options?.data?.method,
        status: "ok",
        error: ""
      };

    if (response?.status === "error") {
      throw new Error(`${response.error} (${options?.data?.method}: ${options?.data?.room})`);
    }
    return { ...response, method: options?.data?.method };
  })
