import styled from 'styled-components'

export default styled.div`
  margin-top: 12px;
  padding: 8px 12px;
  font-size: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.signFormsErrorText};
  background-color: ${({ theme }) => theme.colors.signFormsErrorBackground};

  &:first-of-type {
    margin-top: 20px;
  }
`
