import React from 'react'
import { Button } from '../../buttons'

export default ({
                  text,
                  pageUrl,
                  dataKey,
                  data,
                  disabled,
                  event = () => true
                }) => {

  const onClick = () => {
    const res = event()
    if (res) {
      localStorage.setItem(dataKey, JSON.stringify(data))
      window.open(pageUrl, '_blank')
    }
  }

  return (
    <Button size="sm" onClick={onClick} disabled={disabled}>
      {text}
    </Button>
  )
}
