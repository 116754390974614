import React from 'react'
import {
  FaUsers,
  FaMoneyBillWave,
  FaStickyNote,
  FaDollarSign, FaRegPaperPlane,
} from 'react-icons/fa'

const iconStyle = { marginLeft: 7, marginBottom: -3, fontSize: 16 }

export const GroupIcon = () => <FaUsers style={iconStyle} />

export const GroupIconGrid = () => (
  <FaUsers
    style={{
      width: 20,
      height: 'auto',
      marginTop: 3,
    }}
  />
)

export const PrepayedIcon = () => <FaMoneyBillWave style={iconStyle} />

export const NoteIcon = () => <FaStickyNote style={iconStyle} />

export const PayedInAdvanceIcon = () => <FaDollarSign style={iconStyle} />

export const TelegramIcon = () => <FaRegPaperPlane  />
