import React, {useEffect, useMemo, useState} from 'react'
import {paymentTypesList, paymentTypesNoBank} from '../../../../../Modules/helpers/paymentTypes'
import {Cell, ReadOnlyCell} from '../../../common/Cell'
import {Input, InputNumber, Select} from '../../../../form-stuff'
import {Button, ButtonGroup} from '../../../../buttons'
import {day} from '../../../../../Modules/formatters/date'
import {useActions} from '../../../../common/hooks/use-actions'
import * as servicesActions from '../../../../../Modules/reservation/actions/services'
import {useSelector} from 'react-redux'
import {callPrompt} from '../../../../dynamic-modal'
import {reservationSelectors} from '../../../../../Modules/reservation'
import {defaultDateOption} from '../../../constants/default-values'
import {ItemRegularPrice} from '../../../../common/ItemPrice'
import {specialServiceTypesSelectors} from '../../../../../Modules/special-service-types'
import {CarNumberSelector} from "../CarNumberSelector";

function enterQuantity({currentQuantity}) {
    return callPrompt({
        title: 'Количество',
        text: 'Введите возвращенное количество',
        initialValue: currentQuantity,
        validators: [
            {
                fn: Boolean,
                message: 'Укажите значение',
            },
            {
                fn: value => !Number.isNaN(Number(value)),
                message: 'Значение должно быть числом',
            },
            {
                fn: value => Number(value) > 0,
                message: 'Значение должно быть больше 0',
            },
            {
                fn: value => Number(value) <= currentQuantity,
                message:
                    'Значение должно быть меньше или равно оставшемуся количеству услуги',
            },
        ],
        normalizer: Number,
    })
}

export const Item = ({data, validationErrors, availableActions, carLimit}) => {
    const {
        updateServiceDate,
        updateServicePrice,
        updateServiceQuantity,
        updateServicePaymentType,
        copyService,
        deleteService,
        refundService,
        updateCarNumber
    } = useActions(servicesActions)

    const {id, parking_car_numbers} = data

    const setDate = value => updateServiceDate({id, value})
    const setPrice = value => updateServicePrice({id, value})
    const setQuantity = value => updateServiceQuantity({id, value})
    const setPaymentType = value => updateServicePaymentType({id, value})
    const copy = () => copyService({id})
    const remove = () => deleteService({id})

    const parkingService = useSelector(specialServiceTypesSelectors.parkingService)

    const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)
    const [carNumbers, setCarNumbers] = useState(parking_car_numbers?.map(it => ({label: it, value: it}) || []))

    useEffect(() => {
        updateCarNumber({id, value: carNumbers?.map(({value}) => value)})
    }, [carNumbers])

    useEffect(() => {
        const list = parkingService.is_available_for_sale_by_bank
            ? paymentTypesList
            : paymentTypesNoBank
        setPaymentTypeList(list)
    }, [parkingService])

    const refund = async () => {
        const refundedQuantity = await enterQuantity({
            currentQuantity: data.quantity,
        })

        refundService({id, quantity: refundedQuantity})
    }

    const availableDates = useSelector(reservationSelectors.daysDates)

    const dateSelectOptions = useMemo(() => {
        return availableDates.map(date => ({
            label: day(date),
            value: date,
        }))
    }, [availableDates])

    const isPayed = Boolean(data.payment_date)

    const copyCondition = availableActions.copy && dateSelectOptions.length > 0
    const updateCondition = availableActions.update && !isPayed
    const deleteCondition = availableActions.delete && !isPayed
    const refundCondition = availableActions.refund && isPayed

    const dataCells = !updateCondition ? (
        <>
            <ReadOnlyCell>{day(data.date)}</ReadOnlyCell>
            <ReadOnlyCell>
                <ItemRegularPrice item={data}/>
            </ReadOnlyCell>
            <ReadOnlyCell>{data.quantity}</ReadOnlyCell>
            <ReadOnlyCell>{data.parking_car_numbers?.join(', ')}</ReadOnlyCell>
            <ReadOnlyCell>{data.payment_type}</ReadOnlyCell>
        </>
    ) : (
        <>
            <Cell>
                <Select
                    defaultOption={defaultDateOption}
                    value={data.date}
                    options={dateSelectOptions}
                    onChange={setDate}
                    hasError={validationErrors['date']}
                    noMargin
                />
            </Cell>
            <Cell>
                <Input
                    type="number"
                    placeholder="Введите стоимость"
                    value={data.price}
                    onChange={setPrice}
                    hasError={validationErrors['price']}
                />
            </Cell>
            <Cell>
                <InputNumber
                    value={data.quantity}
                    onChange={setQuantity}
                    hasError={validationErrors['quantity']}
                    noMargin
                />
            </Cell>
            <Cell>
                <CarNumberSelector
                    carNumberValue={carNumbers}
                    setCarNumberValue={setCarNumbers}
                    carLimit={carLimit}
                    hasError={validationErrors['parking_car_numbers']}/>
            </Cell>
            <Cell>
                <Select
                    value={data.payment_type}
                    options={paymentTypeList}
                    onChange={setPaymentType}
                    hasError={validationErrors['payment_type']}
                    noMargin
                />
            </Cell>
        </>
    )

    return (
        <tr>
            {dataCells}
            <Cell>
                <ButtonGroup align="right">
                    {copyCondition && (
                        <Button type="success" onClick={copy}>
                            Копировать
                        </Button>
                    )}
                    {deleteCondition && (
                        <Button type="danger" onClick={remove}>
                            Удалить
                        </Button>
                    )}
                    {refundCondition && (
                        <Button type="danger" onClick={refund}>
                            Возврат
                        </Button>
                    )}
                </ButtonGroup>
            </Cell>
        </tr>
    )
}
