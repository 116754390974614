import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { breakfastsSelectors } from '../../../../../Modules/breakfasts'

export const BreakfastSelect = ({ breakfastPk, onChange, hasError }) => {
  const [realValue, setRealValue] = useState(null)

  useEffect(() => {
    if (!realValue) onChange(null)
    else onChange(realValue.value)
  }, [realValue, onChange])

  useEffect(() => {
    if (breakfastPk) return
    setRealValue(null)
  }, [breakfastPk])

  const breakfasts = useSelector(breakfastsSelectors.items)

  return (
    <FormGroup validationState={hasError ? 'error' : null}>
      <ControlLabel>Завтрак</ControlLabel>
      <ReactSelect
        placeholder="Выберите завтрак"
        value={realValue}
        onChange={setRealValue}
        options={breakfasts.map(breakfast => ({
          label: breakfast.name,
          value: breakfast.pk,
        }))}
        menuPlacement="top"
        styles={{
          control: base => {
            const newStyles = Object.assign({}, base)

            newStyles.height = 30
            newStyles.minHeight = 30
            newStyles.fontSize = 12

            if (hasError) {
              newStyles.borderColor = '#a94442 !important'
              newStyles.boxShadow = 'none !important'
            }

            return newStyles
          },
          dropdownIndicator: base => ({
            ...base,
            padding: 4,
          }),
          clearIndicator: base => ({
            ...base,
            padding: 4,
          }),
          valueContainer: base => ({
            ...base,
            padding: '0px 6px',
          }),
          input: base => ({
            ...base,
            margin: 0,
            padding: 0,
          }),
          option: base => ({
            ...base,
            fontSize: 12,
            paddingTop: 6,
            paddingBottom: 6,
          }),
        }}
      />
    </FormGroup>
  )
}
