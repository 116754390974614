import store from '../../../../store'
import {loyaltyPurchaseActions, loyaltyPurchaseSelectors,} from '../../../loyalty-purchase'
import {callModal, modalTemplates} from '../../../../Components/dynamic-modal'
import {loyaltySelectors} from '../../../loyalty'
import {reservationSelectors} from '../..'

export async function finishPurchaseBonusesCalculation({state}) {
    const isLoyaltyUsed = loyaltySelectors.isUsed(state)

    if (!isLoyaltyUsed) {
        return {isCanceled: false}
    }

    const areTherePurchasedItemsWithAllowableLoyalty = reservationSelectors.areTherePurchasedItemsWithAllowableLoyalty(
        state
    )

    if (!areTherePurchasedItemsWithAllowableLoyalty) {
        return {isCanceled: false}
    }

    const usedBonuses = loyaltyPurchaseSelectors.usedBonuses(state) || 0
    const canApplyAutomatically = loyaltySelectors.canBeAppliedAutomatically(
        state
    )

    const isConfirmed = loyaltyPurchaseSelectors.isConfirmed(state)

    if (isConfirmed) {
        return {isCanceled: false}
    }

    if ((!usedBonuses || usedBonuses === 0) && canApplyAutomatically) {
        await store.dispatch(loyaltyPurchaseActions.apply0())
        store.dispatch(loyaltyPurchaseActions.confirm())
        return {isCanceled: false}
    }

    if (usedBonuses > 0 && canApplyAutomatically) {
        const modalActions = {
            onlyGain: 'only-gain',
            apply: 'apply',
        }

        const {action} = await callModal(
            modalTemplates.confirm({
                title: 'Расчет бонусов',
                text: [
                    `Вы произвели расчет для ${usedBonuses} бонусов, но не нажали «Применить».`,
                    'Выберите дальнейшее действие.',
                ],
                buttons: [
                    {
                        text: 'Отменить списание и провести только начисление',
                        color: 'warning',
                        action: modalActions.onlyGain,
                    },
                    {
                        text: `Применить ${usedBonuses} бонусов`,
                        color: 'warning',
                        action: modalActions.apply,
                    },
                ],
                disableCloseOnClickOutside: true,
            })
        )

        if (action === modalActions.apply) {
            store.dispatch(loyaltyPurchaseActions.confirm())
            return {isCanceled: false}
        }

        if (action === modalActions.onlyGain) {
            await store.dispatch(loyaltyPurchaseActions.apply0())
            store.dispatch(loyaltyPurchaseActions.confirm())
            return {isCanceled: false}
        }
    }
    return {isCanceled: false}
}
