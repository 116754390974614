import { getClientCash } from '../../../get-client-cash'
import { CANCEL_SYMBOL } from '../../../lib/constants'
import { createCheckLine } from '../../../lib/create-check-line'
import { mergeAndConcat } from 'merge-anything'

export const getCheckData = async ({
  basicData,
  method,
  total,
  isPrinterOn,
  useProcessing,
}) => {
  const totals = {
    cash: 0,
    card: 0,
  }

  totals[method] = Number(total)

  const lines = [
    createCheckLine({
      name: 'Услуги проживания',
      price: totals[method],
      quantity: 1,
      total: totals[method],
    }),
  ]

  const basicCheckData = {
    shouldPrint: totals.cash + totals.card > 0,
    totals,
  }

  const clientCash = isPrinterOn
    ? await getClientCash({
        requiredCash: totals.cash,
      })
    : totals.cash

  if (clientCash === CANCEL_SYMBOL)
    return {
      ...basicCheckData,
      wasCanceled: true,
      printObject: null,
    }

  return {
    ...basicCheckData,
    wasCanceled: false,
    printObject: mergeAndConcat(
      {
        TypeCheck: 1,
        PayByProcessing: useProcessing && totals.card > 0,
        CheckStrings: lines,
        Cash: Number(clientCash).toFixed(2),
        ElectronicPayment: Number(totals.card).toFixed(2),
      },
      basicData
    ),
  }
}
