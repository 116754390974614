const isDay = item => item.type === 'day'
const isService = item => item.type === 'service'

export const getItems = reservation => {
  const refundedItems = reservation.refundedItems

  const refundedDays = refundedItems.filter(isDay)
  const refundedServices = refundedItems.filter(isService)

  return {
    days: refundedDays,
    services: refundedServices,
  }
}
