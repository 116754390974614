import { getClientCash } from '../../../get-client-cash'
import { CANCEL_SYMBOL } from '../../../lib/constants'
import { currency } from '../../../../helpers/converters'
import { getItemsCheckData } from '../../../lib/get-items-check-data'
import { mergeAndConcat } from 'merge-anything'

export const getCheckData = async ({
  basicData,
  printedItems,
  isPrinterOn,
}) => {
  const { lines, totals, printTotals } = getItemsCheckData(printedItems)

  const basicCheckData = {
    shouldPrint: totals.cash + totals.card > 0,
    totals,
  }

  const clientCash = isPrinterOn
    ? await getClientCash({
        requiredCash: totals.cash,
      })
    : totals.cash

  if (clientCash === CANCEL_SYMBOL)
    return {
      ...basicCheckData,
      wasCanceled: true,
      printObject: null,
    }

  return {
    ...basicCheckData,
    wasCanceled: false,
    printObject: mergeAndConcat(
      {
        TypeCheck: 0,
        PayByProcessing: totals.card > 0,
        CheckStrings: lines,
        Cash: currency.toString(clientCash),
        ElectronicPayment: printTotals.card,
      },
      basicData
    ),
  }
}
