import history from '../helpers/history'
import { CriticalOperations } from './critical-operations'

const TARGET_PATHNAMES = ['/reservation', '/new-reservation']

export function startBlockingAnyAppActionsWhileOnReservationPage() {
  let isActive = false
  let finishOperation = () => {}

  history.listen(location => {
    const { pathname } = location

    if (!isActive && TARGET_PATHNAMES.includes(pathname)) {
      isActive = true
      finishOperation = CriticalOperations.start()
    }

    if (isActive && !TARGET_PATHNAMES.includes(pathname)) {
      isActive = false
      finishOperation()
    }
  })
}
