import React from 'react'
import {
  InfoLabel,
  InfoText,
  InfoError,
  InfoContainer,
  InfoSegment,
} from '../../common/info'
import { useSelector } from 'react-redux'
import { loyaltyPurchaseSelectors } from '../../../../Modules/loyalty-purchase'
import { loyaltySelectors } from '../../../../Modules/loyalty'

const Content = () => {
  const calculationResult = useSelector(
    loyaltyPurchaseSelectors.calculationResult
  )

  const remainingAmount = useSelector(loyaltySelectors.remainingAmount)

  const {
    summary: { totalDiscount },
    bonuses: { applied, collected },
  } = calculationResult

  const formattedDiscount = Number(totalDiscount).toFixed(2)
  const formattedRemainingAmount = Number(remainingAmount).toFixed(2)

  return (
    <InfoContainer>
      <InfoSegment>
        <InfoLabel>Будет применено</InfoLabel>
        <InfoText>{applied}</InfoText>
      </InfoSegment>
      <InfoSegment>
        <InfoLabel>Будет получено</InfoLabel>
        <InfoText>{collected}</InfoText>
      </InfoSegment>
      <InfoSegment>
        <InfoLabel>Суммарная скидка</InfoLabel>
        <InfoText>{formattedDiscount} руб.</InfoText>
      </InfoSegment>
      <InfoSegment>
        <InfoLabel>Осталось к оплате</InfoLabel>
        <InfoText>{formattedRemainingAmount} руб.</InfoText>
      </InfoSegment>
    </InfoContainer>
  )
}

export const Info = () => {
  const loadingState = useSelector(loyaltyPurchaseSelectors.loadingState)
  const isCalculationActual = useSelector(loyaltySelectors.isCalculationActual)
  const availableActions = useSelector(loyaltySelectors.availableActions)

  const { receive, spend, receiveForPaid } = availableActions

  if (!receiveForPaid && !receive && !spend) {
    return <div>Для действий с бонусами выберите способ оплаты за проживание или доп. услугу</div>
  }

  const { isLoading, isLoaded, isFailed, error } = loadingState

  if (isLoading) return <div>Загрузка..</div>
  if (isFailed) return <InfoError>{error}</InfoError>
  if (!isLoaded) return <div>Нет данных</div>

  if (!isCalculationActual) {
    return (
      <InfoError>
        Расчет устарел. Нажмите «Обновить», чтобы получить актуальные данные.
      </InfoError>
    )
  }

  return <Content />
}
