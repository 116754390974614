import * as types from './types'

const initialState = {
  items: [],
  total: 0,
  totalRaw: 0,
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_REQUEST:
      return {
        ...state,
        items: [],
        total: 0,
        totalRaw: 0,
        isLoading: true,
        isLoaded: false,
        isFailed: false,
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        items: payload.items,
        total: payload.total,
        totalRaw: payload.totalRaw,
        isLoading: false,
        isLoaded: true,
      }

    case types.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
