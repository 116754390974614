export const loyaltyErrors = {
  1: 'Чек не обработан процессингом или обработан с ошибкой',
  2: 'В запросе к процессингу обнаружена ошибка или неверный вид JSON',
  3: 'Клиент не найден',
  4: 'Аккаунт клиента заблокирован',
  5: 'Не найден магазин',
  6: 'Списание бонусов превышает допустимое значение',
  10: 'Возвращаемый товар не найден в чеке продажи',
  11: 'Сумма возврата больше суммы продажи',
  13: 'Чек продажи не найден на сервере процессинга',
  14: 'Чек для отмены не найден',
  15: 'Отмена допускается только для последней операции',
  16: 'Превышен допустимый интервал времени для отмены операции',
  17: 'Чек с данным номером уже обработан',
  20: 'Номер телефона клиента не валиден',
  21: 'Продажа не найдена',
  22: 'Не передан ключ аутентификации',
  23: 'Клиент не аутентифицирован',
  24: 'Клиент с таким номером телефона уже существует',
  25: 'Карта уже привязана к другому клиенту',
  26: 'Код подтверждения не отправлялся',
  27: 'Код подтверждения некорректен',
  28: 'Слишком частая отправка кода подтверждения',
  29: 'У клиента не задан номер телефона',
  30: 'Клиент с таким внешним идентификатором уже существует',
  31: 'Заказ не найден',
  32: 'Заказ уже обработан',
  33: 'Промокод не найден',
  34: 'Условия промокода не выполнены',
  35: 'Промокод уже использован максимальное число раз',
  36: 'Время действия промокода еще не наступило или уже прошло',
  37: 'Промокод работает только совместно с бонусным счетом',
  404: 'Неверный адрес для отправки запроса',
  403: 'Ключ аутентификации не передан или некорректен',
  405: 'Неверный тип запроса',
  500: 'Сервер не выполнил запрос из-за внутренней ошибки',
  NETWORK_ERROR: 'Не удалось связаться с сервером лояльности',
  WRAPPER_ERROR: 'Ошибка при обращении к /loyalty_wrapper',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
}
