import React from 'react'
import { EditSection } from '../../common/EditSection'
import { Content } from './components/Content'
import { Footer } from './components/Footer'

export const CheckTimes = ({
  checkIn,
  checkOut,
  checkInTime,
  checkOutTime,
  validationErrors,
  actions,
  availableActions = {
    create: true,
    update: true,
    delete: true,
    refund: false,
  },
}) => {
  const contentCondition = checkIn || checkOut

  const content = contentCondition && (
    <Content
      checkIn={checkIn}
      checkOut={checkOut}
      checkInTime={checkInTime}
      checkOutTime={checkOutTime}
      validationErrors={validationErrors}
      actions={actions}
      availableActions={availableActions}
    />
  )

  const footerCondition = availableActions.create && (!checkIn || !checkOut)

  const footer = footerCondition && (
    <Footer checkIn={checkIn} checkOut={checkOut} actions={actions} />
  )

  return (
    <EditSection
      name="Время заезда и выезда"
      content={content}
      footer={footer}
    />
  )
}
