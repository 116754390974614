import * as types from './types'
import {ccAPI} from '../api/ccAPI'
import {logoutAdmin} from "../session/actions";
import {Notifications} from "../Notifications";

const loadDataOpenSuccess = payload => ({
    type: types.LOAD_DATA_OPEN_SUCCESS,
    payload,
})

const loadDataLoadingRequest = () => ({
    type: types.LOAD_DATA_LOADING_REQUEST,
})

const loadDataCloseSuccess = payload => ({
    type: types.LOAD_DATA_CLOSE_SUCCESS,
    payload,
})

const loadDataFailure = () => ({
    type: types.LOAD_DATA_FAILURE,
})

export const openShiftIfNotExists = (lcode) => async dispatch => {
    const {shiftId, status, checklistsCompleted} = await currentShiftInfo(lcode)
    if (!shiftId) {
        await dispatch(openShift(lcode))
    } else
        dispatch({
            type: types.LOAD_DATA_OPEN_SUCCESS,
            payload: {shiftId, status, checklistsCompleted}
        })
}

const currentShiftInfo = async (lcode) => {
    try {
        const {
            work_shift_id: shiftId,
            status,
            checklists_completed: checklistsCompleted
        } = await ccAPI.checkShift(lcode)
        return {shiftId, status, checklistsCompleted}
    } catch (e) {
        return {shiftId: null, status: 'no', checklistsCompleted: false}
    }
}

export const checkShift = (lcode) => async dispatch => {
    const payload = await currentShiftInfo(lcode)
    dispatch({
        type: types.LOAD_DATA_SHIFT,
        payload
    })
}

export const openShift = (lcode) => async dispatch => {
    dispatch(loadDataLoadingRequest())

    try {
        const {
            work_shift_id: shiftId,
            check_list_items: openItems, status,
            start_at: startAt,
            end_at: endAt,
        } = await ccAPI.openShift(lcode)
        dispatch(loadDataOpenSuccess({shiftId, openItems, startAt, endAt, status}))
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const setCheckListOpen = ({lcode, data}) => async (dispatch, getState) => {
    dispatch(loadDataLoadingRequest())
    const {session} = getState()

    try {
        const {
            work_shift_id: shiftId,
            check_list_items: openItems, status,
            start_at: startAt,
            end_at: endAt,
        } = await ccAPI.checkListOpen({lcode, data})
        dispatch(loadDataOpenSuccess({shiftId, openItems, startAt, endAt, status}))
        Notifications.success('Успех! Хорошего рабочего дня))')
        dispatch(checkShift(lcode))
    } catch (err) {
        Notifications.failure('Что-то пошло не так')
        dispatch(loadDataFailure())
    }
}

export const getOpenShiftCheckList = (lcode) => async (dispatch) => {
    dispatch(loadDataLoadingRequest())

    try {
        const {
            work_shift_id: shiftId,
            check_list_items: openItems, status,
            start_at: startAt,
            end_at: endAt,
        } = await ccAPI.getOpenShiftCheckList(lcode)
        dispatch(loadDataOpenSuccess({shiftId, openItems, startAt, endAt, status}))
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const closeShift = ({data, lcode}) => async (dispatch) => {
    dispatch(loadDataLoadingRequest())

    try {
        const {
            work_shift_id: shiftId,
            check_list_items: closeItems, status,
            start_at: startAt,
            end_at: endAt,
        } = await ccAPI.setCloseShift({data, lcode})
        dispatch(loadDataCloseSuccess({shiftId, closeItems, startAt, endAt, status}))
        Notifications.success('Успех! Смена закрыта))')
        dispatch(logoutAdmin())
        dispatch(resetShift())
    } catch (err) {
        Notifications.failure('Что-то пошло не так')
        dispatch(loadDataFailure())
    }
}

export const getCloseShiftCheckList = (lcode) => async (dispatch) => {
    dispatch(loadDataLoadingRequest())

    try {
        const {
            work_shift_id: shiftId,
            check_list_items: closeItems, status,
            start_at: startAt,
            end_at: endAt,
        } = await ccAPI.getCloseShiftCheckList(lcode)
        dispatch(loadDataCloseSuccess({shiftId, closeItems, startAt, endAt, status}))
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const getReviewCheckList = (lcode) => async (dispatch) => {

    try {
        const {
            check_list_items: reviewItems,
            work_shift_id:reviewShiftId
        } = await ccAPI.getReviewCheckListClose(lcode)

        dispatch({type: types.LOAD_DATA_REVIEW_SUCCESS, payload: {reviewShiftId, reviewItems}})
    } catch (err) {
    }
}


export const setReviewCheckList = ({data,lcode}) => async (dispatch) => {

    try {
        const {
            check_list_items: reviewItems,
            work_shift_id:reviewShiftId
        } = await ccAPI.setReviewCheckListClose({data,lcode})

        dispatch({type: types.LOAD_DATA_REVIEW_SUCCESS, payload: {reviewShiftId, reviewItems}})
    } catch (err) {
    }
}

export const resetShift = () => ({
    type: types.RESET,
})
