import * as types from './types'

const initialState = {
  calculationQuery: {},
  calculationResult: {},
  calculatedFor: {
    phoneNumber: null,
    totalAmount: null,
    usedBonuses: null,
    itemsIds: [],
  },
  isConfirmed: false,
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  error: null,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.CALCULATE_REQUEST:
      return {
        ...initialState,
        isConfirmed: false,
        isLoading: true,
      }

    case types.CALCULATE_SUCCESS:
      return {
        ...state,
        calculationQuery: payload.calculationQuery,
        calculationResult: payload.calculationResult,
        calculatedFor: {
          phoneNumber: payload.calculatedFor.phoneNumber,
          totalAmount: payload.calculatedFor.totalAmount,
          usedBonuses: payload.calculatedFor.usedBonuses,
          itemsIds: payload.calculatedFor.itemsIds,
        },
        isLoading: false,
        isLoaded: true,
      }

    case types.CALCULATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        error: payload.error,
      }

    case types.CONFIRM:
      return {
        ...state,
        isConfirmed: true,
      }

    case types.CANCEL:
      return {
        ...state,
        isConfirmed: false,
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
