import React from 'react'
import { BugReportModal } from './BugReport'
import { SuggestFeatureModal } from './SuggestFeature'
import { DebugModal } from './DebugMode'

export const SpecialModals = () => (
  <>
    <SuggestFeatureModal />
    <BugReportModal />
    <DebugModal/>
  </>
)
