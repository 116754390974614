import React, {useEffect, useMemo, useState} from 'react'
import {paymentTypesNoBank, paymentTypesList} from '../../../../../Modules/helpers/paymentTypes'
import {Cell, ReadOnlyCell} from '../../../common/Cell'
import {Select, InputNumber} from '../../../../form-stuff'
import {Button, ButtonGroup} from '../../../../buttons'
import {day} from '../../../../../Modules/formatters/date'
import {ItemRegularPrice, ServiceItemPrice} from '../../../../common/ItemPrice'
import {useActions} from '../../../../common/hooks/use-actions'
import * as servicesActions from '../../../../../Modules/reservation/actions/services'
import {useSelector} from 'react-redux'
import {breakfastsSelectors} from '../../../../../Modules/breakfasts'
import {callPrompt} from '../../../../dynamic-modal'
import {reservationSelectors} from '../../../../../Modules/reservation'
import {defaultDateOption} from '../../../constants/default-values'

function enterQuantity({currentQuantity}) {
    return callPrompt({
        title: 'Количество',
        text: 'Введите возвращенное количество',
        initialValue: currentQuantity,
        validators: [
            {
                fn: Boolean,
                message: 'Укажите значение',
            },
            {
                fn: value => !Number.isNaN(Number(value)),
                message: 'Значение должно быть числом',
            },
            {
                fn: value => Number(value) > 0,
                message: 'Значение должно быть больше 0',
            },
            {
                fn: value => Number(value) <= currentQuantity,
                message:
                    'Значение должно быть меньше или равно оставшемуся количеству услуги',
            },
        ],
        normalizer: Number,
    })
}

export const Item = ({data, validationErrors, availableActions, actions}) => {
    const {
        updateServiceDeliveryDate,
        updateServiceQuantity,
        updateServicePaymentType,
        copyService,
        deleteService,
        refundService,
    } = useActions(servicesActions)

    const {id, breakfast_pk} = data

    const setDeliveryDate = value => updateServiceDeliveryDate({id, value})
    const setQuantity = value => updateServiceQuantity({id, value})
    const setPaymentType = value => updateServicePaymentType({id, value})
    const copy = () => copyService({id})
    const remove = () => deleteService({id})
    const {edit} = actions

    const refund = async () => {
        const refundedQuantity = await enterQuantity({
            currentQuantity: data.quantity,
        })
        if (refundedQuantity) {
            refundService({id, quantity: refundedQuantity})
        }
    }

    const availableDates = useSelector(
        reservationSelectors.availableBreakfastDates
    )

    const availableBreakfastDatesForEdit = useSelector(
        reservationSelectors.availableBreakfastDatesForEdit
    )

    const dateSelectOptions = useMemo(() => {
        return availableDates.map(date => ({
            label: day(date),
            value: date,
        }))
    }, [availableDates])

    const isPayed = Boolean(data.payment_date)

    const copyCondition = availableActions.copy && dateSelectOptions.length > 0
    const updateCondition = availableActions.update && !isPayed
    const deleteCondition = availableActions.delete && !isPayed
    const refundCondition = availableActions.refund && isPayed
    const editAvailable = availableBreakfastDatesForEdit.find(it => it === data.breakfast_delivery_date)

    const breakfastNameByPk = useSelector(breakfastsSelectors.namesByPk)
    const breakfastService = useSelector(reservationSelectors.breakfastServices)
    const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

    useEffect(() => {
        const list = breakfastService ? breakfastService.is_available_for_sale_by_bank
                ? paymentTypesList
                : paymentTypesNoBank
            : paymentTypesList
        setPaymentTypeList(list)
    }, [breakfastService])

    const dataCells = !updateCondition ? (
        <>
            <ReadOnlyCell>
                {data.breakfast_delivery_date ? day(data.breakfast_delivery_date) : '-'}
            </ReadOnlyCell>
            <ReadOnlyCell>{breakfastNameByPk[breakfast_pk]}</ReadOnlyCell>
            <ReadOnlyCell>
                <ServiceItemPrice item={data}/>
            </ReadOnlyCell>
            <ReadOnlyCell>{data.quantity}</ReadOnlyCell>
            <ReadOnlyCell>{data.payment_type}</ReadOnlyCell>
        </>
    ) : (
        <>
            <Cell>
                <Select
                    defaultOption={defaultDateOption}
                    value={data.breakfast_delivery_date}
                    options={dateSelectOptions}
                    onChange={setDeliveryDate}
                    hasError={validationErrors['breakfast_delivery_date']}
                    noMargin
                />
            </Cell>
            <ReadOnlyCell>{breakfastNameByPk[breakfast_pk]}</ReadOnlyCell>
            <ReadOnlyCell>
                <ItemRegularPrice item={data}/>
            </ReadOnlyCell>
            <Cell>
                <InputNumber
                    value={data.quantity}
                    onChange={setQuantity}
                    hasError={validationErrors['quantity']}
                    noMargin
                />
            </Cell>
            <Cell>
                <Select
                    value={data.payment_type}
                    options={paymentTypeList}
                    onChange={setPaymentType}
                    hasError={validationErrors['payment_type']}
                    noMargin
                />
            </Cell>
        </>
    )

    return (
        <tr>
            {dataCells}
            <Cell>
                <ButtonGroup align="right">
                    {!updateCondition && editAvailable && <Button
                        onClick={() => edit()}
                        size="small"
                        className={'btn-success'}
                    >
                        <span className="glyphicon glyphicon-pencil"/>
                    </Button>}
                    {copyCondition && (
                        <Button type="success" onClick={copy}>
                            Копировать
                        </Button>
                    )}
                    {deleteCondition && (
                        <Button type="danger" onClick={remove}>
                            Удалить
                        </Button>
                    )}
                    {refundCondition && (
                        <Button type="danger" onClick={refund}>
                            Возврат
                        </Button>
                    )}
                </ButtonGroup>
            </Cell>
        </tr>
    )
}
