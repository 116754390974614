import React from 'react'
import styled from 'styled-components'

const StyledButtonGroup = styled.div`
  margin-bottom: -8px;
  text-align: ${props => props.align};
`

export const ButtonGroup = ({ align = 'left', children }) => (
  <StyledButtonGroup align={align}>{children}</StyledButtonGroup>
)
