// Helpers
import canBePrinted from './canBePrinted'

const getItems = reservation => {
  const { refundedItems } = reservation

  // Get days from refunded items with type that equals to "day"
  // Also day should satisfy the condition to be printed
  const days = refundedItems.filter(i => i.type === 'day' && canBePrinted(i))
  // Get services from refunded items with type that equals to "service"
  // Also service should satisfy the condition to be printed
  const services = refundedItems.filter(
    i => i.type === 'service' && canBePrinted(i)
  )

  return {
    days,
    services,
  }
}

export default getItems

export const getItemsForLoyaltyReturn = reservation => {
  const { refundedItems } = reservation

  const days = refundedItems.filter(i => i.type === 'day')
  const services = refundedItems.filter(i => i.type === 'service')

  return {
    days,
    services,
  }
}
