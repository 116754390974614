import axios from 'axios'

const createDefaultRequest = ({ baseUrl }) => {
  return async ({ url, ...restOptions }) => {
    const finalUrl = baseUrl + url

    const requestOptions = {
      url: finalUrl,
      ...restOptions,
    }

    return axios(requestOptions).then(res => res.data)
  }
}

const createRequestWithToken = ({ baseUrl, getToken }) => {
  return async ({ url, withToken, ...restOptions }) => {
    const finalUrl = baseUrl + url

    const requestOptions = {
      url: finalUrl,
      ...restOptions,
    }

    if (withToken) {
      if (!requestOptions.headers) requestOptions.headers = {}

      const token = await getToken()

      requestOptions.headers['Authorization'] = `Basic ${token}`
    }

    return axios(requestOptions).then(res => res.data)
  }
}

const createRequest = ({ type, baseUrl, getToken }) => {
  switch (type) {
    case 'default':
      return createDefaultRequest({ baseUrl })

    case 'withToken':
      return createRequestWithToken({ baseUrl, getToken })

    default:
      return createDefaultRequest({ baseUrl })
  }
}

export default createRequest
