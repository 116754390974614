import { request } from './request'

export const getTasksCall = adminID =>
  request({
    url: '/task/' + adminID,
    method: 'get',
  })

export const getAdminsCall = () =>
  request({
    url: '/admins/',
    method: 'get',
  })

export const completeTaskCall = task =>
  request({
    url: '/task/complete/' + task.pk,
    method: 'put',
    data: {
      ...task,
      completed: true,
    },
  })

export const createTaskCall = ({ admin, task, author }) =>
  request({
    url: '/task/',
    method: 'post',
    data: {
      admin,
      task,
      author,
      completed: false,
    },
  })



