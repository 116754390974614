import React, { useEffect, useState } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Checkbox,
  Button,
  Glyphicon,
} from 'react-bootstrap'
import Select from '../../common/Select'
import moment from 'moment'
import 'moment/locale/ru'
import { callModal, modalTemplates } from '../../dynamic-modal'
import { DebouncedTextField } from '../../common/DebouncedTextField'
import { useSelector } from 'react-redux'
import { specialServiceTypesSelectors } from '../../../Modules/special-service-types'
import { paymentTypesNoBank, paymentTypesList } from '../../../Modules/helpers/paymentTypes'

moment.locale('ru')

const PayedCheckbox = props => {
  return (
    <FormGroup>
      <ControlLabel>{props.title}</ControlLabel>
      <label
        style={{
          width: 34,
          height: 34,
          backgroundColor: props.disabled
            ? '#eee'
            : props.value
            ? '#5cb85c'
            : '#fff',
          border: '1px solid',
          borderColor: props.value ? '#4cae4c' : '#ced4da',
          borderRadius: 5,
          display: 'flex',
          cursor: !props.disabled && 'pointer',
        }}
      >
        <Glyphicon
          glyph="ok"
          style={{
            margin: 'auto',
            fontSize: 18,
            color: props.disabled ? '#eee' : '#fff',
            transform: 'translate(-1px, -1px)',
          }}
        />
        <Checkbox
          value={props.value}
          onChange={() => props.onChange(!props.value)}
          style={{ display: 'none' }}
          disabled={props.disabled}
        />
      </label>
    </FormGroup>
  )
}

const Day = ({
  day,
  disabled,
  onDayPriceChange,
  onDayPaymentChange,
  onDayPayedChange,
  dayRefund,
  notPayedConfirmed,
  confirmNotPayed,
  modes,
  validationErrors,
  isLast,
}) => {
  const livingService = useSelector(specialServiceTypesSelectors.livingService)
  const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

  useEffect(() => {
    const list = livingService ? livingService.is_available_for_sale_by_bank
        ? paymentTypesList
        : paymentTypesNoBank
      : paymentTypesList
    setPaymentTypeList(list)
  }, [livingService])

  return (
    <Grid fluid={true}>
      <Row>
        <Col xs={3}>
          <div style={{ marginTop: 30 }}>
            <h4 style={{ fontSize: 20 }}>
              {moment(day.date).format('DD MMMM')}
            </h4>
          </div>
        </Col>
        <Col xs={2}>
          <DebouncedTextField
            size="sm"
            type="number"
            label="Стоимость"
            placeholder="Стоимость"
            value={day.price}
            onChange={onDayPriceChange}
            error={validationErrors[day.id].price}
            disabled={disabled}
          />
        </Col>
        <Col xs={3}>
          <Select
            onChange={onDayPaymentChange}
            value={day.payment_type}
            validationState={
              validationErrors[day.id].payment_type ? 'error' : null
            }
            values={paymentTypeList.map(p => ({ value: p, text: p }))}
            name="Метод оплаты"
            disabled={disabled}
          />
        </Col>
        <Col xs={2}>
          <PayedCheckbox
            onChange={async value => {
              if (!modes.create) {
                onDayPayedChange(value)
                return
              }

              if (notPayedConfirmed) {
                return onDayPayedChange(value)
              }

              const { action } = await callModal(
                modalTemplates.confirm({
                  text: 'Вы действительно хотите создать бронь без оплаты?',
                })
              )

              if (action !== modalTemplates.confirm.actions.confirm) {
                return
              }

              confirmNotPayed()
              onDayPayedChange(value)
            }}
            value={Boolean(day.payment_date)}
            style={{ marginTop: 10 }}
            disabled={disabled}
            title="Оплачен"
          />
        </Col>
        {modes.refund && isLast && (
          <Col xs={2}>
            <Button
              bsStyle="danger"
              bsSize="small"
              style={{ marginTop: 27 }}
              onClick={() => dayRefund(day.id)}
            >
              Возврат
            </Button>
          </Col>
        )}
      </Row>
    </Grid>
  )
}

export default Day
