import React from 'react'
import { Alert } from 'react-bootstrap'

const ErrorMessage = props => (
  <Alert bsStyle="danger" {...props}>
    {props.children}
  </Alert>
)

export default ErrorMessage
