import React, { useCallback } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { LogsAPI } from '../../../../Modules/logs-api'
import { useSelector } from 'react-redux'
import { useActions } from '../../../common/hooks/use-actions'
import {
  loyaltyPurchaseSelectors,
  loyaltyPurchaseActions,
} from '../../../../Modules/loyalty-purchase'
import { loyaltySelectors } from '../../../../Modules/loyalty'

const StyledButton = styled(Button).attrs(() => ({
  bsSize: 'sm',
  bsStyle: 'success',
}))`
  width: 100%;
  margin-top: 10px;
`

export const ConfirmButton = () => {
  const isCalculationActual = useSelector(loyaltySelectors.isCalculationActual)
  const isConfirmed = useSelector(loyaltyPurchaseSelectors.isConfirmed)
  const { confirm } = useActions(loyaltyPurchaseActions)

  const confirmWithLogging = useCallback(() => {
    LogsAPI.pressAction('Применить бонусы')
    confirm()
  }, [confirm])

  if (!isCalculationActual) return null

  if (isConfirmed) {
    return <StyledButton disabled={true}>Бонусы применены</StyledButton>
  }

  return (
    <StyledButton onClick={confirmWithLogging}>Применить бонусы</StyledButton>
  )
}
