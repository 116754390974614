import React, { useCallback } from 'react'
import { ActionsBlock, ActionButton } from '../../common/actions'
import { LogsAPI } from '../../../../Modules/logs-api'
import { useSelector } from 'react-redux'
import {
  loyaltyPurchaseSelectors,
  loyaltyPurchaseActions,
} from '../../../../Modules/loyalty-purchase'
import { loyaltySelectors } from '../../../../Modules/loyalty'
import { useActions } from '../../../common/hooks/use-actions'

export const Actions = () => {
  const loadingState = useSelector(loyaltyPurchaseSelectors.loadingState)
  const isCalculationActual = useSelector(loyaltySelectors.isCalculationActual)
  const isConfirmed = useSelector(loyaltyPurchaseSelectors.isConfirmed)
  const usedBonuses = useSelector(loyaltyPurchaseSelectors.usedBonuses)
  const { refresh, cancel } = useActions(loyaltyPurchaseActions)

  const { isLoaded } = loadingState

  const refreshCond = isLoaded && !isCalculationActual
  const cancelCond = isConfirmed && usedBonuses > 0

  const refreshWithLogging = useCallback(() => {
    LogsAPI.pressAction('Обновить расчет бонусов')
    refresh()
  }, [refresh])

  const cancelWithLogging = useCallback(() => {
    LogsAPI.pressAction('Отменить начисление бонусов')
    cancel()
  }, [cancel])

  const refreshButton = (
    <ActionButton type="primary" onClick={refreshWithLogging}>
      Обновить
    </ActionButton>
  )

  const cancelButton = (
    <ActionButton type="danger" onClick={cancelWithLogging} fluid>
      Отменить начисление
    </ActionButton>
  )

  return (
    <ActionsBlock>
      {refreshCond && refreshButton}
      {cancelCond && cancelButton}
    </ActionsBlock>
  )
}
