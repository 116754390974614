import store from '../../store'

import {
  logCustom,
  logEvent,
  logPressAction,
  logEditAction,
  logError,
} from '../reservation/ReservationModule'

export const custom = text => store.dispatch(logCustom(text))

export const event = event => store.dispatch(logEvent(event))

export const pressAction = target => store.dispatch(logPressAction(target))

export const editAction = (field, value) =>
  store.dispatch(logEditAction(value, field))

export const error = error => store.dispatch(logError(error))
