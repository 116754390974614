import { createSelector } from 'reselect'

export const everything = state => state.washing

export const machines = state => everything(state).machines

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const loadState = createSelector(
  isLoading,
  isLoaded,
  isFailed,
  (isLoading, isLoaded, isFailed) => ({
    isLoading,
    isLoaded,
    isFailed,
  })
)

export const freeMachines = createSelector(machines, machines => {
  return machines.filter(machine => machine.is_running === false)
})

export const numberOfFreeMachines = state => freeMachines(state).length

export const hasFreeMachines = state => numberOfFreeMachines(state) !== 0
