import moment from 'moment'

function getNewDate({ copiedDate, dates, today }) {
  if (dates.length === 0) {
    throw new Error('No dates available')
  }

  if (dates.includes(today)) {
    return today
  }

  const nextDate = moment(copiedDate)
    .add(1, 'day')
    .format('YYYY-MM-DD')

  if (dates.includes(nextDate)) {
    return nextDate
  }

  if (dates.includes(copiedDate)) {
    return copiedDate
  }

  return dates[0]
}

export function getDatesForCopiedService({
  reservationStart,
  service: copiedService,
  availableDates,
  availableBreakfastDates,
  today,
}) {
  if (copiedService.service_type === 'breakfast') {
    return {
      breakfast_delivery_date: getNewDate({
        copiedDate: copiedService.breakfast_delivery_date,
        dates: availableBreakfastDates,
        today,
      }),
      date: moment(today).isSameOrAfter(reservationStart)
        ? today
        : reservationStart,
    }
  }

  return {
    date: getNewDate({
      copiedDate: copiedService.date,
      dates: availableDates,
      today,
    }),
  }
}
