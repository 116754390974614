import { buildCalculationRows } from '../../helpers/loyalty/build-calculation-rows'
import { loyaltyClientSelectors } from '../../loyalty-client'
import { reservationSelectors } from '../../reservation'
import { sessionSelectors } from '../../session'

export function buildCalculationQuery({
  state,
  days,
  services,
  applyBonuses,
  phoneNumber = loyaltyClientSelectors.phoneNumber(state),
}) {
  const session = sessionSelectors.everything(state)
  const serviceLoyaltyAllowabilityMap = reservationSelectors.serviceLoyaltyAllowabilityMap(
    state
  )
  const isReady = reservationSelectors.isReady(state)

  const rows = buildCalculationRows({
    days,
    services,
    serviceLoyaltyAllowabilityMap,
    isReady
  })

  return {
    client: {
      phoneNumber,
    },
    shop: {
      code: session.lcode,
      name: session.lcodeName,
    },
    rows,
    applyBonuses,
  }
}
