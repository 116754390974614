import { connect } from 'react-redux'
import Settings from '../Components/Settings/Settings'

import {
  updateSettings,
  setGridDate,
  resetGridDate,
} from '../Modules/settings/actions'
import { settingsSelectors } from '../Modules/settings'

const mapStateToProps = state => ({
  daysInPast: state.settings.daysInPast,
  daysInFuture: state.settings.daysInFuture,
  gridDate: settingsSelectors.gridDate(state),
  gridScale: settingsSelectors.gridScale(state),
  isLoading: state.settings.isLoading,
})

const mapDispatchToProps = {
  updateSettings,
  setGridDate,
  resetGridDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
