import { createSelector } from 'reselect'
import paymentTypes from '../../../../constants/paymentTypes'

export const checksSelector = state => state.checks

const itemsSelector = createSelector(checksSelector, checks => checks.items)

const getTotal = (items, paymentType) =>
  items
    .filter(item => item.payment_type === paymentTypes[paymentType])
    .reduce((total, item) => total + item.total, 0)

const getAllTotals = (items, neededPaymentTypes) => {
  const result = {}
  let totalForAll = 0

  neededPaymentTypes.forEach(PT => {
    const tmpTotal = getTotal(items, PT)
    result[PT] = tmpTotal
    totalForAll += tmpTotal
  })

  result.all = totalForAll

  return result
}

export const checksStatsSelector = createSelector(itemsSelector, items => {
  const sells = items.filter(item => !item.isRefund)
  const refunds = items.filter(item => item.isRefund)

  return {
    sells: {
      count: sells.length,
      totals: getAllTotals(sells, ['cash', 'card']),
    },
    refunds: {
      count: refunds.length,
      totals: getAllTotals(refunds, ['cash', 'card']),
    },
  }
})
