import { createSelector } from 'reselect'

export const everything = state => state.news

export const items = state => everything(state).items
export const count = state => everything(state).count
export const newNewsCount = state => everything(state).newNewsCount
export const categories = state => everything(state).categories

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const categoryOptions = createSelector(categories, items => (
  items.map(mapItemToPkOption)
))

export const categoriesIds = createSelector(categories, items => (
  { categories: items.map(({ id }) => ({ id })) }
))

function mapItemToPkOption(item) {
  return {
    label: item.name,
    value: item.id,
    color: item.color
  }
}


