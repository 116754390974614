import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { checkSelectors } from '../../../Modules/check'
import { rub } from '../../../Modules/formatters/currency'
import { reservationSelectors } from '../../../Modules/reservation'
import styled from 'styled-components'

const RedSpan = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: crimson;
`

export const TotalSumm = () => {
  const modes = useSelector(reservationSelectors.modes)
  const previouslyPayed = useSelector(reservationSelectors.previouslyPayed)
  const { total: pTotal, paymentType: pPaymentType } = useSelector(checkSelectors.purchaseTotal)
  const { total: rTotal, paymentType: rPaymentType } = useSelector(checkSelectors.refundTotal)

  const [purchaseData, setPurchaseData] = useState('')
  const [refundData, setRefundData] = useState('')

  const secondRowStyles = !modes.readonly
    ? { fontSize: 15, color: '#555' }
    : { fontSize: 20, color: '#222' }
  const secondRowPriceStyles = !modes.readonly
    ? { fontSize: 17, fontWeight: 500, color: '#f1637e' }
    : { fontSize: 22, fontWeight: 500, color: 'crimson' }

  useEffect(() => {
    if (pPaymentType) {
      const data = Object.keys(pPaymentType).reduce((a, b) => {
        a += `${rub(pPaymentType[b].total)} - ${pPaymentType[b].count} шт. - ${b}, `
        return a
      }, '')
      setPurchaseData(`(${data.slice(0, -2)})`)
    }
  }, [pPaymentType])

  useEffect(() => {
    if (rPaymentType) {
      const data = Object.keys(rPaymentType).reduce((a, b) => {
        a += `${rub(rPaymentType[b].total)} - ${rPaymentType[b].count} шт. - ${b},`
        return a
      }, '')
      setRefundData(`(${data.slice(0, -2)})`)
    }
  }, [rPaymentType])

  return (
    <div style={{ marginRight: 'auto' }}>
      {!modes.readonly && (
        <span style={{ fontSize: 20 }}>
          {modes.refund ? (
            <Fragment>
              Возврат будет выполнен на сумму&nbsp;
              <RedSpan>
                {rub(rTotal)}
              </RedSpan>&nbsp;
                <RedSpan>
                  {refundData}
                </RedSpan>
            </Fragment>
          ) : (
            <Fragment>
              Продажа будет выполнена на сумму&nbsp;
              <RedSpan>
                {rub(pTotal)}
              </RedSpan>&nbsp;
              <RedSpan>
                {purchaseData}
              </RedSpan>
            </Fragment>
          )}
        </span>
      )}

      {previouslyPayed > 0 && (
        <Fragment>
          {!modes.readonly && <br />}
          <span style={secondRowStyles}>
            Ранее по этому заказу было оплачено{' '}
            <span style={secondRowPriceStyles}>{rub(previouslyPayed)}</span>
          </span>
        </Fragment>
      )}
    </div>
  )
}
