export function validateForm(values, schema) {
  const errors = {}
  let wereErrors = false

  function addError(field, message) {
    errors[field] = message
    wereErrors = true
  }

  for (let field in schema) {
    const value = values[field]
    const validators = schema[field]

    for (let validator of validators) {
      const isValid = validator.fn(value, values)

      if (isValid) continue

      addError(field, validator.message)
      break
    }
  }

  return {
    errors,
    hasErrors: wereErrors,
    wereErrors,
  }
}
