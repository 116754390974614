import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { maidsActions, maidsSelectors } from '../../../Modules/maids'
import styled from 'styled-components'
import { Button } from '../../buttons'
import { sessionSelectors } from '../../../Modules/session'
import { ccAPI } from '../../../Modules/api/ccAPI'
import { callAlert, callPrompt } from '../../dynamic-modal'
import { useActions } from '../../common/hooks/use-actions'

const InlineItem = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;

  svg {
    margin-right: 10px;
  }
`

const Warning = styled.div`
  color: #da4e4e;
  font-size: 15px;
  margin: 0;
  text-align: center;
  padding-bottom: 20px;
`


export const MaidsCheckOut = () => {
  const lcode = useSelector(sessionSelectors.lcode)
  const checkedInList = useSelector(maidsSelectors.checkedInList)
  const { removeFromCheckOutList } = useActions(maidsActions)

  const selectMaid = useCallback(
    user => {
      ccAPI.sendMaidsCheckoutCode({ lcode, users: [user] }).then(_ => {
        callPrompt({
          title: 'Подтверждение',
          text: 'Введите код из письма, отправленного на почту горничной',
        }).then(async code => {
          if (code == null) {
            return
          }
          await ccAPI
            .confirmMaidsCheckout({ code, user, lcode })
            .then(() => {
              removeFromCheckOutList({ id: user })
              callAlert({
                title: 'Успех',
                text: 'Ваша смена окончена',
              })
            })
            .catch(({ detail }) => {
              callAlert({
                title: 'Ошибка',
                text: detail,
              })
            })
        })
      }).catch(({ detail }) => {
        callAlert({
          title: 'Ошибка',
          text: detail,
        })
      })
    }, []
  )

  return (
    <div>
      <Warning>Кнопка "Завершить" используется только при уходе горничной с работы раньше 18:00</Warning>
      {checkedInList.map(({ name: maid, id }) =>
        <InlineItem key={maid + '_' + id}>
          {maid}
          <Button size="sm" onClick={() => selectMaid(id)}>Завершить</Button>
        </InlineItem>
      )}
    </div>
  )
}
