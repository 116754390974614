import moment from 'moment'
import { createReducer } from '../helpers/redux/createReducer'
import * as types from './types'

function getInitialState() {
  return {
    date: moment().format('YYYY-MM-DD'),
    hour: moment().hour(),
    minute: moment().minute(),
  }
}

export const reducer = createReducer(getInitialState, {
  [types.SET_DATE]: (state, payload) => ({ ...state, date: payload }),
  [types.SET_HOUR]: (state, payload) => ({ ...state, hour: payload }),
  [types.SET_MINUTE]: (state, payload) => ({ ...state, hour: payload }),
})
