export default (array, itemID, key, value) =>
  array.map(item => {
    if (item.id === itemID) {
      return {
        ...item,
        [key]: value,
      }
    }
    return item
  })
