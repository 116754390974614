export const isTraining =
  window.location.host === 'training.winterfell-hotels.ru'

export const localBaseUrl = isTraining
  ? 'https://training.winterfell-hotels.ru'
  : 'http://127.0.0.1:9000'

const basicAuthToken = isTraining
  ? btoa('dev:57OdCD2uM3')
  : btoa('admin:pMk71gabyVB')

export const basicAuth = `Basic ${basicAuthToken}`

export const remoteAPIEndpoint = 'http://cc.winterfell-hotels.ru'
export const remoteAPIAuthData = {
  username: 'admin',
  password: '2007200788a',
}

export const loyaltyWrapperBaseUrl = localBaseUrl + '/loyalty_wrapper/'

export const HOURS_BEFORE_ARCHIVING = 12
