import * as types from './types'
import { ccAPI } from '../api/ccAPI'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = () => async dispatch => {
  dispatch(loadRequest())

  try {
    const items = await ccAPI.getSpecialServiceTypes()

    dispatch(loadSuccess({ items: items?.sort((a, b) => a.name.localeCompare(b.name)) }))
  } catch {
    dispatch(loadFailure())
  }
}
