import * as types from './types'

const initialState = {
  data: {
    phoneNumber: '',
    fullName: '',
    email: '',
    bonuses: 0,
    pendingBonuses: 0,
    level: 1,
  },
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  error: null,

  isSendingRegistrationSMS: false,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.CONNECT_LOYALTY_REQUEST:
      return {
        ...state,
        data: initialState.data,
        isLoading: true,
        isLoaded: false,
        isFailed: false,
        error: null,
      }

    case types.CONNECT_LOYALTY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          phoneNumber: payload.phoneNumber,
          fullName: payload.fullName,
          email: payload.email,
          bonuses: payload.bonuses,
          pendingBonuses: payload.pendingBonuses,
          level: payload.level,
        },
        isLoading: false,
        isLoaded: true,
      }

    case types.CONNECT_LOYALTY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        error: payload.error,
      }

    case types.CONNECT_LOYALTY_CANCEL:
      return {
        ...state,
        isLoading: false,
      }

    case types.START_SENDING_REGISTRATION_SMS:
      return {
        ...state,
        isSendingRegistrationSMS: true,
      }

    case types.STOP_SENDING_REGISTRATION_SMS:
      return {
        ...state,
        isSendingRegistrationSMS: false,
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
