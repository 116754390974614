import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from './Modules/index'
import {ccRequestInterceptors} from "./Modules/api/ccAPI/request";

const middleware = [thunk]

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
)

window.reduxStore = store
ccRequestInterceptors(store)

export default store
