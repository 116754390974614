import { loyaltyRequest } from './request'

export const getBalance = ({ phoneNumber }) => loyaltyRequest({
  url: '/get-balance/',
  method: 'post',
  data: {
    phoneNumber,
  },
})

export const newClient = ({ client, shop }) => loyaltyRequest({
  url: '/new-client/',
  method: 'post',
  data: {
    client,
    shop,
  },
})

export const updateClient = ({ phoneNumber, client }) => loyaltyRequest({
  url: '/update-client/',
  method: 'post',
  data: {
    phoneNumber,
    client,
  },
})


export const calculatePurchase = ({ calculationQuery }) => loyaltyRequest({
  url: '/calculate-purchase/',
  method: 'post',
  data: {
    calculationQuery,
  },
}).then(response => {
  const { calculationResult } = response
  const { bonuses } = calculationResult

  if (bonuses.error) {
    const { code, description } = bonuses.error

    return {
      errorCode: code,
      description,
      maxBonuses: bonuses.maxToApply,
    }
  }

  return response
})

export const setPurchase = ({ txid, calculationQuery }) => loyaltyRequest({
  url: '/set-purchase/',
  method: 'post',
  data: {
    txid,
    calculationQuery,
  },
})

export const confirmTicket = ({ txid, ticket }) => {
  return loyaltyRequest({
    url: '/confirm-ticket/',
    method: 'post',
    data: {
      txid,
      ticket,
    },
  })
}

export const discardTicket = ({ txid, ticket }) => loyaltyRequest({
  url: '/discard-ticket/',
  method: 'post',
  data: {
    txid,
    ticket,
  },
})

export const calculateReturn = ({ calculate }) => loyaltyRequest({
  url: '/calculate-return/',
  method: 'post',
  data: {
    calculate,
  },
})

export const applyReturn = ({ transaction }) => loyaltyRequest({
  url: '/apply-return/',
  method: 'post',
  data: {
    transaction,
  },
})
