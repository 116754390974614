import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { AllPrepayeds } from './AllPrepayeds'
import { allPrepayedsSelectors, allPrepayedsActions } from './reducer'
import { hotelsSelectors } from '../../Modules/hotels'
import { sessionSelectors } from '../../Modules/session';

const mapStateToProps = state => ({
  prepayeds: allPrepayedsSelectors.prepayeds(state),
  count: allPrepayedsSelectors.count(state),
  currentPage: allPrepayedsSelectors.currentPage(state),
  nextPage: allPrepayedsSelectors.nextPage(state),
  previousPage: allPrepayedsSelectors.previousPage(state),
  prepayedsLoadingState: allPrepayedsSelectors.prepayedsLoadingState(state),
  reservationsDataLoadingState: allPrepayedsSelectors.reservationsDataLoadingState(
    state
  ),
  reservationListsByBookingNumber: allPrepayedsSelectors.reservationListsByBookingNumber(
    state
  ),
  lcode: sessionSelectors.lcode(state),
  hotelsMappings: hotelsSelectors.hotelsMappings(state),
})

const mapDispatchToProps = dispatch => ({
  allPrepayedsActions: bindActionCreators(allPrepayedsActions, dispatch),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export const EnhancedAllPrepayeds = enhance(AllPrepayeds)
