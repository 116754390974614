import React from 'react'
import styled from 'styled-components'
import { FaNewspaper } from 'react-icons/fa'
import history from '../../../Modules/helpers/history'
import { useSelector } from 'react-redux'
import { newsActions, newsSelectors } from './reducer'

const StyledButton = styled.button`
  width: 100%;
  padding: 6px 12px;
  border: none;
  outline: none;
  background-color: #46b8da;
  color: #ffffff;
  position: relative;

  transition: background-color 0.3s;

  &:hover {
    background-color: #47a7c4;
  }
`
const Count = styled.div`
  position: absolute;
  background-color: #d61b3e;
  border-radius: 50%;
  top: calc(-${props => props.ch + 'ch'} / 2);
  left: calc(-${props => props.ch + 'ch'} / 2);
  line-height: calc(${props => props.ch + 'ch'} + 10px);
  height: calc(${props => props.ch + 'ch'} + 10px);
  width: calc(${props => props.ch + 'ch'} + 10px);
`


export const Button = () => {
  const count = useSelector(newsSelectors.newNewsCount)
  return (
    <StyledButton onClick={() => history.push('/news')}>
      <Count ch={count?.toString().length}>{count}</Count>
      <FaNewspaper style={{ fontSize: 16, marginRight: 5, marginBottom: -3 }} />
      Новости
    </StyledButton>
  )
}
