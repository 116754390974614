import styled from 'styled-components'

export default styled.div`
  font-size: 14px;
  padding: 16px;
  text-align: center;
  color: ${props => props.theme.colors.pageText};
  background-color: ${props => props.theme.colors.checksTableBlockBackground};
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.27);
`
