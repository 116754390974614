export const white = '#ffffff'
export const gray = '#f7f7f8'
export const purple = '#4b538c'
export const green = '#1c943c'

export const lightRed = '#ad343b'
export const lightGray = '#e2e2e2'
export const lightGreen = '#26af4a'
export const lightPurple = '#8d97e0'

export const veryLightRed = '#f16169'
export const veryLightPurple = '#cacee8'

export const darkRed = '#ce000b'
export const darkGreen = '#299a50'
export const darkOrange = '#dc7700'
export const darkBlue = '#252b56'
export const darkBlue2 = '#181c38'
export const darkPurple = '#0d1021'

export const transparentWhite = 'rgba(255, 255, 255, 0.85)'
export const transparentPurple = 'rgba(156, 138, 210, 0.5)'

// NAVBAR
export const navbarBackground = darkBlue
export const navbarText = white

// SIGN FORMS
export const signFormsBackground = darkBlue2
export const signFormsErrorBackground = lightRed
export const signFormsErrorText = white

// CHECKS TABLE
export const checksTableRowBorder = lightGray
export const checksTableSuccess = lightGreen
export const checksTableSuccessDark = green
export const checksTableFailure = veryLightRed

// LCODE SELECT
export const lcodeSelectOptionHoverBackground = veryLightPurple
export const lcodeSelectOptionActiveBackground = purple
export const lcodeSelectOptionSelectBackground = purple
