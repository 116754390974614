const LS_KEY = 'LAST_WUBOOK_FETCH'
const TIMEOUT = 60 // 1 min

export const wasNewWubookLoadedRecently = () => {
  const lastWubookFetch = localStorage.getItem(LS_KEY)

  const diff = (new Date() - lastWubookFetch) / 1000

  if (lastWubookFetch && diff < TIMEOUT) {
    const remainingMinutes = ((TIMEOUT - diff) / 60) >> 0
    const remainingSeconds = (TIMEOUT - diff) % 60 >> 0

    return [true, remainingMinutes, remainingSeconds]
  }

  return [false]
}

export const setNewWubookLoaded = () => {
  localStorage.setItem(LS_KEY, +new Date())
}
