import React from 'react'
import GlobalPrintStyles from './GlobalPrintStyles'

export const Pages = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalPrintStyles />
      {children}
    </React.Fragment>
  )
}
