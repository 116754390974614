export const dayTypes = {
  new: 'new',
  payed: 'payed',
  notPayed: 'not-payed',
}

export const serviceTypes = {
  new: 'new',
  payed: 'payed',
}

export const MODES = {
  INITIAL: 'initial',
  CREATE: 'create',
  EDIT: 'edit',
  WUBOOK: 'wubook',
  REFUND: 'refund',
  READONLY: 'readonly',
}

export const COOKIES = {
  SMS_TIME: 'sms_time',
  REGISTR_TIME: 'registr_time'
}
