import React from 'react'
import { EditSection } from '../common/EditSection'
import { Content } from './components/Content'

export const CheckLines = ({ checkLines, defaultIsOpen = true }) => {
  const hasCheckLines = checkLines.length > 0

  return (
    <EditSection
      size="small"
      name="Чек"
      content={hasCheckLines && <Content checkLines={checkLines} />}
      defaultVisibility={defaultIsOpen}
      withVisibilityToggle={hasCheckLines}
      customColor="white"
    />
  )
}
