import React from 'react'
import styled from 'styled-components'
import { Button as BootstrapButton } from 'react-bootstrap'

const StyledBootstrapButton = styled(BootstrapButton)`
  margin-right: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`

export const Button = ({
  type = 'success',
  size = 'small',
  onClick,
  children,
  ...rest
}) => (
  <StyledBootstrapButton
    bsStyle={type}
    bsSize={size}
    onClick={onClick}
    {...rest}
  >
    {children}
  </StyledBootstrapButton>
)
