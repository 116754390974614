import React from 'react'
import LeftSide from './LeftSide'
import styled from 'styled-components'
import { Grid } from './components/grid'

const MainPageBlock = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`

export class MainPage extends React.Component {
  componentDidMount() {
    const { loadData, isTodoListEnabled, tasksActions } = this.props
    loadData()
    if (isTodoListEnabled) {
      tasksActions.loadData()
    }
  }

  componentWillUnmount() {
    const { resetData } = this.props
    resetData()
  }

  render() {
    return (
      <MainPageBlock>
        <LeftSide />
        <Grid />
      </MainPageBlock>
    )
  }
}
