import 'mdn-polyfills/Array.prototype.find'
import 'mdn-polyfills/Array.prototype.filter'
import 'mdn-polyfills/Array.prototype.includes'
import 'mdn-polyfills/Array.prototype.some'

if (!Array.prototype.flat)
  // eslint-disable-next-line no-extend-native
  Array.prototype.flat = function(depth = 1) {
    depth = isNaN(depth) ? 0 : Math.floor(depth)
    if (depth < 1) return this.slice()
    return [].concat(
      ...(depth < 2
        ? this
        : this.map(v => (Array.isArray(v) ? v.flat(depth - 1) : v)))
    )
  }
