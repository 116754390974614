import React from 'react'
import { Table } from 'react-bootstrap'
import { Item } from './Item'

export const Content = ({
  items,
  availableDates,
  actions,
  validationErrors,
  availableActions,
}) => {
  return (
    <Table style={{ marginBottom: 0 }}>
      <thead>
        <tr>
          <th>Дата проживания</th>
          <th>Услуга</th>
          <th>Стоимость за штуку</th>
          <th>Количество</th>
          <th>Метод оплаты</th>
          <th
            style={{
              textAlign: 'right',
            }}
          >
            Действия
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <Item
            key={item.id}
            data={item}
            availableDates={availableDates}
            validationErrors={validationErrors[item.id] || {}}
            actions={actions}
            availableActions={availableActions}
          />
        ))}
      </tbody>
    </Table>
  )
}
