import React from 'react'
import { Checkbox, FormGroup, ControlLabel } from 'react-bootstrap'

const CheckBoxComponent = ({
  name,
  title,
  value,
  onChange,
  style,
  formStyle,
  disabled,
}) => {
  return (
    <FormGroup
      controlId="formControlsSelect"
      style={formStyle ? formStyle : { marginBottom: 0 }}
    >
      {name ? (
        <ControlLabel style={{ letterSpacing: 0.5 }}>{name}</ControlLabel>
      ) : null}
      <Checkbox
        checked={value}
        onChange={e => onChange(e.target.checked)}
        value={value}
        style={style ? style : { margin: 0 }}
        disabled={disabled}
      >
        {` ${title} `}
      </Checkbox>
    </FormGroup>
  )
}

export default CheckBoxComponent
