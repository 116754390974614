import React from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/ru'
import { day } from '../../../Modules/formatters/date'

moment.locale('ru')

const StyledCol = props => {
  return (
    <Col {...props} style={{ fontSize: 18, marginBottom: 15 }}>
      {props.children}
    </Col>
  )
}

const PrePayedBreakfast = ({ breakfast, click, editable }) => {
  if (!breakfast) return null

  return (
    <Grid fluid={true}>
      <Row>
        <StyledCol xs={3}>{day(breakfast.delivery_date)}</StyledCol>
        <StyledCol xs={4}>{breakfast.breakfastObj ? breakfast.breakfastObj.name : ''}</StyledCol>
        <StyledCol xs={2}>{breakfast.qty}</StyledCol>
        <StyledCol xs={2}>
          {editable && <Button
            onClick={click}
            style={{ marginBottom: 0 }}
            size="small"
            className={'btn-success'}
          >
            <span className="glyphicon glyphicon-pencil" />
          </Button>}
        </StyledCol>
      </Row>
    </Grid>
  )
}

export default PrePayedBreakfast
