import React from 'react'
import { ModalBlock } from './components/Block'
import { ModalHeader, ModalContent, ModalActions } from './components/Sections'
import { Title, RedText } from './components/Contents'
import { CloseButton } from './components/CloseButton'
import { ModalContainer } from './components/ModalContainer'

export const PayTimerFinishModalView = ({ isOpen, close }) => {
  if (!isOpen) return null

  return (
    <ModalContainer>
      <ModalBlock>
        <ModalHeader>
          <Title>Внимание!</Title>
        </ModalHeader>
        <ModalContent>
          <RedText>
            Время на оплату картой истекло. Не проводите оплату. Нажмите на
            терминале кнопку «Отмена» и начните оплату заново.
          </RedText>
        </ModalContent>
        <ModalActions>
          <CloseButton onClick={close}>Закрыть предупреждение</CloseButton>
        </ModalActions>
      </ModalBlock>
    </ModalContainer>
  )
}
