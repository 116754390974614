export const processPrepayeds = prepayeds => {
  const result = {}

  for (let item of prepayeds) {
    const { booking_number, ...rest } = item

    if (!result[booking_number]) {
      result[booking_number] = []
    }

    result[booking_number].push(rest)
  }

  return result
}
