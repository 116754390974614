import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { Formik } from 'formik'
import {
  ModalControls as DefaultModalControls,
  ModalButton,
  ModalTitle,
} from '../../../common/ModalParts'
import { modalDefaultStyles } from '../../../../App'
import { Notifications } from '../../../../Modules/Notifications'
import { sendBugReport } from '../../../../Modules/bug-report/send'

const ModalControls = styled(DefaultModalControls)`
  padding: 20px;
`

const FormBody = styled.div`
  padding: 20px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
`

export const ModalView = ({ isOpen, close }) => {
  return (
    <Modal
      isOpen={isOpen}
      defaultStyles={{
        ...modalDefaultStyles,
        overlay: {
          ...modalDefaultStyles.overlay,
          zIndex: 201,
        },
        content: {
          ...modalDefaultStyles.content,
          width: 400,
          minWidth: 400,
          maxWidth: 'auto',
        },
      }}
    >
      <Formik
        initialValues={{
          message: '',
          subject: '',
        }}
        onSubmit={values => {
          sendBugReport({
            message: values.message,
            subject: values.subject,
            onFinish: () => {
              close()
              Notifications.success('Сообщение было успешно отправлено')
            },
          })
        }}
        render={({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <ModalTitle>Сообщение об ошибке</ModalTitle>
            <FormBody>
              <FormGroup style={{ marginBottom: 0 }}>
                <ControlLabel>Тема</ControlLabel>
                <FormControl
                  name="subject"
                  placeholder="Введите тему"
                  value={values.subject}
                  onChange={handleChange}
                />
                <ControlLabel>Сообщение</ControlLabel>
                <FormControl
                  name="message"
                  componentClass="textarea"
                  placeholder="Опишите возникшую проблему"
                  style={{
                    resize: 'vertical',
                  }}
                  rows={6}
                  value={values.message}
                  onChange={handleChange}
                />
              </FormGroup>
            </FormBody>

            <ModalControls>
              <ModalButton
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={close}
              >
                Отмена
              </ModalButton>
              <ModalButton
                type="submit"
                bsStyle="success"
                style={{ marginLeft: 'auto' }}
                disabled={values.message.length === 0 || isSubmitting}
              >
                Отправить сообщение об ошибке
              </ModalButton>
            </ModalControls>
          </form>
        )}
      />
    </Modal>
  )
}
