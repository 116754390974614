import { Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from 'react'
import Modal from 'react-modal'
import { RangeDatePicker } from '../common/DatePicker'
import { callModal } from '../dynamic-modal'
import { getStyle } from '../dynamic-modal/helpers/get-style'
import { useCloseOnClickOutside } from '../dynamic-modal/helpers/use-close-on-click-outside'
import {
  ModalButton,
  ModalContent,
  ModalControls,
  ModalHR,
  ModalTitle,
} from '../common/ModalParts'

const Actions = {
  Enter: Symbol('enter'),
  Close: Symbol('close'),
}

export const EnterDateRangeModal = ({ isOpen, resolve, ownProps }) => {
  const modalRef = useRef()

  const refSetter = ref => value => {
    ref.current = value
  }

  useCloseOnClickOutside({
    ref: modalRef,
    onClose: () => resolve(Actions.Close),
  })

  return (
    <Modal
      isOpen={isOpen}
      contentRef={refSetter(modalRef)}
      style={getStyle({ width: 240, zIndex: 1000 })}
    >
      <Formik
        initialValues={{
          start: ownProps.date,
          end: ownProps.date,
        }}
        onSubmit={(values, actions) => {
          resolve(Actions.Enter, values)
          actions.setSubmitting(false)
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ModalTitle>Выберите период</ModalTitle>
              <ModalHR />
              <ModalContent>
                <RangeDatePicker
                  startLabel="Начало периода"
                  endLabel="Конец периода"
                  start={values.start}
                  end={values.end}
                  maxDate={moment()}
                  onStartChange={date => setFieldValue('start', date)}
                  onEndChange={date => setFieldValue('end', date)}
                />
              </ModalContent>
              <ModalHR />
              <ModalControls>
                <ModalButton
                  bsStyle="danger"
                  onClick={() => resolve(Actions.Close)}
                >
                  Отмена
                </ModalButton>
                <ModalButton
                  type="submit"
                  bsStyle="success"
                  style={{ marginLeft: 'auto' }}
                >
                  Скачать
                </ModalButton>
              </ModalControls>
            </form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export async function enterDateRange({ date }) {
  const { action, params } = await callModal(EnterDateRangeModal, { date })

  if (action === Actions.Close) return null

  const { start, end } = params
  return { start, end }
}
