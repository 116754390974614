import { CANCEL_SYMBOL } from './lib/constants'
import { callModal, modalTemplates } from '../../Components/dynamic-modal'
import { numbersREs } from '../helpers/regex/numbers'

export const getClientCash = async ({ requiredCash }) => {
  if (requiredCash === 0) return 0

  function number(value) {
    return Number(value.replace(',', '.'))
  }

  const { action, params } = await callModal(
    modalTemplates.prompt({
      title: 'Введите сумму',
      text: [
        `Сумма наличных к оплате: ${requiredCash}.`,
        'Введите сумму, полученную от клиента (до выдачи сдачи):',
      ],
      fieldName: 'Сумма до выдачи сдачи',
      initialValue: String(requiredCash),
      validators: [
        ...modalTemplates.prompt.defaultValidators,
        {
          fn: value => {
            return numbersREs.positiveWithDotOrComma.test(value)
          },
          message: 'Неверный формат',
        },
        {
          fn: value => {
            return number(value) >= requiredCash
          },
          message: `Сумма должна быть больше или равна ${requiredCash}`,
        },
      ],
    })
  )

  if (action !== modalTemplates.prompt.actions.enter) {
    return CANCEL_SYMBOL
  }

  const { value } = params

  return number(value)
}
