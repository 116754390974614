import {createSelector} from "reselect";

export const CLOSING = "closing"
export const OPENED = "opened"

export const everything = state => state.shifts

export const closeItems = (state) => everything(state).closeItems
export const openItems = (state) => everything(state).openItems
export const reviewItemsAll = (state) => everything(state).reviewItems

export const shiftId = (state) => everything(state).shiftId
export const reviewShiftId = (state) => everything(state).reviewShiftId

export const isShiftOpened = (state) => everything(state).status === OPENED

export const isChecklistsNotCompleted = (state) => everything(state).checklistsCompleted

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const reviewItems = createSelector(reviewItemsAll, items =>
    items.filter(({item}) => item.category === CLOSING)
)


export const openItemsObject = createSelector(openItems, items => items?.reduce((a, b) => {
        a[b.id] = b
        return a
    }, {})
)

export const closeItemsObject = createSelector(closeItems, items =>
    items?.reduce((a, b) => {
        a[b.id] = b
        return a
    }, {})
)

export const reviewItemsObject = createSelector(reviewItems, items =>
    items?.reduce((a, b) => {
        a[b.id] = b
        return a
    }, {})
)
