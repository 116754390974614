import React from 'react'
import CheckStringBlock from './components/CheckStringBlock'
import Name from './components/Name'
import Total from './components/Total'
import { rub } from '../../../../../../../../../../Modules/formatters/currency'

export default ({ name, total }) => (
  <CheckStringBlock>
    <Name>{name}</Name>
    <Total>{rub(total)}</Total>
  </CheckStringBlock>
)
