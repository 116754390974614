import { loyaltyPurchaseSelectors } from '../../../loyalty-purchase'

export function useLoyaltyHandler({ state }) {
  const isCalculated = loyaltyPurchaseSelectors.isCalculated(state)
  const isConfirmed = loyaltyPurchaseSelectors.isConfirmed(state)

  if (!isCalculated || !isConfirmed) {
    return false
  }

  return true
}
