import { currency } from './converters'

export const getItemPrice = item => {
  const { price } = item
  return Number(price)
}

export const getItemTotal = item => {
  const { price, quantity = 1 } = item
  return price * quantity
}

export const getItemData = item => {
  const { quantity = 1, discount = 0 } = item

  const price = getItemPrice(item)
  const total = getItemTotal(item)

  return {
    price,
    quantity,
    discount,
    total,
  }
}

export const getItemQtyPrice = item => {
  const { price, quantity = 1 } = item
  return price / quantity
}

export const getDiscountedItemPrice = item => {
  const { price, quantity = 1, discount = 0 } = item
  return price - discount / quantity
}

export const getDiscountedItemTotal = item => {
  const { price, quantity = 1, discount = 0 } = item
  return price * quantity - discount
}

export const getDiscountedItemData = item => {
  const { quantity = 1, discount = 0 } = item

  const price = getDiscountedItemPrice(item)
  const total = getDiscountedItemTotal(item)

  return {
    price,
    quantity,
    discount,
    total,
  }
}

export const getPrintedItemPrice = item => {
  const { price } = item
  return currency.toNumber(price)
}

export const getPrintedItemTotal = item => {
  const { quantity = 1 } = item
  const printedPrice = getPrintedItemPrice(item)
  const total = printedPrice * quantity
  return currency.toNumber(total)
}

export const getPrintedItemData = item => {
  const { quantity = 1, discount = 0 } = item

  const printPrice = getPrintedItemPrice(item)
  const printTotal = getPrintedItemTotal(item)

  return {
    price: currency.toString(printPrice),
    quantity,
    discount,
    total: currency.toString(printTotal),
  }
}

export const getPrintedDiscountedItemPrice = item => {
  const discountedPrice = getDiscountedItemPrice(item)
  return currency.toNumber(discountedPrice)
}

export const getPrintedItemQtyPrice = item => {
  const discountedPrice = getItemQtyPrice(item)
  return currency.toNumber(discountedPrice)
}

export const getPrintedDiscountedItemTotal = item => {
  const { quantity = 1 } = item
  const printedDiscountedPrice = getPrintedDiscountedItemPrice(item)
  const discountedTotal = printedDiscountedPrice * quantity
  return currency.toNumber(discountedTotal)
}

export const getPrintedDiscountedItemsPrice = item => {
  const printedDiscountedPrice = getDiscountedItemTotal(item)
  return currency.toNumber(printedDiscountedPrice)
}

export const getPrintedDiscountedItemData = item => {
  const { quantity = 1, discount = 0 } = item

  const printedDiscountedPrice = getPrintedDiscountedItemPrice(item)
  const printedDiscountedTotal = getPrintedDiscountedItemTotal(item)

  return {
    price: currency.toString(printedDiscountedPrice),
    quantity,
    discount,
    total: currency.toString(printedDiscountedTotal),
  }
}

export const getPrintedDiscountedDayItemData = item => {
  const { quantity = 1, discount = 0 } = item

  const printedDiscountedPrice =  item.price + item.discount
  const printedDiscountedTotal = item.price

  return {
    price: currency.toString(printedDiscountedPrice),
    quantity,
    discount,
    total: currency.toString(printedDiscountedTotal),
    totalNum: printedDiscountedTotal
  }
}

export const isItemDiscounted = item => {
  const { discount = 0 } = item
  return discount > 0
}

export const getPrintedDiscountedServicePrice = item => {
  const { price, discount, quantity } = item
  return currency.toString(((price * quantity) - discount) / quantity)
}
