import styled from 'styled-components'

export default styled.div`
  display: table;
  width: 100%;
  color: ${props => props.theme.colors.pageText};
  background-color: ${props => props.theme.colors.checksTableBlockBackground};
  border-radius: 2px;
  border-collapse: collapse;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.27);
`
