import { connect } from 'react-redux'
import CheckPrinterCP from './CheckPrinterCP'
import { specialServiceTypesSelectors } from '../../Modules/special-service-types'
import { sessionSelectors } from '../../Modules/session'
import { settingsSelectors } from '../../Modules/settings'

const mapStateToProps = state => ({
  lcode: state.session.lcode,
  livingService: specialServiceTypesSelectors.livingService(state),
  session: sessionSelectors.everything(state),
  settings: settingsSelectors.everything(state)
})

const mapDispatchToProps = () => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(CheckPrinterCP)
