import React, {Component, Fragment} from 'react'
import ButtonWithLoading from 'react-bootstrap-button-loader'
import {Button, Glyphicon} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Modal from 'react-modal'
import styled from 'styled-components'
import history from '../../Modules/helpers/history'
import {useSelector} from 'react-redux'
import {settingsSelectors} from '../../Modules/settings'
import {cardAPI} from '../../Modules/api/cardApi'
import {FaCheck} from "react-icons/fa";

const NavbarBlock = styled.div`
    width: 100%;
    height: 90px;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    user-select: none;
`

const NavbarContent = props => {
    const isWashingOperationsOn = useSelector(
        settingsSelectors.isWashingOperationsOn
    )

    switch (props.location) {
        case '/':
            return (
                <Fragment>
                    <NavbarLogo/>
                    <NavbarMenu>
                        <NavbarLink to="/wubook">
                            Бронирования
                        </NavbarLink>
                        <NavbarLink to="/search-by-name">Поиск</NavbarLink>
                        <NavbarLink to="/search-by-date">
                            Поиск по дате изменений
                        </NavbarLink>
                        <NavbarLink to="/search-by-start">
                            Поиск невозвратных по дате заезда
                        </NavbarLink>
                        <NavbarLink to="/no-show-search">
                            Поиск незаездов по дате
                        </NavbarLink>
                        <OpenCheckCardLink onClick={props.openCheckCardModal}>
                            Проверить карту
                        </OpenCheckCardLink>
                        <NavbarLink to="/my-services-sells">Мои продажи услуг</NavbarLink>
                        <NavbarLink to="/check-printer-cp">Кассовые операции</NavbarLink>
                        <NavbarLink to="/all-prepayeds">Предоплаты</NavbarLink>
                        <NavbarLink to="/bedclothes-change">Уборка</NavbarLink>
                        <NavbarLink to="/day-totals">Итоги дня</NavbarLink>
                        <NavbarLink to="/local-inventory">Товары</NavbarLink>
                        <NavbarLink to="/breakfast-actions">Завтраки</NavbarLink>
                        <NavbarLink to="/files">Файлы</NavbarLink>
                        <NavbarLink to="/blacklist">Черный список</NavbarLink>
                        {isWashingOperationsOn && (
                            <NavbarLink to="/free-washing">Стирка по паролю</NavbarLink>
                        )}
                        <NavbarLink to="/settings">Настройки</NavbarLink>
                        <NavbarLink to="/telephony">Телефония</NavbarLink>
                        <NavbarLink to="/trello">Тех. Отдел</NavbarLink>
                        <NavbarLink to="/knowledge_base">База знаний</NavbarLink>
                    </NavbarMenu>
                </Fragment>
            )

        case '/login':
            return <div/>

        case '/register':
            return <LeftActionLink to="/login" text="Войти в аккаунт" icon="log-in"/>

        default:
            return (
                <GoBackLink
                    onClick={() => {
                        if (history.action !== 'POP') {
                            history.goBack()
                        } else {
                            history.push('/')
                        }
                    }}
                    text="Назад"
                    icon="menu-left"
                />
            )
    }
}

const NavbarLogo = () => (
    <div
        style={{
            fontSize: 16,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: 10,
            marginLeft: -10,
        }}
    >
        <Glyphicon glyph="home" style={{height: 16}}/>
        <span
            style={{
                textTransform: 'uppercase',
                letterSpacing: 1,
                marginLeft: 12,
                height: 20,
            }}
        >
      GHotel
    </span>
    </div>
)

const NavbarLink = styled(Link)`
    padding: 3px;
    margin-left: 10px;
    color: #fff;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        text-decoration: none;
    }
`

const OpenCheckCardLink = styled.span`
    padding: 3px;
    margin-left: 10px;
    color: #fff;
    text-decoration: none;
    outline: none;
    cursor: pointer;
`

const LeftActionLink = props => (
    <Link
        to={props.to}
        style={{
            fontSize: 16,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: 15,
            marginLeft: -10,
            textDecoration: 'none',
            outline: 'none',
        }}
    >
        <Glyphicon glyph={props.icon} style={{height: 16}}/>
        <span
            style={{
                textTransform: 'uppercase',
                letterSpacing: 1,
                marginLeft: 12,
                height: 20,
            }}
        >
      {props.text}
    </span>
    </Link>
)

const StyledGoBackLink = styled.span`
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-left: -10px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
`

const GoBackLink = props => (
    <StyledGoBackLink {...props}>
        <Glyphicon glyph={props.icon} style={{height: 16}}/>
        <span
            style={{
                textTransform: 'uppercase',
                letterSpacing: 1,
                marginLeft: 12,
                height: 20,
            }}
        >
      {props.text}
    </span>
    </StyledGoBackLink>
)

const NavbarMenu = styled.div`
    font-size: 14px;
    margin-left: -10px;
    padding: 0 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const NavbarProfile = props => {
    document.title = props.lcode ? props.lcode.pretty_name_ru : 'ГОтель'
    if (!props.userName) return (
        <div
            style={{
                alignItems: 'center',
                color: '#fff',
                fontSize: 16,
                width: '100%',
                textAlign: 'right'
            }}>
            <Glyphicon glyph="home" style={{height: 16, marginRight: 15, display: 'inline'}}/>
            <span style={{height: 20, letterSpacing: 0.5, whiteSpace: 'nowrap'}}>
        ГОтель
      </span>
        </div>
    )
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                color: '#fff',
                fontSize: 16,
                marginRight: -10,
                justifyContent: 'end'
            }}
        >
            <div>
                <Glyphicon glyph="user" style={{height: 16, marginRight: 15}}/>
                <span style={{height: 20, letterSpacing: 0.5, whiteSpace: 'nowrap'}}>
        {props.userName}{props.isShiftOpened && <FaCheck style={{margin: "0 5px", color: "#12ab45"}}/>}
        </span>
                <br/>
                <Glyphicon glyph="home" style={{height: 16, marginRight: 15, display: 'inline'}}/>
                <span style={{height: 20, letterSpacing: 0.5, whiteSpace: 'nowrap'}}>
        {props.lcode ? props.lcode.pretty_name_ru : 'ГОтель'}
      </span>
            </div>
            <Glyphicon
                glyph="log-out"
                style={{height: 36, marginLeft: 30, padding: 10, cursor: 'pointer'}}
                onClick={() => props.getCloseShift(props.lcode.lcode)}
            />
        </div>
    )
}

const ModalTitle = styled.h2`
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 20px;
`

const ModalText = styled.p`
    margin: 0;
    padding: 15px 20px;
`

const ModalHR = styled.hr`
    margin: 0;
    border-color: #e2e2e2;
`

const ModalControls = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    padding-left: 5px;
`

const ModalButton = styled(Button)`
    margin-left: 10px;
`

const ModalButtonWithLoading = styled(ButtonWithLoading)`
    margin-left: 10px;
    padding-left: ${props => (props.loading ? 0 : '12px')};
`

const ModalError = styled.p`
    font-size: 12px;
    color: #d9534f;
    padding: 10px 20px;
    margin: 0;
    margin-top: -10px;
`

const CardInfoRow = props => (
    <span style={{display: 'block'}}>
    {props.name ? <b>{props.name}</b> : null} {props.children}
  </span>
)

const CardInfoModal = props => {
    if (!props.info) {
        return null
    }

    let modalText
    if (props.info.isEmpty) {
        modalText = <CardInfoRow>Чистая карта</CardInfoRow>
    } else if (props.info.roomNotFound) {
        modalText = <CardInfoRow>Комната не найдена</CardInfoRow>
    } else {
        modalText = (
            <Fragment>
                <CardInfoRow name="Комната:">
                    {props.info.roomID} {props.info.roomName}
                </CardInfoRow>
                <CardInfoRow name="Заселение:">
                    {props.info.checkInDate} в {props.info.checkInTime}
                </CardInfoRow>
                <CardInfoRow name="Выселение:">
                    {props.info.checkOutDate} в {props.info.checkOutTime}
                </CardInfoRow>
            </Fragment>
        )
    }

    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Карта прочитана</ModalTitle>
            <ModalHR/>
            <ModalText>{modalText}</ModalText>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    style={{marginLeft: 'auto'}}
                    onClick={() => {
                        props.hideModal()
                    }}
                >
                    Закрыть
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

class CheckCardModal extends Component {
    state = {
        fetching: false,
        error: null,
        isChecked: false,
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} style={{content: {width: 540}}}>
                <ModalTitle>Проверка карты</ModalTitle>
                <ModalHR/>
                <ModalText>Приложите карту</ModalText>
                {this.state.error ? <ModalError>{this.state.error}</ModalError> : null}
                <ModalHR/>
                <ModalControls>
                    <ModalButton
                        bsStyle="danger"
                        onClick={() => {
                            this.props.hideModal()
                        }}
                    >
                        {this.state.isChecked ? 'Завершить' : 'Отмена'}
                    </ModalButton>
                    <ModalButtonWithLoading
                        loading={this.state.fetching}
                        bsStyle="success"
                        onClick={() => {
                            const {doorLockType, apiData, lcode, openCardInfo} = this.props
                            this.setState({fetching: true, error: null})

                            cardAPI[doorLockType].checkCardCall({lcode, ...apiData,})
                                .then(({isSuccess, meta, error}) => {
                                    if (!isSuccess) {
                                        this.setState({error})
                                        return
                                    }
                                    openCardInfo(meta)
                                })
                                .catch(error => {
                                    this.setState({fetching: true, error: error.message})
                                })
                                .then(() => {
                                    this.setState({fetching: false, isChecked: true})
                                })
                        }}
                    >
                        {this.state.isChecked ? 'Проверить еще' : 'Проверить'}
                    </ModalButtonWithLoading>
                </ModalControls>
            </Modal>
        )
    }
}

class Navbar extends Component {
    state = {
        isCheckCardOpened: false,
        isCardInfoOpened: false,
        cardInfo: null,
    }


    openCheckCard = () => this.setState({isCheckCardOpened: true})
    closeCheckCard = () => this.setState({isCheckCardOpened: false})
    openCardInfo = cardInfo => this.setState({isCardInfoOpened: true, cardInfo})
    closeCardInfo = () =>
        this.setState({isCardInfoOpened: false, cardInfo: null})

    render() {
        return (
            <NavbarBlock className="global-navbar">
                <NavbarContent
                    location={this.props.location.pathname}
                    openCheckCardModal={this.openCheckCard}
                    printerIsOn={this.props.printerIsOn}
                />
                <NavbarProfile
                    userName={this.props.admin}
                    getCloseShift={this.props.getCloseShift}
                    isShiftOpened={this.props.isShiftOpened}
                    lcode={this.props.lcodeObj}
                />
                <CheckCardModal
                    isOpen={this.state.isCheckCardOpened}
                    hideModal={this.closeCheckCard}
                    openCardInfo={this.openCardInfo}
                    lcode={this.props.lcode}
                    apiData={this.props.apiData}
                    doorLockType={this.props.doorLockType}
                />
                <CardInfoModal
                    isOpen={this.state.isCardInfoOpened}
                    hideModal={this.closeCardInfo}
                    info={this.state.cardInfo}
                />
            </NavbarBlock>
        )
    }
}

export default Navbar
