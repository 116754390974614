import styled from 'styled-components'

const getColor = ({ btnStyle, theme }) => {
  switch (btnStyle) {
    case 'success':
      return theme.colors.darkGreen

    case 'warning':
      return theme.colors.darkOrange

    case 'danger':
      return theme.colors.darkRed

    case 'primary':
      return theme.colors.purple

    default:
      return theme.colors.purple
  }
}

export default styled.button`
  display: inline-block;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 8px 10px;
  cursor: pointer;
  background-color: ${props => getColor(props)};
  color: #fff;
  border: none;
  outline: none;
  border-radius: 2px;
`
