import React from 'react'
import Modal from 'react-modal'
import CheckString from './components/CheckString'
import zIndexes from '../../../../../../ui/theme/values/zIndexes'
import { ModalClose, ModalBody, ModalTitle } from './components/ModalParts'

const modalStyles = {
  content: {
    outline: 0,
    backgroundColor: '#fff',
    width: 500,
    position: 'relative',
    margin: 'auto',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: 100,
    zIndex: zIndexes.modal,
    display: 'flex',
    overflowY: 'scroll',
  },
}

const DetailsModal = ({ isOpen, close, positions }) => {
  const strings = positions.split('@').map((pos, idx) => {
    const [name, quantity, total] = pos.split('~')

    const finalName = `${name} (${quantity} шт)`

    return <CheckString key={idx} name={finalName} total={total} />
  })

  return (
    <Modal isOpen={isOpen} defaultStyles={modalStyles} onRequestClose={close}>
      <ModalClose onClick={close} />
      <ModalBody>
        <ModalTitle>Строки чека</ModalTitle>
        {strings}
      </ModalBody>
    </Modal>
  )
}

export default DetailsModal
