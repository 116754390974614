import React from 'react'
import styled from 'styled-components'
import { FaBug } from 'react-icons/fa'

const StyledButton = styled.button`
  width: 100%;
  padding: 6px 12px;
  border: none;
  outline: none;
  background-color: #ed143d;
  color: #ffffff;

  transition: background-color 0.3s;

  &:hover {
    background-color: #d61b3e;
  }
`

export const ButtonView = ({ open }) => {
  return (
    <StyledButton onClick={open}>
      <FaBug style={{ fontSize: 16, marginRight: 5, marginBottom: -3 }} />
      Сообщить об ошибке
    </StyledButton>
  )
}
