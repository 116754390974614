import styled from 'styled-components'

export default styled.div`
  display: table-row;
  border-bottom: 1px solid ${props => props.theme.colors.checksTableRowBorder};
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};

  &:last-child {
    border-bottom: none;
  }
`
