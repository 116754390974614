import transactionsRequest from './transactionsRequest'

export const getTransactionsCall = ({ lcode, date }) =>
  transactionsRequest({
    url: `/auth/list/`,
    method: 'get',
    withToken: true,
    params: { lcode, date },
  })

export const getTransactionsWithoutAuthCall = ({ lcode, date }) =>
  transactionsRequest({
    url: `/list/`,
    method: 'get',
    withToken: true,
    params: { lcode, date },
  })

export const confirmTransactionCall = ({ rrn }) =>
  transactionsRequest({
    url: `/auth/confirm/`,
    method: 'get',
    withToken: true,
    params: { rrn },
  })

export const cancelTransactionCall = ({ rrn }) =>
  transactionsRequest({
    url: `/auth/cancel/`,
    method: 'get',
    withToken: true,
    params: { rrn },
  })

export const returnPaymentByPaymentCardCall = ({ rrn }) =>
  transactionsRequest({
    url: `/refund/`,
    method: 'get',
    withToken: true,
    params: { rrn },
  })

export * from './localAPI/requests'
