import * as types from './types'
import {getCleaningList, getRoomsCall} from '../api/ccAPI/requests'

const loadDataRequest = () => ({
    type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = ({schedule, leavedRooms, otherRooms}) => ({
    type: types.LOAD_DATA_SUCCESS,
    payload: {
        schedule,
        leavedRooms,
        otherRooms,
    },
})

const loadDataFailure = () => ({
    type: types.LOAD_DATA_FAILURE,
})

export const loadData = (date) => async (dispatch) => {
    dispatch(loadDataRequest())

    try {
        const {scheduled: schedule, checkout: leavedRooms, others: otherRooms} = await getCleaningList(date)

        dispatch(loadDataSuccess({schedule, leavedRooms, otherRooms}))

        dispatch(loadRoomsList())
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

const loadPrintDataRequest = () => ({
    type: types.LOAD_PRINT_DATA_REQUEST,
})

const loadPrintDataSuccess = ({roomsList}) => ({
    type: types.LOAD_PRINT_DATA_SUCCESS,
    payload: {
        roomsList,
    },
})

const loadRoomsList = () => async (dispatch, getState) => {
    const state = getState()
    const {isRoomsListLoaded} = state.bedclothesChange
    if (isRoomsListLoaded) return
    const {lcode} = state.session

    dispatch(loadPrintDataRequest())

    try {
        const roomsList = await getRoomsCall(lcode)
        roomsList.sort((a, b) => +a.room_id - +b.room_id)

        dispatch(
            loadPrintDataSuccess({
                roomsList,
            })
        )
    } catch (err) {
        // error
    }
}

export const reset = () => ({
    type: types.RESET,
})
