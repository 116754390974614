import { connect } from 'react-redux'
import { modalsSelectors, modalsActions } from '../../../../Modules/modals'
import { ModalView } from './ModalView'
import { settingsSelectors } from '../../../../Modules/settings'

const mSTP = state => ({
  isOpen: modalsSelectors.isOpen(state, 'debug-modal'),
  settings: settingsSelectors.everything(state)
})

const mDTP = dispatch => ({
  close: () => dispatch(modalsActions.close('debug-modal')),
})

export const Modal = connect(mSTP, mDTP)(ModalView)
