import { closePaymentMethodErrorsModal } from '../../../../Modules/reservation/ReservationModule'
import { connect } from 'react-redux'
import { PaymentMethodErrorsModal } from './PaymentMethodErrorsModal'

const mSTP = state => ({
  isOpen: state.reservation.isPaymentMethodErrorsModalOpen,
})

const mDTP = dispatch => ({
  close: () => dispatch(closePaymentMethodErrorsModal()),
})

export const ConnectedPaymentMethodErrorsModal = connect(
  mSTP,
  mDTP
)(PaymentMethodErrorsModal)
