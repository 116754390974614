// Libraries
import moment from 'moment'

// Constants
import paymentTypes from '../../helpers/paymentTypes'

export default item => {
  const { payment_type, payment_date } = item

  // We should print only items which was paid today and by a cash
  const paymentTypeCondition = payment_type === paymentTypes.cash
  const paymentDateCondition = payment_date === moment().format('YYYY-MM-DD')

  return paymentTypeCondition && paymentDateCondition
}
