import styled from 'styled-components'

export const PageBase = styled.div`
  width: 210mm;
  min-height: 296mm;

  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
  margin: auto;
`
