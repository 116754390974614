import axios from 'axios'

const HOST = '127.0.0.1'
const PORT = 5893

const USER = 'Admin'
const PASS = ''

const Authorization = 'Basic ' + window.btoa(USER + ':' + PASS)

export const request = axios.create({
  baseURL: `http://${HOST}:${PORT}`,
  headers: {
    Authorization,
  },
})
