import React from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/ru'
import { day } from '../../../../Modules/formatters/date'
import { ItemPrice } from '../../../common/ItemPrice'

moment.locale('ru')

const StyledCol = props => {
  return (
    <Col {...props} style={{ fontSize: 18, marginBottom: 15 }}>
      {props.children}
    </Col>
  )
}

const PayedDay = props => {
  return (
    <Grid fluid={true}>
      <Row>
        <StyledCol xs={3}>{day(props.day.date)}</StyledCol>
        <StyledCol xs={3}>
          <ItemPrice item={props.day} />
        </StyledCol>
        <StyledCol xs={3}>{props.method}</StyledCol>
        {props.modes.refund && props.isLast && (
          <StyledCol xs={3}>
            <Button
              bsStyle="danger"
              bsSize="small"
              onClick={() => props.dayRefund(props.day.id)}
              style={{ marginTop: -3 }}
            >
              Возврат
            </Button>
          </StyledCol>
        )}
      </Row>
    </Grid>
  )
}

export default PayedDay
