import * as types from './types'
import { getTasksCall, completeTaskCall } from '../../../../Modules/api'

const loadDataRequest = () => ({
  type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = ({ items }) => ({
  type: types.LOAD_DATA_SUCCESS,
  payload: {
    items,
  },
})

const loadDataFailure = () => ({
  type: types.LOAD_DATA_FAILURE,
})

export const loadData = () => async (dispatch, getState) => {
  const state = getState()
  const adminId = state.session.adminID

  dispatch(loadDataRequest())

  try {
    const items = await getTasksCall(adminId)

    dispatch(loadDataSuccess({ items }))
  } catch (err) {
    dispatch(loadDataFailure())
  }
}

const completeTaskRequest = pk => ({
  type: types.COMPLETE_TASK_REQUEST,
  payload: {
    pk,
  },
})

const completeTaskSuccess = pk => ({
  type: types.COMPLETE_TASK_SUCCESS,
  payload: {
    pk,
  },
})

const completeTaskFailure = pk => ({
  type: types.COMPLETE_TASK_FAILURE,
  payload: {
    pk,
  },
})

export const completeTask = task => async dispatch => {
  dispatch(completeTaskRequest(task.pk))

  try {
    await completeTaskCall(task)

    dispatch(completeTaskSuccess(task.pk))
  } catch (err) {
    dispatch(completeTaskFailure(task.pk))
  }
}

export const reset = () => ({
  type: types.RESET,
})
