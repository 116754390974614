// Class
import CheckPrintController from './index'

const isServerRunning = async () => {
  try {
    await CheckPrintController.getKKTState()
  } catch (err) {
    return false
  }
  return true
}

export default isServerRunning
