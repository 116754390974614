import * as types from './types'
import * as selectors from './selectors'
import {
  getAllReservationsByBookingNumberNotCanceled, getPrepaidByDate,
} from '../../../Modules/api/ccAPI/requests'

const loadPrepayedsRequest = () => ({
  type: types.LOAD_PREPAYEDS_REQUEST,
})

const loadPrepayedsSuccess = prepayeds => ({
  type: types.LOAD_PREPAYEDS_SUCCESS,
  payload: {
    prepayeds,
  },
})

const loadPrepayedsFailure = () => ({
  type: types.LOAD_PREPAYEDS_FAILURE,
})

const loadReservationsDataRequest = () => ({
  type: types.LOAD_RESERVATIONS_DATA_REQUEST,
})

const loadReservationsDataSuccess = reservationsData => ({
  type: types.LOAD_RESERVATIONS_DATA_SUCCESS,
  payload: {
    reservationsData,
  },
})

const loadReservationsDataFailure = () => ({
  type: types.LOAD_RESERVATIONS_DATA_FAILURE,
})

export const loadPrepayeds = ({ date, page = 1, lcode = null }) => async dispatch => {
  dispatch(loadPrepayedsRequest())

  try {
    const prepayeds = await getPrepaidByDate({ date, page, lcode })

    dispatch(loadPrepayedsSuccess(prepayeds))
  } catch (err) {
    dispatch(loadPrepayedsFailure())
  }
}

export const loadReservations = () => async (dispatch, getState) => {
  const state = getState()

  const prepayeds = selectors.prepayeds(state)
  const isPrepayedsLoaded = selectors.isPrepayedsLoaded(state)

  if (!isPrepayedsLoaded) {
    return
  }

  dispatch(loadReservationsDataRequest())

  const promises = prepayeds.map(async item => {
    const { booking_number } = item
    const reservations = (await getAllReservationsByBookingNumberNotCanceled(booking_number))

    return {
      booking_number,
      reservations,
    }
  })

  try {
    const reservationsData = await Promise.all(promises)

    dispatch(loadReservationsDataSuccess(reservationsData))
  } catch (err) {
    dispatch(loadReservationsDataFailure())
  }
}

export const loadData = ({ date, page, lcode }) => async dispatch => {
  await dispatch(loadPrepayeds({ date, page, lcode }))
  dispatch(loadReservations())
}
