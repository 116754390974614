import CheckPrintController from '../CheckPrintController'

export default async slip => {
  if (slip) {
    await CheckPrintController.printSlip(
      slip.split('\r\n').map(s => ({
        PrintText: { Text: s },
      }))
    )
  }
}
