// Constants
import paymentTypes from '../../helpers/paymentTypes'

// Main functions
import getItems from './getItems'

// Helpers
import checkPaymentType from './checkPaymentType'

// checkMode used just to return some values to check should we print the check or not
// if checkMode is true, we don't perform any actions with user (input client cash amount for example)
const getValues = (
  reservation,
  checkMode,
  modes = {},
  printScopes = { days: true, services: true }
) => {
  const { days, services } = getItems(reservation)

  // Values to return
  let cashSumm = 0,
    cardSumm = 0,
    payedByCash = false,
    payedByCard = false

  const daysCondition = printScopes.days
  const servicesCondition = printScopes.services

  const usedDays = []
  // Process days to count cash and card summs
  if (daysCondition) {
    days.forEach(d => {
      usedDays.push(d)

      const price = parseFloat(parseFloat(d.price).toFixed(2))

      if (d.payment_date) {
        switch (d.payment_type) {
          case paymentTypes.cash:
            cashSumm += price
            break
          case paymentTypes.card:
            cardSumm += price
            break
          default:
            break
        }
      }
    })
  }

  const usedServices = []
  // Process services to count cash and card summs
  if (servicesCondition) {
    services.forEach(s => {
      usedServices.push(s)

      const price = parseFloat(parseFloat(s.price).toFixed(2))
      const quantity = parseInt(s.quantity, 10)

      if (s.payment_date) {
        switch (s.payment_type) {
          case paymentTypes.cash:
            cashSumm += price * quantity
            break
          case paymentTypes.card:
            cardSumm += price * quantity
            break
          default:
            break
        }
      }
    })
  }

  // Determine the payment method used by client
  // Set payedBeCard to true if it's a card payment
  // And input the cash summ if it's a cash payment
  if (checkPaymentType([usedDays, usedServices], paymentTypes.card)) {
    payedByCard = true
  }

  if (checkPaymentType([usedDays, usedServices], paymentTypes.cash)) {
    payedByCash = true
  }

  // Format summs to float numbers with 2-digit fraction
  // (to avoid JS float fraction issues)
  cashSumm = cashSumm.toFixed(2)
  cardSumm = cardSumm.toFixed(2)

  return {
    cashSumm,
    cardSumm,
    payedByCash,
    payedByCard,
  }
}

export default getValues
