export const symbols = {
  saved: Symbol('Saved'),
  canceled: Symbol('Canceled'),
  deleted: Symbol('Deleted'),
  errorWhileDeleting: Symbol('Error while deleting'),
}


export const breakfast_disabled_dates = [
    "2024-08-12",
    "2024-08-13"
]
