import React from 'react'
import { Button, ButtonGroup } from '../../../buttons'

export const Footer = ({ checkIn, checkOut, actions }) => {
  return (
    <ButtonGroup align="right">
      {!checkIn && (
        <Button type="success" onClick={actions.checkIn.create}>
          Изменить время заезда
        </Button>
      )}
      {!checkOut && (
        <Button type="success" onClick={actions.checkOut.create}>
          Изменить время выезда
        </Button>
      )}
    </ButtonGroup>
  )
}
