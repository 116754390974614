import React, { useEffect, useRef, useState } from 'react'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import PureDatePicker from 'react-datepicker'
import moment from 'moment'
import { ccAPI } from '../../Modules/api/ccAPI'
import { callAlert, callPrompt } from '../dynamic-modal'

function normalizeInner(value) {
  return value ? new Date(value) : null
}

function normalizeOuter(value) {
  return value ? moment(value).format('YYYY-MM-DD') : null
}

export const SingleDatePicker = ({
  label,
  value,
  onChange,
  disabled,
  minDate,
  maxDate,
  isClearable,
  containerStyle = {},
  getFormatted= true,
}) => {
  const [localValue, setLocalValue] = useState(normalizeInner(value))
  const dataRef = useRef({ updatedFromOutside: false })

  useEffect(() => {
    setLocalValue(normalizeInner(value))
    dataRef.current.updatedFromOutside = true
  }, [value])

  useEffect(() => {
    if (!isClearable && dataRef.current.updatedFromOutside) {
      dataRef.current.updatedFromOutside = false
      return
    }

    onChange(getFormatted ? normalizeOuter(localValue) : localValue)
    // eslint-disable-next-line
  }, [localValue]);

  return (
    <>
      <FormGroup style={{ width: '100%', maxWidth: 240, ...containerStyle }}>
        {label && <ControlLabel>{label}</ControlLabel>}
        <PureDatePicker
          selected={localValue}
          minDate={minDate}
          maxDate={maxDate}
          onChange={setLocalValue}
          onBlur={() => {
            if (moment(localValue).isSame(value, 'day')) return
            onChange(normalizeOuter(localValue))
          }}
          disabled={disabled}
          dateFormat="dd.MM.yyyy"
          locale="ru"
          isClearable={isClearable}
          disabledKeyboardNavigation
        />
      </FormGroup>
    </>
  )
}

function subtractDay(date) {
  const copy = new Date(date)
  copy.setDate(copy.getDate() - 1)
  return copy
}

export const RangeDatePicker = ({
  startLabel,
  endLabel,
  start,
  end,
  endMinDate,
  maxDate,
  onStartChange,
  onEndChange,
  startDisabled,
  endDisabled,
  canBeSame = false,
  startEditByPassword = false,
  endEditByPassword = false,
  startMinDate,
}) => {
  const [startMinDateLocal] = useState(
    startMinDate ? startMinDate : null
  )

  const [currentEnd] = useState(new Date(end))

  const changeStartDate = async date => {
    date = moment(date).format('YYYY-MM-DD')
    if (startEditByPassword) {
      callPrompt({
        title: 'Проверка',
        text: [
          'Введите пароль для смены даты',
          'Запросите его у админ. директора',
        ],
      }).then(async password => {
        if (password == null) {
          return
        }
        await ccAPI
          .getChangeDatePassword(password)
          .then(() => {
            onStartChange(date)
          })
          .catch(() => {
            callAlert({
              title: 'Ошибка',
              text: 'Неправильный пароль',
            })
          })
      })
    } else {
      onStartChange(date)
    }
  }

  const changeEndDate = async date => {
    date = moment(date).format('YYYY-MM-DD')
    const pass = endEditByPassword
      ? moment(currentEnd).isAfter(date)
        ? endEditByPassword[0]
        : endEditByPassword[1]
      : false
    if (pass) {
      const correctPassword = await ccAPI.getChangeDatePassword()
      callPrompt({
        title: 'Проверка',
        text: [
          'Введите пароль для смены даты',
          'Запросите его у админ. директора',
        ],
      }).then(res => {
        if (res == null) {
          return
        }
        if (res !== correctPassword) {
          callAlert({
            title: 'Ошибка',
            text: 'Неправильный пароль',
          })
          return
        }
        onEndChange(date)
      })
    } else {
      onEndChange(date)
    }
  }

  const [localStart, setLocalStart] = useState(new Date(start))
  const [localEnd, setLocalEnd] = useState(new Date(end))
  const [endMinDateLocal, setEndMinDateLocal] = useState(new Date(endMinDate))

  useEffect(() => {
    setLocalStart(new Date(start))
    setLocalEnd(new Date(end))
    setStartMaxDate(new Date(subtractDay(end)))
    setEndMinDateLocal(endMinDate)
  }, [end, start])

  const [startMaxDate, setStartMaxDate] = useState(
    canBeSame ? localEnd : subtractDay(localEnd)
  )
  return (
    <>
      <FormGroup style={{ width: '100%', maxWidth: 240 }}>
        {startLabel && <ControlLabel>{startLabel}</ControlLabel>}
        <PureDatePicker
          selected={localStart}
          startDate={localStart}
          endDate={localEnd}
          minDate={startMinDateLocal}
          maxDate={startMaxDate}
          onChange={changeStartDate}
          disabled={startDisabled}
          dateFormat="dd.MM.yyyy"
          locale="ru"
          className="range-date-picker-start"
          disabledKeyboardNavigation
        />
      </FormGroup>
      <FormGroup style={{ width: '100%', maxWidth: 240 }}>
        {endLabel && <ControlLabel>{endLabel}</ControlLabel>}
        <PureDatePicker
          selected={localEnd}
          startDate={localStart}
          endDate={localEnd}
          minDate={endMinDateLocal}
          maxDate={maxDate}
          onChange={changeEndDate}
          disabled={endDisabled}
          dateFormat="dd.MM.yyyy"
          locale="ru"
          disabledKeyboardNavigation
        />
      </FormGroup>
    </>
  )
}
