import { gRequest } from './requests'
import { request } from '../../../../Modules/api/ccAPI/request'

/**
 * Get checks from server
 * @param {object} data
 * @param {string} data.lcode - Hotel lcode
 * @param {string} data.dateStart - Date in YYYY-MM-DD format
 * @param {string} data.dateEnd - Date in YYYY-MM-DD format
 * @returns {promise} API response
 */
export const getChecks = ({ lcode, dateStart, dateEnd }) =>
  request({
    url: '/check/find/',
    method: 'get',
    params: {
      lcode,
      date: dateStart,
      until_date: dateEnd,
    },
    withToken: true,
  })

/**
 * Get lcodes from server
 * @returns {promise} API response
 */
export const getLcodes = () =>
  gRequest({
    url: '/lcodes/',
    method: 'get',
  })

/**
 * Get refunded transactions
 * @param {object} data
 * @param {string} data.lcode - Hotel lcode
 * @param {string} data.dateStart - Date in YYYY-MM-DD format
 * @param {string} data.dateEnd - Date in YYYY-MM-DD format
 * @returns {promise} API response
 */
export const getRefunds = ({ lcode, dateStart, dateEnd }) =>
  request({
    url: '/transactions/list/',
    method: 'get',
    params: {
      lcode,
      date: dateStart,
      until_date: dateEnd,
      refunded: true,
    },
    withToken: true,
  })
