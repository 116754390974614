import { useState, useEffect } from 'react'

export function useSource(request, options = {}) {
  const { isManual = false, initialValue = null } = options

  const [data, setData] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [failed, setFailed] = useState(false)

  const fetchData = async (...params) => {
    setLoading(true)

    try {
      const data = await request(...params)
      setData(data)
      setLoaded(true)
    } catch {
      setFailed(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (isManual) return
    fetchData()
    // eslint-disable-next-line
  }, [])

  const startFetching = (...params) => fetchData(...params)

  return {
    startFetching,
    data,
    loading,
    loaded,
    failed,
  }
}
