import * as types from './types'

const initialState = {
  schedule: null,
  leavedRooms: null,
  otherRooms: null,

  isLoading: false,
  isLoaded: false,
  isFailed: false,

  printData: {
    roomsList: [],
    bedLinen: [
      'Полотенца большие',
      'Наволочки',
      'Пододеяльники',
      'Простыни маленькие',
      'Простыни большие',
      'Наматрасник',
      // 'Подушка',
      // 'Одеяло',
    ].map(name => ({
      name,
    })),
  },
  isPrintDataLoading: false,
  isPrintDataLoaded: false,
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_DATA_REQUEST: {
      return {
        ...state,
        schedule: null,
        leavedRooms: null,
        otherRooms: null,

        isLoading: true,
        isLoaded: false,
        isFailed: false,
      }
    }

    case types.LOAD_DATA_SUCCESS: {
      return {
        ...state,
        schedule: payload.schedule,
        leavedRooms: payload.leavedRooms,
        otherRooms: payload.otherRooms,

        isLoading: false,
        isLoaded: true,
      }
    }

    case types.LOAD_DATA_FAILURE: {
      return {
        ...state,

        isLoading: false,
        isFailed: true,
      }
    }

    case types.LOAD_PRINT_DATA_REQUEST: {
      return {
        ...state,
        printData: initialState.printData,
        isPrintDataLoading: true,
        isPrintDataLoaded: false,
      }
    }

    case types.LOAD_PRINT_DATA_SUCCESS: {
      return {
        ...state,
        printData: {
          roomsList: payload.roomsList,
          bedLinen: state.printData.bedLinen,
        },
        isPrintDataLoading: false,
        isPrintDataLoaded: true,
      }
    }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
