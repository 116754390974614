export const getCalculatedItems = ({ items, txid, calculatedRowsMap }) => {
  const { days, services } = items

  const calculatedDays = days.map(day => {
    const row = calculatedRowsMap[day.id]

    if (!row) return day

    return {
      ...day,
      discount: row.totalDiscount,
      price: day.price - row.totalDiscount,
      refund_id: txid,
    }
  })

  const calculatedServices = services.map(service => {
    const row = calculatedRowsMap[service.id]

    if (!row) return service

    return {
      ...service,
      discount: row.totalDiscount,
      refund_id: txid,
    }
  })

  return {
    days: calculatedDays,
    services: calculatedServices,
  }
}
