import React from 'react'
import DayPickerStyles from './DayPickerStyles'
import ReactModalStyles from './ReactModalStyles'

const AllStyles = () => (
  <React.Fragment>
    <DayPickerStyles />
    <ReactModalStyles />
  </React.Fragment>
)

export default AllStyles
