import { getBasicCheckData } from '../../lib/get-basic-check-data'
import { getItems } from './helpers/get-items'
import { getTotals } from './helpers/get-totals'
import { getCheckData } from './helpers/get-check-data'
import { getMeta } from './helpers/get-meta'
import { getPrintedItems } from './helpers/get-printed-items'

export const getPrintData = async ({
  state,
  reservation,
  printScopes,
  isPrinterOn,
  sendReceiptToEmail,
}) => {
  const basicCheckData = getBasicCheckData(state)
  if (sendReceiptToEmail) {
    basicCheckData.ClientAddress = reservation.guest_mail
    basicCheckData.NotPrint = true
  }

  const items = getItems(reservation)

  const printedItems = getPrintedItems({
    items,
    printScopes,
  })
  const {
    shouldPrint,
    wasCanceled,
    totals: checkTotals,
    printObject,
  } = await getCheckData({
    basicData: basicCheckData,
    printedItems,
    isPrinterOn,
  })

  const totals = getTotals(items)
  const meta = getMeta(items)

  return {
    checkType: 'sell',
    isPrinterOn,
    shouldPrint,
    checkTotals,
    printObject,
    wasCanceled,
    state,
    reservation,
    items,
    printedItems,
    totals,
    meta,
  }
}
