export const LOAD_PREPAYEDS_REQUEST =
  'ghotel/all-prepayeds/LOAD_PREPAYEDS_REQUEST'
export const LOAD_PREPAYEDS_SUCCESS =
  'ghotel/all-prepayeds/LOAD_PREPAYEDS_SUCCESS'
export const LOAD_PREPAYEDS_FAILURE =
  'ghotel/all-prepayeds/LOAD_PREPAYEDS_FAILURE'

export const LOAD_RESERVATIONS_DATA_REQUEST =
  'ghotel/all-prepayeds/LOAD_RESERVATIONS_DATA_REQUEST'
export const LOAD_RESERVATIONS_DATA_SUCCESS =
  'ghotel/all-prepayeds/LOAD_RESERVATIONS_DATA_SUCCESS'
export const LOAD_RESERVATIONS_DATA_FAILURE =
  'ghotel/all-prepayeds/LOAD_RESERVATIONS_DATA_FAILURE'

export const RESET = 'ghotel/all-prepayeds/RESET'
