export function datePeriodEnd({
  dateStartField,
  message = 'Конец периода должен быть позже или равен началу периода',
  checkTime = false,
}) {
  return {
    fn: (value, values) => {
      const dateStart = values[dateStartField]

      if (!value || !dateStart) {
        return true
      }

      const minDate = new Date(dateStart)
      const maxDate = new Date(value)

      if (!checkTime) {
        minDate.setHours(0, 0, 0, 0)
        maxDate.setHours(0, 0, 0, 0)
      }

      return maxDate >= minDate
    },
    message,
  }
}
