import { connect } from 'react-redux'
import { modalsSelectors, modalsActions } from '../../../../Modules/modals'
import { ModalView } from './ModalView'

const mSTP = state => ({
  isOpen: modalsSelectors.isOpen(state, 'bug-report'),
})

const mDTP = dispatch => ({
  close: () => dispatch(modalsActions.close('bug-report')),
})

export const Modal = connect(mSTP, mDTP)(ModalView)
