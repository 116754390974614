import * as types from './types'
import { ccAPI } from '../../../../Modules/api/ccAPI'

const loadRequest = () => ({
  type: types.LOAD_CANCELED_WARNINGS_REQUEST,
})

const loadSuccess = data => ({
  type: types.LOAD_CANCELED_WARNINGS_SUCCESS,
  payload: {
    data,
  },
})

const loadFailure = () => ({
  type: types.LOAD_CANCELED_WARNINGS_FAILURE,
})


export const loadData = () => async (dispatch) => {
  dispatch(loadRequest())

  try {
    const data = await ccAPI.getCanceledWarningsReservations()

    dispatch(loadSuccess(data))
  } catch (err) {
    dispatch(loadFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
