import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import TransactionConfirmModal from './TransactionConfirmModal'

const Row = styled.tr`
  cursor: pointer;
`

const Cell = styled.td`
  vertical-align: middle;
  padding: 16px 8px;
  width: ${props => props.width}px;
  text-align: ${props => props.textAlign};
`

const HeaderRow = () => (
  <tr>
    <th>Дата</th>
    <th>Время</th>
    <th>Сумма транзакции</th>
  </tr>
)

const TransactionRow = ({
  data,
  updateResult,
  transactionClickAction,
  withModal,
  openModal,
}) => {
  const defaultClickAction = async additionalProps => {
    const shouldUpdate = await transactionClickAction(
      {
        rrn: data.rrn.toString(),
        authCode: data.authCode.toString(),
        total:
          (data.total / 100) % 1 !== 0
            ? (data.total / 100).toFixed(2)
            : (data.total / 100).toFixed(0),
      },
      additionalProps
    )

    if (shouldUpdate) updateResult()
  }

  const clickAction = withModal
    ? () => openModal(defaultClickAction)
    : defaultClickAction

  return (
    <Row onClick={clickAction}>
      <Cell width={100}>{moment(data.date).format('DD MMMM YYYY')}</Cell>
      <Cell width={60}>{moment(data.date).format('HH:mm')}</Cell>
      <Cell width={300}>{data.total / 100} рублей</Cell>
    </Row>
  )
}

const TypeHint = styled.span`
  background-color: #2caf41;
  text-align: center;
  padding: 15px;
  display: block;
  color: #fff;
`

const Error = styled.span`
  background-color: #e64646;
  color: #fff;
  text-align: center;
  padding: 15px;
  display: block;
`

class ResultsTable extends Component {
  state = {
    isModalOpened: false,
    submitAction: null,
  }

  openModal = submitAction =>
    this.setState({
      isModalOpened: true,
      submitAction,
    })
  closeModal = () =>
    this.setState({
      isModalOpened: false,
      submitAction: null,
    })

  render() {
    const {
      items,
      updateResult,
      transactionClickAction,
      withModal,
      lcode,
    } = this.props

    if (!items) {
      return <TypeHint>Выберите дату для поиска</TypeHint>
    }

    if (!items.length) {
      return <Error>Транзакций за эту дату не найдено</Error>
    }

    const sortedByDate = items.sort((a, b) => moment(a.date) - moment(b.date))

    const rows = sortedByDate.map(t => {
      return (
        <TransactionRow
          data={t}
          updateResult={updateResult}
          transactionClickAction={transactionClickAction}
          withModal={withModal}
          openModal={this.openModal}
          key={t.rrn}
        />
      )
    })

    return (
      <Fragment>
        <Table
          responsive
          className="table-hover condensed"
          style={{ width: '100%' }}
        >
          <thead>
            <HeaderRow />
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <TransactionConfirmModal
          title="Продажа наличными"
          isOpen={this.state.isModalOpened}
          close={this.closeModal}
          submitAction={this.state.submitAction}
          lcode={lcode}
          date={moment().format('YYYY-MM-DDTHH:mm:ss')}
        />
      </Fragment>
    )
  }
}

export default ResultsTable
