import styled from 'styled-components'

export default styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`
