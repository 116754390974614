import React, { useState } from 'react'
import { Item } from './Item'
import { callModal, modalTemplates } from '../../../../dynamic-modal'
import { useSelector } from 'react-redux'
import { specialServiceTypesSelectors } from '../../../../../Modules/special-service-types'
import styled from 'styled-components'

const Ul = styled.ul`
  padding-inline-start: 10px;
  margin-bottom: 5px;
`
const Link = styled.a`
  color: #da4e4e;
  border-bottom: 1px dashed #da4e4e;
  padding-bottom: 2px;

  &:hover {
    cursor: pointer;
    color: #da4e4e;
    text-decoration: none;
    border-bottom: 1px dashed #da4e4e;
  }
`

export const Content = ({
                          checkIn,
                          checkOut,
                          checkInTime,
                          checkOutTime,
                          validationErrors,
                          actions,
                          availableActions,
                        }) => {
  const [hasConfirmedChange, setConfirmedChange] = useState(false)
  const earlyCheckinService = useSelector(specialServiceTypesSelectors.earlyCheckinService)
  const lateCheckoutService = useSelector(specialServiceTypesSelectors.lateCheckoutService)

  const handlePriceFocus = async event => {
    if (hasConfirmedChange) return
    event.persist()
    event.target.blur()

    const { action } = await callModal(
      modalTemplates.confirm({
        text: [
          'Ранний заезд до 14:00 тарифицируется из расчета 1/2 стоимости первых суток, и считается автоматически. Заезд ранее 8:00 тарифицируется из расчета полной стоимости первых суток.',
          'Поздний заезд до 18:00 тарифицируется из расчета 1/2 стоимости последних суток, и считается автоматически. Выезд после 18:00 тарифицируется из расчета полной стоимости последних суток.',
          'Вы уверены, что хотите изменить сумму вручную?',
        ],
      })
    )

    if (action === modalTemplates.confirm.actions.confirm) {
      setConfirmedChange(true)
      event.target.focus()
    }
  }

  return (
    <React.Fragment>
      {checkIn && (
        <Item
          data={checkIn}
          time={checkInTime}
          validationErrors={validationErrors[checkIn.id] || []}
          actions={actions.checkIn}
          availableActions={availableActions}
          handlePriceFocus={handlePriceFocus}
          service={earlyCheckinService}
          notice={noticeCheckin}
        />
      )}
      {checkOut && (
        <Item
          data={checkOut}
          time={checkOutTime}
          validationErrors={validationErrors[checkOut.id] || {}}
          actions={actions.checkOut}
          availableActions={availableActions}
          handlePriceFocus={handlePriceFocus}
          service={lateCheckoutService}
          notice={noticeCheckout}
        />
      )}
    </React.Fragment>
  )
}

const link = (<Link
  target="_blank"
  href="https://wf-manuals.teamly.ru/space/01b1e770-9d71-4321-90e5-39b2458d8f17/article/87a872d5-b35c-4d8f-b33d-5c117d6bd869">Подробнее
  в инструкциях</Link>)

const noticeCheckout = (<><Ul>
  <li>Выезд до 18:00 - ½ от стоимости текущих суток проживания, актуальной <strong>на момент
    оплаты</strong> позднего заезда.
  </li>
  <li>Выезд позже 18:00 - оплата полных суток, тарифицируется по <strong>актуальной</strong> на момент оплаты позднего
    выезда стоимости <strong>текущих суток</strong>.
  </li>
</Ul>{link}</>)

const noticeCheckin = (<><Ul>
  <li>Заезд с 8:00-14:00 - ½ от стоимости первых суток проживания, актуальной <strong>на момент
    оплаты</strong> раннего заезда.
  </li>
  <li>Заезд до 8:00 - оплата полных суток, тарифицируется по актуальной <strong>на момент оплаты</strong> раннего
    заезда
    стоимости за <strong>предыдущие сутки</strong>.
  </li>
</Ul>{link}</>)
