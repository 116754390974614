import {
  getDiscountedItemTotal,
  getPrintedDiscountedDayItemData,
} from '../../../../../../Modules/helpers/item-selectors'

export const getTotals = reservation => {
  const { reserved_days, additional_services, prepaidBreakfasts } = reservation

  let totalSumm = 0
  let payedSumm = 0

  const items = [...reserved_days, ...additional_services, ...prepaidBreakfasts]

  for (let item of items) {
    let totalNum = 0
    if (item.service)
      totalNum = getDiscountedItemTotal(item) || 0
    else
      totalNum = getPrintedDiscountedDayItemData(item)?.totalNum || 0

    totalSumm += totalNum
    if (item.payment_date || item.is_prepayed) payedSumm += totalNum
  }

  return {
    totalSumm,
    payedSumm,
  }
}
