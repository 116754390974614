import React, { useState } from 'react'
import Modal from 'react-modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'


import { FormGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ModalButton, ModalControls, ModalTitle } from '../../common/ModalParts'
import { maidsSelectors } from '../../../Modules/maids'
import { modalDefaultStyles } from '../../../App'
import { MainFormPart } from '../../CheckPrinterCP/components/common'
import Select from 'react-select'
import { sessionSelectors } from '../../../Modules/session'
import { ccAPI } from '../../../Modules/api/ccAPI'
import styled from 'styled-components'
import PrintButton from './PrintButton'
import { FaCheck } from 'react-icons/fa'

const InlineItem = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 10px;
  }
`

const ErrorField = styled.div`
  color: #d9534f;
  font-size: 12px;
`


export const MaidsModal = ({
                             isOpen,
                             close,
                             reservationsData,
                           }) => {
  const maidList = useSelector(maidsSelectors.maidsOptions)
  const lcode = useSelector(sessionSelectors.lcode)
  const [printData, setPrintData] = useState(null)
  const [printed, setPrinted] = useState({})

  const handlePrint = (maid_url) => {
    setPrinted(prev => ({ ...prev, [maid_url]: true }))
    return true
  }

  return (
    <Modal
      isOpen={isOpen}
      defaultStyles={{
        ...modalDefaultStyles,
        content: {
          ...modalDefaultStyles.content,
          width: 600,
          minWidth: 600,
          maxWidth: 'auto',
        },
      }}
    >
      <Formik
        initialValues={{
          maids: []
        }}
        validationSchema={
          Yup.object().shape({
            maids: Yup.array().min(1, "Выберите горничных")
          })}
        onSubmit={async (values, actions) => {
          const { maids } = values
          const users = maids.map(it => it.value)

          await ccAPI.getMaidsSessionData({ users, lcode }).then(res => setPrintData(res))

          actions.setSubmitting(false)
        }}
        render={({
                   errors,
                   handleSubmit,
                   setFieldValue,
                   isSubmitting,
                 }) => (
          <Form onSubmit={handleSubmit}>
            <ModalTitle>Выберите горничных</ModalTitle>
            <MainFormPart>
              <FormGroup>
                <Select
                  placeholder="Выберите горничных"
                  closeMenuOnSelect={false}
                  isMulti
                  options={maidList}
                  invalid={errors.maids}
                  onChange={(val) => setFieldValue("maids", val)}
                />
                {errors.maids && <ErrorField>{errors.maids}</ErrorField>}
              </FormGroup>

              {printData && printData.map(({ name: maid, uri: maid_url, user_id: maid_id }) =>
                (<InlineItem key={maid + maid_id}>
                  <div>{maid}</div>
                  <div>
                    {printed[maid_url] && <FaCheck />}
                    <PrintButton
                      text="Печать"
                      pageUrl="/print_bedclothes_change_rooms"
                      dataKey="BEDCLOTHES_CHANGE_ROOMS_DATA"
                      data={{ ...reservationsData, maid, maid_url, maid_id }}
                      event={() => handlePrint(maid_url)}
                    />
                  </div>
                </InlineItem>)
              )}

            </MainFormPart>

            <ModalControls>
              <ModalButton
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={close}
              >
                Закрыть
              </ModalButton>
              <ModalButton
                type="submit"
                bsStyle="success"
                style={{ marginLeft: 'auto' }}
                disabled={isSubmitting}
              >
                Сгенерировать бланк уброрки
              </ModalButton>
            </ModalControls>
          </Form>
        )}
      />
    </Modal>
  )
}
