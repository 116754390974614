import { createSelector } from 'reselect'

export const everything = state => state.breakfasts

export const items = state => everything(state).items

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const namesByPk = createSelector(items, items =>
  items.reduce((acc, item) => {
    acc[item.pk] = item.name
    return acc
  }, {})
)

export const breakfastByPk = createSelector(items, items => {
    return items.reduce((acc, item) => {
        acc[item.pk] = item
        return acc
    }, {})
})

export const breakfastValueList = createSelector(items, items =>
  items.map(it => {
      return {
          label: it.name,
          value: it.pk,
      }
  }
))
