import * as types from './types'
import * as selectors from './selectors'
import {
  setReservation,
  getBonusesFromPaidItems,
  clearReservation,
} from '../../../../Modules/reservation/ReservationModule'
import { MODES } from '../../../../Modules/helpers/types'
import {
  loyaltyClientActions,
  loyaltyClientSelectors,
} from '../../../../Modules/loyalty-client'
import { Notifications } from '../../../../Modules/Notifications'
import { gridActions } from '../grid'
import { reservationSelectors } from '../../../../Modules/reservation'
import { loyaltyAPI } from '../../../../Modules/api/loyaltyAPI'

const loadLoyaltyClientsRequest = () => ({
  type: types.LOAD_LOYALTY_CLIENTS_REQUEST,
})

const loadLoyaltyClientsSuccess = ({ loyaltyClientsPhones }) => ({
  type: types.LOAD_LOYALTY_CLIENTS_SUCCESS,
  payload: { loyaltyClientsPhones },
})

const loadLoyaltyClientsFailure = ({ message }) => ({
  type: types.LOAD_LOYALTY_CLIENTS_FAILURE,
})

function splitReservationsToGroups(reservations, count = 5) {
  const groups = []

  for (let i = 0; i < reservations.length; i++) {
    const alreadyGroupedCount = i

    if (alreadyGroupedCount % count === 0) {
      groups.push([])
    }

    const lastGroup = groups[groups.length - 1]

    lastGroup.push(reservations[i])
  }

  return groups
}

export const loadLoyaltyClients = () => async (dispatch, getState) => {
  dispatch(loadLoyaltyClientsRequest())

  const state = getState()

  try {
    const reservations = selectors.prematchingReservations(state)

    const reservationGroups = splitReservationsToGroups(reservations)

    const loyaltyClientsPhones = []

    for (const reservationGroup of reservationGroups) {
      const promises = reservationGroup.map(reservation => {
        const { guest_phone: phoneNumber } = reservation

        return loyaltyAPI.getBalance({ phoneNumber }).then(response => {
          if (response.errorCode) return { found: false }
          return { found: true, phoneNumber }
        })
      })

      const responses = await Promise.all(promises)

      const foundClientsPhones = responses
        .filter(result => result.found)
        .map(result => result.phoneNumber)

      loyaltyClientsPhones.push(...foundClientsPhones)
    }

    dispatch(loadLoyaltyClientsSuccess({ loyaltyClientsPhones }))
  } catch (error) {
    dispatch(loadLoyaltyClientsFailure({ message: error.message }))
  }
}

const startApplying = () => ({
  type: types.START_APPLYING,
})

const stopApplying = () => ({
  type: types.STOP_APPLYING,
})

export const applyBonusesForPaidItemsInReservation = ({
  reservation,
}) => async (dispatch, getState) => {
  let state = getState()
  dispatch(startApplying())

  try {
    dispatch(setReservation({ reservation, mode: MODES.EDIT }))

    await dispatch(loyaltyClientActions.setupLoyalty())
    state = getState()
    const isConnectionFailed = loyaltyClientSelectors.isFailed(state)

    if (isConnectionFailed) {
      throw new Error('Не удалось получить клиента программы лояльности')
    }

    await dispatch(getBonusesFromPaidItems())
    state = getState()
    const updatedReservation = reservationSelectors.everything(state)
    dispatch(gridActions.updateReservation({ reservation: updatedReservation }))
  } catch (err) {
    Notifications.failure(err.message ?? 'Неизвестная ошибка')
  }

  dispatch(clearReservation())
  dispatch(stopApplying())
}

export const reset = () => ({
  type: types.RESET,
})
