import { ccAPI } from '../api/ccAPI'
import { modalActions } from '../../Components/dynamic-modal/templates/confirm'
import { callModal, modalTemplates } from '../../Components/dynamic-modal'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'



const DateInfo = styled.div`
  color: ${props => props.zero ? '#da4e4e' : 'default'};
`

const DatesBlock = ({ data }) => (<div>{
  data.map((it) => Object.entries(it).map(([date, num]) => (
      <DateInfo zero={num <= 0} key={date + num}>На {moment(date).format("DD.MM.YYYY")} есть {num} свободных
        номера</DateInfo>)
    )
  )}< /div>)



export const isRoomNotAvailable = async ({ lcode, reserved_days }) => {
  return await ccAPI.checkRomAvailability({ lcode, reserved_days })
    .then(_ => false)
    .catch(async error => {
      if (+error.code === 90 || +error.code === 80) {
        const action = await buildAvailabilityErrorModal(error)
        return action === modalActions.close
      }
      return false
    })
}

export const buildAvailabilityErrorModal = async ({ details_pretty_ru, meta }) => {
  const { action } = await callModal(modalTemplates.confirm({
    title: details_pretty_ru,
    text: <div>
      <DatesBlock data={meta} />
      <br />
      <div>Вы уверены, что хотите продолжить?</div>
    </div>,
    buttons: ['Нет', 'Да'],
  }))
  return action
}
