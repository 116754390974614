import * as types from './types'
import { ccAPI } from '../api/ccAPI'

const loadDataRequest = () => ({
  type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = items => ({
  type: types.LOAD_DATA_SUCCESS,
  payload: {
    items,
  },
})

const loadDataFailure = () => ({
  type: types.LOAD_DATA_FAILURE,
})

export const loadData = ({ bookingNumber }) => async dispatch => {
  dispatch(loadDataRequest())

  try {
    const items = await ccAPI.getLocalNotesByBookingNumber(bookingNumber)
    dispatch(loadDataSuccess(items))
  } catch (err) {
    dispatch(loadDataFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
