import * as types from './types'
import * as selectors from './selectors'
import moment from 'moment'

const setDate = date => ({
  type: types.SET_DATE,
  payload: date,
})

const setHour = hour => ({
  type: types.SET_HOUR,
  payload: hour,
})

const setMinute = minute => ({
  type: types.SET_MINUTE,
  payload: minute,
})

let intervalId = null

export const startDateTracking = () => (dispatch, getState) => {
  const process = () => {
    const state = getState()
    const currentDate = selectors.value(state)
    const currentHour = selectors.hour(state)
    const nextDate = moment().format('YYYY-MM-DD')
    const nextHour = moment().hour()
    const nextMinute = moment().minute()

    if (nextDate !== currentDate) {
      dispatch(setDate(nextDate))
    }

    if (nextHour !== currentHour) {
      dispatch(setHour(nextHour))
      dispatch(setMinute(nextMinute))
    }
  }

  intervalId = window.setInterval(process, 10000)
}

export const stopDateTracking = () => () => {
  window.clearInterval(intervalId)
  intervalId = null
}
