import { EmailRE } from '../../regex/EmailRE'

export function email(message) {
  return {
    fn: value => {
      if (!value) return true
      return EmailRE.test(value)
    },
    message,
  }
}
