import * as types from './types'
import * as selectors from './selectors'

export const open = name => (dispatch, getState) => {
  const state = getState()

  const isOpen = selectors.isOpen(state, name)

  if (isOpen) return

  dispatch({
    type: types.OPEN,
    payload: { name },
  })
}

export const close = name => ({
  type: types.CLOSE,
  payload: { name },
})

export const toggle = name => (dispatch, getState) => {
  const state = getState()

  const isOpen = selectors.isOpen(state, name)

  if (isOpen) dispatch(close(name))
  else dispatch(open(name))
}
