import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useActions } from '../../common/hooks/use-actions'
import { maidsActions, maidsSelectors } from '../../../Modules/maids'
import { useSelector } from 'react-redux'
import { sessionSelectors } from '../../../Modules/session'
import { MaidsCheckOut } from '../../BedclothesChange/components/MaidsCheckOut'


const Block = styled.div`
  padding: 30px 30px 0;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
`

export const MaidsCheckOutMain = () => {
  const lcode = useSelector(sessionSelectors.lcode)
  const checkedList = useSelector(maidsSelectors.checkedInList)
  const { getMaidsCheckedIns } = useActions(maidsActions)

  useEffect(() => {
    getMaidsCheckedIns({ lcode })
  }, [])

  return (
    checkedList.length ?
    <Block>
      <h2>Горничные на смене:</h2>
      <MaidsCheckOut />
    </Block> : ''
  )

}
