import {connect} from 'react-redux'
import {PassportFieldGroupView} from './PassportFieldGroupView'
import {reservationSelectors} from '../../Modules/reservation'
import {
    onPassportCreate,
    onPassportEdit,
    onPassportRemove,
    addPassportsToBlacklist,
} from '../../Modules/reservation/ReservationModule'
import {bindActionCreators} from 'redux'

const mSTP = (state, ownProps) => ({
    items: reservationSelectors.passport(state),
    hasError: reservationSelectors.hasGeneralError(state, 'passport'),
    mode: reservationSelectors.mode(state),
    ...ownProps,
})

const mDTP = dispatch => ({
    actions: bindActionCreators(
        {
            create: onPassportCreate,
            edit: onPassportEdit,
            remove: onPassportRemove,
            blacklist: addPassportsToBlacklist,
        },
        dispatch
    ),
})

export const PassportFieldGroup = connect(mSTP, mDTP)(PassportFieldGroupView)
