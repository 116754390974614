import React from 'react'
import Modal from 'react-modal'
import {
  ModalTitle,
  ModalHR,
  ModalControls,
  ModalButton,
  ModalTextBlock,
} from './ModalParts'
import kkmServerIcon from '../../Assets/kkmserver-icon.jpg'

const PrintServerErrorModal = props => {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalTitle>Ошибка!</ModalTitle>
      <ModalHR />
      <ModalTextBlock>
        <p style={{ padding: '0 0 15px' }}>
          Сервер печати чеков не запущен. Запустите его и попробуйте снова.
          <br />
          Ярлык для запуска сервера можно найти на рабочем столе компьютера:
        </p>
        <img src={kkmServerIcon} alt="Иконка сервера" />
      </ModalTextBlock>
      <ModalHR />
      <ModalControls>
        <ModalButton bsStyle="danger" onClick={props.hideModal}>
          Закрыть
        </ModalButton>
        {props.tryAgainAction && (
          <ModalButton
            bsStyle="success"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              props.hideModal()
              props.tryAgainAction()
            }}
          >
            Попробовать снова
          </ModalButton>
        )}
      </ModalControls>
    </Modal>
  )
}

export default PrintServerErrorModal
