import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const Block = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(148, 24, 24, 0.8);
  font-size: 18px;
`

const ContentWrapper = styled.div`
  max-width: 200px;
  text-align: center;
`

const Text = styled.div`
  width: 100%;
  max-width: 200px;
  color: #fff;
  margin-bottom: 10px;
`

export const ErrorScreen = ({ text, reloadAction }) => {
  return (
    <Block>
      <ContentWrapper>
        <Text>{text}</Text>
        <Button bsSize="sm" onClick={reloadAction}>
          Попробовать еще раз
        </Button>
      </ContentWrapper>
    </Block>
  )
}
