import { createSelector } from 'reselect'

export const everything = state => state.breakfastsBooked

export const items = state => everything(state).items

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const breakfastsIsGivenAway = createSelector(items, items => items.filter(it => it.is_given_away))
export const breakfastsIsNotGivenAway = createSelector(items, items => items.filter(it => !it.is_given_away))
export const breakfastsIsNotGivenAwayNotCanceled = createSelector(items, items => items.filter(it => !it.is_given_away && it.action !== 'refund'))

export const namesByPk = createSelector(breakfastsIsNotGivenAway, items =>
  items.reduce((acc, item) => {
    acc[item.pk] = item.name
    return acc
  }, {})
)

export const breakfastsByBooking = createSelector(breakfastsIsNotGivenAway, items =>
  items.reduce((acc, item) => {
    acc[item.booking_number] = items?.filter(it => it.booking_number === item.booking_number).map(it => ({
      ...it,
      quantity: it.qty
    }))
    return acc
  }, {})
)

export const breakfastsByBookingPrice = createSelector(breakfastsIsNotGivenAway, items =>
  items.reduce((acc, item) => {
    acc[item.booking_number] = items?.filter(it => it.booking_number === item.booking_number).reduce((a, b) => a + b.price * b.qty, 0)
    return acc
  }, {})
)
