import * as types from './types'
import { ccAPI } from '../../../../Modules/api/ccAPI'
import moment from 'moment'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items, total, totalRaw }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items, total, totalRaw },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = ({ month, lcode } = {}) => async (dispatch, getState) => {
  dispatch(loadRequest())
  const state = getState()

  try {
    const { checksV2Enabled } = state.settings

    const start = moment(month, 'MMMM YYYY')
      .startOf('month')
      .format('YYYY-MM-DD')

    const end = moment(month, 'MMMM YYYY')
      .endOf('month')
      .format('YYYY-MM-DD')
    if (checksV2Enabled) {
      const { details } = await ccAPI.getAdminServicesSalesV2({
        from: start,
        to: end,
        lcode,
      })
      dispatch(loadSuccess({ items: details.items, total: details.total, totalRaw: details.total_raw }))
    } else {
      const { adminID } = state.session
      const { items, total, total_raw } = await ccAPI.getAdminServicesSales({
        admin: adminID,
        date: start,
        date_until: end,
        lcode
      })

      dispatch(loadSuccess({ items, total, totalRaw: total_raw }))
    }


  } catch (err) {
    dispatch(loadFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
