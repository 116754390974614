import React from 'react'
import { connect } from 'react-redux'
import { FilesView } from './FilesView'
import { bindActionCreators } from 'redux'
import { filesActions, filesSelectors } from './reducers/files'

class FilesContainer extends React.Component {
  componentDidMount() {
    const { filesActions } = this.props
    filesActions.load()
  }

  componentWillUnmount() {
    const { filesActions } = this.props
    filesActions.reset()
  }

  render() {
    const { sharedFiles, privateFiles, loadingState } = this.props

    return (
      <FilesView
        sharedFiles={sharedFiles}
        privateFiles={privateFiles}
        loadingState={loadingState}
      />
    )
  }
}

const mSTP = state => ({
  sharedFiles: filesSelectors.sharedFiles(state),
  privateFiles: filesSelectors.privateFiles(state),
  loadingState: filesSelectors.loadingState(state),
})

const mDTP = dispatch => ({
  filesActions: bindActionCreators(filesActions, dispatch),
})

export const Files = connect(mSTP, mDTP)(FilesContainer)
