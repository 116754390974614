import { createGlobalStyle } from 'styled-components'
import BedclothesGrid from './BedclothesGrid'

export default createGlobalStyle`
   @media print {
    .global-navbar {
      display: none;
    }

    .global-page {
      padding-top: 40px !important;
    }

    ${BedclothesGrid} {
      margin-top: 0;
    }
  }
`
