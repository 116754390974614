import React, { useMemo } from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  margin-top: 6px;
  font-size: 12px;
  color: #a94442;
`

export const Select = ({
  name,
  label,
  defaultOption,
  value,
  options,
  onChange = () => {},
  disabled = false,
  hasError = false,
  error = null,
  size = 'small',
  noMargin = false,
}) => {
  const optionsObjects = useMemo(
    () =>
      options.map(option => {
        if (typeof option === 'object') return option
        return { label: option, value: option }
      }),
    [options]
  )

  return (
    <FormGroup
      validationState={hasError || error ? 'error' : null}
      style={{ marginBottom: noMargin && 0 }}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl
        name={name}
        value={value}
        onChange={event => onChange(event.target.value)}
        disabled={disabled}
        componentClass="select"
        bsSize={size}
      >
        {defaultOption && (
          <option value={defaultOption.value}>{defaultOption.label}</option>
        )}
        {optionsObjects.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormControl>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormGroup>
  )
}
