import { LOYALTY_REGISTRATION_LINES } from '../custom-check-texts/loyalty-registration'

export const getBasicCheckData = ({ settings }) => {
  const basicData = {
    Command: 'RegisterCheck',
    CashierName: settings.cashier,
    CashierVATIN: settings.inn,
    IsFiscalCheck: true,
    CheckStrings: [],
  }

  if (settings.isLoyaltyEnabled) {
    basicData.CheckStrings.push({ PrintText: { Text: '<<->>' } })
    basicData.CheckStrings.push(...LOYALTY_REGISTRATION_LINES)
  }

  return basicData
}
