import React, { useEffect } from 'react'
import Modal from 'react-modal'
import { modalDefaultStyles } from '../../App'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

import { ModalButton, ModalTitle } from '../common/ModalParts'

import { Form, MainFormPart, Field, ModalControls } from './components/common'
import paymentTypes from '../../Modules/helpers/paymentTypes'
import { Button } from '../buttons'
import { RoomSelect } from './components/RoomSelect'
import { useReservationsSearch } from './helpers/use-reservations-search'
import { AlphaBookingNumberRE } from '../../Modules/helpers/regex/AlphaBookingNumberRE'
import { applyBonuses } from './helpers/loyalty'
import { useSelector } from 'react-redux'
import { specialServiceTypesSelectors } from '../../Modules/special-service-types'
import * as ccAPI from '../../Modules/api/ccAPI/requests'
import { createDoubleBooking } from '../../Modules/api/ccAPI/requests'
import { sessionSelectors } from '../../Modules/session'
import AsyncCreatable from 'react-select/async-creatable'

const ValidationSchema = Yup.object().shape({
  guest_name: Yup.string().required(),
  guest_phone: Yup.string().required(),
  booking_number: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .required(),
  room: Yup.string().required(),
  payment_method: Yup.string().required(),
  amount: Yup.string()
    .matches(/^[0-9]{1,}([,.][0-9]+)?$/)
    .trim()
    .required(),
  is_loyalty_allowed: Yup.boolean(),
})

function normalizeValues(values, Schema) {
  const casted = Schema.cast(values)

  casted.amount = casted.amount.replace(',', '.').trim()
  casted.booking_number = casted.booking_number.value + ' (двойная сдача)'

  return casted
}

const initialValues = {
  guest_name: '',
  guest_phone: '',
  booking_number: '',
  room: '',
  payment_method: '',
  amount: '',
  is_loyalty_allowed: true,
  booking_number_uuid: '',
}

export const DoubleBookingModal = ({
  title,
  isRefund,
  close,
  cashAction,
  cardAction,
  bankAction,
  ...restProps
}) => {
  const livingService = useSelector(specialServiceTypesSelectors.livingService)
  const lcodeObj = useSelector(sessionSelectors.lcodeObj)
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values, actions) => {
      const normalizedValues = normalizeValues(values, ValidationSchema)

      const {
        guest_name,
        guest_phone,
        booking_number,
        room,
        payment_method,
        amount,
        is_loyalty_allowed,
        booking_number_uuid,
      } = normalizedValues

      const getCheckFunction = () => {
        switch (payment_method) {
          case paymentTypes.cash:
            return cashAction
          case paymentTypes.card:
            return cardAction
          case paymentTypes.bank:
            return bankAction
          default:
            return () => {}
        }
      }

      const saveCheck = getCheckFunction()
      let checkList = isRefund
        ? await ccAPI
          .getChecksByUUID(booking_number_uuid)
          .then(res => res.details)
        : []

      const checkOptions = {
        reservationPk: booking_number_uuid,
        guestName: guest_name,
        bookingNumber: booking_number,
        room,
        paymentAmount: amount,
        livingService,
        checkList,
        onSuccess: async () => {
          await createDoubleBooking({
            is_refund: isRefund,
            amount,
            payment_method,
            lcode: lcodeObj.pk
          })

          if (isRefund) return
          if (!is_loyalty_allowed) return

          await applyBonuses({
            phoneNumber: guest_phone,
            paymentAmount: amount,
          })
        },
      }

      await saveCheck(checkOptions)

      actions.setSubmitting(false)
      close()
      actions.resetForm(initialValues)
    },
  })

  const {
    exactResults,
    foundFor,
    bookingNumberOptions,
    loadReservations,
    makeSearch,
    reset,
    isLoading,
  } = useReservationsSearch({ sources: ['reservations'], lcode: lcodeObj.lcode })

  useEffect(() => {
    if (AlphaBookingNumberRE.test(values.booking_number)) {
      setFieldValue('guest_name', '')
      setFieldValue('guest_phone', '')
      setFieldValue('is_loyalty_allowed', true)
      return
    }

    if (foundFor !== values.booking_number.value) return

    const [firstReservation] = exactResults
    if (!firstReservation) {
      setFieldValue('guest_name', '')
      setFieldValue('guest_phone', '')
      setFieldValue('is_loyalty_allowed', true)
      return
    }
    setFieldValue('guest_name', firstReservation.guest_name)
    setFieldValue('guest_phone', firstReservation.guest_phone)
    setFieldValue('is_loyalty_allowed', firstReservation.is_loyalty_allowed)
    setFieldValue('booking_number_uuid', firstReservation.pk)
    // eslint-disable-next-line
  }, [exactResults, foundFor])

  return (
    <Modal
      defaultStyles={{
        ...modalDefaultStyles,
        content: {
          ...modalDefaultStyles.content,
          width: 400,
          minWidth: 400,
          maxWidth: 'auto',
        },
      }}
      onAfterClose={() => {
        reset()
        resetForm(initialValues)
      }}
      {...restProps}
    >
      <Form onSubmit={handleSubmit}>
        <ModalTitle>{title}</ModalTitle>
        <MainFormPart>
          <FormGroup style={{ width: '100%', marginBottom: 20 }}>
            <ControlLabel>Номер брони</ControlLabel>
            <AsyncCreatable
              name="booking_number"
              placeholder="Укажите номер брони (минимум 4 символа)"
              value={values.booking_number}
              loadOptions={option => loadReservations(option)}
              onChange={option => {
                setFieldValue('booking_number', option)

                if (!option) {
                  setFieldValue('guest_name', '')
                  setFieldValue('guest_phone', '')
                  setFieldValue('is_loyalty_allowed', true)
                  return
                }

                makeSearch(option.value)
              }}
              formatCreateLabel={booking_number => {
                return `Указать "${booking_number}" вручную`
              }}
              onBlur={handleBlur}
              options={bookingNumberOptions}
              isClearable
              isLoading={isLoading}
            />
            <Button
              type="primary"
              size="xs"
              style={{ marginTop: 10, marginBottom: 0 }}
              onClick={() => {
                setFieldValue('booking_number', {
                  label: 'Без б',
                  value: 'Без б',
                  __isNew__: true,
                })
                setFieldValue('guest_name', '')
                setFieldValue('guest_phone', '')
                setFieldValue('is_loyalty_allowed', true)
              }}
            >
              Без брони
            </Button>
          </FormGroup>
          <FormGroup style={{ width: '100%', marginBottom: 20 }}>
            <ControlLabel>Имя гостя</ControlLabel>
            <Field
              name="guest_name"
              placeholder="Укажите имя гостя"
              value={values.guest_name}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.guest_name && errors.guest_name}
            />
          </FormGroup>
          <FormGroup style={{ width: '100%', marginBottom: 20 }}>
            <ControlLabel>Номер телефона</ControlLabel>
            <Field
              name="guest_phone"
              placeholder="Укажите номер телефона"
              value={values.guest_phone}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.guest_phone && errors.guest_phone}
            />
          </FormGroup>
          <RoomSelect
            name="room"
            value={values.room}
            onChange={handleChange}
            withLabel
          />
          <FormGroup style={{ width: '100%', marginBottom: 20 }}>
            <ControlLabel>Метод оплаты</ControlLabel>
            <FormControl
              componentClass="select"
              value={values.payment_method}
              onChange={e => setFieldValue('payment_method', e.target.value)}
            >
              <option value="">Выберите метод оплаты</option>
              <option value={paymentTypes.cash}>{paymentTypes.cash}</option>
              <option value={paymentTypes.card}>{paymentTypes.card}</option>
              <option value={paymentTypes.bank}>{paymentTypes.bank}</option>
            </FormControl>
          </FormGroup>
          <FormGroup style={{ width: '100%', marginBottom: 20 }}>
            <ControlLabel>Сумма</ControlLabel>
            <Field
              name="amount"
              placeholder="Укажите сумму в рублях"
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.amount && errors.amount}
            />
          </FormGroup>
        </MainFormPart>

        <ModalControls>
          <ModalButton
            bsStyle="danger"
            style={{ marginLeft: 0 }}
            onClick={close}
          >
            Закрыть
          </ModalButton>
          <ModalButton
            type="submit"
            bsStyle="success"
            style={{ marginLeft: 'auto' }}
            disabled={!isValid || isSubmitting}
          >
            Произвести {isRefund ? 'возврат' : 'продажу'}
          </ModalButton>
        </ModalControls>
      </Form>
    </Modal>
  )
}
