import { useState, useCallback, useEffect } from 'react'
import { globals } from './globals'
import { makeContainerReady } from './container-ready'

export const DynamicModalContainer = () => {
  const [modals, setModals] = useState([])

  const localAppendModal = useCallback(modal => {
    setModals(current => current.concat(modal))
  }, [])

  const localRemoveModal = useCallback(modal => {
    setModals(current =>
      current.filter(item => {
        return item !== modal
      })
    )
  }, [])

  useEffect(() => {
    globals.appendModal = localAppendModal
    globals.removeModal = localRemoveModal
    makeContainerReady()
  }, [localAppendModal, localRemoveModal])

  return modals
}
