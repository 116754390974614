import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { useActualCallback } from './hooks/use-actual-callback'
import { useFirstRender } from './hooks/use-first-render'
import { useForceUpdate } from './hooks/use-force-update'

let pendingCount = 0
let subscribers = []

function subscribe(callback) {
  subscribers.push(callback)
}

function unsubscribe(callback) {
  subscribers = subscribers.filter(subscriber => {
    return subscriber !== callback
  })
}

function addPendingCount() {
  pendingCount += 1
  subscribers.forEach(callback => callback())
}

function removePendingCount() {
  pendingCount -= 1
  subscribers.forEach(callback => callback())
}

export function useInputPending() {
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    subscribe(forceUpdate)
    return () => unsubscribe(forceUpdate)
    // eslint-disable-next-line
  }, [])

  return pendingCount > 0
}

export const DebouncedTextField = ({
  componentType = 'input',
  type = 'text',
  label,
  placeholder,
  value,
  onChange,
  disabled = false,
  error,
  containerStyle = {},
  inputStyle = {},
  size = 'small',
  debounceTime = 600,
}) => {
  const [visibleValue, setVisibleValue] = useState(value)

  const isFirstRender = useFirstRender()
  const [isPending, setPending] = useState(false)

  const memoizedOnChange = useActualCallback(value => {
    onChange(value)
    setPending(false)
  })

  const debouncedOnChange = useMemo(() => {
    return debounce(memoizedOnChange, debounceTime)
  }, [memoizedOnChange, debounceTime])

  const handleChange = event => {
    const { value } = event.target
    setVisibleValue(value)
    setPending(true)
    debouncedOnChange(value)
  }

  /*
   * Value changed from outside
   * No need to call onChange, just sync visible value
   */
  useEffect(() => {
    if (isFirstRender) return
    setVisibleValue(value)
    debouncedOnChange.cancel()
    setPending(false)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (isFirstRender) return
    if (isPending) addPendingCount()
    else removePendingCount()
    // eslint-disable-next-line
  }, [isPending])

  return (
    <FormGroup
      validationState={error ? 'error' : null}
      style={{
        marginBottom: 0,
        ...containerStyle,
      }}
    >
      <ControlLabel style={{ letterSpacing: 0.5 }}>{label}</ControlLabel>
      <FormControl
        componentClass={componentType}
        type={type}
        placeholder={placeholder}
        value={visibleValue}
        onChange={handleChange}
        disabled={disabled}
        style={{
          resize: 'vertical',
          ...inputStyle,
        }}
        bsSize={size}
      />
    </FormGroup>
  )
}
