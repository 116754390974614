import React, { useState } from 'react'
import Modal from 'react-modal'
import { modalDefaultStyles } from '../../../App'
import { Formik } from 'formik'
import { ModalButton, ModalTitle } from '../../common/ModalParts'
import { RemindersList, Reminder } from './Reminder'
import { Form, MainFormPart, Field, ModalControls } from './common'
import { ccAPI } from '../../../Modules/api/ccAPI'
import { Notifications } from '../../../Modules/Notifications'

export const PayByBankGuardModal = ({ isOpen, close, nextStep }) => {
  const [loading] = useState(false)

  return (
    <Modal
      isOpen={isOpen}
      defaultStyles={{
        ...modalDefaultStyles,
        content: {
          ...modalDefaultStyles.content,
          width: 400,
          minWidth: 400,
          maxWidth: 'auto',
        },
      }}
    >
      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={(values, actions) => {
          ccAPI
            .getBankCheckPassword(values.password)
            .then(() => {
              actions.setSubmitting(false)
              close()
              nextStep()
            })
            .catch(() => {
              actions.setErrors({
                password: 'Неверный пароль',
              })
              Notifications.failure('Неверный пароль')
              actions.setSubmitting(false)
            })
        }}
        render={({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <ModalTitle>Подтверждение доступа</ModalTitle>
            <MainFormPart>
              <RemindersList>
                <Reminder>
                  Для печати безналичного чека вручную обратитесь в бухгалтерию
                </Reminder>
              </RemindersList>
              <Field
                type="password"
                name="password"
                placeholder="Введите пароль"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.password && errors.password}
              />
            </MainFormPart>

            <ModalControls>
              <ModalButton
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={close}
              >
                Закрыть
              </ModalButton>
              <ModalButton
                type="submit"
                bsStyle="success"
                style={{ marginLeft: 'auto' }}
                disabled={loading || isSubmitting}
              >
                Продолжить
              </ModalButton>
            </ModalControls>
          </Form>
        )}
      />
    </Modal>
  )
}
