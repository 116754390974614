import styled from 'styled-components'
import { Row as BootstrapRow, Col as BootstrapCol } from 'react-bootstrap'

const Wrapper = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Row = styled(BootstrapRow)`
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Col = BootstrapCol

const Cell = styled.div``

const ReadOnlyCell = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
`

const Notice = styled.p`
  color: #da4e4e;
  font-size: 12px;
`

const NoticeList = styled.div`
  color: #da4e4e;
  font-size: 12px;
`

export const ItemGrid = {
  Wrapper,
  Row,
  Col,
  Cell,
  ReadOnlyCell,
  Notice,
  NoticeList
}
