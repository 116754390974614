import React from 'react'
import { connect } from 'react-redux'
import { PayTimerView } from './PayTimerView'
import { payTimerSelectors } from '../../Modules/pay-timer'
import { PAY_TIMER_DURATION } from '../../Constants/pay-timer-duration'

class PayTimerContainer extends React.Component {
  state = {
    counter: PAY_TIMER_DURATION,
  }

  intervalId = null

  componentDidUpdate(prevProps) {
    const { timerState } = this.props
    const { isActive, untilDate } = timerState

    if (isActive === prevProps.timerState.isActive) return

    if (isActive) {
      const processFn = () => {
        const diffMs = new Date(untilDate) - new Date()
        const diff = Math.ceil(diffMs / 1000)

        this.setState({
          counter: diff,
        })
      }

      this.intervalId = window.setInterval(processFn, 200)
      document.body.classList.add('no-scroll')
    } else {
      window.clearInterval(this.intervalId)
      this.intervalId = null

      this.setState({
        counter: PAY_TIMER_DURATION,
      })

      document.body.classList.remove('no-scroll')
    }
  }

  render() {
    const { counter } = this.state
    const { timerState } = this.props
    const { isActive } = timerState

    return <PayTimerView isActive={isActive} counter={counter} />
  }
}

const mSTP = state => ({
  timerState: payTimerSelectors.everything(state),
})

export const PayTimer = connect(mSTP)(PayTimerContainer)
