import * as types from './types'
import { ccAPI } from '../../../Modules/api/ccAPI'


export const load = (params) => async (dispatch, getSate) => {
  const { wubookReservations } = getSate()
  const { date } = wubookReservations
  try {
    return Promise.all([
      date ?
        ccAPI.getWubookReservationsCallByDate({ date, params }) :
        ccAPI.getWubookReservationsCall(params),
      ccAPI.getLocalPrepayeds(),
    ]).then((res) => res)
  } catch {
  }
}

export const setDate = ({ date }) => {
  return ({
    type: types.SET_DATE,
    payload: { date },
  })
}
