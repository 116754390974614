import { basicAuth, localBaseUrl } from '../../config/config'
import axios from 'axios'

export const axiosInstance = axios.create({
  baseURL: localBaseUrl,
})

export const loggedRequests = new Set()

export const request = ({
  url,
  method,
  params,
  data,
  headers = {},
  log = false,
  ...rest
}) => {
  if (log) {
    loggedRequests.add(`${method}-${url}`)
  }

  return axiosInstance({
    url,
    method,
    params,
    data,
    ...rest,
    headers: {
      Authorization: basicAuth,
      ...headers,
    },
  }).then(res => res.data)
}

export function addLocalRequestInterceptors({
  requestHandlers: [onRequestSuccess, onRequestFailure],
  responseHandlers: [onResponseSuccess, onResponseFailure],
}) {
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function(config) {
      onRequestSuccess(config)
      return config
    },
    function(error) {
      onRequestFailure(error)
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    function(response) {
      onResponseSuccess(response)
      return response
    },
    function(error) {
      onResponseFailure(error)
      return Promise.reject(error)
    }
  )
}
