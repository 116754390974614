import React from 'react'

export const DateCell = ({ date }) => {
  const dateObj = new Date(date)

  const weekdayOptions = {
    weekday: 'short',
  }

  const dayOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  return (
    <div className="date-cell">
      <div className="date-cell-weekday">
        {dateObj.toLocaleString('ru', weekdayOptions)}
      </div>
      <div className="date-cell-day">
        <div className="date-inner">
          {dateObj.toLocaleString('ru', dayOptions)}
        </div>
      </div>
    </div>
  )
}
