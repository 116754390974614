import store from '../../../store'
import { payTimerActions, payTimerSelectors } from '../../pay-timer'
import { LogsAPI } from '../../logs-api'

export const stopPaymentTimer = ({ checkType, checkTotals, reduxStates }) => {
  const isTimerActive = payTimerSelectors.isActive(reduxStates.current)

  if (isTimerActive) {
    store.dispatch(payTimerActions.stop())
    LogsAPI.event('Получен ответ от KKM, таймер оплаты картой остановлен')
  }
}
