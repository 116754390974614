function createDateSorting(field, multiplier) {
  return (a, b) => {
    const diff = new Date(a[field]) - new Date(b[field])
    return diff * multiplier
  }
}

export const byDateASC = createDateSorting('date', 1)
export const byDateDESC = createDateSorting('date', -1)

export const byPayedAtASC = createDateSorting('payed_at', 1)
export const byPayedAtDESC = createDateSorting('payed_at', -1)
