import store from '../../../store'
import { settingsSelectors } from '../../settings'
import { washingActions, washingUtils } from '../../washing'
import { callModal, modalTemplates } from '../../../Components/dynamic-modal'
import { selectWashingMachine } from '../../../Components/WashingMachineSelectionModal'
import { sessionSelectors } from '../../session'
import { getIsWashingMachineDoorOpened, startWashing } from '../../api/ccAPI/requests'

export const processWashingServices = async ({
  checkType,
  items,
  scenarioState,
  reduxStates,
}) => {
  if (checkType !== 'sell') {
    return { success: true }
  }

  const isWashingOperationsOn = settingsSelectors.isWashingOperationsOn(
    reduxStates.current
  )
  const lcode = sessionSelectors.lcode(reduxStates.current)

  if (!isWashingOperationsOn) {
    return { success: true }
  }

  if (!scenarioState.succeed) {
    return
  }

  const { services } = items

  const newWashingServices = services.filter(
    service => service.service === 'Стирка'
  )

  if (newWashingServices.length === 0) {
    return { success: true }
  }

  // for case when machines are loading at the moment
  await washingUtils.waitForLoading()

  const closedMachines = []

  for (let i = 0; i < newWashingServices.length; i++) {
    const machineNumber = await selectWashingMachine({
      service: newWashingServices[i],
    })

    const isOpen = await getIsWashingMachineDoorOpened({
      lcode,
      machine: machineNumber,
    })

    if (!isOpen) {
      closedMachines.push(machineNumber)
    }

    await startWashing({
      lcode,
      machine: machineNumber,
      service_uuid: newWashingServices[i].uuid,
    })

    await store.dispatch(washingActions.loadMachines())
  }

  const createClosedMachinesStartString = () => {
    if (closedMachines.length === 1) {
      return 'В стиральной машине ' + closedMachines[0]
    }

    return 'В стиральных машинах ' + closedMachines.join(', ')
  }

  if (closedMachines.length > 0) {
    callModal(
      modalTemplates.alert({
        title: 'Предупреждение',
        text:
          createClosedMachinesStartString() +
          ' еще лежит белье с предыдущей стирки. Его необходимо выгрузить.',
      })
    )
  }

  return { success: true }
}
