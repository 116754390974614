import React, { useEffect, useState } from 'react'
import { paymentTypesNoBank, paymentTypesList } from '../../../../../Modules/helpers/paymentTypes'
import { ItemGrid } from '../../../common/ItemGrid'
import { TimeInput, Select, Input } from '../../../../form-stuff'
import { Button, ButtonGroup } from '../../../../buttons'
import { defaultPaymentTypeOption } from '../../../constants/default-values'
import { ItemPrice } from '../../../../common/ItemPrice'

export const Item = ({
                       data,
                       time,
                       validationErrors,
                       actions,
                       availableActions,
                       handlePriceFocus,
                       service,
                       notice
                     }) => {
  const isPayed = Boolean(data.payment_date)

  const updateCondition = availableActions.update && !isPayed
  const deleteCondition = availableActions.delete && !isPayed
  const refundCondition = availableActions.refund && isPayed

  const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

  useEffect(() => {
    const list = service ? service.is_available_for_sale_by_bank
        ? paymentTypesList
        : paymentTypesNoBank
      : paymentTypesList
    setPaymentTypeList(list)
  }, [service])

  const dataCols = !updateCondition ? (
    <>
      <ItemGrid.Col xs={2}>
        <ItemGrid.ReadOnlyCell>{data.service}</ItemGrid.ReadOnlyCell>
      </ItemGrid.Col>
      <ItemGrid.Col xs={2}>
        <ItemGrid.ReadOnlyCell>{time}</ItemGrid.ReadOnlyCell>
      </ItemGrid.Col>
      <ItemGrid.Col xs={3}>
        <ItemGrid.ReadOnlyCell>{data.payment_type}</ItemGrid.ReadOnlyCell>
      </ItemGrid.Col>
      <ItemGrid.Col xs={2}>
        <ItemGrid.ReadOnlyCell>
          <ItemPrice item={data} />
        </ItemGrid.ReadOnlyCell>
      </ItemGrid.Col>
    </>
  ) : (
    <>
      <ItemGrid.Col xs={2}>
        <ItemGrid.ReadOnlyCell>{data.service}</ItemGrid.ReadOnlyCell>
      </ItemGrid.Col>
      <ItemGrid.Col xs={2}>
        <TimeInput
          label="Время"
          placeholder="Введите время"
          value={time}
          onChange={actions.changeTime}
          disabled={!updateCondition}
          hasError={validationErrors['time']}
          noMargin
          showSeconds
        />
      </ItemGrid.Col>
      <ItemGrid.Col xs={3}>
        <Select
          label="Метод оплаты"
          defaultOption={defaultPaymentTypeOption}
          value={data.payment_type}
          options={paymentTypeList}
          onChange={actions.changePaymentType}
          disabled={!updateCondition}
          hasError={validationErrors['payment_type']}
          noMargin
        />
      </ItemGrid.Col>
      <ItemGrid.Col xs={2}>
        <Input
          type="number"
          placeholder="Введите стоимость"
          value={data.price}
          onChange={actions.changePrice}
          hasError={validationErrors['price']}
          noMargin
          disabled={true}
          onFocus={handlePriceFocus}
        />
      </ItemGrid.Col>
    </>
  )

  return (
    <ItemGrid.Wrapper>
      <ItemGrid.Row>
        {dataCols}
        <ItemGrid.Col xs={3}>
          <ButtonGroup align="right">
            {deleteCondition && (
              <Button type="danger" onClick={actions.delete}>
                Удалить
              </Button>
            )}
            {refundCondition && (
              <Button type="danger" onClick={actions.refund}>
                Возврат
              </Button>
            )}
          </ButtonGroup>
        </ItemGrid.Col>
      </ItemGrid.Row>
      {isPayed && (
        <ItemGrid.Notice>
          Для изменения времени, оформите возврат данной услуги и затем создайте
          новую с актуальным временем
        </ItemGrid.Notice>
      )}
      {notice && (<ItemGrid.NoticeList>
        {notice}
      </ItemGrid.NoticeList>)}
    </ItemGrid.Wrapper>
  )
}
