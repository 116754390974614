import styled from 'styled-components'

export const Title = styled.h3`
  margin: 0;
`

export const RedText = styled.p`
  color: crimson;
  margin: 0;
`
