import axios from 'axios'
import { logoutAdmin } from '../../session/actions'
import Cookies from 'js-cookie'
import * as types from '../../settings/types'
import { localhost } from '../../../Constants/urls'

const CC_TOKEN = 'CC_TOKEN'

let defaultUrl = process.env.REACT_APP_PMS_BE_BASE_URL

const localSettings = Cookies.get(types.LOCAL_SETTINGS)

if (localSettings) {
  if(JSON.parse(localSettings).useLocalBaseUrl) {
    defaultUrl = localhost
  }
}
const axiosInstance = axios.create({
  baseURL: defaultUrl,
})

export const request = async ({
  url,
  method,
  data,
  params,
  headers = {},
  useToken = true,
  ...rest
}) => {
  const addedHeaders = {}

  if (useToken) {
    const token = localStorage.getItem(CC_TOKEN)
    addedHeaders.Authorization = `Bearer ${token}`
    const lcodeCookie = Cookies.get('LCODE')
    const lcode = lcodeCookie !== undefined ? JSON.parse(lcodeCookie).lcode : null
    if (lcode) addedHeaders.lcode = lcode
  }
  return axiosInstance({
    url,
    method,
    data,
    params,
    headers: {
      ...addedHeaders,
      ...headers,
    },
    ...rest,
  })
    .then(res => res.data)
    .catch(() => {})
}

export const requestError = async ({
  url,
  method,
  data,
  params,
  headers = {},
  useToken = true,
  ...rest
}) => {
  const addedHeaders = {}

  if (useToken) {
    const token = localStorage.getItem(CC_TOKEN)
    addedHeaders.Authorization = `Bearer ${token}`
  }
  return axiosInstance({
    url,
    method,
    data,
    params,
    headers: {
      ...addedHeaders,
      ...headers,
    },
    ...rest,
  })
    .then(res => res.data)
}

export function ccRequestInterceptors(store) {
  axiosInstance.interceptors.response.use(
    requestState => requestState,
    async error => {
      if (!error) return
      const { response } = error
      if (response && response.status === 401) {
        await store.dispatch(logoutAdmin())
        window.location = '/login'
      }
      return Promise.reject(error.response.data)
    }
  )
}
