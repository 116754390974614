import { callModal, modalTemplates } from '../../../Components/dynamic-modal'
import ls from 'local-storage'
import { ccAPI } from '../../api/ccAPI'
import Cookies from 'js-cookie'
import { COOKIES } from '../types'
import { differenceInSeconds } from 'date-fns'
import moment from 'moment'

const LS_KEY = 'loyalty/confirmation-disabled'

window.enableLoyaltyConfirmationWithCode = () => ls.set(LS_KEY, false)
window.disableLoyaltyConfirmationWithCode = () => ls.set(LS_KEY, true)

export async function confirmWithCode({ phoneNumber }) {
  const isConfirmationDisabled = ls.get(LS_KEY)

  if (isConfirmationDisabled) {
    return true
  }
  const enabled = JSON.parse(Cookies.get(COOKIES.SMS_TIME) || null)
  if (!enabled) {
    const confirmationCodeRes = await ccAPI.sendLoyaltyConfirmationSMS({
      phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
    })

    const expired = new Date()
    expired.setSeconds(expired.getSeconds() + 60)

    Cookies.set(COOKIES.SMS_TIME, {
      set: new Date(),
      expired
    }, { expires: expired })

    if (!confirmationCodeRes.code) {
      const { action } = await callModal(
        modalTemplates.confirm({
          title: 'Ошибка',
          text: 'Не удалось отправить смс',
          buttons: ['Отмена', 'Попробовать еще раз'],
        })
      )

      return action === modalTemplates.confirm.actions.confirm
        ? confirmWithCode({ phoneNumber })
        : false
    }

    const { code } = confirmationCodeRes

    const { action } = await callModal(
      modalTemplates.prompt({
        title: 'Подтверждение',
        text: `Код подтвержения был отправлен на телефон ${phoneNumber}`,
        fieldPlaceholder: 'Введите код подтверждения',
        validators: [
          ...modalTemplates.prompt.defaultValidators,
          {
            fn: value => {
              return value === code
            },
            message: 'Код неверен',
          },
        ],
      })
    )
    return action === modalTemplates.prompt.actions.enter;
  } else {
    const seconds = differenceInSeconds(moment(enabled.expired).toDate(), new Date())
    return alert(
      `Для повторной отправки кода подождите ${seconds} секунд.`
    )
  }
}
