import React, {useMemo} from 'react'
import moment from 'moment'
import {Fields} from '../common'
import {
    Row,
    Column,
    Cell,
    FirstCell,
    RoomIdCell,
    TotalCell,
    HotelName,
    BigField,
    CornerCell,
    MarginCell,
    RoomId,
    Legend, TimeCell, NoBorderCell,
} from './components/common'
import {Page} from '../../../common/Page'
import styled, {ThemeProvider} from 'styled-components'
import QRCode from 'react-qr-code'

const QrBlock = styled.div`
    margin-left: 3mm;
`

const cellWidth = 9
const cellHeight = 4
const rowMargin = 4

const calculateRowsPerPage = ({rowsCount, cellHeight}) => {
    const rowHeight = rowsCount * cellHeight + rowMargin

    const maxHeight = 165

    return Math.floor(maxHeight / rowHeight)
}

const calculateColumnsPerRow = ({cellWidth}) => {
    const maxWidth = 256

    return Math.floor(maxWidth / cellWidth)
}

const buildMap = (reservations, clean = false) =>
    reservations
        .reduce((acc, {room, reservation}) => {
            acc[room] = {
                clean,
                early: moment(reservation.check_in_time, 'HH:mm').isBefore(moment('13:45', 'HH:mm')),
                late: moment(reservation.check_out_time, 'HH:mm').isAfter(moment('12:00', 'HH:mm')),
                isReady: reservation.is_ready
            }
            return acc
        }, {})

export const RoomsSchedule = ({
                                  admin,
                                  hotelName,
                                  bedLinen,
                                  roomsList,
                                  leavedRooms: leavedReservations,
                                  schedule: scheduledReservations,
                                  otherRooms,
                                  maid_url,
                                  maid,
                                  maid_id,
                                  cleaningDate
                              }) => {
    const linenCount = bedLinen.length

    const firstColumn = (
        <Column>
            <NoBorderCell/>
            <CornerCell/>
            {bedLinen.map(({name}) => (
                <FirstCell key={name}>{name}</FirstCell>
            ))}
            <FirstCell>Смена белья по графику</FirstCell>
            <FirstCell>Занятость</FirstCell>
        </Column>
    )

    const emptyCells = Array.from({length: linenCount}, (_, idx) => (
        <Cell key={idx}/>
    ))

    const marginCells = Array.from({length: linenCount + 2}, (_, idx) => (
        <MarginCell key={idx}/>
    ))

    const Totals = ({onNewRow = false}) => (
        <>
            {!onNewRow && <Column>{marginCells}</Column>}
            <Column>
                <TotalCell colSpan={2}>Итог</TotalCell>
                {bedLinen.map(({name}) => (
                    <Cell key={name}>{name}</Cell>
                ))}
                <Cell>Мешков всего</Cell>
            </Column>
            <Column>
                <Cell style={{borderTop: '1px solid'}}/>
                {emptyCells}
                <Cell/>
            </Column>
        </>
    )

    const columnsPerRow = calculateColumnsPerRow({
        cellWidth,
    })

    const rowsPerPage = calculateRowsPerPage({
        rowsCount: linenCount + 2,
        cellHeight,
    })

    const pagesRows = []

    const sortedRooms = roomsList.sort(
        (r1, r2) => Number(r1.room) - Number(r2.room_id)
    )

    let currentPageRows = []
    let currentRowColumns = []
    let totalsAdded = false

    const leavedRoomsMap = useMemo(() => {
        return buildMap(leavedReservations, true)
    }, [leavedReservations])

    const scheduledRoomsMap = useMemo(() => {
        return buildMap(scheduledReservations)
    }, [scheduledReservations])

    const otherRoomsMap = useMemo(() => {
        return buildMap(otherRooms)
    }, [otherRooms])

    const qrUrl = maid_url ? `${window.location.origin}${maid_url}?id=${maid_id}&maid=${maid.replace(' ', '%20')}` : ''

    for (let room of sortedRooms) {
        const {room_id} = room

        const {
            clean,
            early,
            late,
            isReady
        } = leavedRoomsMap[room_id] ? leavedRoomsMap[room_id] : otherRoomsMap[room_id] ? otherRoomsMap[room_id] : scheduledRoomsMap[room_id]

        currentRowColumns.push(
            <Column key={room_id}>
                <TimeCell
                    hasLate={late}
                    hasEarly={early}
                />
                <RoomIdCell>
                    <RoomId withCircle={clean}>{room_id}</RoomId>
                </RoomIdCell>
                {emptyCells}
                <Cell>{scheduledRoomsMap[room_id] ? '+' : ''}</Cell>
                <Cell isReady={isReady}/>
            </Column>
        )

        const isLast = room === sortedRooms[sortedRooms.length - 1]
        const totalsWidth = 37
        const rowHasEnoughSpace =
            (columnsPerRow - currentRowColumns.length) * cellWidth >= totalsWidth

        if (currentRowColumns.length === columnsPerRow || isLast) {
            const addTotals = isLast && rowHasEnoughSpace
            currentPageRows.push(
                <Row>
                    {firstColumn}
                    {currentRowColumns}
                    {addTotals && <Totals/>}
                    {addTotals && maid_url && <QrBlock><QRCode
                        size={120}
                        value={qrUrl}
                        viewBox={`0 0 120 120`}
                    /></QrBlock>}
                </Row>
            )
            currentRowColumns = []

            if (addTotals) {
                totalsAdded = true
            }
        }

        if (currentPageRows.length === rowsPerPage || isLast) {
            if (!totalsAdded && currentPageRows.length < rowsPerPage) {
                currentPageRows.push(
                    <Row>
                        <Totals onNewRow={true}/>
                        {maid_url && isLast && <QrBlock><QRCode
                            size={120}
                            value={qrUrl}
                            viewBox={`0 0 120 120`}
                        /></QrBlock>}
                    </Row>
                )
                totalsAdded = true
            }

            pagesRows.push(currentPageRows)
            currentPageRows = []
        }

        if (isLast && !totalsAdded) {
            pagesRows.push([
                <Row>
                    <Totals onNewRow={true}/>
                    {maid_url && <QrBlock><QRCode
                        size={120}
                        value={qrUrl}
                        viewBox={`0 0 120 120`}
                    /></QrBlock>}
                </Row>,
            ])
            totalsAdded = true
        }
    }

    if (currentRowColumns.length > 0) {
        currentPageRows.push(
            <Row>
                {firstColumn}
                {currentRowColumns}
            </Row>
        )
    }

    if (currentPageRows.length > 0) {
        pagesRows.push(currentPageRows)
    }
    const date = cleaningDate ? moment(cleaningDate).format('DD.MM.YYYY в HH:mm') : moment().format('DD.MM.YYYY в HH:mm')

    return (
        <ThemeProvider theme={{cellWidth, cellHeight, rowMargin}}>
            {pagesRows.map((pageRows, pageIndex) => {
                return (
                    <Page horizontal key={pageIndex}>
                        <HotelName>{hotelName}</HotelName>
                        <Fields>
                            <BigField name="Дата" value={date}/>
                            <BigField name="Администратор" value={admin}/>
                            <BigField name="Горничная" value={maid}/>
                        </Fields>
                        {pageRows}
                        <Legend/>
                    </Page>
                )
            })}
        </ThemeProvider>
    )
}
