import { createSelector } from 'reselect'
import moment from 'moment'

export const everything = state => state.currentDate

export const value = state => everything(state).date

export const date = state => everything(state).date
export const hour = state => everything(state).hour
export const minute = state => everything(state).minute

export const today = state => value(state)

export const yesterday = createSelector(today,today =>
  moment(today)
    .subtract(1, 'day')
    .format('YYYY-MM-DD')
)

export const tomorrow = createSelector(today, today =>
  moment(today)
    .add(1, 'day')
    .format('YYYY-MM-DD')
)
