import breakpoints from './values/breakpoints'
import * as sizes from './values/sizes'
import * as colors from './values/colors'
import zIndexes from './values/zIndexes'

const theme = {
  breakpoints,
  sizes,
  colors,
  zIndexes,
}

export default theme
