import moment from 'moment'

export const prepareReservation = async ({ reservation, adminName }) => {
  const moscowDay = moment().format('YYYY-MM-DD')
  const payed_at = new Date().toISOString()

  let newDays = reservation.reserved_days.map(day => {
    if (day.payment_date) {
      return {
        ...day,
        payment_date: moscowDay,
        payed_at,
      }
    }

    return {
      ...day,
      payed_at: '',
    }
  })

  let newServices = reservation.additional_services.map(service => {
    // Already payed
    if (service.payment_date) return service

    return {
      ...service,
      payment_date: moscowDay,
      payed_at,
    }
  })

  let newPassport = reservation.passport.map(item => ({
    ...item,
    passport: item.passport.replace(/ /g, ''),
  }))

  return {
    ...reservation,
    pk: undefined,
    reserved_days: newDays,
    additional_services: newServices,
    passport: newPassport,
    admin: adminName,
  }
}
