import { ALL_PHONES, GET_INTERNAL, GET_PHONES } from './types'

const initialState = {
  phones: [],
  internal: [],
  all: [],
}

export const reducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case GET_PHONES:
      return { ...state, phones: payload.phones }
    case GET_INTERNAL:
      return { ...state, internal: payload.internal }
    case ALL_PHONES:
      return { ...state, all: payload.all }
    default:
      return state
  }
}
