import {createSelector} from 'reselect'

export const everything = state => state.allNotes

export const byBookingNumber = createSelector(everything, ({items}) =>
    items?.reduce((acc, note) => {
        const {booking_number} = note

        const current = acc[booking_number] || []
        acc[booking_number] = current.concat(note)

        return acc
    }, {}) || []
)
