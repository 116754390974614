import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, Col, Row } from 'react-bootstrap'
import PrintServerErrorModal from '../common/PrintServerErrorModal'
import CheckPrintController from '../../Modules/CheckPrintController'
import isServerRunning from '../../Modules/CheckPrintController/isServerRunning'
import LoadingModal from '../common/LoadingModal'
import history from '../../Modules/helpers/history'
import printSlip from '../../Modules/TransactionsAPI/printSlip'
import { SellRefundActionModalWithPk } from './SellRefundActionModal'
import { ReservationSaving } from '../../Modules/reservation-saving'
import { PayByBankGuardModal } from './components/PayByBankGuardModal'
import { RemindersList, Reminder } from './components/Reminder'
import { callModal, modalTemplates } from '../dynamic-modal'
import { numbersREs } from '../../Modules/helpers/regex/numbers'
import { BookingSellActionModal } from './BookingSellActionModal'
import { DoubleBookingModal } from './DoubleBookingModal'
import { MixedPaymentModal } from './MixedPaymentModal'
import { updateAccountingCall } from '../../Modules/api/ccAPI/requests'
import { saveCustomPaymentAction } from '../../Modules/reservation-saving/util-callbacks'

const CheckPrinterCPBlock = styled.div`
  background-color: #eee;
  width: 100%;
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
`

const ActionsGroupTitle = styled.h2`
  margin: 0 16px 16px;
  font-size: 15px;
  font-weight: 600;
  color: #555;
  text-align: center;
`

const BlockButton = styled(Button)`
  display: block;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

class CheckPrinterCP extends Component {
  state = {
    isLoading: false,
    isServerOFFModalActive: false,
    activeModals: [],
  }

  openModal = name =>
    this.setState(prev => ({ activeModals: prev.activeModals.concat(name) }))
  closeModal = name =>
    this.setState(prev => ({
      activeModals: prev.activeModals.filter(n => n !== name),
    }))
  isModalOpened = name => this.state.activeModals.includes(name)

  startLoading = () =>
    this.setState({
      isLoading: true,
    })

  stopLoading = () =>
    this.setState({
      isLoading: false,
    })

  showServerOFFModal = () =>
    this.setState({
      isServerOFFModalActive: true,
    })

  hideServerOFFModal = () =>
    this.setState({
      isServerOFFModalActive: false,
    })

  inputSumm = async ({ title, text, maxValue = Infinity }) => {
    function number(value) {
      return Number(value.replace(',', '.'))
    }

    const { action, params } = await callModal(
      modalTemplates.prompt({
        title,
        text,
        fieldName: 'Сумма',
        validators: [
          ...modalTemplates.prompt.defaultValidators,
          {
            fn: value => {
              return numbersREs.positiveWithDotOrComma.test(value)
            },
            message: 'Неверный формат',
          },
          {
            fn: value => {
              return number(value) > 0
            },
            message: 'Сумма должна быть больше 0',
          },
          {
            fn: value => {
              return number(value) <= maxValue
            },
            message: `Сумма должна быть меньше или равна ${maxValue}`,
          },
        ],
      })
    )

    if (action !== modalTemplates.prompt.actions.enter) {
      return null
    }

    const { value } = params

    return number(value)
  }

  getErrorText = error => {
    if (typeof error !== 'object') {
      return error
    }

    return error.message || 'Неизвестная ошибка'
  }

  createAction = (callback, usePrinter = true) => async () => {
    const isServerOK = usePrinter ? await isServerRunning() : true

    if (!isServerOK) {
      this.showServerOFFModal()
      return
    }

    const processAction = async () => {
      try {
        const result = await callback()

        if (!result) {
          return
        }

        if (typeof result === 'string')
          return {
            title: 'Результат',
            text: result,
          }

        if (result.Error)
          return {
            title: 'Ошибка KKM',
            text: result.Error,
          }

        return {
          title: 'Результат',
          text: 'Действие было успешно выполнено',
        }
      } catch (error) {
        return {
          title: 'Не удалось выполнить действие',
          text: this.getErrorText(error),
        }
      }
    }

    this.startLoading()

    const resultModalOptions = await processAction()

    if (resultModalOptions) {
      callModal(modalTemplates.alert(resultModalOptions))
    }

    this.stopLoading()
  }

  printLastCheck = this.createAction(() => {
    return CheckPrintController.printCheckAgain()
  })

  getXReport = this.createAction(() => {
    return CheckPrintController.getXReport()
  })

  printTerminalReport = this.createAction(() => {
    return CheckPrintController.printTerminalReport()
  })

  paymentCash = this.createAction(async () => {
    const state = await CheckPrintController.getKKTState()
    const maxSumm = state.Info && state.Info.BalanceCash

    const summ = await this.inputSumm({
      title: 'Введите сумму наличных для снятия',
      text: `Текущее количество наличных в кассе: ${maxSumm}`,
      maxValue: maxSumm,
    })

    if (!summ) {
      return
    }

    return CheckPrintController.paymentCash(summ)
  })

  depositCash = this.createAction(async () => {
    const summ = await this.inputSumm({
      title: 'Введите сумму вносимых наличных',
    })

    if (!summ) {
      return
    }

    return CheckPrintController.depositCash(summ)
  })

  showResult = printResult => {
    const error = printResult?.Error
    const modalOptions = error
      ? { title: 'Ошибка KKM', text: error }
      : { text: 'Команда успешно выполнена' }

    callModal(modalTemplates.alert(modalOptions))
  }

  payByCash = ({
                 reservationPk,
                 room,
                 guestName,
                 bookingNumber,
                 paymentAmount,
                 livingService,
                 onSuccess = () => {
                 },
               }) => {
    this.startLoading()
    const formattedTotal = Number(paymentAmount).toFixed(2)
    const method = 'cash'
    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: false,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: false,
    }
    return ReservationSaving.handleCustomPurchase({
      printScopes: { days: true, services: true },
      customData: {
        guestName,
        bookingNumber,
        total: paymentAmount,
        method,
      },
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        ReservationSaving.callbacks.saveTransaction,
        onSuccess,
        ({ printResult }) => {
          this.stopLoading()
          this.showResult(printResult)
        }
      ]),
      onSuccess: ReservationSaving.callbacks.combine([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        onSuccess,
      ]),
      onCancel: () => this.stopLoading(),
      onPrintFinish: ({ printResult }) => {
        this.stopLoading()
        this.showResult(printResult)
      },
    })
  }

  payByCard = ({
                 reservationPk,
                 room,
                 guestName,
                 bookingNumber,
                 paymentAmount,
                 livingService,
                 onSuccess = () => {
                 },
               }) => {
    this.startLoading()
    const formattedTotal = Number(paymentAmount).toFixed(2)
    const method = 'card'

    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: false,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: false,
    }

    return ReservationSaving.handleCustomPurchase({
      printScopes: { days: true, services: true },
      customData: {
        guestName,
        bookingNumber,
        total: paymentAmount,
        method,
      },
      beforePrint: ReservationSaving.callbacks.startPaymentTimer,
      afterPrint: ReservationSaving.callbacks.stopPaymentTimer,
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        ReservationSaving.callbacks.saveTransaction,
        onSuccess,
        ({ printResult }) => {
          this.stopLoading()
          this.showResult(printResult)
        }
      ]),
      onSuccess: ReservationSaving.callbacks.combine([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        ReservationSaving.callbacks.saveTransaction,
        onSuccess,
      ]),
      onCancel: () => this.stopLoading(),
      onPrintFinish: ({ printResult }) => {
        this.stopLoading()
        this.showResult(printResult)
      }
    })
  }

  payByBank = ({
                 reservationPk,
                 room,
                 guestName,
                 bookingNumber,
                 paymentAmount,
                 livingService,
                 onSuccess = () => {
                 },
               }) => {
    this.startLoading()

    const formattedTotal = Number(paymentAmount).toFixed(2)

    const printMethod = 'card'
    const actionMethod = 'bank'
    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method: actionMethod,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: false,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: false,
    }
    return ReservationSaving.handleCustomPurchase({
      printScopes: { days: true, services: true },
      useProcessing: false,
      customData: {
        guestName,
        bookingNumber,
        total: paymentAmount,
        method: printMethod,
      },
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        onSuccess,
        ({ printResult }) => {
          this.stopLoading()
          this.showResult(printResult)
        }
      ]),
      onSuccess: ReservationSaving.callbacks.combine([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        onSuccess,
      ]),
      onCancel: () => this.stopLoading(),
      onPrintFinish: ({ printResult }) => {
        this.stopLoading()
        this.showResult(printResult)
      },
    })
  }

  payByBankDontPrint = async ({
                                reservationPk,
                                room,
                                guestName,
                                bookingNumber,
                                paymentAmount,
                                livingService,
                                onSuccess = () => {
                                },
                              }) => {
    this.startLoading()
    const formattedTotal = Number(paymentAmount).toFixed(2)
    const actionMethod = 'bank'
    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method: actionMethod,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: false,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: false,
    }
    const { settings, session } = this.props
    const reduxStates = {
      current: {settings, session},
    }
    const promises = [
      await saveCustomPaymentAction(check)({ reduxStates }),
      await onSuccess(),
      this.stopLoading(),
      this.showResult({ Error: '' })
    ]
   return await Promise.all(promises)
  }

  refundByCash = ({
                    reservationPk,
                    room,
                    guestName,
                    bookingNumber,
                    paymentAmount,
                    livingService,
                    checkList,
                    onSuccess,
                  }) => {
    this.startLoading()

    const formattedTotal = Number(paymentAmount).toFixed(2)
    const method = 'cash'

    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: true,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: true,
      checkList,
    }

    return ReservationSaving.handleCustomRefund({
      printScopes: { days: true, services: true },
      customData: {
        guestName,
        bookingNumber,
        total: paymentAmount,
        method,
      },
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        onSuccess,
        ({ printResult }) => {
          this.stopLoading()
          this.showResult(printResult)
        }
      ]),
      onSuccess: ReservationSaving.callbacks.combine([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        onSuccess,
      ]),
      onCancel: () => this.stopLoading(),
      onPrintFinish: ({ printResult }) => {
        this.stopLoading()
        this.showResult(printResult)
      },
    })
  }

  refundByCard = ({
                    reservationPk,
                    room,
                    guestName,
                    bookingNumber,
                    paymentAmount,
                    livingService,
                    checkList,
                    onSuccess,
                  }) => {
    this.startLoading()

    const formattedTotal = Number(paymentAmount).toFixed(2)
    const method = 'card'
    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: true,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: true,
      checkList,
    }
    return ReservationSaving.handleCustomRefund({
      printScopes: { days: true, services: true },
      customData: {
        guestName,
        bookingNumber,
        total: paymentAmount,
        method,
      },
      beforePrint: ReservationSaving.callbacks.startPaymentTimer,
      afterPrint: ReservationSaving.callbacks.stopPaymentTimer,
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        ReservationSaving.callbacks.saveTransaction,
        onSuccess,
        ({ printResult }) => {
          this.stopLoading()
          this.showResult(printResult)
        }
      ]),
      onSuccess: ReservationSaving.callbacks.combine([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        ReservationSaving.callbacks.saveTransaction,
        onSuccess,
      ]),
      onCancel: () => this.stopLoading(),
      onPrintFinish: ({ printResult }) => {
        this.stopLoading()
        this.showResult(printResult)
      },
    })
  }

  refundByBankDontPrint = async ({
                                   reservationPk,
                                   room,
                                   guestName,
                                   bookingNumber,
                                   paymentAmount,
                                   livingService,
                                   checkList,
                                   onSuccess,
                                 }) => {
    this.startLoading()
    const formattedTotal = Number(paymentAmount).toFixed(2)
    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method: 'bank',
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: true,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: true,
      checkList
    }
    const { settings, session } = this.props
    const reduxStates = {
      current: { settings, session },
    }
    const promises = [
      await saveCustomPaymentAction(check)({ reduxStates }),
      await onSuccess(),
      this.stopLoading(),
      this.showResult({ Error: '' })
    ]
    return await Promise.all(promises)
  }

  refundByBank = ({
                    reservationPk,
                    room,
                    guestName,
                    bookingNumber,
                    paymentAmount,
                    onSuccess,
                    livingService,
                    checkList
                  }) => {
    this.startLoading()

    const formattedTotal = Number(paymentAmount).toFixed(2)

    const method = 'card'
    const actionMethod = 'bank'
    const check = {
      reservationPk,
      room,
      guestName,
      bookingNumber,
      total: paymentAmount,
      method: actionMethod,
      items: [
        {
          item_name: 'Проживание',
          amount: formattedTotal,
          price: +paymentAmount,
          service_id: livingService.pk + '',
          refunded: true,
        },
      ],
      positions: 'Услуги проживания~1~' + formattedTotal,
      isRefund: true,
      checkList,
    }
    ReservationSaving.handleCustomRefund({
      printScopes: { days: true, services: true },
      useProcessing: false,
      customData: {
        room,
        guestName,
        bookingNumber,
        total: paymentAmount,
        method,
      },
      whenCheckPrinterIsDisabled: ReservationSaving.callbacks.pipe([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        () => onSuccess,
        ({ printResult }) => {
          this.stopLoading()
          this.showResult(printResult)
        }
      ]),
      onSuccess: ReservationSaving.callbacks.combine([
        ReservationSaving.callbacks.saveCustomPaymentAction(check),
        () => onSuccess,
      ]),
      onCancel: () => this.stopLoading(),
      onPrintFinish: ({ printResult }) => {
        this.stopLoading()
        this.showResult(printResult)
      },
    }).then(_ => {})
  }

  getDailyCardsTotals = this.createAction(async () => {
    const result = await CheckPrintController.getDailyCardsTotals()

    if (result.Status === 0) {
      printSlip(result.Slip)
    }

    return result
  })

  openShift = this.createAction(() => {
    return CheckPrintController.openShift()
  })

  closeShift = this.createAction(async () => {
    const { action } = await callModal(
      modalTemplates.confirm({
        title: 'Внимание',
        text: [
          'Будет произведено закрытие смены.',
          'Данная операция выполняется один раз в сутки с 23:30 до 23:59.',
          'Продолжить?',
        ],
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }

    return CheckPrintController.closeShift()
  })

  updateAccounting = this.createAction(async () => {
    const { lcode } = this.props
    try {
      await updateAccountingCall(lcode)

      return 'Учет будет обновлен в ближайшие несколько минут'
    } catch (err) {
      return 'Не удалось получить ответ от сервера'
    }
  }, false)

  render() {
    const { isLoading, isServerOFFModalActive } = this.state

    return (
      <CheckPrinterCPBlock>
        <RemindersList>
          <Reminder>
            Закрытие смены и сверка итогов по картам проводятся ежедневно и
            однократно с 23:30 по 23:59
          </Reminder>
          <Reminder>X-отчеты снимаются без ограничений по количеству</Reminder>
        </RemindersList>

        <Row>
          <Col xs={6}>
            <ActionsGroupTitle>Смены</ActionsGroupTitle>
            <BlockButton bsStyle="primary" onClick={this.openShift}>
              Открыть смену
            </BlockButton>
            <BlockButton bsStyle="primary" onClick={this.closeShift}>
              Закрыть смену
            </BlockButton>

            <ActionsGroupTitle>Отчеты</ActionsGroupTitle>
            <BlockButton bsStyle="primary" onClick={this.printLastCheck}>
              Напечатать последний чек
            </BlockButton>
            <BlockButton bsStyle="primary" onClick={this.getXReport}>
              X-Отчет
            </BlockButton>
            <BlockButton bsStyle="primary" onClick={this.getDailyCardsTotals}>
              Сверка итогов по картам
            </BlockButton>
            <BlockButton bsStyle="primary" onClick={this.printTerminalReport}>
              Контрольная лента
            </BlockButton>

            <ActionsGroupTitle>Остальное</ActionsGroupTitle>
            <BlockButton bsStyle="primary" onClick={this.updateAccounting}>
              Обновить учет
            </BlockButton>
          </Col>
          <Col xs={6}>
            {/* <ActionsGroupTitle>Общие действия</ActionsGroupTitle> */}
            {/* <BlockButton bsStyle="primary" onClick={this.paymentCash}>
              Инкассация
            </BlockButton> */}
            {/* <BlockButton bsStyle="primary" onClick={this.depositCash}>
              Внесение
            </BlockButton> */}

            <ActionsGroupTitle>Продажи</ActionsGroupTitle>
            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('pay-by-cash')}
            >
              Продажа наличными
            </BlockButton>
            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('pay-by-card')}
            >
              Продажа картой
            </BlockButton>
            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('pay-by-bank-guard')}
            >
              Продажа безналичным расчетом
            </BlockButton>

            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('booking-sell')}
            >
              Агода / Экспедиа
            </BlockButton>

            <ActionsGroupTitle>Возвраты</ActionsGroupTitle>

            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('refund-by-bank')}
            >
              Возврат безналичным расчетом
            </BlockButton>

            <ActionsGroupTitle>Сдачи</ActionsGroupTitle>

            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('double-booking/sell')}
            >
              Двойная сдача (продажа)
            </BlockButton>
            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('double-booking/refund')}
            >
              Двойная сдача (возврат)
            </BlockButton>

            <ActionsGroupTitle>Смешанные оплаты</ActionsGroupTitle>

            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('mixed-payment/sell')}
            >
              Смешанная оплата (продажа)
            </BlockButton>
            <BlockButton
              bsStyle="primary"
              onClick={() => this.openModal('mixed-payment/refund')}
            >
              Смешанная оплата (возврат)
            </BlockButton>

            <ActionsGroupTitle>Действия с транзакциями</ActionsGroupTitle>
            {/* <BlockButton bsStyle="primary" onClick={TransactionsAPI.create}>
              Заблокировать сумму на карте
            </BlockButton> */}
            {/* <BlockButton
              bsStyle="primary"
              onClick={() =>
                history.push("/check-printer-cp/transactions/confirm")
              }
            >
              Списать заблокированную сумму
            </BlockButton> */}
            {/*<BlockButton
              bsStyle="primary"
              onClick={() =>
                history.push("/check-printer-cp/transactions/cancel")
              }
            >
              Разблокировать сумму на карте
            </BlockButton> */}
            <BlockButton
              bsStyle="primary"
              onClick={() =>
                history.push('/check-printer-cp/transactions/return')
              }
            >
              Отмена оплаты картой
            </BlockButton>
          </Col>
        </Row>

        <PayByBankGuardModal
          isOpen={this.isModalOpened('pay-by-bank-guard')}
          close={() => this.closeModal('pay-by-bank-guard')}
          nextStep={() => this.openModal('pay-by-bank')}
        />
        <SellRefundActionModalWithPk
          title="Продажа наличными"
          lcode={this.props.lcode}
          isOpen={this.isModalOpened('pay-by-cash')}
          close={() => this.closeModal('pay-by-cash')}
          submitAction={this.payByCash}
        />
        <SellRefundActionModalWithPk
          title="Продажа картой"
          lcode={this.props.lcode}
          isOpen={this.isModalOpened('pay-by-card')}
          close={() => this.closeModal('pay-by-card')}
          submitAction={this.payByCard}
        />
        <SellRefundActionModalWithPk
          title="Продажа безналичным расчетом"
          lcode={this.props.lcode}
          isOpen={this.isModalOpened('pay-by-bank')}
          close={() => this.closeModal('pay-by-bank')}
          submitAction={this.payByBank}
        />
        <BookingSellActionModal
          title="Агода / Экспедиа"
          isOpen={this.isModalOpened('booking-sell')}
          close={() => this.closeModal('booking-sell')}
          submitAction={this.payByCard}
        />
        <SellRefundActionModalWithPk
          title="Возврат безналичным расчетом"
          isRefund={true}
          lcode={this.props.lcode}
          isOpen={this.isModalOpened('refund-by-bank')}
          close={() => this.closeModal('refund-by-bank')}
          hasOtherOption={true}
          submitAction={this.refundByBank}
        />
        <DoubleBookingModal
          title="Двойная сдача (продажа)"
          isRefund={false}
          lcode={this.props.lcode}
          isOpen={this.isModalOpened('double-booking/sell')}
          close={() => this.closeModal('double-booking/sell')}
          cashAction={this.payByCash}
          cardAction={this.payByCard}
          bankAction={this.payByBankDontPrint}
        />
        <DoubleBookingModal
          title="Двойная сдача (возврат)"
          isRefund={true}
          isOpen={this.isModalOpened('double-booking/refund')}
          close={() => this.closeModal('double-booking/refund')}
          cashAction={this.refundByCash}
          cardAction={this.refundByCard}
          bankAction={this.refundByBankDontPrint}
        />
        <MixedPaymentModal
          title="Смешанная оплата (продажа)"
          isRefund={false}
          isOpen={this.isModalOpened('mixed-payment/sell')}
          close={() => this.closeModal('mixed-payment/sell')}
          cashAction={this.payByCash}
          cardAction={this.payByCard}
          bankAction={this.payByBankDontPrint}
        />
        <MixedPaymentModal
          title="Смешанная оплата (возврат)"
          isRefund={true}
          lcode={this.props.lcode}
          isOpen={this.isModalOpened('mixed-payment/refund')}
          close={() => this.closeModal('mixed-payment/refund')}
          cashAction={this.refundByCash}
          cardAction={this.refundByCard}
          bankAction={this.refundByBankDontPrint}
        />
        <LoadingModal isOpen={isLoading} />
        <PrintServerErrorModal
          isOpen={isServerOFFModalActive}
          hideModal={this.hideServerOFFModal}
        />
      </CheckPrinterCPBlock>
    )
  }
}

export default CheckPrinterCP
