import * as types from './types'
import {getRoomsCall} from '../api/ccAPI/requests'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = () => async (dispatch, getState) => {
  dispatch(loadRequest())
  const state = getState()
  const { lcode } = state.session
  try {
    const items = await getRoomsCall(lcode)

    dispatch(loadSuccess({ items }))
  } catch {
    dispatch(loadFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
