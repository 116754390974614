import React, { memo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { gridSelectors, gridActions } from '../../../reducers/grid'
import { useActions } from '../../../../common/hooks/use-actions'
import { SCALE_LIST } from '../../../../../Constants/scale-list'
import { settingsActions, settingsSelectors } from '../../../../../Modules/settings'
import { ShowTableLegend } from './Legend'

const SizeControlBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: slateblue;
  border: 1px solid #483d8b;
`

const SizeControlInner = styled.div`
  margin: auto;
  text-align: left;
`

const SizeControlTitle = styled.h2`
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #fff;
  display: block;
`

const SizeControlItem = styled.span`
  line-height: 1.2;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 6px 8px 4px;
  background-color: ${({ isActive }) => (isActive ? '#483d8b' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#333')};
  cursor: ${({ isActive }) => (!isActive ? 'pointer' : 'auto')};

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

const UpdateButton = styled.button`
  font-size: 13px;
  padding: 6px 12px;
  color: #fff;
  background-color: #483d8b;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`
const scaleList = SCALE_LIST

const PureControlsCell = ({ style }) => {
  const size = useSelector(settingsSelectors.gridScale)
  const isLoading = useSelector(gridSelectors.isLoading)
  const { loadData } = useActions(gridActions)
  const { setGridScale } = useActions(settingsActions)

  return (
    <SizeControlBlock style={style}>
      <SizeControlInner>
        <SizeControlTitle>Размер таблицы</SizeControlTitle>

        {scaleList.map(({ key, label }) => (
          <SizeControlItem key={key} isActive={size === key} onClick={() => setGridScale(key)}>
            {label}
          </SizeControlItem>
        ))}
        <UpdateButton onClick={loadData} disabled={isLoading}>
          Обновить таблицу
        </UpdateButton>
        <ShowTableLegend/>
      </SizeControlInner>
    </SizeControlBlock>
  )
}

export const ControlsCell = memo(PureControlsCell)
