import { getTotals } from './getTotals'
import { isReservationArchived } from '../../../../../../Modules/helpers/base-data/is-archived'
import { AlphaBookingNumberRE } from '../../../../../../Modules/helpers/regex/AlphaBookingNumberRE'
import { sortDays } from '../../../../../../Modules/reservation/sort-days'
import { warningsKind } from '../../../../../../Constants/warning-types'

// Check check-out late or not
const isCheckOutLate = checkOutTime => {
  return +checkOutTime.substr(0, 2) > 12
}

export const processReservations = (reservations, notes, groupReservations, breakfasts = []) => {
  const processedReservations = {}
  const model = {}

  const serverNotesByBN = {}
  for (let note of notes) {
    const bn = note.booking_number

    const current = serverNotesByBN[bn] || []
    serverNotesByBN[bn] = current.concat(note)
  }

  const isGroupByBN = {}
  for (let groupReservation of groupReservations) {
    const bn = String(groupReservation.booking_number)

    if (AlphaBookingNumberRE.test(bn)) continue

    isGroupByBN[bn] = true
  }

  /*
   * Transfer information about the reservation in general
   * and separately for each room in different objects
   * and calculate some additional parameters
   */
  reservations.forEach(reservation => {
    reservation.prepaidBreakfasts = breakfasts[reservation.booking_number] || []
    const totals = getTotals(reservation)

    const { warnings = [] } = reservation
    const hasWarning = warnings.find(it => (it.kind === warningsKind.canceled_and_ready || it.kind === warningsKind.group_part_canceled) && !it.action_performed)

    processedReservations[reservation.pk] = {
      pk: reservation.pk,
      bookingNumber: reservation.booking_number,
      start: reservation.start,
      end: reservation.end,
      clientName: reservation.guest_name,
      clientPhone: reservation.guest_phone,
      guestsNumber: reservation.guests_number > reservation.booked_room_occupancy ? reservation.booked_room_occupancy : reservation.guests_number,
      clientNote: reservation.note,
      isPayed: reservation.payed,
      isArchived: isReservationArchived(reservation),
      isCheckOutLate: isCheckOutLate(reservation.check_out_time),
      isGroup: isGroupByBN[reservation.booking_number] || false,
      serverNotes: serverNotesByBN[reservation.booking_number] || null,
      hasRefund: reservation.has_refund,
      totalSumm: totals.totalSumm,
      payedSumm: totals.payedSumm,
      canceled: reservation.canceled,
      hasWarning
    }
    const reservedDays = reservation.reserved_days.sort(sortDays)

    const migrationMap = {}

    const daysRooms = reservedDays.map(day => ({
      date: day.date,
      roomID: day.room?.room_id ?? 'неизвестно',
    }))

    if (reservedDays.length === 1) {
      migrationMap[daysRooms[0].date] = [null, null]
    }

    for (let i = 0; i < daysRooms.length - 1; i++) {
      const current = daysRooms[i]
      const next = daysRooms[i + 1]

      if (!migrationMap[current.date]) migrationMap[current.date] = [null, null]
      if (!migrationMap[next.date]) migrationMap[next.date] = [null, null]

      if (next.roomID !== current.roomID) {
        migrationMap[current.date][1] = next.roomID
        migrationMap[next.date][0] = current.roomID
      }
    }

    let roomID, date, paymentType
    reservedDays.forEach(day => {
      roomID = day.room?.room_id

      if (!roomID) return

      date = day.date
      if (!model[roomID]) model[roomID] = {}

      paymentType = day.payment_type
      model[roomID][date] = {
        reservation: reservation.pk,
        migratedTo: migrationMap[date][1],
        migratedFrom: migrationMap[date][0],
        paymentType,
      }
    })
  })

  return {
    reservations: processedReservations,
    model,
  }
}
