import { request } from "./request";
import { getRoomsCall } from '../../ccAPI/requests'

export const checkinCall = ({
                              room,
                              check_in_date,
                              check_in_time,
                              check_out_date,
                              check_out_time,
                            }) =>
  request({
    url: "/pacs/",
    method: "post",
    data: {
      method: "checkin",
      room,
      check_in_date,
      check_in_time,
      check_out_date,
      check_out_time,
    },
    log: true,
  })

export const checkoutCall = ({ room }) =>
  request({
    url: '/pacs/',
    method: 'post',
    data: {
      method: 'checkout',
      room,
    },
    log: true,
  })

export const copycardCall = ({
                               room,
                               check_in_date,
                               check_in_time,
                               check_out_date,
                               check_out_time,
                             }) =>
  request({
    url: "/pacs/",
    method: "post",
    data: {
      method: "copycard",
      room,
      check_in_date,
      check_in_time,
      check_out_date,
      check_out_time,
    },
    log: true,
  })

export const checkCardCall = async ({ lcode }) => {
  const readCardRes = await readCardCall()

  const { data, error } = readCardRes

  if (error)
    return {
      isSuccess: false,
      error,
    }

  if (!data)
    return {
      isSuccess: false,
      error: 'Неизвестная ошибка',
    }

  const rooms = await getRoomsCall(lcode)

  const roomID = data.room

  if (roomID === 0) {
    return {
      isSuccess: true,
      meta: { isEmpty: true },
    }
  }

  const roomObj = rooms.find(room => room.room_id === roomID)

  if (!roomObj)
    return {
      isSuccess: true,
      meta: { isEmpty: false, roomNotFound: true },
    }

  const { name: roomName } = roomObj

  let tms = data.starttime.substr(0, data.endtime.indexOf(' '))
  let dts = data.starttime
    .substr(data.endtime.indexOf(' ') + 1)
    .replace(/-/g, '.')
  let tme = data.endtime.substr(0, data.endtime.indexOf(' '))
  let dte = data.endtime
    .substr(data.endtime.indexOf(' ') + 1)
    .replace(/-/g, '.')
  tms = tms.substr(0, tme.lastIndexOf(':'))
  tme = tme.substr(0, tme.lastIndexOf(':'))

  return {
    isSuccess: true,
    meta: {
      isEmpty: false,
      roomNotFound: false,
      roomID,
      roomName,
      checkInDate: dts,
      checkInTime: tms,
      checkOutDate: dte,
      checkOutTime: tme,
    },
  }
}

export const readCardCall = () =>
  request({
    url: '/pacs/',
    method: 'post',
    data: {
      method: 'readcard',
    },
  })


export const clearCardCall = () =>
  request({
    url: '/pacs/',
    method: 'post',
    data: {
      method: 'copycard',
    },
  })
