import {createSelector} from 'reselect'
import moment from 'moment'
import {loyaltyClientSelectors} from '../loyalty-client'
import {loyaltyPurchaseSelectors} from '../loyalty-purchase'
import {reservationSelectors} from '../reservation'
import {getCalculatedItems} from '../helpers/loyalty/getCalculatedItems'
import paymentTypes from '../helpers/paymentTypes'
import {settingsSelectors} from '../settings'
import {currentDateSelectors} from '../current-date'

export const isUsed = createSelector(
  loyaltyClientSelectors.isLoaded,
  isClientLoaded => isClientLoaded
)

function normalizePhoneNumber(phoneNumber) {
    return phoneNumber.replace(/\D/g, '')
}

export const isClientActual = createSelector(
  loyaltyClientSelectors.isLoaded,
  loyaltyClientSelectors.phoneNumber,
  reservationSelectors.phoneNumber,
  (isLoaded, pn1, pn2) => {
    if (!isLoaded) return false

    if (!pn1 || !pn2) return false

    const cleanReservationPN = normalizePhoneNumber(pn1)
    const cleanLoyaltyPN = normalizePhoneNumber(pn2)

    return cleanReservationPN === cleanLoyaltyPN
  }
)

export const isCalculated = createSelector(
  loyaltyPurchaseSelectors.isLoaded,
  isCalculationLoaded => isCalculationLoaded
)

export const isCalculationActual = createSelector(
  isCalculated,
  loyaltyPurchaseSelectors.calculatedFor,
  loyaltyClientSelectors.phoneNumber,
  reservationSelectors.purchaseTotal,
  reservationSelectors.itemsWithoutRefundIdIds,
  (
    isCalculated,
    calculatedFor,
    phoneNumber,
    purchaseTotal,
    itemsWithoutRefundIdIds
  ) => {
    if (!isCalculated) return false

    if (phoneNumber !== calculatedFor.phoneNumber) return false
    if (purchaseTotal !== calculatedFor.totalAmount) return false

    const areIdsEqual = (a, b) => {
      if (a.length !== b.length) return false
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false
      }
      return true
    }

    const equalityResult = areIdsEqual(
      calculatedFor.itemsIds,
      itemsWithoutRefundIdIds
    )
    if (!equalityResult) return false

    return true
  }
)

export const validation = createSelector(
  isUsed,
  isClientActual,
  isCalculated,
  isCalculationActual,
  loyaltyPurchaseSelectors.isConfirmed,
  loyaltyPurchaseSelectors.usedBonuses,
  (
    isUsed,
    isClientActual,
    isCalculated,
    isCalculationActual,
    isConfirmed,
    usedBonuses
  ) => {
    // loyalty is not enabled
    if (!isUsed)
      return {
        isValid: true,
      }

    // need to update loyalty phone number
    if (!isClientActual)
      return {
        isValid: false,
        error: 'Номера телефона в лояльности и в брони не совпадают.',
        suggestion:
          'Нажмите «Обновить номер телефона» во вкладке «Данные клиента».',
      }

    // need to recalculate for the new purchase total
    if (isConfirmed && !isCalculationActual)
      return {
        isValid: false,
        error: 'Расчёт бонусов не актуален для итоговой суммы покупки.',
        suggestion:
          'Нажмите «Обновить» во вкладке «Бонусы», чтобы загрузить актуальные данные.',
      }

    // we will calculate it automatically
    return {
      isValid: true,
    }
  }
)

export const isValid = state => validation(state).isValid

export const calculatedItems = createSelector(
  reservationSelectors.days,
  reservationSelectors.services,
  loyaltyPurchaseSelectors.calculatedRowsMap,
  (days, services, calculatedRowsMap) =>
    getCalculatedItems({
      items: { days, services },
      txid: '',
      calculatedRowsMap,
    })
)

export const remainingAmount = createSelector(
  reservationSelectors.purchaseTotal,
  loyaltyPurchaseSelectors.calculationResult,
  (purchaseTotal, calculationResult) => {
    const { totalDiscount } = calculationResult.summary
    return purchaseTotal - totalDiscount
  }
)

export const nextBonusesCount = createSelector(
  loyaltyClientSelectors.bonuses,
  loyaltyPurchaseSelectors.calculationResult,
  (currentBonuses, calculationResult) => {
    const { bonuses } = calculationResult
    const { applied, collected } = bonuses

    return currentBonuses - applied + collected
  }
)

export const canBeAppliedAutomatically = createSelector(
  reservationSelectors.itemsWithoutRefundId,
  reservationSelectors.isStartOkForAutomaticLoyaltyApply,
  reservationSelectors.isReservationStartToday,
  reservationSelectors.areTherePaidItemsWithoutRefundIdAndReady,
  // reservationSelectors.areTherePaidItemsWithoutRefundId,
  reservationSelectors.areTherePaidItemsWithRefundId,
  (
    { days },
    isStartOkForAutomaticLoyaltyApply,
    isReservationStartToday,
    areTherePaidItemsWithoutRefundIdAndReady,
    areTherePaidItemsWithRefundId
  ) => {
    if (areTherePaidItemsWithoutRefundIdAndReady) {
      return false
    }

    if (!isStartOkForAutomaticLoyaltyApply) {
      return false
    }

    const hasAtLeastOneDayPayedByBank = days.some(
      day => day.payment_type === paymentTypes.bank
    )

    if (hasAtLeastOneDayPayedByBank) {
      if (areTherePaidItemsWithRefundId) return true
      return isReservationStartToday;

    }

    return true
  }
)

export const canApplyBonusesAfterSaving = createSelector(
  settingsSelectors.isLoyaltyEnabled,
  isUsed,
  reservationSelectors.isLoyaltyAllowed,
  reservationSelectors.areTherePaidItemsWithoutRefundId,
  currentDateSelectors.today,
  reservationSelectors.reservationStart,
  (
    isEnabled,
    isUsed,
    isAllowed,
    areTherePaidItemsWithoutRefundId,
    currentDate,
    checkInDate
  ) => {
    return (
      isEnabled &&
      isAllowed &&
      isUsed &&
      areTherePaidItemsWithoutRefundId &&
      moment(currentDate).isSameOrAfter(checkInDate)
    )
  }
)

export const availableActions = createSelector(
  reservationSelectors.areTherePurchasedItemsWithAllowableLoyalty,
  canApplyBonusesAfterSaving,
  (areTherePurchasedItemsWithAllowableLoyalty, canApplyBonusesAfterSaving) => ({
    receive: areTherePurchasedItemsWithAllowableLoyalty,
    spend: areTherePurchasedItemsWithAllowableLoyalty,
    receiveForPaid: canApplyBonusesAfterSaving,
  })
)
