import React, { useCallback } from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

export const Textarea = ({
  name,
  label,
  type = 'text',
  placeholder = 'Введите значение',
  value,
  onChange = () => {},
  onFocus = () => {},
  disabled = false,
  hasError = false,
  size = 'small',
  rows = 4,
  noMargin = false,
}) => {
  const innerOnChange = useCallback(
    event => {
      const { value } = event.target

      onChange(value, event)
    },
    [onChange]
  )

  return (
    <FormGroup
      validationState={hasError ? 'error' : null}
      style={{ marginBottom: noMargin && 0 }}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={innerOnChange}
        onFocus={onFocus}
        disabled={disabled}
        componentClass="textarea"
        bsSize={size}
        rows={rows}
      />
    </FormGroup>
  )
}
