import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { checkMaidsUri, confirmMaidsUri } from '../Modules/api/ccAPI/requests'
import { Button } from '../Components/buttons'
import styled from 'styled-components'
import { Notifications } from '../Modules/Notifications'
import { useActions } from '../Components/common/hooks/use-actions'
import { sessionActions } from '../Modules/session'

const Block = styled.div`
  display: flex;
  height: 100%;

  > div {
    margin: auto;
    width: 350px;

    h4 {
      margin-bottom: 1rem;
      text-align: center;
    }

    button {
      width: 100%;
    }
  }
`

export const MaidsContainer = () => {
  const { uri } = useParams();
  const { maidLogin } = useActions(sessionActions)

  const [newUri, setNewUri] = useState()
  const [detail, setDetail] = useState(null)
  const [maidData, setMaidData] = useState(null)

  useEffect(() => {
    const maidName = new URLSearchParams(window.location.search).get("maid")
    const maidId = new URLSearchParams(window.location.search).get("id")
    checkMaidsUri({ uri }).then(({ name, uri }) => {
      setNewUri(uri)
      setMaidData({ admin: maidName, adminID: maidId })
      setDetail(`${name}, вы успешно авторизовались на смене`)
    }).catch((_) => {
      setNewUri(null)
      setDetail(`${maidName}, данный QR-код ранее уже был использован для авторизации. Убедитесь, что вы перешли по вашему QR-коду`)
    })
  }, [])

  const confirmHandler = () => {
    confirmMaidsUri({ uri: newUri }).then(({ access }) => {
      maidLogin({ ...maidData, token: access })
      Notifications.success('Успех')
    }).catch(_ => {
      Notifications.failure('Что-то пошло не так')
    })
  }

  return <Block>
    {uri && <div>
      <h4>{detail}</h4>
      <br />
      <Button
        size="lg"
        disabled={!newUri}
        onClick={confirmHandler}
      >Подтвердить</Button>
    </div>}
  </Block>
}
