import React, { useCallback } from 'react'
import { Input } from './Input'

function isNumber(value) {
  const casted = Number(value)

  return !Number.isNaN(casted)
}

export const InputNumber = ({
  label,
  type = 'text',
  placeholder = 'Введите значение',
  value,
  onChange = () => {},
  disabled = false,
  hasError = false,
  size = 'small',
  noMargin = false,
  onlyPositive = true,
}) => {
  const innerOnChange = useCallback(
    (value, event) => {
      const string = String(value)

      if (string === '' || string === '-') {
        onChange('')
        return
      }

      const hasZerosOnStart = string.length >= 2 && string.startsWith('0')
      const isPositive = value > 0

      const skipCond =
        !isNumber(value) || (onlyPositive && !isPositive) || hasZerosOnStart

      if (skipCond) {
        event.preventDefault()
        return
      }

      onChange(value)
    },
    [onChange, onlyPositive]
  )

  return (
    <Input
      label={label}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={innerOnChange}
      disabled={disabled}
      hasError={hasError}
      size={size}
      noMargin={noMargin}
    />
  )
}
