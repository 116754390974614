import * as types from './types'
import { getRoomsCallByDate } from '../api/ccAPI/requests'
import moment from 'moment'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = () => async (dispatch) => {
  dispatch(loadRequest())
  const dateReq = moment().format('YYYY-MM-DD')
  try {
    const items = await getRoomsCallByDate(dateReq)

    dispatch(loadSuccess({ items }))
  } catch {
    dispatch(loadFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
