import axios from 'axios'
import {
  addLocalRequestInterceptors,
  axiosInstance as localApiAxiosInstance,
  loggedRequests,
} from './localAPI/request'
import { LogsAPI } from '../logs-api'
import { localBaseUrl } from '../config/config'

function defaultSuccessCallback(response) {
  return response
}

function defaultFailureCallback(error) {
  return Promise.reject(error)
}

function addInterceptors(
  instance,
  { success = defaultSuccessCallback, failure = defaultFailureCallback }
) {
  instance.interceptors.response.use(success, failure)
}

// function createSuccessCallback(stages) {
//   return response => {
//     for (const stage of stages) {
//       stage(response)
//     }

//     return defaultSuccessCallback(response)
//   }
// }

function createFailureCallback(stages) {
  return error => {
    for (const stage of stages) {
      stage(error)
    }

    return defaultFailureCallback(error)
  }
}

function logError(error) {
  const { url, method, data: requestData, headers } = error.config
  const { data: responseData, status, statusText } = error.response

  const recordData = JSON.stringify({
    request: {
      url,
      method,
      data: requestData,
      headers,
    },
    response: {
      data: responseData,
      status,
      statusText,
    },
  })

  LogsAPI.custom(`Произошла ошибка при запросе: ${recordData}`)
}

export function setupInteceptors() {
  addInterceptors(axios, {
    failure: createFailureCallback([logError]),
  })

  addInterceptors(localApiAxiosInstance, {
    failure: createFailureCallback([logError]),
  })

  const createKey = config => {
    const { url, method } = config
    return `${method}-${url}`.replace(localBaseUrl, '')
  }

  addLocalRequestInterceptors({
    requestHandlers: [
      config => {
        const { url, method } = config
        const key = createKey(config)

        if (loggedRequests.has(key)) {
          const { params, data } = config
          const payload = JSON.stringify({ params, data })
          LogsAPI.custom(`[${method}] ${url} <= ${payload}`)
        }
      },
      error => {
        if (typeof error !== 'object') return

        const { config } = error
        const { url, method } = config
        const key = createKey(config)

        if (loggedRequests.has(key)) {
          LogsAPI.custom(
            `[${method}] ${url} - не удалось отправить запрос: ${error?.message}`
          )
        }
      },
    ],
    responseHandlers: [
      response => {
        const { config } = response
        const key = createKey(config)

        if (loggedRequests.has(key)) {
          const { url, method } = config
          const payload = JSON.stringify(response.data || {})
          LogsAPI.custom(
            `[${method}] ${url} => [${response.status}] ${payload}`
          )
        }
      },
      error => {
        if (typeof error !== 'object') return

        const { config, response } = error
        const { url, method } = config
        const key = createKey(config)

        if (loggedRequests.has(key)) {
          const payload = JSON.stringify(response.data || {})
          LogsAPI.custom(
            `[${method}] ${url} => [${response.status}] ${payload}`
          )
        }
      },
    ],
  })
}
