import React from 'react'
import styled, { css } from 'styled-components'
import { PageBase } from './PageBase'

const childMargins = css`
  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`

const VerticalInner = styled.div`
  width: 210mm;
  min-height: 296mm;
  padding: 5mm;

  ${childMargins}
`

const HorizontalInner = styled.div`
  width: 296mm;
  height: 210mm;
  padding: 5mm;

  transform: translateX(210mm) rotate(90deg);
  transform-origin: left top;

  ${childMargins}
`

export const Page = ({ horizontal = false, children }) => {
  const Inner = horizontal ? HorizontalInner : VerticalInner

  return (
    <PageBase>
      <Inner>{children}</Inner>
    </PageBase>
  )
}
