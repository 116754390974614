import { callModal, modalTemplates } from '../../Components/dynamic-modal'
import { numbersREs } from '../helpers/regex/numbers'

export async function inputAmount() {
  const { action, params } = await callModal(
    modalTemplates.prompt({
      title: 'Введите сумму',
      text:
        'Введите целое число или число с 2 знаками после запятой или точки:',
      fieldName: 'Сумма',
      validators: [
        ...modalTemplates.prompt.defaultValidators,
        {
          fn: value => {
            return numbersREs.positiveWithDotOrComma.test(value)
          },
          message: 'Неверный формат',
        },
      ],
    })
  )

  if (action !== modalTemplates.prompt.actions.enter) {
    return null
  }

  const { value } = params

  return Number(value.replace(',', '.'))
}
