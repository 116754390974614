import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import { roomsSelectors } from '../../../Modules/rooms'

export const RoomSelect = ({
  name,
  value,
  onChange,
  withLabel = false,
  disabled,
}) => {
  const rooms = useSelector(roomsSelectors.items)

  const roomOptions = useMemo(() => {
    return rooms.map(room => {
      const label = `${room.room_id} (${room.name})`

      return (
        <option key={room.room_id} value={room.room_id}>
          {label}
        </option>
      )
    })
  }, [rooms])

  return (
    <FormGroup style={{ width: '100%', marginBottom: 20 }}>
      {withLabel && <ControlLabel>Комната</ControlLabel>}
      <FormControl
        name={name}
        componentClass="select"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="">Выберите комнату</option>,{roomOptions}
      </FormControl>
    </FormGroup>
  )
}
