import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { newsActions, newsSelectors } from './reducer'
import styled from 'styled-components'
import {
  Table, Badge, Pagination
} from 'react-bootstrap'
import moment from 'moment'
import { useActions } from '../../common/hooks/use-actions'
import { Button } from '../../buttons'
import Select, { components } from 'react-select'
import { callModal, modalTemplates } from '../../dynamic-modal'
import { modalActions } from '../../dynamic-modal/templates/confirm'

const page_size = 10

const Block = styled.div`
  padding: 100px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
`
const Td = styled.td`
  vertical-align: middle !important;

  &:first-of-type, &:last-of-type {
    width: 20%;
  }
`

const ShowWrapper = styled.div`
  white-space: pre-wrap;
  cursor: default;
  height: ${props => props.all ? 'auto' : '15rem'};
  position: relative;
  overflow: hidden;
  transition: all .3s ease-in-out;
`

const ShowMoreWrapper = styled.div`
  white-space: pre-wrap;
  height: ${props => props.all ? 'auto' : '15rem'};
  position: relative;
  overflow: hidden;
  transition: all .3s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background-image: ${props => props.all ? 'none' : 'linear-gradient(180deg,hsla(0,0%,100%,0) 0,#ffff 50%,#ffff)'};
  }
`

const ShowMore = styled.a`
  color: #222;
  padding-bottom: 1px;
  font-weight: 600;
  float: right;

  &:hover {
    cursor: pointer;
    color: #222;
    text-decoration: underline;
    text-decoration-style: dashed;
  }`

const Stack = styled.div`
  display: flex;
  gap: 5px;
`

const DescriptionHtml = styled.div`
  img {
    display: block;
    max-width: 350px;
    max-height: 200px;
    margin: 10px;
  }
`

const SelectedItem = styled.div`
  background-color: ${props => props.color};

  & > div {
    height: 100%;
  }
`
const buildCategoriesList = list => list.map(({ value }) => ({ id: value }))

const DescriptionItem = ({ description }) => {
  const isLong = description.length > 500 || description.matchAll(/\r\n/g).length > 10
  const [more, setMore] = useState(!isLong)
  if (isLong) {
    return <><ShowMoreWrapper all={more}>
      <DescriptionHtml dangerouslySetInnerHTML={{ __html: description }} />
    </ShowMoreWrapper>
      <ShowMore onClick={() => setMore(!more)}>Подробнее</ShowMore>
    </>
  } else {
    return (<ShowWrapper all={more}>
      <DescriptionHtml dangerouslySetInnerHTML={{ __html: description }} />
    </ShowWrapper>)
  }
}


const NewsItem = ({ item }) => {

  return (<div>
    <Title>{item.title}</Title>
    <Stack>
      {item.categories.map((it) => (
        <Badge style={{ backgroundColor: it.color }} key={it.id + '_' + item.id}>{it.name}</Badge>
      ))}
    </Stack>
    <DescriptionItem description={item.description} />
  </div>)
}

const TableBody = ({ list, setRead, showControls }) => {
  const read = (id) => {
    setRead({ id })
  }

  return (
    <tbody>
    {list && list.map((it) => {
      return (<tr key={it.id + '_news'} style={{ paddingBottom: 30 }}>
        <Td>{moment(it.publication_at).format('DD.MM.YYYY HH:mm')}</Td>
        <Td><NewsItem item={it} /></Td>
        <Td> {showControls && !it.mark && <Button onClick={() => read(it.id)}>Пометить прочитанным</Button>}
        </Td>
      </tr>)
    })}
    </tbody>
  )
}

const MultiValueLabel = (props) => {
  return (
    <SelectedItem color={props.data.color}>
      <components.MultiValueLabel {...props} />
    </SelectedItem>
  );
};

const MultiValueRemove = (props) => {
  return (
    <SelectedItem color={props.data.color}>
      <components.MultiValueRemove {...props} />
    </SelectedItem>
  );
};

const work = "work"
const completed = "completed"

export const News = () => {
  const list = useSelector(newsSelectors.items)
  const count = useSelector(newsSelectors.count)
  const categories = useSelector(newsSelectors.categoryOptions)
  const categoriesIds = useSelector(newsSelectors.categoriesIds)
  const { load, checkNews, getCategories, loadNewCount, readAll } = useActions(newsActions)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [selectedCategories, setSelectedCategories] = useState(null)
  const [urlCategories, setUrlCategories] = useState("")
  const [showMarked, setShowMarked] = useState(false)
  const [showInWork, setShowInWork] = useState(false)

  useEffect(() => {
    getCategories()
    loadNewCount()
  }, [])

  useEffect(() => {
    const num = Math.ceil(count / page_size)
    setPageCount(num)
    const pages = Array.from({ length: num }, (_, i) => i + 1)
    setPages(pages)
  }, [count])

  useEffect(() => {
    load({
      page,
      page_size,
      is_not_marked: showMarked || null,
      status: showInWork ? work : completed
    }, urlCategories)
  }, [page, urlCategories, showMarked, showInWork])

  const selectCategory = (val = []) => {
    setPage(1)
    if (val) {
      const url = new URL(window.location);
      val.forEach(({ value }) => url.searchParams.append('category', value))
      setUrlCategories(url.search)
      const categories = buildCategoriesList(val)
      setSelectedCategories({ categories })
    } else {
      setUrlCategories("")
      setSelectedCategories(null)
    }
  }

  const readAllHandler = async () => {
    const { action } = await callModal(modalTemplates.confirm({
      title: 'Подтвердите',
      text: 'Вы уверены, что хотите пометить все новости прочитанными?',
      buttons: ['Нет', 'Да'],
    }))
    if (action === modalActions.confirm)
      if (selectedCategories)
        readAll(selectedCategories)
      else readAll(categoriesIds)
  }
  return (<Block>
    <Select
      placeholder="Выберите категории новостей"
      isMulti
      options={categories}
      components={{ MultiValueLabel, MultiValueRemove }}
      onChange={selectCategory}
      styles={{
        container: (base) => ({
          ...base,
          paddingBottom: 20,
        })
      }}
    />
    <Table>
      <thead>
      <tr>
        <th width="20%">
          <Button type="warning" onClick={() => setShowInWork(!showInWork)}>
            {showInWork ? 'Показать завершенные' : 'Показать в работе'}
          </Button>
        </th>
        {showInWork
          ? <>
            <th />
            <th />
          </>
          : <>
            <th style={{ textAlign: 'right' }}><Button
              onClick={() => setShowMarked(!showMarked)}>{showMarked ? 'Показать все' : 'Показать непрочитанные'}</Button>
            </th>
            <th width="20%"><Button onClick={readAllHandler}>Пометить все прочитанным</Button></th>
          </>
        }
      </tr>
      </thead>
      <TableBody
        list={list}
        setRead={checkNews}
        showControls={!showInWork}
      />
    </Table>

    <Pagination>
      <Pagination.First onClick={() => setPage(1)} />
      <Pagination.Prev onClick={() => setPage(prev => prev - 1 || 1)} />
      {pages.map(it => <Pagination.Item
        key={'page_' + it}
        active={page === it}
        onClick={() => setPage(it)}
      >{it}</Pagination.Item>)}
      <Pagination.Next onClick={() => {
        if (page + 1 <= count) setPage(prev => prev + 1)
      }} />
      <Pagination.Last onClick={() => setPage(pageCount)} />
    </Pagination>
  </Block>)
}

