import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { washingSelectors } from '../../../../Modules/washing'

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`

const FreeNumber = styled.span`
  font-weight: 700;
`

const Content = () => {
  const { isLoading, isLoaded, isFailed } = useSelector(
    washingSelectors.loadState
  )
  const numberOfFreeMachines = useSelector(
    washingSelectors.numberOfFreeMachines
  )

  if (isLoading) {
    return <div>Загрузка данных..</div>
  }

  if (isFailed) {
    return <div>Не удалось загрузить данные</div>
  }

  if (!isLoaded) {
    return <div>Нет данных</div>
  }

  if (numberOfFreeMachines === 0) {
    return <div>Нет свободных машинок</div>
  }

  return (
    <span>
      Свободных машинок: <FreeNumber>{numberOfFreeMachines}</FreeNumber>
    </span>
  )
}

export const FreeWashingMachines = () => {
  return (
    <div style={{ padding: 30 }}>
      <Title>Статус стиральных машинок</Title>
      <Content />
    </div>
  )
}
