import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import history from '../../../../Modules/helpers/history'
import { Table, Button } from 'react-bootstrap'

const calculateTotal = reservation => {
  let total = 0
  for (let i = 0; i < reservation.reserved_days.length; i++) {
    total = total + parseFloat(reservation.reserved_days[i].price)
  }

  for (let i = 0; i < reservation.additional_services.length; i++) {
    total = total + parseFloat(reservation.additional_services[i].price)
  }

  return Math.round(total)
}

const NoShowTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`

const RTD = styled.td`
  vertical-align: middle !important;
  padding: 16px 8px;
  width: ${props => props.width}px;
  text-align: ${props => props.textAlign};
`

const NoShowHint = styled.p`
  font-size: 14px;
  margin-top: 0;
  color: #da4e4e;
`

const GRCell = styled.td.attrs(() => ({
  colSpan: 3,
}))`
  font-weight: 700;
  color: #555;
  text-align: center;
  cursor: default;

  &:hover {
    background-color: #fff;
  }
`

const GRHeader = props => (
  <tr>
    <GRCell>Групповая бронь</GRCell>
  </tr>
)

const GRFooter = props => (
  <tr>
    <GRCell>Общая стоимость групповой брони: {props.total} ₽</GRCell>
  </tr>
)

const ReservationRow = ({ reservation }) => (
  <tr
    style={{ cursor: 'pointer' }}
    onClick={() => {
      history.push('/reservation?pk=' + reservation.pk)
    }}
  >
    <RTD width={120}>{reservation.booking_number}</RTD>
    <RTD width={150}>{reservation.guest_name}</RTD>
    <RTD width={110}>{moment(reservation.end).format('DD MMMM')}</RTD>
    <RTD width={80} textAlign="right">
      {calculateTotal(reservation)} ₽
    </RTD>
  </tr>
)

const NoShowTable = ({ data }) => {
  if (!data.length) {
    return (
      <div style={{ marginBottom: 10 }}>Незаезды за этот день не найдены</div>
    )
  }

  const groupedByBN = {}
  data.forEach(r => {
    if (!groupedByBN[r.booking_number]) groupedByBN[r.booking_number] = []
    groupedByBN[r.booking_number].push(r)
  })

  const rows = []
  let idx = 0
  for (let bn in groupedByBN) {
    let total = 0
    const isGroup = groupedByBN[bn].length > 1

    if (isGroup) rows.push(<GRHeader key={idx++} />)

    // eslint-disable-next-line
    groupedByBN[bn].forEach(r => {
      rows.push(<ReservationRow reservation={r} key={idx++} />)
      total += calculateTotal(r)
    })

    if (isGroup) rows.push(<GRFooter total={total} key={idx++} />)
  }

  return (
    <Table
      responsive
      className="table-hover condensed"
      style={{ width: '100%' }}
    >
      <thead>
        <tr>
          <th>Номер брони</th>
          <th>Гость</th>
          <th>Дата выезда</th>
          <th style={{ textAlign: 'right' }}>Цена</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}

class TodayNoShow extends Component {
  renderContent = () => {
    const { todayNoShow, todayNoShowActions } = this.props
    const { isLoading, isLoaded, isFailed } = todayNoShow

    if (isLoading) return (<div>Загрузка..</div>)

    if (isFailed)
      return (
        <React.Fragment>
          <p>Ошибка загрузки данных</p>
          <Button bsSize="sm" onClick={todayNoShowActions.loadData}>
            Попробовать еще раз
          </Button>
        </React.Fragment>
      )

    if (!isLoaded) return (<div>Нет данных</div>)

    const { data } = todayNoShow

    return (
      <React.Fragment>
        {data.length > 0 && (
          <NoShowHint>
            Чтобы зайти в просмотр брони, нажмите на нужную строку таблицы
          </NoShowHint>
        )}
        <NoShowTable data={data} />
      </React.Fragment>
    )
  }

  render() {
    const content = this.renderContent()

    return (
      <div style={{ padding: 30 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <NoShowTitle>
            Незаезды за{' '}
            {moment()
              .subtract(1, 'days')
              .format('DD MMMM')}
          </NoShowTitle>
        </div>

        {content}
      </div>
    )
  }
}

export default TodayNoShow
