import React from 'react'

import { A4, Field, Head, Name, SeparatorLine, SeparatorText, Space, StyledText, Text, TitleBlock } from './FieldUtils'
import moment from 'moment'

import headLogo from '../../../Assets/head-logo.jpg'


const Separator = props => (
  <SeparatorLine variant={props.variant}>
    <SeparatorText variant={props.variant} weight={props.weight}
                   empty={props.empty}>      {props.children}    </SeparatorText>
  </SeparatorLine>)

const BlankContent = ({ reservation }) => (
  <React.Fragment>
    <A4>
      <Head src={headLogo} />
      <TitleBlock>
        <StyledText>ЗАЯВЛЕНИЕ</StyledText>
        <StyledText>на возврат денежных средств.</StyledText>
      </TitleBlock>

      <Field>
        <Name> Прошу произвести возврат денежных средств по бронированию №* </Name>
        <Text width={25}>{reservation.booking_number}</Text>
      </Field>
      <Space height={10} />
      <Field>
        <Name>Ф.И.О.</Name>
        <Text width={45}>{reservation.guest_name}</Text>
      </Field>
      <Field>
        <Name>Подпись заявителя</Name>
        <Text width={15} />
      </Field>
      <Field> <Name>Дата заявления:</Name> <Text width={25}>{moment().format("DD.MM.YYYY HH:mm:SS")}</Text> </Field>
      <Field> <Name>М.П. (для заявлений от юридических лиц)</Name> </Field>
      <Field> <Name italic bold>Возврат производится в течение 14 календарных дней</Name> </Field>
      <Field>
        <Name bold italic> Возврат осуществляется тем же способом, которым была осуществлена оплата. </Name>
      </Field>
      <Separator variant="dotted">ЗАПОЛНЯЕТСЯ АДМИНИСТРАТОРОМ</Separator>
      <Field> <Name bold>Расчет суммы возврата:</Name> </Field>
      <Field> <Name>1. Изначальная сумма оплаты:</Name> <Text width={30} /> </Field>
      <Field>
        <Name> 2. Способ оплаты : Наличные / Банковская карта / Безналичная оплата / Наш сайт </Name>
        <Name italic>(нужное подчеркнуть)</Name>
      </Field>
      <Field> <Name>3. Сумма удержания:</Name> <Text width={30} /> </Field>
      <Field> <Name>4. ИТОГО к выплате:</Name> <Text width={30} /> </Field>
      <Space height={7} />
      <Field> <Name>Заявление принял(-а)</Name> <Text width={35} /> <Name>/Ф.И.О. сотрудника/</Name> </Field>
      <Space height={10} />
      <Field justify="end"> <Text width={15} /> <Name>/Подпись сотрудника/</Name> </Field>
      <Separator variant="dotted" empty />
      <Field> <Name italic> *при отсутствии номера бронирования указывается «без брони». </Name> </Field>
      <Space height={10} />
      <Separator variant="dashed" weight="bold"> КОПИЯ ЗАЯВЛЕНИЯ С ПЕЧАТЬЮ ОРГАНИЗАЦИИ ВЫДАЕТСЯ ГОСТЮ </Separator>
    </A4>
  </React.Fragment>
)

export default BlankContent
