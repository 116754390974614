import { createSelector } from 'reselect'
import { sessionSelectors } from '../../../../Modules/session'

export const everything = state => state.files

export const items = state => everything(state).items

export const availableFiles = createSelector(
  items,
  sessionSelectors.lcode,
  (items, lcode) =>
    items.filter(item => {
      for (let data of item.lcodes) {
        if (data.lcode === lcode) {
          return true
        }
      }

      return false
    })
)

export const sharedFiles = createSelector(availableFiles, availableFiles =>
  availableFiles.filter(item => {
    return item.lcodes.length > 1
  })
)

export const privateFiles = createSelector(availableFiles, availableFiles =>
  availableFiles.filter(item => {
    return item.lcodes.length === 1
  })
)

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const loadingState = createSelector(
  isLoading,
  isLoaded,
  isFailed,
  (isLoading, isLoaded, isFailed) => ({
    isLoading,
    isLoaded,
    isFailed,
  })
)
