import {
  callModal,
  modalTemplates,
} from '../../../Components/dynamic-modal'

export const commonPrintFailure = ({ printResult }) => {
  callModal(
    modalTemplates.alert({
      title: 'Ошибка',
      text: `Не удалось напечатать чек: ${printResult.Error}`,
    })
  )
}
