import React from 'react'
import styled from 'styled-components'

const TimerBlock = styled.div`
  text-align: center;
`

const TimerCounter = styled.span`
  font-size: 124px;
  color: #fff;
  margin-bottom: 20px;
`

const TimerText = styled.p`
  color: #fff;
`

const Timer = ({ counter }) => (
  <TimerBlock>
    <TimerCounter>{counter}</TimerCounter>
    <TimerText>Доступное время для оплаты с карты</TimerText>
  </TimerBlock>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 161;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
`

export const PayTimerView = ({ isActive, counter }) => {
  if (!isActive) return null

  return (
    <Container>
      <Timer counter={counter} />
    </Container>
  )
}
