import React, { useState, useCallback, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { paymentTypesNoBank, paymentTypesList } from '../../../../../Modules/helpers/paymentTypes'
import { Select, InputNumber } from '../../../../form-stuff'
import { ButtonGroup, Button } from '../../../../buttons'
import {
  defaultPaymentTypeOption,
  defaultQuantityOption,
} from '../../../constants/default-values'
import { ServiceTypeSelect } from './ServiceTypeSelect'
import { useDateSelectData } from '../../../common/hooks/use-date-select-data'

export const Footer = ({ availableDates, availableServices, actions }) => {
  const {
    date,
    setDate,
    resetDate,
    dateOptions,
    dateDefaultOption,
  } = useDateSelectData(availableDates)

  const [service, setService] = useState(null)
  const [quantity, setQuantity] = useState('')
  const [paymentType, setPaymentType] = useState('')
  const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

  useEffect(() => {
    const list = service ? service.is_available_for_sale_by_bank
      ? paymentTypesList
      : paymentTypesNoBank
      : paymentTypesList
    setPaymentTypeList(list)
    setPaymentType('')
  }, [service])

  const resetValues = () => {
    resetDate()
    setService(null)
    setQuantity('')
    setPaymentType('')
  }

  const [errors, setErrors] = useState([])
  const resetErrors = useCallback(() => setErrors([]), [])

  const handleSubmit = () => {
    resetErrors()

    const currentErrors = []

    if (!date) currentErrors.push('date')
    if (!service) currentErrors.push('service')
    if (!quantity) currentErrors.push('quantity')
    if (!paymentType) currentErrors.push('paymentType')

    if (currentErrors.length > 0) {
      setErrors(currentErrors)
      return
    }

    const serviceData = {
      name: service.name,
      price: service.price,
      quantity,
      paymentType,
      date,
    }

    if (service.bonus_rate != null) {
      serviceData.special_service_id = service.pk
    }

    actions.create(serviceData)

    resetValues()
  }

  const isWashing = service?.name === 'Стирка'

  useEffect(() => {
    if (!isWashing) return
    if (paymentType !== 'Безналичный расчет') return
    setPaymentType('')
  }, [isWashing, paymentType])

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Col xs={3}>
          <Select
            label="Дата проживания"
            defaultOption={dateDefaultOption}
            options={dateOptions}
            value={date}
            onChange={setDate}
            hasError={errors.includes('date')}
          />
        </Col>
        <Col xs={3}>
          <ServiceTypeSelect
            service={service}
            services={availableServices}
            onChange={setService}
            hasError={errors.includes('service')}
          />
        </Col>
        <Col xs={3}>
          <InputNumber
            label="Количество"
            defaultValue={defaultQuantityOption}
            value={quantity}
            onChange={setQuantity}
            hasError={errors.includes('quantity')}
          />
        </Col>
        <Col xs={3}>
          <Select
            label="Метод оплаты"
            defaultOption={defaultPaymentTypeOption}
            options={paymentTypeList}
            value={paymentType}
            onChange={setPaymentType}
            hasError={errors.includes('paymentType')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3} xsOffset={9}>
          <ButtonGroup>
            <Button
              type="success"
              onClick={handleSubmit}
              style={{ width: '100%' }}
            >
              Добавить услугу
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </React.Fragment>
  )
}
