import { createGlobalStyle } from 'styled-components'

const ReactModalStyles = createGlobalStyle`
  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.25s;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`

export default ReactModalStyles
