import React from 'react'
import { Block, Title } from './styleds'
import { List } from './List'

const Content = ({ data }) => {
  const { items, isLoading, isFailed } = data

  if (isLoading) return <div>Загрузка..</div>
  if (isFailed) return <div>Ошибка загрузки</div>
  if (items.length === 0) return <div>Нет данных</div>

  return <List items={items} />
}

export const Notes = ({ data }) => {
  return (
    <Block>
      <Title>Заметки</Title>
      <Content data={data} />
    </Block>
  )
}
