import styled from 'styled-components'

export const ItemText = styled.p`
  font-size: 12px;
  color: ${props => props.color};

  &:last-child {
    margin-bottom: 0;
  }
`
