import { settingsSelectors } from '../../settings'
import { cancelWashing } from '../../api/ccAPI/requests'

export const deleteIrrelevantWashingOperations = async ({
  checkType,
  items,
  scenarioState,
  reduxStates,
}) => {
  if (checkType !== 'refund') {
    return { success: true }
  }

  const isWashingOperationsOn = settingsSelectors.isWashingOperationsOn(
    reduxStates.current
  )

  if (!isWashingOperationsOn) {
    return { success: true }
  }

  if (!scenarioState.succeed) {
    return
  }

  const { services } = items

  const refundedWashingServices = services.filter(
    service => service.name === 'Стирка'
  )

  if (refundedWashingServices.length === 0) {
    return { success: true }
  }

  const washingServicesUuids = refundedWashingServices.map(
    service => service.uuid
  )

  const cancelationPromises = washingServicesUuids.map(uuid => {
    return cancelWashing({ service_uuid: uuid })
  })

  try {
    await Promise.all(cancelationPromises)
    return { success: true }
  } catch {
    return { success: false }
  }
}
