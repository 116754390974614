import * as types from './types'

const initialState = {
    shiftId: null,
    reviewShiftId: null,
    status: null,
    checklistsCompleted: null,
    openItems: [],
    closeItems: [],
    reviewItems: [],
    startAt: null,
    endAt: null,
    isLoading: false,
    isLoaded: false,
    isFailed: false,
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_DATA_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isFailed: false,
            }

        case types.LOAD_DATA_OPEN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_CLOSE_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.LOAD_DATA_SHIFT:
            return {
                ...state,
                ...payload
            }

        case types.LOAD_DATA_REVIEW_SUCCESS:
            return {
                ...state,
                ...payload
            }

        case types.RESET:
            return initialState

        default:
            return state
    }
}
