import * as types from './types'

const initialState = {
  items: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_REQUEST:
      return {
        ...state,
        items: [],
        isLoading: true,
        isLoaded: false,
        isFailed: false,
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        items: payload.items,
        isLoading: false,
        isLoaded: true,
      }

    case types.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
