import * as types from './types'
import { createReducer } from '../../../Modules/helpers/redux/createReducer'

const initialState = {
  date: null,
}

export const reducer = createReducer(initialState, {
  [types.SET_DATE]: (state, payload) => ({
    ...state,
    date: payload.date
  }),
})
