import React from 'react'
import styled, { css } from 'styled-components'
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import { LogsAPI } from '../../Modules/logs-api'

const mapSizeToPaddings = {
  small: {
    header: '10px 20px',
    content: '16px 20px',
    footer: '10px 20px',
  },
  normal: {
    header: '20px 28px',
    content: '28px',
    footer: '20px 28px',
  },
  big: {
    header: '48px',
    content: '48px',
    footer: '48px',
  },
}

const mapSizeToNameFontSize = {
  small: 16,
  normal: 18,
  big: 20,
}

const Block = styled.div`
  margin-bottom: 32px;
  background-color: ${props => props.customColor ?? '#fbfbfb'};
  border: 1px solid #dadce0;
  border-radius: 8px !important;

  &:last-child {
    margin-bottom: 0;
  }
`

const withPaddingRelation = css`
  padding: ${props => props.padding};
`

const withBorderBottom = css`
  border-bottom: 1px solid #dadce0;

  &:last-child {
    border-bottom: none;
  }
`

const Header = styled.div`
  ${withPaddingRelation}
  ${withBorderBottom}

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div`
  ${withPaddingRelation}
  ${withBorderBottom}
`

const Footer = styled.div`
  ${withPaddingRelation}
`

const Name = styled.h3`
  margin: 0;
  font-size: ${props => props.fontSize}px;
  font-weight: 600;
  line-height: 24px;
`

export const IconBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
`

const Body = ({ isVisible, isLoading, content, footer, paddings }) => {
  if (!isVisible) return null

  if (isLoading) return <Content padding={paddings.content}>Загрузка..</Content>

  return (
    <React.Fragment>
      {content && <Content padding={paddings.content}>{content}</Content>}
      {footer && <Footer padding={paddings.footer}>{footer}</Footer>}
    </React.Fragment>
  )
}

const ToggleIcon = ({ isOpen, open, close }) => {
  return (
    <IconBlock>
      {isOpen ? <FaEyeSlash onClick={close} /> : <FaEye onClick={open} />}
    </IconBlock>
  )
}

export class EditSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: props.defaultVisibility,
    }
  }

  show = () => {
    const { name } = this.props

    LogsAPI.event(`Показать блок ${name}`)

    this.setState({ isVisible: true })
  }

  hide = () => {
    const { name } = this.props

    LogsAPI.event(`Скрыть блок ${name}`)

    this.setState({ isVisible: false })
  }

  render() {
    const { isVisible } = this.state
    const {
      name,
      size,
      content,
      footer,
      isLoading,
      withVisibilityToggle,
      customColor,
    } = this.props

    const paddings = mapSizeToPaddings[size]
    const nameFontSize = mapSizeToNameFontSize[size]

    return (
      <Block customColor={customColor}>
        <Header padding={paddings.header}>
          <Name fontSize={nameFontSize}>{name}</Name>
          {withVisibilityToggle && (
            <ToggleIcon isOpen={isVisible} open={this.show} close={this.hide} />
          )}
        </Header>
        <Body
          isVisible={isVisible}
          isLoading={isLoading}
          content={content}
          footer={footer}
          paddings={paddings}
        />
      </Block>
    )
  }
}

EditSection.defaultProps = {
  size: 'normal',
  defaultVisibility: true,
  withVisibilityToggle: false,
}
