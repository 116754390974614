import React from 'react'
import styled from 'styled-components'

const LayoutOuter = styled.div`
  position: relative;
  height: 100%;
`

const LayoutInner = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: auto;
  grid-template-areas:
    'left right'
    'toolbar toolbar';
  grid-column-gap: 0;
  grid-row-gap: 0;
  justify-items: stretch;
  align-items: stretch;
  height: 100%;
`

const ToolbarBlock = styled.div`
  grid-area: toolbar;

  z-index: 10;
`

const Side = styled.section`
  grid-area: ${({ area }) => area};

  overflow-y: scroll;
  padding: 20px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #dde2ec;
  }

  &::-webkit-scrollbar-thumb {
    background: #222;
  }
`

const LeftSide = styled(Side)`
  grid-area: left;
`

const RightSide = styled(Side)`
  grid-area: right;
  background-color: #fbfbfb;
`

const Warning = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 25px);
  font-size: 20px;
  color: crimson;
  background-color: white;
  padding: 16px;
  width: fit-content;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
`

export const ReservationLayout = ({
  left,
  right,
  toolbar,
  warning,
  showWarning = false,
}) => {
  return (
    <LayoutOuter>
      {showWarning && <Warning>{warning}</Warning>}
      <LayoutInner>
        <LeftSide>{left}</LeftSide>
        <RightSide>{right}</RightSide>
        <ToolbarBlock>{toolbar}</ToolbarBlock>
      </LayoutInner>
    </LayoutOuter>
  )
}
