import { useState, useEffect } from 'react'
import nanoid from 'nanoid'
import useOnClickOutside from 'use-onclickoutside'
import { LogsAPI } from '../../../Modules/logs-api'

let stack = []

export function useCloseOnClickOutside({
  ref,
  disableCloseOnClickOutside,
  onClose,
}) {
  const [uniqueId] = useState(() => nanoid())

  useEffect(() => {
    stack.push(uniqueId)

    return () => {
      stack = stack.filter(id => id !== uniqueId)
    }
  }, [uniqueId])

  useOnClickOutside(ref, () => {
    if (disableCloseOnClickOutside) return

    const lastOpened = stack[stack.length - 1]
    if (uniqueId !== lastOpened) return

    LogsAPI.event('Модалка закрыта кликом на оверлей')
    stack = stack.filter(id => id !== uniqueId)
    onClose()
  })
}
