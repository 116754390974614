import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { MainPage } from './MainPage'

import { checksActions, checksSelectors } from './reducers/checks'
import { refundsActions, refundsSelectors } from './reducers/refunds'

const mapStateToProps = state => ({
  checks: checksSelectors.checksSelector(state),
  checksStats: checksSelectors.checksStatsSelector(state),
  refunds: refundsSelectors.refundsSelector(state),
  lcode: state.session.lcode,
})

const mapDispatchToProps = dispatch => ({
  checksActions: bindActionCreators(checksActions, dispatch),
  refundsActions: bindActionCreators(refundsActions, dispatch),
})

export const ConnectedMainPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPage)
