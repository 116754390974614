import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { modalDefaultStyles } from '../../App'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ModalButton, ModalTitle } from '../common/ModalParts'

import { Field, FieldName, Form, MainFormPart, ModalControls, SeparateFormPart, } from './components/common'
import { FormControl } from 'react-bootstrap'
import { RoomSelect } from './components/RoomSelect'
import { getLastRoom } from './helpers/get-last-room'
import { useReservationsSearch } from './helpers/use-reservations-search'
import { useSelector } from 'react-redux'
import { specialServiceTypesSelectors } from '../../Modules/special-service-types'
import * as ccAPI from '../../Modules/api/ccAPI/requests'

const ValidationSchemaWithoutReservationPk = Yup.object().shape({
  guestName: Yup.string().required(),
  bookingNumber: Yup.string().required(),
  paymentAmount: Yup.string()
    .matches(/^[0-9]{1,}([,.][0-9]+)?$/)
    .trim()
    .required(),
})

const ValidationSchemaWithReservationPk = Yup.object().shape({
  guestName: Yup.string().required(),
  bookingNumber: Yup.string().required(),
  reservationPk: Yup.string().required(),
  paymentAmount: Yup.string()
    .matches(/^[0-9]{1,}([,.][0-9]+)?$/)
    .trim()
    .required(),
})

function normalizeValues(values, Schema) {
  return Schema.cast(values)
}

function getFieldPlaceholder(text, mode) {
  if (mode === 'automatic') {
    return text + ' (заполнится автоматически при выборе брони)'
  }

  return text
}

function getPkInputPlaceholder(options, isLoading, isLoaded, isFailed) {
  if (isFailed) return 'Не удалось загрузить брони'
  if (isLoading) return 'Загрузка броней..'
  if (!isLoaded) return 'Для загрузки броней введите номер брони'
  if (options.length === 0) return 'Броней не найдено'
  return 'Выберите бронь'
}

function getPkInputDisabled(options, isLoading, isLoaded, isFailed) {
  if (isFailed) return true
  if (isLoading) return true
  if (!isLoaded) return true
  return options.length === 0;

}

function mapReservationsToOptions(reservations) {
  return reservations.map(reservation => {
    const {
      pk,
      guest_name,
      start,
      end,
      reserved_days,
      room_names,
    } = reservation

    if (reserved_days.length === 0)
      return {
        label: `${guest_name}, с ${start} до ${end} (нет дней - желательно создать бронь заново)`,
        value: pk,
      }

    const lastDay = reserved_days[reserved_days.length - 1]

    const room = lastDay.room
      ? `${lastDay.room.room_id}, ${lastDay.room.name}`
      : room_names || 'неизвестная комната'

    return {
      label: `${guest_name}, с ${start} до ${end} (${room})`,
      value: pk,
    }
  })
}


export const SellRefundActionModalWithPk = ({
                                              title,
                                              lcode,
                                              isRefund,
                                              close,
                                              submitAction,
                                              hasOtherOption,
                                              ...restProps
                                            }) => {
  const [mode] = useState('automatic')
  const {
    foundResults,
    isLoading,
    isLoaded,
    isFailed,
    loadReservations,
    makeSearch,
    reset,
  } = useReservationsSearch()

  const [searchString, setSearchString] = useState('')

  const livingService = useSelector(specialServiceTypesSelectors.livingService)

  useEffect(() => {
    if (searchString) {
      loadReservations(searchString)
    }
  }, [searchString])

  const ValidationSchema =
    mode === 'automatic'
      ? ValidationSchemaWithReservationPk
      : ValidationSchemaWithoutReservationPk

  const reservationPkOptions = useMemo(() => {
    return mapReservationsToOptions(foundResults)
  }, [foundResults])

  return (
    <Modal
      defaultStyles={{
        ...modalDefaultStyles,
        content: {
          ...modalDefaultStyles.content,
          width: 800,
          minWidth: 800,
          maxWidth: 'auto',
        },
      }}
      onAfterClose={() => {
        reset()
        setSearchString('')
      }}
      {...restProps}
    >
      <Formik
        initialValues={{
          room: '',
          guestName: '',
          bookingNumber: '',
          reservationPk: '',
          paymentAmount: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values, actions) => {
          let normalizedValues
          let checkList = isRefund
            ? await ccAPI
              .getChecksByUUID(values.reservationPk)
              .then(res => res.details)
            : []

          if (mode === 'automatic') {
            const { reserved_days } = foundResults.find(
              ({ pk }) => pk === values.reservationPk
            )
            const roomId =
              reserved_days[reserved_days.length - 1]?.room?.room_id

            normalizedValues = normalizeValues(values, ValidationSchema, roomId)
          } else {
            normalizedValues = normalizeValues(values, ValidationSchema)
          }

          const { paymentAmount } = normalizedValues
          const formattedPaymentAmount = paymentAmount.replace(',', '.').trim()

          submitAction({
            ...normalizedValues,
            livingService,
            checkList,
            paymentAmount: formattedPaymentAmount,
          })

          actions.setSubmitting(false)
          close()
        }}
        render={({
                   values,
                   touched,
                   errors,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                   isValid,
                   isSubmitting,
                   setFieldValue,
                 }) => {
          const clearReservationData = () => {
            setFieldValue('reservationPk', '')
            setFieldValue('guestName', '')
            setFieldValue('bookingNumber', '')
            setFieldValue('room', '')
          }

          return (
            <Form onSubmit={handleSubmit}>
              <ModalTitle>{title}</ModalTitle>

              <SeparateFormPart>
                <FieldName>Бронь</FieldName>

                <Field
                  name="searchString"
                  placeholder="Введите номер брони для поиска (минимум 4 символа)"
                  value={searchString}
                  onChange={event => {
                    setSearchString(event.target.value)
                    clearReservationData()
                  }}
                  style={{ marginBottom: 15 }}
                />
                <FormControl
                  name="reservationPk"
                  componentClass="select"
                  value={values.reservationPk}
                  disabled={hasOtherOption ? false : getPkInputDisabled(
                    reservationPkOptions,
                    isLoading,
                    isLoaded,
                    isFailed
                  )}
                  onChange={event => {
                    handleChange(event)
                    setFieldValue('guestName', '')
                    setFieldValue('bookingNumber', '')
                    setFieldValue('room')

                    const { value } = event.target

                    if (!value) return

                    if (value === "other") {
                      setFieldValue('guestName', '-')
                      setFieldValue('bookingNumber', '-')
                      setFieldValue('room')
                      return
                    }

                    const reservation = foundResults.find(
                      ({ pk }) => pk === value
                    )
                    const { guest_name, booking_number } = reservation

                    setFieldValue('guestName', guest_name)
                    setFieldValue('bookingNumber', booking_number)
                    setFieldValue('room', getLastRoom(reservation))
                  }}
                >
                  {hasOtherOption && <option key="other" value="other">
                    Другое
                  </option>}
                  <option key="placeholder" value="">
                    {getPkInputPlaceholder(
                      reservationPkOptions,
                      isLoading,
                      isLoaded,
                      isFailed
                    )}
                  </option>
                  {reservationPkOptions.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </FormControl>
              </SeparateFormPart>

              <MainFormPart>
                <Field
                  name="guestName"
                  placeholder={getFieldPlaceholder('Имя гостя', mode)}
                  value={values.guestName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.guestName && errors.guestName}
                  disabled={true}
                />
                <Field
                  name="bookingNumber"
                  placeholder={getFieldPlaceholder('Номер брони', mode)}
                  value={values.bookingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.bookingNumber && errors.bookingNumber}
                  disabled={true}
                />
                <RoomSelect
                  name="room"
                  value={values.room}
                  onChange={handleChange}
                  disabled={true}
                />
                <Field
                  name="paymentAmount"
                  placeholder="Сумма в рублях"
                  value={values.paymentAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.paymentAmount && errors.paymentAmount}
                />
              </MainFormPart>

              <ModalControls>
                <ModalButton
                  bsStyle="danger"
                  style={{ marginLeft: 0 }}
                  onClick={close}
                >
                  Закрыть
                </ModalButton>
                <ModalButton
                  type="submit"
                  bsStyle="success"
                  style={{ marginLeft: 'auto' }}
                  disabled={!isValid || isSubmitting}
                >
                  Произвести продажу
                </ModalButton>
              </ModalControls>
            </Form>
          )
        }}
      />
    </Modal>
  )
}
