import * as types from './types'
import { createReducer } from '../../../../Modules/helpers/redux/createReducer'

const initialState = {
  items: [],
  count: 0,
  categories: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = createReducer(initialState, {
  [types.LOAD_REQUEST]: state => ({
    ...state,
    items: [],
    count: 0,
    isLoading: true,
    isLoaded: false,
    isFailed: false,
  }),
  [types.LOAD_SUCCESS]: (state, payload) => ({
    ...state,
    items: payload.items,
    count: payload.count,
    isLoading: false,
    isLoaded: true,
  }),
  [types.LOAD_FAILURE]: state => ({
    ...state,
    count: 0,
    isLoading: false,
    isFailed: true,
  }),
  [types.LOAD_CATEGORIES_SUCCESS]: (state, payload) => ({
    ...state,
    categories: payload.categories
  }),
  [types.LOAD_NEW_COUNT_SUCCESS]: (state, payload) => ({
    ...state,
    newNewsCount: payload.newNewsCount,
  }),
})
