import moment from 'moment'
import 'moment-timezone'
import { submitLogsCall } from '../api/ccAPI/requests'

const getTimestamp = () => {
  return moment()
    .tz('Europe/Moscow')
    .format('YYYY-MM-DD HH:mm:ss')
}

class Logs {
  records = {}
  tempRecords = []

  getRecords = () => ({
    common: this.records,
    temp: this.tempRecords,
  })

  clear = () => {
    this.records = {}
  }

  clearTemp = () => {
    this.tempRecords = []
  }

  addRecord = async (pk, action) => {
    const path = window.location.pathname

    if (path !== '/reservation' && path !== '/new-reservation') {
      return
    }

    const data = {
      action,
      timestamp: getTimestamp(),
    }
    if (pk) {
      if (!this.records[pk]) {
        this.records[pk] = []
      }

      this.records[pk].push(data)
    } else {
      this.tempRecords.push(data)
    }
  }

  startSubmitting = (pk, records) => {
    let attempts = 0

    const submitFn = async () => {
      try {
        records.forEach(it => {
          it.reservation_pk = pk
          submitLogsCall(it)
        })
      } catch (err) {
        if (++attempts <= 5) {
          setTimeout(submitFn, 2000)
        }
      }
    }

    submitFn()
  }

  submit = () => {
    for (let pk in this.records) {
      const records = [...this.records[pk]]

      if (records.length) {
        this.startSubmitting(pk, records)
        delete this.records[pk]
      }
    }
  }

  submitRecord = async (pk, action) => {
    await submitLogsCall({
      action,
      reservation_pk: pk,
      timestamp: getTimestamp(),
    })
  }

  submitTemp = pk => {
    const records = [...this.tempRecords]
    if (records.length) {
      this.startSubmitting(pk, records)
      this.clearTemp()
    }
  }

  interval = null

  start = () => {
    this.interval = setInterval(this.submit, 20 * 1000)
  }

  stop = () => {
    clearInterval(this.interval)
    this.interval = null
  }

  log = (pk, text) => this.submitRecord(pk, text)
  logPressAction = (pk, value) => this.addRecord(pk, `Нажатие «${value}»`)
  logEditAction = (pk, value, target) =>
    this.addRecord(pk, `Установка ${value} для ${target}`)
  logEvent = (pk, event) => this.addRecord(pk, `Событие «${event}»`)
  logError = (pk, error) => this.addRecord(pk, `Ошибка «${error}»`)

  logPressActionSubmit = (pk, value) => this.submitRecord(pk, `Нажатие «${value}»`)
  logEditActionSubmit = (pk, value, target) =>
    this.submitRecord(pk, `Установка ${value} для ${target}`)
  logEventSubmit = (pk, event) => this.submitRecord(pk, `Событие «${event}»`)
  logErrorSubmit = (pk, error) => this.submitRecord(pk, `Ошибка «${error}»`)

  logSubmit = (pk, text) => this.submitRecord(pk, text)
}

const logs = new Logs()

export default logs
