import styled from 'styled-components'

export const CloseButton = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  padding: 8px 12px;
  color: #fff;
  background-color: crimson;
  font-family: inherit;
  transition: background-color 0.15s;

  &:hover {
    background-color: #b10e2d;
  }
`
