import * as types from './types'
import { getBreakfasts, updateBreakfastAction } from "../api/ccAPI/requests"

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = () => async dispatch => {
  dispatch(loadRequest())

  try {
    const items = await getBreakfasts()

    dispatch(loadSuccess({ items }))
  } catch {
    dispatch(loadFailure())
  }
}

export const update = (pk, data) => async dispatch => {
  dispatch(loadRequest())
  try {
    await updateBreakfastAction(pk, data)
  } catch {
    dispatch(loadFailure())
  }
}
