import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { FormGroup, ControlLabel, FormControl, Table, Pagination } from 'react-bootstrap'
import { rub } from '../../Modules/formatters/currency'
import { Link } from 'react-router-dom'
import { fullDate } from '../../Modules/formatters/date'
import { sorting } from './converters'
import { SingleDatePicker } from '../common/DatePicker'
import { page_size } from '../../Constants/others'

const Block = styled.div`
  background-color: #eee;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
`

const Content = ({
                   prepayeds,
                   prepayedsLoadingState,
                   reservationListsByBookingNumber,
                   reservationsDataLoadingState,
                   hotelsMappings,
                 }) => {
  if (prepayedsLoadingState.isLoading) return <div>Загрузка..</div>
  if (prepayedsLoadingState.isFailed) return <div>Ошибка загрузки</div>
  if (!prepayedsLoadingState.isLoaded) return <div>Данные не загружены</div>

  if (prepayeds.length === 0) return <div>Не найдено предоплат за эту дату</div>

  return (
    <Results
      prepayeds={prepayeds}
      hotelsMappings={hotelsMappings}
      reservationListsByBookingNumber={reservationListsByBookingNumber}
      reservationsDataLoadingState={reservationsDataLoadingState}
    />
  )
}

const Actions = ({
                   bookingNumber,
                   reservationListsByBookingNumber,
                   reservationsDataLoadingState,
                 }) => {
  if (reservationsDataLoadingState.isLoading) return <div>Загрузка...</div>
  if (reservationsDataLoadingState.isFailed) return <div>Ошибка загрузки</div>
  if (!reservationsDataLoadingState.isLoaded) return <div>Данные не загружены</div>

  const reservations = reservationListsByBookingNumber[bookingNumber]

  if (!reservations) {
    return null
  }

  if (reservations.length === 0) {
    return null
  }

  if (reservations.length === 1) {
    const reservation = reservations[0]

    const { isReady, canceled } = reservation

    if (canceled) {
      return null
    }

    const linkText = isReady ? 'Посмотреть бронь' : 'Заселить'

    return (
      <React.Fragment>
        <Link to={`/reservation?pk=${reservation.pk}`}>{linkText}</Link>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <p>Групповая бронь:</p>
      <ol>
        {reservations.map(({ pk, isReady }) => {
          const linkText = isReady ? 'Посмотреть бронь' : 'Заселить'
          const linkUrl = `/reservation?pk=${pk}`

          return (
            <li key={pk}>
              <Link to={linkUrl}>{linkText}</Link>
            </li>
          )
        })}
      </ol>
    </React.Fragment>
  )
}

const Results = ({
                   prepayeds,
                   hotelsMappings,
                   reservationListsByBookingNumber,
                   reservationsDataLoadingState,
                 }) => {
  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <Table className="table-hover" style={{ border: 'none' }}>
        <thead>
        <tr>
          <th>Объект</th>
          <th>Номер брони</th>
          <th>Дата заезда</th>
          <th>Сумма</th>
          <th>Метод оплаты</th>
          <th>Плательщик</th>
          <th>Дата оплаты</th>
          <th>Примечание</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        {prepayeds.map(prepayed => {
          const {
            pk,
            lcode,
            booking_number,
            date,
            amount,
            payment_method,
            note,
            payed_at,
            payed_by,
          } = prepayed

          const key = pk || `${booking_number}-${amount}`

          return (
            <tr key={key}>
              <td>{hotelsMappings.lcodeToName[lcode]}</td>
              <td>{booking_number}</td>
              <td>{fullDate(date)}</td>
              <td>{rub(amount)}</td>
              <td>{payment_method}</td>
              <td>{payed_by}</td>
              <td>{fullDate(payed_at)}</td>
              <td>{note || '-'}</td>
              <td>
                <Actions
                  bookingNumber={booking_number}
                  reservationsDataLoadingState={reservationsDataLoadingState}
                  reservationListsByBookingNumber={
                    reservationListsByBookingNumber
                  }
                />
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </div>
  )
}

const sortingTypes = {
  byDateASC: 'by-date-asc',
  byDateDESC: 'by-date-desc',
  byPayedAtASC: 'by-payed_at-asc',
  byPayedAtDESC: 'by-payed_at-desc',
}

export class AllPrepayeds extends React.Component {
  state = {
    date: moment().add(-3, 'days').format('YYYY-MM-DD'),
    sortingType: sortingTypes.byDateASC,
    page: 1,
    lcode: this.props.lcode
  }

  filterOptions = [
    {
      label: 'Этот отель',
      value: this.props.lcode,
    },
    {
      label: 'Все отели',
      value: 0,
    },
  ]

  sorting = {
    [sortingTypes.byDateASC]: sorting.byDateASC,
    [sortingTypes.byDateDESC]: sorting.byDateDESC,
    [sortingTypes.byPayedAtASC]: sorting.byPayedAtASC,
    [sortingTypes.byPayedAtDESC]: sorting.byPayedAtDESC,
  }

  sortingOptions = [
    {
      label: 'По дате (по возрастанию)',
      value: sortingTypes.byDateASC,
    },
    {
      label: 'По дате (по убыванию)',
      value: sortingTypes.byDateDESC,
    },
    {
      label: 'По дате оплаты (по возрастанию)',
      value: sortingTypes.byPayedAtASC,
    },
    {
      label: 'По дате оплаты (по убыванию)',
      value: sortingTypes.byPayedAtDESC,
    },
  ]

  handleFilterChange = value => {
    this.setState({ lcode: value, }, this.updateResult)
  }

  handleSortingChange = value =>
    this.setState({
      sortingType: value,
    })

  changeFocus = datePickerFocused => this.setState({ datePickerFocused })

  setDate = date => {
    this.setState({ date }, this.updateResult)
  }

  setPage = page => {
    this.setState({ page }, this.updateResult)
  }

  updateResult = () => {
    const { date, page, lcode } = this.state
    const { allPrepayedsActions } = this.props

    allPrepayedsActions.loadData({ date, page, lcode: lcode === '0' ? null : lcode })
  }

  render() {
    const { date, lcode, sortingType, page } = this.state

    const {
      prepayeds = [],
      count = 0,
      nextPage,
      currentPage,
      previousPage,
      prepayedsLoadingState,
      reservationListsByBookingNumber,
      reservationsDataLoadingState,
      hotelsMappings,
    } = this.props

    const pageCount = Math.ceil(count / page_size)
    const pages = Array.from({ length: pageCount }, (_, i) => i + 1)

    const shownItems = prepayeds
      .sort(this.sorting[sortingType])

    return (
      <Block>
        <SingleDatePicker label="Дата" value={date} onChange={this.setDate} getFormatted={false} />
        <FormGroup style={{ width: '100%', maxWidth: 240 }}>
          <ControlLabel>Объект</ControlLabel>
          <FormControl
            componentClass="select"
            value={lcode}
            onChange={e => this.handleFilterChange(e.target.value)}
          >
            {this.filterOptions.map(value => (
              <option key={value.value} value={value.value}>
                {value.label}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <FormGroup style={{ width: '100%', maxWidth: 240 }}>
          <ControlLabel>Сортировка</ControlLabel>
          <FormControl
            componentClass="select"
            value={sortingType}
            onChange={e => this.handleSortingChange(e.target.value)}
          >
            {this.sortingOptions.map(value => (
              <option key={value.value} value={value.value}>
                {value.label}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <div style={{ marginTop: 48 }}>
          <Content
            prepayeds={shownItems}
            prepayedsLoadingState={prepayedsLoadingState}
            reservationListsByBookingNumber={reservationListsByBookingNumber}
            reservationsDataLoadingState={reservationsDataLoadingState}
            hotelsMappings={hotelsMappings}
          />
        </div>
        <Pagination>
          <Pagination.First onClick={() => this.setPage(1)} />
          <Pagination.Prev onClick={() => this.setPage(previousPage)} />
          {pages.map(it =>
            <Pagination.Item
              key={it}
              active={currentPage === it}
              onClick={() => this.setPage(it)}
            >{it}</Pagination.Item>
          )}
          <Pagination.Next onClick={() => this.setPage(nextPage)} />
          <Pagination.Last onClick={() => this.setPage(pageCount)} />
        </Pagination>
      </Block>
    )
  }
}
