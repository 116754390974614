import moment from 'moment'
import * as types from './types'
import { noShowSearchCall } from '../../../../Modules/api/ccAPI/requests'

const loadDataRequest = () => ({
  type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = data => ({
  type: types.LOAD_DATA_SUCCESS,
  payload: {
    data,
  },
})

const loadDataFailure = () => ({
  type: types.LOAD_DATA_FAILURE,
})

export const loadData = () => async (dispatch) => {
  dispatch(loadDataRequest())

  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
  try {
    const data = await noShowSearchCall(yesterday)

    dispatch(loadDataSuccess(data))
  } catch (err) {
    dispatch(loadDataFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
