import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { paymentTypesList, paymentTypesNoBank } from '../../../../../Modules/helpers/paymentTypes'
import { Cell, ReadOnlyCell } from '../../../common/Cell'
import { Select, InputNumber } from '../../../../form-stuff'
import { Button, ButtonGroup } from '../../../../buttons'
import { day } from '../../../../../Modules/formatters/date'
import { ItemRegularPrice, ServiceItemPrice } from '../../../../common/ItemPrice'
import { useSelector } from 'react-redux'
import { settingsSelectors } from '../../../../../Modules/settings'
import { specialServiceTypesSelectors } from '../../../../../Modules/special-service-types'

export const Item = ({
  data,
  availableDates,
  validationErrors,
  availableActions,
  actions,
}) => {
  const { id, service } = data

  const setDate = useCallback(value => actions.setDate({ id, value }), [
    id,
    actions,
  ])

  const setQuantity = useCallback(value => actions.setQuantity({ id, value }), [
    id,
    actions,
  ])
  const findService = useSelector(specialServiceTypesSelectors.itemsByName)
  const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

  useEffect(() => {
    const serviceItem = findService[service]
    const list = serviceItem ? serviceItem.is_available_for_sale_by_bank
        ? paymentTypesList
        : paymentTypesNoBank
      : paymentTypesList
    setPaymentTypeList(list)
  }, [service])

  const setPaymentType = useCallback(
    value => actions.setPaymentType({ id, value }),
    [id, actions]
  )

  const copy = () => actions.copy({ id })
  const remove = () => actions.delete({ id })
  const refund = () => actions.refund({ id })

  const isPayed = Boolean(data.payment_date)

  const copyCondition = availableActions.copy
  const updateCondition = availableActions.update && !isPayed
  const deleteCondition = availableActions.delete && !isPayed

  const isWashingOperationsOn = useSelector(
    settingsSelectors.isWashingOperationsOn
  )
  const isNewWashing = data.service === 'Стирка' && isWashingOperationsOn
  const refundCondition = availableActions.refund && isPayed && !isNewWashing

  const dateSelectOptions = useMemo(
    () =>
      availableDates.map(date => ({
        label: day(date),
        value: date,
      })),
    [availableDates]
  )

  const dataCells = !updateCondition ? (
    <>
      <ReadOnlyCell>{day(data.date)}</ReadOnlyCell>
      <ReadOnlyCell>{data.service}</ReadOnlyCell>
      <ReadOnlyCell>
        <ServiceItemPrice item={data} />
      </ReadOnlyCell>
      <ReadOnlyCell>{data.quantity}</ReadOnlyCell>
      <ReadOnlyCell>{data.payment_type}</ReadOnlyCell>
    </>
  ) : (
    <>
      <Cell>
        <Select
          value={data.date}
          options={dateSelectOptions}
          onChange={setDate}
          hasError={validationErrors['date']}
          noMargin
        />
      </Cell>
      <ReadOnlyCell>{data.service}</ReadOnlyCell>
      <ReadOnlyCell>
        <ItemRegularPrice item={data} />
      </ReadOnlyCell>
      <Cell>
        <InputNumber
          value={data.quantity}
          onChange={setQuantity}
          hasError={validationErrors['quantity']}
          noMargin
        />
      </Cell>
      <Cell>
        <Select
          value={data.payment_type}
          options={paymentTypeList}
          onChange={setPaymentType}
          hasError={validationErrors['payment_type']}
          noMargin
        />
      </Cell>
    </>
  )

  return (
    <tr>
      {dataCells}
      <Cell>
        <ButtonGroup align="right">
          {copyCondition && (
            <Button type="success" onClick={copy}>
              Копировать
            </Button>
          )}
          {deleteCondition && (
            <Button type="danger" onClick={remove}>
              Удалить
            </Button>
          )}
          {refundCondition && (
            <Button type="danger" onClick={refund}>
              Возврат
            </Button>
          )}
        </ButtonGroup>
      </Cell>
    </tr>
  )
}
