import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { LoyaltyClient } from './LoyaltyClient'
import {
  loyaltyClientSelectors,
  loyaltyClientActions,
} from '../../../Modules/loyalty-client'
import { loyaltySelectors } from '../../../Modules/loyalty'
import { reservationSelectors } from '../../../Modules/reservation'
import { settingsSelectors } from '../../../Modules/settings'

const mapStateToProps = (state, ownProps) => ({
  isLoyaltyEnabled: settingsSelectors.isLoyaltyEnabled(state),
  loyaltyClient: loyaltyClientSelectors.everything(state),
  isActual: loyaltySelectors.isClientActual(state),
  isLoyaltyAllowed: reservationSelectors.isLoyaltyAllowed(state),
  ...ownProps,
})

const mapDispatchToProps = dispatch => ({
  loyaltyClientActions: bindActionCreators(loyaltyClientActions, dispatch),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export const EnhancedLoyaltyClient = enhance(LoyaltyClient)
