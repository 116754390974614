import * as types from './types'
import { createReducer } from '../helpers/redux/createReducer'

const initialState = {
  items: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = createReducer(initialState, {
  [types.LOAD_REQUEST]: state => ({
    ...state,
    items: [],
    isLoading: true,
    isLoaded: false,
    isFailed: false,
  }),
  [types.LOAD_SUCCESS]: (state, payload) => ({
    ...state,
    items: payload.items,
    isLoading: false,
    isLoaded: true,
  }),
  [types.LOAD_FAILURE]: state => ({
    ...state,
    isLoading: false,
    isFailed: true,
  }),
})
