import store from '../../store'
import { prepareDebugMessage } from './prepare-debug-message'
import { sendReportCall } from '../api/ccAPI/requests'

export function sendBugReport({ message, subject, onFinish }) {
  const state = store.getState()
  const debug = prepareDebugMessage({ state })

  sendReportCall({
    message,
    subject,
    debug,
  }).then(_ => {})

  if (onFinish) {
    onFinish()
  }
}
