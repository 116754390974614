import { dayTypes } from '../../../helpers/types'
import moment from 'moment'

export const prepareReservation = async ({ reservation, modes, adminName }) => {
  const moscowDay = moment().format('YYYY-MM-DD')
  const payed_at = new Date().toISOString()

  let newDays = reservation.reserved_days
    .map(day => {
      if (day.type !== dayTypes.notPayed) return day

      if (!day.payment_date || day.admin) return day

      return {
        ...day,
        admin: adminName,
        payed_at: '',
      }
    })
    .map(day => {
      if (![dayTypes.new, dayTypes.notPayed].includes(day.type)) return day

      if (!day.payment_date) return day

      return {
        ...day,
        payment_date: moscowDay,
        payed_at,
      }
    })

  let newServices = reservation.additional_services.map(service => {
    // Already payed
    if (service.payment_date) return service

    return {
      ...service,
      payment_date: moscowDay,
      payed_at,
    }
  })

  let newPassport = reservation.passport.map(item => ({
    ...item,
    passport: item.passport.replace(/ /g, ''),
  }))

  let newAdmin = reservation.admin
  let newReady = reservation.isReady

  if (modes.wubook) {
    newDays = newDays.map(day => ({
      ...day,
      admin: adminName,
    }))

    newServices = newServices.map(service => ({
      ...service,
      admin: adminName,
    }))

    newAdmin = adminName

    newReady = true
  }

  return {
    ...reservation,
    reserved_days: newDays,
    additional_services: newServices,
    passport: newPassport,
    admin: newAdmin,
    isReady: newReady,
  }
}
