import styled from 'styled-components'
import { ModalControls as DefaultModalControls } from '../../common/ModalParts'

export const ModalControls = styled(DefaultModalControls)`
  padding: 20px;
`

export const Form = styled.form.attrs({
  autoComplete: 'off',
})`
  padding: 5px;
`

export const MainFormPart = styled.div`
  padding: 20px;
`

export const FieldName = styled.h3`
  margin: 2px 0 10px;
  font-size: 14px;
  font-weight: 600;
`

export const SeparateFormPart = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ccc;
`

export const Field = styled.input.attrs({
  type: 'text',
})`
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  height: 34px;
  padding: 6px 12px;
  transition: border-color 0.15s, box-shadow 0.15s;
  width: 100%;
  margin-bottom: 15px;
  outline: none;

  ${props =>
    props.invalid &&
    `
    box-shadow: inset 0 1px 1px rgba(255, 0, 0, 0.2);
    border-color: #ff9090;
  `}

  &:last-of-type {
    margin-bottom: 0;
  }

  &:disabled {
    background-color: #eee;
  }
`
