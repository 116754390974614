import React from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#root')
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: 'flex',
    zIndex: 160,
  },
  content: {
    margin: 'auto',
    outline: 'none',
  },
}

const LoadingModal = props => (
  <Modal
    isOpen={props.isOpen}
    style={styles}
    defaultStyles={{
      overlay: null,
      content: null,
    }}
  >
    <div className="loading-spinner">
      <div className="cube1" />
      <div className="cube2" />
    </div>
  </Modal>
)

export default LoadingModal
