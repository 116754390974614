import {createSelector} from 'reselect'

export const everything = state => state.canceledWarnings

export const items = (state) => everything(state).data

export const isLoading = (state) => everything(state).isLoading

export const isLoaded = (state) => everything(state).isLoaded

export const isFailed = (state) => everything(state).isFailed


export const reservationWarnings = createSelector(items, items => {
        if (items) {
            return items.reduce((a, b) => {
                const l = b.warnings?.find(({
                                                kind,
                                                action_performed
                                            }) => (kind === "canceled_and_ready" || kind === "group_part_canceled") && !action_performed)

                if (l) a.push(b)
                return a
            }, [])
        }
        return []
    }
)


export const breakfastWarnings = createSelector(items, items => {
        if (items) {
            return items.reduce((a, b) => {
                const l = b.warnings?.find(({
                                                kind,
                                                action_performed
                                            }) => kind === "prepaid_breakfasts_left" && !action_performed)

                if (l) a.push(b)
                return a
            }, [])
        }
        return []
    }
)
