export const LOAD_DATA_OPEN_SUCCESS = 'ghotel/shifts/LOAD_DATA_OPEN_SUCCESS'

export const LOAD_DATA_CLOSE_SUCCESS = 'ghotel/shifts/LOAD_DATA_CLOSE_SUCCESS'

export const LOAD_DATA_REVIEW_SUCCESS = 'ghotel/shifts/LOAD_DATA_REVIEW_SUCCESS'

export const LOAD_DATA_FAILURE = 'ghotel/shifts/LOAD_DATA_CLOSE_FAILURE'

export const LOAD_DATA_SHIFT = 'ghotel/shifts/LOAD_DATA_SHIFT'

export const LOAD_DATA_LOADING_REQUEST = 'ghotel/shifts/LOAD_DATA_LOADING_REQUEST'

export const RESET = 'ghotel/shifts/RESET'
