export const everything = state => state.loyaltyClient

export const data = state => everything(state).data

export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const isSendingRegistrationSMS = state =>
  everything(state).isSendingRegistrationSMS

export const phoneNumber = state => data(state).phoneNumber

export const bonuses = state => data(state).bonuses
