import {room_statuses} from './room-statuses'
import React from 'react'
import {callModal, modalTemplates} from '../../../../dynamic-modal'
import {modalActions} from '../../../../dynamic-modal/templates/confirm'


export const statusWarningAlert = async (status) => {
    if (status === room_statuses.clean.title) return true

    const text = status === room_statuses.repair.title
        ? "Данный номер в статусе “На ремонте”. Вы уверены, что хотите в него заселить?"
        : status === room_statuses.busy_with_employee.title ? "Данный номер в статусе “Занят сотрудником”. Вы уверены, что хотите в него заселить?"
            : "Данный номер возможно еще не убран. Вы уверены, что хотите заселить в него?"

    const {action} = await callModal(modalTemplates.confirm({
        title: "Статус номера",
        text: <div>
            {text}
        </div>,
        buttons: ['Нет', 'Да'],
    }))
    return action === modalActions.confirm
}
