import { createSelector } from 'reselect'

export const everything = state => state.hotels

export const items = state => everything(state).items

export const lcodes = createSelector(items, items =>
  items.map(item => item.lcode)
)

const lcodeToName = createSelector(items, items =>
  items.reduce((acc, item) => {
    acc[item.lcode] = item.name
    return acc
  }, {})
)

export const lcodeByPk = createSelector(items, items =>
  items.reduce((acc, item) => {
    acc[item.pk] = item
    return acc
  }, {})
)

const nameToLcode = createSelector(items, items =>
  items.reduce((acc, item) => {
    acc[item.name] = item.lcode
    return acc
  }, {})
)

export const hotelsMappings = createSelector(
  lcodeToName,
  nameToLcode,
  lcodeByPk,
  (lcodeToName, nameToLcode, lcodeByPk) => ({
    lcodeToName,
    nameToLcode,
    lcodeByPk
  })
)

export const hotelOptions = createSelector(items, items =>
  items ? items.map(item => ({
    label: item.pretty_name_ru,
    value: item.lcode,
    key: item.pk,
  })).sort((a, b) => a.label.localeCompare(b.label)) : []
)

export const hotelOptionsWithAll = createSelector(
  hotelOptions,
  hotelOptions => [{ label: 'Все отели', value: '', key: '*' }, ...hotelOptions]
)
