import { createSelector } from 'reselect'
import { reservationSelectors } from '../reservation'
import { loyaltySelectors } from '../loyalty'
import { loyaltyPurchaseSelectors } from '../loyalty-purchase'
import {
  getPrintedItemTotal,
} from '../helpers/item-selectors'

const createPurchaseLineGenerator = ({ useLoyalty, calculatedRowsMap }) => (
  items,
  getName
) => {
  return items.map(item => {
    const name = getName(item)
    const { id, date, quantity = 1, payment_type } = item

    const initialTotal = getPrintedItemTotal(item)

    const total =
      useLoyalty && calculatedRowsMap[id]
        ? initialTotal - calculatedRowsMap[id].totalDiscount
        : initialTotal

    return {
      type: 'purchased',
      id,
      name,
      date,
      quantity,
      total,
      payment_type
    }
  })
}

const createRefundLineGenerator = () => (items, getName) => {
  return items.map(item => {
    const name = getName(item)
    const { id, date, quantity = 1, payment_type } = item

    const total = getPrintedItemTotal(item)

    return {
      type: 'refunded',
      id,
      name,
      date,
      quantity,
      total,
      payment_type
    }
  })
}

export const lines = createSelector(
  loyaltySelectors.isUsed,
  loyaltySelectors.isValid,
  loyaltyPurchaseSelectors.isConfirmed,
  loyaltyPurchaseSelectors.calculatedRowsMap,
  reservationSelectors.purchasedItems,
  reservationSelectors.refundedItemsByType,
  (
    isLoyaltyUsed,
    isLoyaltyValid,
    isBonusesConfirmed,
    calculatedRowsMap,
    purchasedItems,
    refundedItems
  ) => {
    const { days: purchasedDays, services: purchasedServices } = purchasedItems
    const { days: refundedDays, services: refundedServices } = refundedItems

    const generatePurchaseLines = createPurchaseLineGenerator({
      useLoyalty: isLoyaltyUsed && isLoyaltyValid && isBonusesConfirmed,
      calculatedRowsMap,
    })

    const purchaseLines = [
      ...generatePurchaseLines(purchasedDays, () => 'Проживание'),
      ...generatePurchaseLines(purchasedServices, item => item.service),
    ]

    const generateRefundLines = createRefundLineGenerator()

    const refundLines = [
      ...generateRefundLines(refundedDays, () => 'Проживание'),
      ...generateRefundLines(refundedServices, item => item.name),
    ]

    return [...purchaseLines, ...refundLines]
  }
)

export const purchaseTotal = createSelector(lines, lines => {
  const list = lines.filter(line => line.type === 'purchased')
  return getTotalInfo(list)
})

export const refundTotal = createSelector(lines, lines => {
  const list = lines.filter(line => line.type === 'refunded')
  return getTotalInfo(list)
})


const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  )

const getTotalInfo = list => {
  const grouped = list.length ? groupBy('payment_type')(list) : null
  return {
    total: list
      .reduce((acc, line) => acc + line.total, 0)
      .toFixed(2),
    paymentType: grouped ? Object.keys(grouped).reduce((a, b) => {
        a[b] = {}
        a[b].total = grouped[b].reduce((x, y) => x + y.total, 0)
        a[b].count = grouped[b].length
        return a
      }, {}
    ) : null
  }
}
