import { callCallback } from '../lib/call-callback'

export const pipeCallbacks = callbacks => async callbackData => {
  const results = []

  for (let cb of callbacks) {
    const result = await callCallback(cb, callbackData)
    results.push(result)
  }

  return results
}
