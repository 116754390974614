import React from 'react'
import { colorsByType } from './constants'
import { ItemText } from './styleds/ItemText'
import { rub } from '../../../../Modules/formatters/currency'

export const Item = ({ type, name, date, quantity, total, payment_type }) => {
  const color = colorsByType[type] || '#000000'

  return (
    <ItemText color={color}>
      {name} {date} ({quantity} шт) - {rub(total)} ({payment_type})
    </ItemText>
  )
}
