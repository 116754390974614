import React from 'react'
import styled from 'styled-components'

const FieldBlock = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.h4`
  display: inline-block;
  margin: 0 5px 0 0;
  font-weight: 700;
  font-size: ${props => props.fontSize}px;
`

const ValueBlock = styled.div`
  display: inline-block;
  min-width: ${props => props.lineWidth}px;
  border-bottom: ${props => props.lineHeight}px solid;
`

const Value = styled.p`
  display: inline-block;
  font-size: ${props => props.fontSize}px;
  margin: 0;
`

export const Field = ({ name, value = '', sizes = {} }) => {
  const { fonts = {}, line = {} } = sizes
  const { name: nameSize = 16, value: valueSize = 15 } = fonts
  const { width: lineWidth = 120, height: lineHeight = 1 } = line

  return (
    <FieldBlock>
      {name && <Label fontSize={nameSize}>{name}</Label>}
      <ValueBlock lineWidth={lineWidth} lineHeight={lineHeight}>
        <Value fontSize={valueSize}>{value}</Value>
      </ValueBlock>
    </FieldBlock>
  )
}

export const BigField = ({ name, value }) => {
  return (
    <Field
      name={name}
      value={value}
      sizes={{
        fonts: { name: 18, value: 16 },
        line: { width: 240, height: 2 },
      }}
    />
  )
}
