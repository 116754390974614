import * as types from './types'
import API from '../../../../helpers/API'

const getRefundsRequest = () => ({
  type: types.GET_REFUNDS_REQUEST,
})

const getRefundsSuccess = items => ({
  type: types.GET_REFUNDS_SUCCESS,
  payload: {
    items,
  },
})

const getRefundsFailure = error => ({
  type: types.GET_REFUNDS_FAILURE,
  payload: {
    error,
  },
})

/**
 * Get refunds from server and assign result to refunds reducer
 * @param {object} data
 * @param {string} data.lcode - Hotel lcode
 * @param {string} data.date - Selected date in YYYY-MM-DD format
 * @returns {boolean} Is the data received successfully
 */
export const getRefunds = ({ lcode, date }) => async dispatch => {
  dispatch(getRefundsRequest())

  try {
    const items = await API.getRefunds({
      lcode,
      dateStart: date,
      dateEnd: date,
    })

    dispatch(getRefundsSuccess(items))
  } catch (err) {
    dispatch(getRefundsFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
