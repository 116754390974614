import React, { useCallback } from 'react'
import { ActionsBlock, ActionButton } from '../../common/actions'
import { LogsAPI } from '../../../../Modules/logs-api'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { useActions } from '../../../common/hooks/use-actions'
import { loyaltyClientActions } from '../../../../Modules/loyalty-client'
import { loyaltyPurchaseActions } from '../../../../Modules/loyalty-purchase'

export const Actions = ({
  isLoaded,
  isLoading,
  isActual,
  sendRegistrationSMS,
  setup,
  updatePhoneNumber,
}) => {
  const { reset: resetClient } = useActions(loyaltyClientActions)
  const { reset: resetPurchase } = useActions(loyaltyPurchaseActions)

  const resetLoyalty = () => {
    resetClient()
    resetPurchase()
  }

  const sendRegistrationSMSCond = !isLoaded && !isLoading
  const findClientCond = !isLoaded && !isLoading
  const updatePhoneNumberCond = isLoaded && !isActual
  const resetLoyaltyCond = isLoaded && !isActual

  const sendRegistrationSMSWithLogging = useCallback(
    ({ language }) => {
      LogsAPI.pressAction('Отправить СМС для регистрации')
      sendRegistrationSMS({ language })
    },
    [sendRegistrationSMS]
  )

  const setupWithLogging = useCallback(() => {
    LogsAPI.pressAction('Найти клиента')
    setup()
  }, [setup])

  const updatePhoneNumberWithLogging = useCallback(() => {
    LogsAPI.pressAction('Обновить номер телефона')
    updatePhoneNumber()
  }, [updatePhoneNumber])

  const sendRegistrationSMSButtons = (
    <DropdownButton
      id="send-loyalty-sms"
      title="Отправить ссылку для регистрации"
      bsStyle="success"
      bsSize="sm"
      style={{ marginRight: 10, marginBottom: 10 }}
    >
      <MenuItem
        onClick={() =>
          sendRegistrationSMSWithLogging({
            language: 'ru',
          })
        }
      >
        RU
      </MenuItem>
      <MenuItem
        onClick={() =>
          sendRegistrationSMSWithLogging({
            language: 'en',
          })
        }
      >
        EN
      </MenuItem>
    </DropdownButton>
  )

  const findClientButton = (
    <ActionButton type="success" onClick={setupWithLogging}>
      Найти клиента
    </ActionButton>
  )

  const updatePhoneNumberButton = (
    <ActionButton type="primary" onClick={updatePhoneNumberWithLogging}>
      Обновить телефон в лояльности
    </ActionButton>
  )

  const resetLoyaltyButton = (
    <ActionButton type="primary" onClick={resetLoyalty}>
      Обновить телефон в брони
    </ActionButton>
  )

  return (
    <ActionsBlock>
      {sendRegistrationSMSCond && sendRegistrationSMSButtons}
      {findClientCond && findClientButton}
      {updatePhoneNumberCond && updatePhoneNumberButton}
      {resetLoyaltyCond && resetLoyaltyButton}
    </ActionsBlock>
  )
}
