import { parseCheckTime } from './check-times'
import { HOURS_BEFORE_ARCHIVING } from '../../config/config'

export const isReservationArchivedModular = ({ end, checkOutTimeObject }) => {
  const archivationDate = new Date(end)

  const { hours, minutes, seconds } = checkOutTimeObject
  archivationDate.setHours(hours + HOURS_BEFORE_ARCHIVING)
  archivationDate.setMinutes(minutes)
  archivationDate.setSeconds(seconds)

  return new Date() >= archivationDate
}

export const isReservationArchived = reservation => {
  return isReservationArchivedModular({
    end: reservation.end,
    checkOutTimeObject: parseCheckTime(reservation.check_out_time),
  })
}
