import React, { useEffect, useState, useCallback } from 'react'
import { containerReady } from './container-ready'
import nanoid from 'nanoid'
import { globals } from './globals'

/**
 * @callback ResolveCallback
 *
 * @param {String} action
 * @param {Object<string,*>} [params]
 */

/**
 * @typedef RenderData
 *
 * @property {Boolean} isOpen
 * @property {ResolveCallback} resolve
 * @property {() => void} close
 */

/**
 * @callback RenderFunction
 *
 * @param {RenderData} renderData
 */

/**
 * @typedef CallModalResponse
 *
 * @property {String} action
 * @property {Object<string,*>} [params]
 */

/**
 * @param {RenderFunction} renderFunction
 *
 * @returns {Promise<CallModalResponse>}
 */
export async function callModal(renderFunction, ownProps) {
  await containerReady

  let locals = {}

  let resolveResult

  const result = new Promise(resolve => {
    resolveResult = resolve
  })

  const key = nanoid()

  function resolve(action, params = {}) {
    resolveResult({
      action,
      params,
    })

    locals.closeModal()
    setTimeout(() => globals.removeModal(locals.modal), 2000)
  }

  const Modal = () => {
    const [isOpen, setOpen] = useState(false)

    const openModal = useCallback(() => {
      setOpen(true)
    }, [])

    const closeModal = useCallback(() => {
      setOpen(false)
    }, [])

    useEffect(() => {
      locals.openModal = openModal
      locals.closeModal = closeModal

      openModal()
    }, [openModal, closeModal])

    return renderFunction({
      isOpen,
      resolve,
      close: () => resolve('close'),
      ownProps,
    })
  }

  const modal = <Modal key={key} />
  locals.modal = modal

  globals.appendModal(modal)

  return result
}
