import { createSelector } from 'reselect'

export const everything = state => state.otasList

export const items = state => everything(state).items


export const mapLogoByName= createSelector(items, rooms =>
  rooms.reduce((acc, room) => {
    acc[room.name] = room.logo
    return acc
  }, {})
)

export const isLoading = state => everything(state).isLoading
