const getHour = timeObject => {
  const { hours, minutes, seconds } = timeObject
  const hour = hours || 24
  return hour + minutes / 60 + seconds / 3600
}

export const getCheckInMultiplier = timeObject => {
  const fullHour = getHour(timeObject)

  let multiplier = 0
  if (fullHour < 13.75) multiplier = 0.5
  if (fullHour < 8) multiplier = 1

  return multiplier
}

export const getCheckOutMultiplier = timeObject => {
  const fullHour = getHour(timeObject)

  let multiplier = 0
  if (fullHour > 12) multiplier = 0.5
  if (fullHour > 18) multiplier = 1

  return multiplier
}
