import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const ActionsBlock = styled.div`
  margin-top: 15px;
  margin-bottom: -10px;
`

export const ActionButton = ({ onClick, children, type, fluid = false }) => (
  <Button
    onClick={onClick}
    bsStyle={type}
    bsSize="sm"
    style={{
      marginRight: 10,
      marginBottom: 10,
      width: fluid ? '100%' : 'auto',
    }}
  >
    {children}
  </Button>
)
