import * as types from './types'

const initialState = {
    date: null,
    default: {},
    with_check_ins: {},
    isLoading: false,
    isLoaded: false,
    isFailed: false,
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOADING_DATA:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isFailed: false,
                default: {},
                with_check_ins: {},
                ...payload
            }
        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isFailed: false,
                ...payload
            }

        case types.LOAD_DATA_FAILURE:
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: false,
                isFailed: true,
            }

        case types.RESET:
            return initialState

        default:
            return state
    }
}
