import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const RoomNameText = styled.p`
  margin: 0;
  color: #fff;
  font-size: 14px;
`

const RoomNameTooltip = () => (
  <ReactTooltip
    id="room-name-tooltip"
    getContent={data => {
      const parsedData = JSON.parse(data)
      if (!data) return null
      return <RoomNameText>{parsedData.roomName}</RoomNameText>
    }}
    place="right"
  />
)

export default RoomNameTooltip
