import { createSelectorCreator } from 'reselect'

const defaultEqualityCheck = (a, b) => a + b

const multiCacheMemoize = (fn, equalityCheck = defaultEqualityCheck) => {
  const cache = new Map()

  return (...args) => {
    const composedArgs = JSON.stringify(args)

    const memoizedResult = cache.get(composedArgs)
    if (memoizedResult) return memoizedResult

    if (cache.size > 3) {
      const firstKey = cache.keys().next().value
      cache.delete(firstKey)
    }

    const newResult = fn.apply(null, args)
    cache.set(composedArgs, newResult)
    return newResult
  }
}

export const createMCSelector = createSelectorCreator(multiCacheMemoize)
