import * as types from './types'
import { ccAPI } from '../../../../Modules/api/ccAPI'

const loadRequest = () => ({
  type: types.LOAD_REQUEST,
})

const loadSuccess = ({ items }) => ({
  type: types.LOAD_SUCCESS,
  payload: { items },
})

const loadFailure = () => ({
  type: types.LOAD_FAILURE,
})

export const load = () => async dispatch => {
  dispatch(loadRequest())

  try {
    const items = await ccAPI.getFiles()

    dispatch(loadSuccess({ items }))
  } catch (err) {
    dispatch(loadFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
