import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/ru'
import { ItemPrice } from '../../../common/ItemPrice'
import Select from '../../../common/Select'
import { day as formattedDay } from '../../../../Modules/formatters/date'

moment.locale('ru')

const StyledCol = props => {
  return (
    <Col {...props} style={{ fontSize: 18, marginBottom: 15 }}>
      {props.children}
    </Col>
  )
}

export const PayedDayEditable = ({
                                   day,
                                   method,
                                   paymentTypeList,
                                   ontPayedDayMethodChange,
                                   validationErrors,
                                 }) => {
  return (
    <Grid fluid={true}>
      <Row>
        <StyledCol xs={3}>{formattedDay(day.date)}</StyledCol>
        <StyledCol xs={3}>
          <ItemPrice item={day} />
        </StyledCol>
        <Col xs={3}>
          <Select
            onChange={(val) => ontPayedDayMethodChange(day.id, val)}
            value={method}
            validationState={
              validationErrors[day.id].payment_type ? 'error' : null
            }
            values={paymentTypeList.map(p => ({ value: p, text: p }))}
            name="Метод оплаты"
          />
        </Col>
      </Row>
    </Grid>
  )
}
