import styled from 'styled-components'
import { Button, ButtonGroup } from 'react-bootstrap'

const BottomBarButtons = styled.div`
  max-width: calc(100% - 500px);
  margin-bottom: -16px;
  text-align: right;
`

export const BarButton = styled(Button)`
  margin-right: 16px;
  margin-bottom: 16px;
`

export const BarButtonGroup = styled(ButtonGroup)`
  margin-right: 16px;
  margin-bottom: 16px;
`

export default BottomBarButtons
