import React from 'react'
import styled from 'styled-components'
import { BugReportButton } from './BugReport'
import { SuggestFeatureButton } from './SuggestFeature'
import { DebugButton } from './DebugMode'
import { useSelector } from 'react-redux'
import { settingsSelectors } from '../../Modules/settings'
import { NewsButton } from './News'

const SpecialButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 64px;
  max-width: 136px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  z-index: 200;
`

export const SpecialButtons = () => {
  const isDebug = useSelector(settingsSelectors.isDebugMenuEnabled)
  return (
    <SpecialButtonsContainer>
      <NewsButton/>
      {isDebug && <DebugButton />}
      <SuggestFeatureButton />
      <BugReportButton />
    </SpecialButtonsContainer>
  )
}
