import React from 'react'
import { FormGroup, ControlLabel } from 'react-bootstrap'

const Rooms = props => {
  return (
    <FormGroup controlId="formBasicText">
      <ControlLabel style={{ letterSpacing: 0.5 }}>
        {props.rooms.length > 1 ? 'Комнаты' : 'Комната'}
      </ControlLabel>
      <table>
        <tbody>
          {props.rooms.map(r => (
            <tr style={{ marginBottom: 10 }} key={r.id}>
              <th
                style={{
                  fontSize: 20,
                  fontWeight: 300,
                  paddingLeft: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{r.id} </span>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 300,
                      marginLeft: 8,
                    }}
                  >
                    ({r.name})
                  </span>
                </div>
              </th>
              <td style={{ paddingLeft: 12, paddingTop: 5, color: '#777' }}>
                {r.date ? `с ${r.date}` : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FormGroup>
  )
}

export default Rooms
