export const LOAD_DATA_REQUEST = 'bedclothesChange/LOAD_DATA_REQUEST'
export const LOAD_DATA_SUCCESS = 'bedclothesChange/LOAD_DATA_SUCCESS'
export const LOAD_DATA_FAILURE = 'bedclothesChange/LOAD_DATA_FAILURE'

export const LOAD_PRINT_DATA_REQUEST =
  'bedclothesChange/LOAD_PRINT_DATA_REQUEST'
export const LOAD_PRINT_DATA_SUCCESS =
  'bedclothesChange/LOAD_PRINT_DATA_SUCCESS'

export const RESET = 'bedclothesChange/RESET'
