import React, { Component } from 'react'
import moment from 'moment'
import { Form, Button, FormGroup, ControlLabel } from 'react-bootstrap'
import TextField from '../common/Textfield'
import history from '../../Modules/helpers/history'
import LoadingModal from '../common/LoadingModal'
import { SingleDatePicker } from '../common/DatePicker'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { SCALE_LIST } from '../../Constants/scale-list'

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 16px;
`

const SettingsBlock = props => (
  <div
    style={{
      backgroundColor: '#eee',
      width: 500,
      margin: '50px auto',
      padding: 30,
      borderRadius: 5,
    }}
  >
    {props.children}
  </div>
)
const scaleList = SCALE_LIST

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      daysInFuture: '',
      daysInPast: '',
      isCustomDate: Boolean(props.gridDate),
      customGridDate: props.gridDate || moment().format('YYYY-MM-DD'),
      scale: null,
    }
  }

  componentDidMount = () => {
    this.syncPropsWithState()
  }

  componentDidUpdate = prevProps => {
    if (prevProps === this.props) return
    this.syncPropsWithState()
  }

  syncPropsWithState = () => {
    const { daysInFuture, daysInPast, gridDate, gridScale } = this.props

    this.setState(prev => ({
      ...prev,
      daysInFuture,
      daysInPast,
      scale: scaleList.find(it => it.key === gridScale)
    }))

    if (gridDate)
      this.setState(prev => ({
        ...prev,
        customGridDate: gridDate,
      }))
  }

  changeDaysInFuture = daysInFuture => this.setState({ daysInFuture })
  changeDaysInPast = daysInPast => this.setState({ daysInPast })
  changeGridDate = customGridDate => this.setState({ customGridDate })

  resetGridDate = () => {
    const { resetGridDate } = this.props
    resetGridDate()

    this.setState({
      isCustomDate: false,
      customGridDate: moment().format('YYYY-MM-DD'),
    })
  }

  handleSubmit = async e => {
    e.preventDefault()

    await this.props.updateSettings(this.state.daysInFuture, this.state.daysInPast, this.state.scale.key)

    if (this.state.isCustomDate) {
      this.props.setGridDate(this.state.customGridDate)
    } else {
      this.props.resetGridDate()
    }

    history.push('/')
  }

  render() {
    if (this.props.isLoading) return <LoadingModal isOpen={true} />

    return (
      <SettingsBlock>
        <Form onSubmit={this.handleSubmit}>
          <TextField
            name="Дней в будущее в сетке"
            value={this.state.daysInFuture}
            onChange={this.changeDaysInFuture}
            style={{ marginBottom: 15 }}
          />
          <TextField
            name="Дней в прошлое в сетке"
            value={this.state.daysInPast}
            onChange={this.changeDaysInPast}
            style={{ marginBottom: 15 }}
          />
          <FormGroup>
            <ControlLabel>Масштаб</ControlLabel>
            <ReactSelect
              placeholder="Выберите масштаб"
              value={this.state.scale}
              onChange={val => {
                this.setState({ scale: val })
              }}
              options={scaleList}
              menuPlacement="top"
            />
          </FormGroup>
          <StyledLabel>
            <input
              type="checkbox"
              checked={this.state.isCustomDate}
              onChange={() => {
                const current = this.state.isCustomDate
                const next = !current

                this.setState({
                  isCustomDate: next,
                })
              }}
            />{' '}
            указать свою дату сетки
          </StyledLabel>
          {this.state.isCustomDate && (
            <SingleDatePicker
              label="Текущая дата сетки"
              value={this.state.customGridDate}
              onChange={this.changeGridDate}
            />
          )}
          <Button bsStyle="success" type="submit" style={{ width: '100%' }}>
            Сохранить
          </Button>
        </Form>
      </SettingsBlock>
    )
  }
}

export default Settings
