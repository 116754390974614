import React from 'react'
import styled from 'styled-components'
import { LogsAPI } from '../../../../../Modules/logs-api'
import { callModal, modalTemplates } from '../../../../dynamic-modal'

const Block = styled.div`
  margin-bottom: 15px;
`

const OptionButtonBlock = styled.div`
  margin-bottom: 8px;
`

const InlineOptionButton = styled.span`
  font-size: 12px;
  color: #337ab7;
  border-bottom: 1px dashed;
  cursor: pointer;
`

const OptionButton = ({ children, ...rest }) => {
  return (
    <OptionButtonBlock>
      <InlineOptionButton {...rest}>{children}</InlineOptionButton>
    </OptionButtonBlock>
  )
}

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const StyledInput = styled.input`
  width: 100%;
  margin-right: 10px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid #ccc;
`

const StyledSubmitButton = styled.input.attrs(() => ({
  type: 'submit',
  value: 'Рассчитать',
}))`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #54ad54;
  border: 1px solid #4cae4c;
  letter-spacing: 0.3px;

  &:disabled {
    opacity: 0.5;
  }
`

const ErrorText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #d02f4f;
`

export class FormView extends React.Component {
  state = {
    isCustomFormOpen: false,
    usedBonuses: '0',
    parsedUsedBonuses: 0,
    isValid: true,
  }

  showCustomForm = () => this.setState({ isCustomFormOpen: true })
  hideCustomForm = () => this.setState({ isCustomFormOpen: false })

  toggleCustomForm = () => {
    const { isCustomFormOpen } = this.state
    const action = isCustomFormOpen ? 'Закрыть' : 'Открыть'

    LogsAPI.pressAction(`${action} форму частичного применения бонусов`)

    this.setState({ isCustomFormOpen: !isCustomFormOpen })
  }

  showApplyForPaidItemsModal = () => {
    callModal(
      modalTemplates.alert({
        title: 'Продолжение',
        text:
          'Чтобы начислить бонусы на уже оплаченные позиции, ' +
          'сохраните бронь и нажмите кнопку «Начислить бонусы за оплаченные позиции».',
      })
    )
  }

  re = /^\d{0,}$/

  handleChange = value => {
    return new Promise(resolve => {
      if (!this.re.test(value)) {
        return resolve()
      }

      const { clientBonuses } = this.props

      const nextParsedUsedBonuses = Number(value)
      const nextIsValid = nextParsedUsedBonuses <= clientBonuses

      this.setState(
        {
          usedBonuses: value,
          parsedUsedBonuses: nextParsedUsedBonuses,
          isValid: nextIsValid,
        },
        resolve
      )
    })
  }

  handleSubmit = () => {
    const { parsedUsedBonuses, isValid } = this.state

    if (!isValid) return

    const { applyCustom } = this.props.loyaltyPurchaseActions

    applyCustom(parsedUsedBonuses)
  }

  handleChangeEvent = e => {
    LogsAPI.editAction('Бонусы к применению', e.target.value)

    this.handleChange(e.target.value)
  }

  handleSubmitEvent = e => {
    e.preventDefault()

    LogsAPI.pressAction('Рассчитать бонусы по данным из формы')

    this.handleSubmit()
  }

  handleApply0Click = () => {
    const { areTherePaidItemsWithoutRefundId } = this.props

    if (areTherePaidItemsWithoutRefundId) {
      callModal(
        modalTemplates.alert({
          title: 'Продолжение',
          text: [
            'В данной брони нельзя начислять бонусы,' +
              ' так как есть оплаченные позиции, за которые еще не были начислены бонусы.',
            'Для начала сохраните бронь и нажмите кнопку «Начислить бонусы за оплаченные позиции».',
          ],
        })
      )

      return
    }

    LogsAPI.pressAction(
      'Рассчитать сколько будет начислено бонусов (применить 0)'
    )

    const { apply0 } = this.props.loyaltyPurchaseActions

    apply0()
  }

  handleApplyAllClick = () => {
    LogsAPI.pressAction('Использовать все бонусы')

    const { applyAll } = this.props.loyaltyPurchaseActions

    applyAll()
  }

  render() {
    const { isCustomFormOpen, usedBonuses, isValid } = this.state
    const {
      clientBonuses,
      canApplyBonusesAutomatically,
      loyaltyAvailableActions,
    } = this.props

    return (
      <Block>
        {loyaltyAvailableActions.receive && (
          <OptionButton onClick={this.handleApply0Click}>
            {canApplyBonusesAutomatically
              ? 'Посмотреть сколько будет начислено бонусов'
              : 'Начислить бонусы'}
          </OptionButton>
        )}
        {loyaltyAvailableActions.receiveForPaid && (
          <OptionButton onClick={this.showApplyForPaidItemsModal}>
            Начислить бонусы на оплаченные позиции
          </OptionButton>
        )}
        {loyaltyAvailableActions.spend && (
          <>
            <OptionButton onClick={this.handleApplyAllClick}>
              Использовать все бонусы
            </OptionButton>
            <OptionButton onClick={this.toggleCustomForm}>
              Использовать бонусы частично
            </OptionButton>
          </>
        )}
        {isCustomFormOpen && (
          <>
            <StyledForm onSubmit={this.handleSubmitEvent}>
              <StyledInput
                value={usedBonuses}
                onChange={this.handleChangeEvent}
              />
              <StyledSubmitButton disabled={!isValid} />
            </StyledForm>
            {!isValid && (
              <ErrorText>
                Клиенту доступно только {clientBonuses} бонусов.
              </ErrorText>
            )}
          </>
        )}
      </Block>
    )
  }
}
