import React from 'react'
import styled from 'styled-components'

const Block = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
`

export const LoadingScreen = () => {
  return (
    <Block>
      <div className="loading-spinner">
        <div className="cube1" />
        <div className="cube2" />
      </div>
    </Block>
  )
}
