import paymentTypes from '../../../../helpers/paymentTypes'

export const getMeta = items => {
  let isCardUsed = false

  const { days, services } = items

  if (
    days.some(day => day.payment_type === paymentTypes.card) ||
    services.some(service => service.payment_type === paymentTypes.card)
  ) {
    isCardUsed = true
  }

  return {
    isCardUsed,
  }
}
