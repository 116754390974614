import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Upgrades } from './Upgrades'
import { reservationSelectors } from '../../../Modules/reservation'
import { upgradeService } from '../../../Modules/helpers/servicesNames'

import {
  createService,
  updateServicePrice,
  updateServicePaymentType,
  copyService,
  deleteService,
  refundService,
} from '../../../Modules/reservation/actions/services'

const mSTP = (state, ownProps) => ({
  items: reservationSelectors.upgradeServices(state),
  availableDates: reservationSelectors.upgradeableDaysDates(state),
  validationErrors: reservationSelectors.servicesValidationErrors(state),
  ...ownProps,
})

const mDTP = (dispatch, ownProps) => ({
  actions: {
    create: ({ date, price, paymentType }) =>
      dispatch(
        createService({
          serviceType: 'upgrade',
          name: upgradeService,
          price: Number(price),
          quantity: 1,
          paymentType,
          date,
        })
      ),

    setPrice: bindActionCreators(updateServicePrice, dispatch),
    setPaymentType: bindActionCreators(updateServicePaymentType, dispatch),

    copy: ({ id, newDate }) =>
      dispatch(
        copyService({
          id,
          overrides: {
            date: newDate,
          },
        })
      ),

    delete: bindActionCreators(deleteService, dispatch),
    refund: bindActionCreators(refundService, dispatch),
  },
})

export const ConnectedUpgrades = connect(mSTP, mDTP)(Upgrades)
