import store from '../../../store'
import { buildCalculationQuery } from '../../../Modules/loyalty-purchase/helpers/build-calculation-query'
import nanoid from 'nanoid'
import moment from 'moment'
import { LoyaltyError } from '../../../Modules/api/loyaltyAPI/helpers/LoyaltyError'
import { callModal, modalTemplates } from '../../dynamic-modal'
import uuid from 'uuid/v4'
import { loyaltyAPI } from '../../../Modules/api/loyaltyAPI'

export async function applyBonuses({ phoneNumber, paymentAmount }) {
  const balanceRes = await loyaltyAPI.getBalance({ phoneNumber })

  if (balanceRes.errorCode) {
    return
  }

  const state = store.getState()

  const calculationQuery = buildCalculationQuery({
    phoneNumber,
    state,
    days: [],
    services: [
      {
        id: nanoid(),
        service: 'Услуги проживания',
        price: paymentAmount,
        quantity: 1,
        date: moment().format('YYYY-MM-DD'),
      },
    ],
    applyBonuses: 0,
  })

  const txid = uuid()

  const loyaltyActions = {
    set() {
      return loyaltyAPI.setPurchase({
        txid,
        calculationQuery,
      })
    },
    confirm(ticket) {
      return loyaltyAPI.confirmTicket({
        txid,
        ticket,
      })
    },
    discard(ticket) {
      return loyaltyAPI.discardTicket({
        txid,
        ticket,
      })
    },
  }

  try {
    const setRes = await loyaltyActions.set()

    if (setRes.errorCode) {
      throw new LoyaltyError(setRes.errorCode)
    }

    const { ticket } = setRes

    const confirmRes = await loyaltyActions.confirm(ticket)

    if (confirmRes.errorCode) {
      throw new LoyaltyError(confirmRes.errorCode)
    }
  } catch (error) {
    callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: `Не удалось применить бонусы: ${error.message}`,
      })
    )
  }
}
