import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import {useActions} from "../common/hooks/use-actions";
import {useSelector} from "react-redux";
import {sessionSelectors} from "../../Modules/session";
import CheckBoxComponent from "../common/Checkbox";
import {shiftsActions, shiftsSelectors} from "../../Modules/shifts";
import TextField from "../common/TextArea";
import {Button} from "../buttons";
import history from "../../Modules/helpers/history";


const Error = styled.div`
    font-size: 12px;
    color: #d61b3e;
    text-align: ${props => props.left ? 'left' : 'center'};
`
const ContentBlock = styled.div`
    width: 100%;
    max-width: 890px;
    margin: 50px auto;
    padding: 30px;
    border-radius: 5px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h3 {
        text-align: center;
    }

    h4 {
        color: #12ab45;
        text-align: center;
        margin: 0;
    }
`
const Flex = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
`


export const CheckList = () => {
    const isCloseShift = history.location?.state?.close
    const {openShiftIfNotExists} = useActions(shiftsActions)

    const lcode = useSelector(sessionSelectors.lcode)
    const work_shift_id = useSelector(shiftsSelectors.shiftId)


    useEffect(() => {
        if (!work_shift_id)
            openShiftIfNotExists(lcode)
    }, []);

    if (work_shift_id) {
        if (isCloseShift) return <CheckListOut lcode={lcode} work_shift_id={work_shift_id}/>
        return <CheckListIn lcode={lcode} work_shift_id={work_shift_id}/>
    }
    return <div/>
}


export const CheckListIn = ({lcode, work_shift_id}) => {

    const {setCheckListOpen, getOpenShiftCheckList, setReviewCheckList, getReviewCheckList} = useActions(shiftsActions)

    const items = useSelector(shiftsSelectors.openItems)
    const itemsObj = useSelector(shiftsSelectors.openItemsObject)
    const isShiftOpened = useSelector(shiftsSelectors.isShiftOpened)
    const isChecklistsNotCompleted = useSelector(shiftsSelectors.isChecklistsNotCompleted)
    const reviewItemsObject = useSelector(shiftsSelectors.reviewItemsObject)
    const reviewItems = useSelector(shiftsSelectors.reviewItems)
    const reviewShiftId = useSelector(shiftsSelectors.reviewShiftId)
    const isFailed = useSelector(shiftsSelectors.isFailed)

    const [currentItems, setCurrentItems] = useState({})
    const [currentReviewItems, setCurrentReviewItems] = useState({})
    const [reviewed, setReviewed] = useState(false)

    useEffect(() => {
        if (!items.length) {
            getOpenShiftCheckList(lcode)
        }
        if (!reviewItems.length) {
            getReviewCheckList(lcode)
        }
    }, []);

    useEffect(() => {
        setCurrentItems(itemsObj)
    }, [itemsObj]);

    useEffect(() => {
        setCurrentReviewItems(reviewItemsObject)
    }, [reviewItemsObject]);

    const onChange = (id, field, value) => {
        const it = currentItems[id]
        it[field] = value
        setCurrentItems({...currentItems, [id]: it})
    }

    const onChangeReview = (id, field, value) => {
        const it = currentReviewItems[id]
        it[field] = value
        setCurrentReviewItems({...currentReviewItems, [id]: it})
    }

    const send = async () => {
        const data = {
            work_shift_id,
            check_list_items: Object.values(currentItems).map(({item, ...other}) => other)
        }
        await setCheckListOpen({data, lcode})

    }

    const sendReview = async () => {
        const data = {
            work_shift_id: reviewShiftId,
            check_list_items: Object.values(currentReviewItems).map(({item, ...other}) => other)
        }
        await setReviewCheckList({data, lcode})
        setReviewed(true)
    }

    return (
        <>
            <ContentBlock>
                <h3>Открытие смены</h3>
                {isShiftOpened && <h4>Смена открыта</h4>}
                {Object.values(currentItems).map(it =>
                    <Item key={it.id} item={it} disabled={isChecklistsNotCompleted} failed={isFailed}
                          onChange={onChange}/>
                )}
                <Button size="small" disabled={isChecklistsNotCompleted} onClick={send}>Отправить</Button>
                {isFailed && <Error>Что-то пошло не так.</Error>}
            </ContentBlock>
            {reviewItems.length ? <ContentBlock>
                <h3>Ревью предыдущей смены</h3>
                {Object.values(reviewItems).map(it =>
                    <ReviewItem key={it.id} item={it} disabled={reviewed} onChange={onChangeReview}/>
                )}
                <Button size="small" disabled={reviewed} onClick={sendReview}>Отправить</Button>
            </ContentBlock> : <div/>}
        </>
    )
}


export const CheckListOut = ({lcode, work_shift_id}) => {

    const {closeShift, getCloseShiftCheckList} = useActions(shiftsActions)

    const items = useSelector(shiftsSelectors.closeItems)
    const itemsObj = useSelector(shiftsSelectors.closeItemsObject)

    const isFailed = useSelector(shiftsSelectors.isFailed)

    const [currentItems, setCurrentItems] = useState({})

    useEffect(() => {
        if (!items.length)
            getCloseShiftCheckList(lcode)
    }, [lcode]);

    useEffect(() => {
        setCurrentItems(itemsObj)
    }, [itemsObj]);

    const onChange = (id, field, value) => {
        const it = currentItems[id]
        it[field] = value
        setCurrentItems({...currentItems, [id]: it})
    }

    const onClose = async () => {
        const data = {
            work_shift_id,
            check_list_items: Object.values(currentItems).map(({item, ...other}) => other)
        }
        await closeShift({data, lcode})
    }

    return (
        <ContentBlock>
            <h3>Закрытие смены</h3>
            {Object.values(currentItems).map(it =>
                <Item key={it.id} item={it} onChange={onChange} failed={isFailed}/>
            )}
            <Button size="small" onClick={onClose}>Отправить</Button>
            {isFailed && <Error>Что-то пошло не так.</Error>}
        </ContentBlock>
    )
}

const ReviewItem = ({item, disabled, onChange}) => {
    return (
        <div>
            <Flex>
                <CheckBoxComponent
                    value={item.state}
                    title=""
                    disabled={true}
                    onChange={(val) => onChange(item.id, "state", val)}
                />
                <CheckBoxComponent
                    value={item.is_accepted}
                    title={item.item.title}
                    disabled={disabled}
                    onChange={(val) => onChange(item.id, "is_accepted", val)}
                />
            </Flex>
            <TextField
                name="Комментарий"
                style={{paddingBottom: 20}}
                value={item.comment || ""}
                disabled={disabled}
                onChange={(val) => onChange(item.id, "comment", val)}/>
        </div>
    )
}

const Item = ({item, disabled, failed, onChange}) => {
    return (
        <div>
            <CheckBoxComponent
                value={item.state}
                title={item.item.title}
                disabled={disabled}
                onChange={(val) => onChange(item.id, "state", val)}
            />
            {item.item.required && !item.state && failed && <Error left>Обязателен к заполнению</Error>}
            <TextField
                name="Комментарий"
                style={{padding: '10px 0'}}
                value={item.comment || ""}
                disabled={disabled}
                onChange={(val) => onChange(item.id, "comment", val)}/>
        </div>
    )
}

