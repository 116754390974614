import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Table, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { fullDate } from '../../Modules/formatters/date'
import debounce from 'lodash.debounce'

const ContentBlock = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
`

const Content = ({ items, loadingState }) => {
  const { isLoading, isLoaded, isFailed } = loadingState
  const [search, setSearch] = useState('')

  const debouncedSearch = useCallback(debounce(setSearch, 500), [setSearch])

  const confirmedRecords = useMemo(() => {
    return items.filter(item => item.is_confirmed)
  }, [items])

  const foundRecords = useMemo(() => {
    const normalizedSearch = search.toLowerCase()

    return confirmedRecords.filter(item => {
      const { name, passport_number } = item

      return (
        name.toLowerCase().includes(normalizedSearch) ||
        passport_number.toLowerCase().includes(normalizedSearch)
      )
    })
  }, [search, confirmedRecords])

  if (isLoading) {
    return <div>Загрузка..</div>
  }

  if (isFailed) {
    return <div>Произошла ошибка при загрузке данных</div>
  }

  if (!isLoaded) {
    return <div>Нет данных</div>
  }

  return (
    <>
      <FormGroup>
        <ControlLabel>Поиск по имени / номеру паспорта</ControlLabel>
        <FormControl
          placeholder="Введите что-нибудь для поиска"
          defaultValue={search}
          onChange={e => debouncedSearch(e.target.value)}
        />
      </FormGroup>
      <Table
        responsive
        className="table-hover condensed"
        style={{ width: '100%', marginBottom: 0 }}
      >
        <thead>
          <tr>
            <th>Номер паспорта</th>
            <th>Имя</th>
            <th>Причина</th>
            <th>Дата блокировки</th>
            <th>Номер брони</th>
          </tr>
        </thead>
        <tbody>
          {foundRecords.map(item => {
            const {
              pk,
              passport_number,
              name,
              reason,
              created_at,
              booking_number,
            } = item

            return (
              <tr key={pk}>
                <td>{passport_number}</td>
                <td>{name || '-'}</td>
                <td>{reason}</td>
                <td>{fullDate(created_at)}</td>
                <td>{booking_number || '-'}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export const BlacklistView = ({ items, loadingState }) => {
  return (
    <ContentBlock>
      <Content items={items} loadingState={loadingState} />
    </ContentBlock>
  )
}
