import React, { isValidElement } from 'react'
import styled from 'styled-components'

const Paragraph = styled.p`
  &:last-child {
    margin-bottom: 0;
  }
`

export function renderText(text) {
  if (typeof text === 'string') {
    return <Paragraph>{text}</Paragraph>
  }

  if (Array.isArray(text)) {
    return text.map((paragraph, idx) => {
      if (isValidElement(text)) {
        return text
      }

      return <Paragraph key={idx}>{paragraph}</Paragraph>
    })
  }

  if (isValidElement(text)) {
    return text
  }

  return null
}
