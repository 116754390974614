import React, { useEffect, useState } from 'react'
import PayedDay from './PayedDay'
import { useSelector } from 'react-redux'
import { specialServiceTypesSelectors } from '../../../../Modules/special-service-types'
import { paymentTypesList, paymentTypesNoBank } from '../../../../Modules/helpers/paymentTypes'
import { PayedDayEditable } from './PayedDayEditable'

export const PayedDaysList = ({
                                payedDays,
                                isReady,
                                modes,
                                dayRefund,
                                lastID,
                                daysValidationErrors,
                                onChangePaymentType
                              }) => {
  const livingService = useSelector(specialServiceTypesSelectors.livingService)
  const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

  useEffect(() => {
    const list = livingService ? livingService.is_available_for_sale_by_bank
        ? paymentTypesList
        : paymentTypesNoBank
      : paymentTypesList
    setPaymentTypeList(list)
  }, [livingService])

  if (isReady) {
    return (
      <>
        {
          payedDays
            .sort((d1, d2) => new Date(d1.date) - new Date(d2.date))
            .map(day => (
              <PayedDay
                key={day.id}
                day={day}
                price={day.price}
                method={day.payment_type}
                modes={modes}
                dayRefund={dayRefund}
                isLast={day.id === lastID}
              />
            ))
        }</>
    )
  }
  return (
    <>
      {
        payedDays
          .sort((d1, d2) => new Date(d1.date) - new Date(d2.date))
          .map(day => (
            <PayedDayEditable
              key={day.id}
              day={day}
              price={day.price}
              method={day.payment_type}
              paymentTypeList={paymentTypeList}
              ontPayedDayMethodChange={onChangePaymentType}
              validationErrors={daysValidationErrors}
            />
          ))
      }</>
  )
}
