import styled from 'styled-components'

export const InfoLabel = styled.h5`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 6px !important;
`

export const InfoText = styled.p`
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 10px;
  }
`

export const InfoError = styled(InfoText)`
  color: #d02f4f;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const InfoSegment = styled.div`
  width: 50%;
`
