import React from 'react'

import Body from '../../shared/table-common/Body'

import { TableHeadRow } from '../../shared/TableHeadRow'
import { TableDataRow } from '../../shared/TableDataRow'

import TableTitle from '../../shared/TableTitle'
import TableBlock from '../../shared/TableBlock'
import TableStatus from '../../shared/TableStatus'

import {
  getTypeString,
  getTimeString,
  getPaymentTypeString,
  getTotalString,
} from '../../shared/formatters'

import DetailsModal from './components/DetailsModal'

export class ChecksTable extends React.Component {
  state = {
    openedDetailsItemID: null,
  }

  openDetails = id => this.setState({ openedDetailsItemID: id })
  closeDetails = () => this.setState({ openedDetailsItemID: null })

  getMainContent = () => {
    const { checks } = this.props
    const { items, isFailed } = checks

    if (isFailed) return <TableStatus color="pageText">Ошибка</TableStatus>

    if (!items.length)
      return (
        <TableStatus color="pageText">
          Чеки с такими параметрами не найдены
        </TableStatus>
      )

    const rows = items
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .map(item => (
        <TableDataRow
          key={item.id}
          rows={[
            {
              content: getTypeString(item.isRefund),
              color: item.isRefund ? 'veryLightRed' : 'veryLightGreen',
            },
            { content: getTimeString(item.date) },
            { content: item.booking_number, url: '/reservation?pk=' + item.booking_number_uuid },
            { content: item.guest_name },
            { content: item.room ?? '-' },
            { content: getPaymentTypeString(item.payment_type) },
            {
              content: getTotalString.forCheck(item.total),
              textAlign: 'right',
            },
          ]}
          clickAction={() => this.openDetails(item.id)}
        />
      ))

    return (
      <React.Fragment>
        <TableHeadRow
          rows={[
            { content: 'Тип чека' },
            { content: 'Время создания' },
            { content: 'Номер брони' },
            { content: 'Имя гостя' },
            { content: 'Комната' },
            { content: 'Способ оплаты' },
            { content: 'Сумма чека', textAlign: 'right' },
          ]}
        />
        <Body>{rows}</Body>
      </React.Fragment>
    )
  }

  getOutsideContent = () => {
    const { checks } = this.props
    const { items } = checks
    const { openedDetailsItemID } = this.state

    let positions = ''
    if (openedDetailsItemID) {
      positions = items.find(item => item.id === openedDetailsItemID).positions
    }

    return (
      <DetailsModal
        isOpen={!!openedDetailsItemID}
        close={this.closeDetails}
        positions={positions}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        <TableTitle>Чеки</TableTitle>
        <TableBlock>{this.getMainContent()}</TableBlock>

        {this.getOutsideContent()}
      </React.Fragment>
    )
  }
}
