export const LOAD_LOYALTY_CLIENTS_REQUEST =
  'ghotel/reservations-waiting-for-bonuses/LOAD_LOYALTY_CLIENTS_REQUEST'
export const LOAD_LOYALTY_CLIENTS_SUCCESS =
  'ghotel/reservations-waiting-for-bonuses/LOAD_LOYALTY_CLIENTS_SUCCESS'
export const LOAD_LOYALTY_CLIENTS_FAILURE =
  'ghotel/reservations-waiting-for-bonuses/LOAD_LOYALTY_CLIENTS_FAILURE'
export const START_APPLYING =
  'ghotel/reservations-waiting-for-bonuses/START_APPLYING'
export const STOP_APPLYING =
  'ghotel/reservations-waiting-for-bonuses/STOP_APPLYING'

export const RESET = 'ghotel/free-rooms/RESET'
