import { getItemsCheckData } from '../../../lib/get-items-check-data'
import { mergeAndConcat } from 'merge-anything'

export const getCheckData = async ({ basicData, printedItems }) => {
  const { lines, totals, printTotals } = getItemsCheckData(printedItems, {
    serviceNameField: 'name',
  })

  return {
    shouldPrint: totals.cash + totals.card > 0,
    totals,
    wasCanceled: false,
    printObject: mergeAndConcat(
      {
        TypeCheck: 1,
        PayByProcessing: false,
        CheckStrings: lines,
        Cash: printTotals.cash,
        ElectronicPayment: printTotals.card,
      },
      basicData
    ),
  }
}
