import Logs from '../../Logs'

export const logPrintResult = ({ shouldPrint, printResult, reduxStates }) => {
  if (!shouldPrint) return

  const json = JSON.stringify(printResult)

  const { reservation } = reduxStates.current

  const pk = reservation.pk || null
  Logs.logSubmit(pk, `Ответ KKM: ${json}`)
}
