import styled from 'styled-components'
import Button from '../../../../../ui/buttons/Button'

export default styled(Button).attrs(() => ({
  type: 'submit',
}))`
  width: 100%;
  padding: 12px 16px;
  border-radius: 0;
  color: ${props => props.theme.colors.pageText};
  background-color: ${props => props.theme.colors.checksFormButtonBackground};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`
