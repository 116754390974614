import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { registrationFormsSelectors } from '../../Modules/registration-forms'

export const PrintFormButtons = ({
                                   buttonGroupComponent: ButtonGroupComponent = ButtonGroup,
                                   ...rest
                                 }) => {
  const ru = useSelector(registrationFormsSelectors.ruForm)
  const en = useSelector(registrationFormsSelectors.enForm)
  const esp = useSelector(registrationFormsSelectors.espForm)
  const ch = useSelector(registrationFormsSelectors.chForm)

  const fileUrl='/print_reg_form.html'

  return (
    <ButtonGroupComponent {...rest}>
      <Button bsStyle="primary" disabled>
        Печать формы
      </Button>
      <Button onClick={() => {window.open(`${fileUrl}?link=${ru}`,'_blank')}}>RU</Button>
      <Button onClick={() => {window.open(`${fileUrl}?link=${en}`,'_blank')}}>EN</Button>
      <Button onClick={() => {window.open(`${fileUrl}?link=${esp}`,'_blank')}}>ES</Button>
      <Button onClick={() => {window.open(`${fileUrl}?link=${ch}`,'_blank')}}>CN</Button>
    </ButtonGroupComponent>
  )
}
