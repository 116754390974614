import { createSelector } from 'reselect'
import { gridSelectors } from '../grid'
import { currentDateSelectors } from '../../../../Modules/current-date'
import moment from 'moment'
import { serviceLoyaltyAllowabilityMap } from '../../../../Modules/reservation/selectors'

export const everything = state => state.reservationsWaitingForBonuses

const checks = {
  isLoyaltyAllowed: ({ reservation }) => reservation.is_loyalty_allowed,
  matchDate: ({ reservation, currentDate, previousDate }) => {
    const { start } = reservation

    return start === currentDate || start === previousDate
  },
  hasNotAppliedBonuses: ({ reservation, serviceLoyaltyAllowabilityMap }) => {
    const { reserved_days, additional_services } = reservation

    const daysWithoutRefundId = reserved_days
      .filter(day => day.payment_date)
      .filter(day => !day.refund_id)

    const servicesWithoutRefundId = additional_services
      .filter(service => !service.refund_id)
      .filter(service => {
        return serviceLoyaltyAllowabilityMap[service.service]
      })

    return daysWithoutRefundId.length > 0 || servicesWithoutRefundId.length > 0
  },
  hasPhoneNumber: ({ reservation }) => {
    const { guest_phone } = reservation
    return Boolean(guest_phone)
  },
}

export const prematchingReservations = createSelector(
  gridSelectors.reservations,
  currentDateSelectors.today,
  serviceLoyaltyAllowabilityMap,
  (reservations, currentDate, serviceLoyaltyAllowabilityMap) => {
    const previousDate = moment(currentDate)
      .subtract(1, 'day')
      .format('YYYY-MM-DD')

    return reservations.filter(reservation => {
      return (
        checks.isLoyaltyAllowed({ reservation }) &&
        checks.hasPhoneNumber({ reservation }) &&
        checks.matchDate({ reservation, currentDate, previousDate }) &&
        checks.hasNotAppliedBonuses({
          reservation,
          serviceLoyaltyAllowabilityMap,
        })
      )
    })
  }
)

export const isApplying = state => everything(state).isApplying

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed
export const errorMessage = state => everything(state).errorMessage

export const loadState = createSelector(
  isLoading,
  isLoaded,
  isFailed,
  errorMessage,
  (isLoading, isLoaded, isFailed, errorMessage) => ({
    isLoading,
    isLoaded,
    isFailed,
    errorMessage,
  })
)

export const loyaltyClientsPhones = state =>
  everything(state).loyaltyClientsPhones

export const loyaltyClientsPhonesMap = createSelector(
  loyaltyClientsPhones,
  loyaltyClientsPhones =>
    loyaltyClientsPhones.reduce((acc, clientPhone) => {
      acc[clientPhone] = true
      return acc
    }, {})
)

export const matchingReservations = createSelector(
  prematchingReservations,
  loyaltyClientsPhonesMap,
  (prematchingReservations, loyaltyClientsPhonesMap) => {
    return prematchingReservations.filter(reservation => {
      const { guest_phone, isReady } = reservation
        return isReady && loyaltyClientsPhonesMap[guest_phone]
    })
  }
)
