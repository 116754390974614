function processInitialState(initialState) {
  if (typeof initialState === 'function') {
    return initialState()
  }

  return initialState
}

export function createReducer(initialState, actionReducers) {
  return (state = processInitialState(initialState), action) => {
    const actionReducer = actionReducers[action.type]

    if (!actionReducer) {
      return state
    }

    return actionReducer(state, action.payload)
  }
}
