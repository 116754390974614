import mem from 'mem'
import moment from 'moment'

const formatters = {
  fullDate: new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }),
  day: new Intl.DateTimeFormat('ru-RU', {
    day: 'numeric',
    month: 'long',
  }),
}

// prettier-ignore
export const day = mem(
  date => formatters.day.format(new Date(date)),
  { maxAge: 60000 }
)

// prettier-ignore
export const fullDate = mem(
  str => formatters.fullDate.format(moment(str)),
  { maxAge: 60000 }
)
